import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import { Can, ReactTable } from "components";
import { useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import "modules/DealerDocumentations/components/DealerDocumentationTable/DealerDocumentationTable.scss";
import { ITranslation } from "util/interfaces";

type DealersDocumentationTableProps = {
  dealersDocumentation: DealerDocumentation[] | undefined;
  locationsNames: string[];
  dealersNames: string[];
  onSetDealerDocumentation: (documentation: DealerDocumentation | null) => void;
  onHandleDocumentation: (documentation: DealerDocumentation | null) => void;
};

export const DealersDocumentationTable = ({
  dealersDocumentation,
  locationsNames,
  dealersNames,
  onSetDealerDocumentation,
  onHandleDocumentation
}: DealersDocumentationTableProps) => {
  if (!dealersDocumentation) return null;

  const t = useTranslation().t as ITranslation;
  const columnHelper = createColumnHelper<DealerDocumentation>();
  const columns = [
    columnHelper.accessor(row => row, {
      id: "title",
      header: t("v8_title").message || "Title",
      cell: ({ cell }) => {
        const { getValue } = cell;
        const { title, is_pinned } = getValue();
        return (
          <>
            <Icon className={is_pinned ? "pin" : "circle regular"} /> {title}
          </>
        );
      },
      size: 200
    }),
    columnHelper.accessor(row => row, {
      id: "dealer",
      header: t("v8_dealer").message || "Dealer",
      cell: () => dealersNames.join(", "),
      size: 150
    }),
    columnHelper.accessor(row => row, {
      id: "locations",
      header: t("v8_locations").message || "Locations",
      cell: () => locationsNames.join(", "),
      size: 150
    }),
    columnHelper.accessor(row => row.publish_date, {
      id: "publish_date",
      header: t("v8_publish_date").message || "Publish Date",
      cell: ({ renderValue }) => <span> {moment(renderValue()).format("DD-MM-YYYY")}</span>,
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "category",
      header: t("v8_category").message || "Category",
      cell: ({ getValue }) => {
        const { category_id } = getValue();
        return <Label className="doc-table-category"> {DOCUMENTATION_CATEGORIES[category_id]} </Label>;
      },
      size: 80
    }),
    columnHelper.accessor(row => row, {
      id: "published_by",
      header: t("v8_published_by").message || "Published By",
      cell: ({ getValue }) => {
        const { updated_by, created_by } = getValue();
        const publishedBy = updated_by ? `${updated_by.first_name} ${updated_by.last_name}` : `${created_by?.first_name} ${created_by?.last_name}`;
        return <span> {publishedBy} </span>;
      },
      size: 100
    }),

    columnHelper.accessor(row => row, {
      id: "edit",
      header: "",
      cell: ({ getValue }) => {
        const data = getValue();
        const user = useUser();

        const canEdit = user?.id === data.created_by_id;

        if (!canEdit) return null;

        return (
          <Can I="update" the="dealer-documentation">
            <div className="edit-button-container">
              <div
                className="edit-button"
                onClick={e => {
                  e.stopPropagation();
                  onSetDealerDocumentation(data);
                }}
              >
                <Icon className="pen to square edit-icon" />
              </div>
            </div>
          </Can>
        );
      },
      size: 50
    })
  ];

  return (
    <div className="DealerDocumentationTable">
      <ReactTable columns={columns} data={dealersDocumentation} onRowClick={onHandleDocumentation} />
    </div>
  );
};

import { useState } from "react";

import { DOCUMENTATION_CATEGORIES } from "models";
import { ITranslation } from "util/interfaces";

export const useDealerCategories = (t: ITranslation) => {
  const [categoryId, setCategoryId] = useState<DOCUMENTATION_CATEGORIES | undefined>(DOCUMENTATION_CATEGORIES.Manual);

  const numericCategoryValues = Object.values(DOCUMENTATION_CATEGORIES).filter(value => typeof value === "number");
  const categoryOptions = numericCategoryValues.map(key => ({
    key,
    text: t(`v8_${DOCUMENTATION_CATEGORIES[key as number].toLowerCase()}`).message || DOCUMENTATION_CATEGORIES[key as number],
    value: key
  }));
  const getCategoryName = (id: number) => categoryOptions.find(category => category.key === id)?.text;

  return { categoryId, setCategoryId, categoryOptions, getCategoryName };
};

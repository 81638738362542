import { Icon } from "semantic-ui-react";

import "modules/AppointmentDetail/components/StatusIcon/StatusIcon.scss";

type StatusIconProps = {
  name: string;
  active?: boolean | "" | null;
  disabled?: boolean;
  onClick?: () => void;
};

export const StatusIcon = ({ name, active, disabled, onClick }: StatusIconProps) => {
  return (
    <div
      className={`StatusIcon ${disabled ? "disabled" : ""} ${active ? "active" : ""}`}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      <Icon className={name} />
    </div>
  );
};

/* eslint-disable no-empty */
import { InvalidateQueryFilters, UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { WebSocketComponent } from "components";
import ENV from "config/Env";
import { useDealersLocations, useUpdate, useUser } from "hooks";
import { Auth, DealerLocation, LANDING_PAGE_ID, User } from "models";
import Service from "modules/Auth/service";
import { PATHS } from "router/paths";
import apiInstance from "util/Api";
import { currentDate, getPreference, setPreference } from "util/common";
import { QUERY_KEY_COMPONENTS, queryKeys, staticLocalKey } from "util/keyFactory";

type Credentials = {
  username: string;
  password: string;
};

type ResetPasswordRequest = {
  email: string;
  language_code: string;
};

type ChangePassword = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

type ResetPassword = {
  new_password: string;
  new_password_confirm: string;
  hash: string;
};

type GoogleLogin = {
  id_token: string;
};

type MicrosoftLogin = {
  id_token: string;
};

type UseAuthType = {
  loginWithCredentials: UseMutationResult<Auth, Error, Credentials, unknown>;
  loginWithGoogle: UseMutationResult<Auth, Error, GoogleLogin, unknown>;
  loginWithMicrosoft: UseMutationResult<Auth, Error, MicrosoftLogin, unknown>;
  resetPasswordMutation: UseMutationResult<Auth, Error, ResetPassword, unknown>;
  changePasswordMutation: UseMutationResult<Auth, Error, ChangePassword, unknown>;
  updateUserAccountMutation: UseMutationResult<any, Error, Partial<User>, unknown>;
  resetPasswordRequestMutation: UseMutationResult<Auth, Error, ResetPasswordRequest, unknown>;
  logout: () => void;
  getNotificationElements: () => Promise<NotificationElement[]>;
  data: Auth | undefined;
};

export type NotificationElement = {
  notification_element_id: number;
  notification_entity_id: number;
  notification_field_id: number;
  entity: {
    notification_entity_id: number;
    name: string;
    template_id: number;
  };
  field: {
    notification_field_id: number;
    name: string;
    value: string;
  };
};

const LandingPageMapping = new Map([
  [LANDING_PAGE_ID.Main, PATHS.APPOINTMENTS],
  [LANDING_PAGE_ID.LeadDashboard, PATHS.LEADS_DASHBOARD],
  [LANDING_PAGE_ID.WarrantyDashboard, PATHS.WARRANTY_DASHBOARD],
  [LANDING_PAGE_ID.ManufacturerDashboard, PATHS.MANUFACTURER_DASHBOARD],
  [LANDING_PAGE_ID.Dayplanner, PATHS.DAY_PLANNER]
]);

export const useAuth = (): UseAuthType => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();
  const user = useUser();
  const { selectedLocation }: { selectedLocation: DealerLocation | undefined } = useDealersLocations();

  const authInfo: Auth | undefined = queryClient.getQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo));

  const changeOldPassword = async (requestData: ChangePassword) => {
    const data = await apiInstance.post(`/password/change`, requestData, ENV.authBaseUrl);
    return Auth.fromAPI(data.data.data);
  };

  const getNotificationElements = async (): Promise<NotificationElement[]> => {
    const data = await apiInstance.get("/users/list_notification_elements", ENV.apiBase);
    return data?.data?.notification_elements || [];
  };

  const updateAccountSettings = async (requestData: Partial<User>) => {
    await apiInstance.post(`/iam/update_user`, requestData);
    return requestData;
  };

  const resetPasswordRequestFn = async (requestData: ResetPasswordRequest): Promise<Auth> => {
    const data = await axios.post(`${ENV.authBaseUrl}/password/forgot`, requestData);
    return Auth.fromAPI(data.data.data);
  };

  const resetPasswordFn = async (requestData: ResetPassword): Promise<Auth> => {
    const data = await axios.post(`${ENV.authBaseUrl}/password/reset`, requestData);
    return Auth.fromAPI(data.data.data);
  };

  const getCredentialsToken = async (requestData: Credentials): Promise<Auth> => {
    const data = await axios.post(`${ENV.authBaseUrl}/session/login/credentials`, requestData);
    return Auth.fromAPI(data.data.data);
  };

  const getGoogleToken = async (requestData: GoogleLogin): Promise<Auth> => {
    const data = await axios.post(`${ENV.authBaseUrl}/session/login/google_sign_in`, requestData);
    return Auth.fromAPI(data.data.data);
  };

  const getMicrosoftToken = async (requestData: MicrosoftLogin): Promise<Auth> => {
    const data = await axios.post(`${ENV.authBaseUrl}/session/login/microsoft_sign_in`, requestData);
    return Auth.fromAPI(data.data.data);
  };

  const resetPasswordRequestMutation = useMutation({
    mutationFn: (credentials: ResetPasswordRequest) => resetPasswordRequestFn(credentials)
  });

  const onSuccessAuth = async (data: Auth) => {
    queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo), () => data);
    apiInstance.authToken = data.token;
    sessionStorage.setItem("_zohoAsapJwt", data.zoho_asap_jwt);

    const user = await Service.getUser();
    if (user) {
      const prevUserId = getPreference("user-id");

      if (prevUserId && prevUserId !== user.id) {
        for (const key in localStorage) {
          if (key.startsWith("preferences-")) delete localStorage[key];
        }
      }

      setPreference("user-id", user.id);
    }

    history.push(LandingPageMapping.get(user?.landing_page_id as number) || PATHS.APPOINTMENTS);
  };

  const credentialsMutation = useMutation({
    mutationFn: (credentials: Credentials) => getCredentialsToken(credentials),
    onSuccess: onSuccessAuth
  });

  const googleMutation = useMutation({
    mutationFn: (credentials: GoogleLogin) => getGoogleToken(credentials),
    onSuccess: onSuccessAuth
  });

  const logout = () => {
    WebSocketComponent.disconnect();
    apiInstance.clearSessionCookie();
    sessionStorage.removeItem("_zohoAsapJwt");
    const broadcastChannel = new BroadcastChannel("auth");
    broadcastChannel.postMessage({ type: "LOGOUT" });
    history.replace(PATHS.LOGIN);

    if ((window as any)?.ZohoHCAsap) {
      try {
        (window as any).ZohoHCAsap.Action("hideLauncher");
      } catch (e) {
        console.error(e);
      }

      try {
        (window as any).ZohoHCAsap.Logout();
      } catch (e) {
        console.error(e);
      }

      try {
        (window as any).ZohoHCAsap.UnInstall();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onSuccessAccountSettings = async (userData: Partial<User>) => {
    if (user?.dealer_location_id !== userData.dealer_location_id) {
      return logout();
    }
    if (user?.list_appointments_by_scheduled_range !== userData.list_appointments_by_scheduled_range) {
      const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;

      const params = { date, location_id: selectedLocation?.id, list_by_scheduled_range: false };
      if (userData.list_appointments_by_scheduled_range) {
        params.list_by_scheduled_range = true;
      }
      queryClient.invalidateQueries(["realtime", { ...queryKeys.appointments.list, params }] as InvalidateQueryFilters);
    }
    const updatedUser = { ...user, ...userData };
    queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.UserData), updatedUser);
    requestUpdate();
  };

  const resetPasswordMutation = useMutation({
    mutationFn: (credentials: ResetPassword) => resetPasswordFn(credentials)
  });

  const microsoftMutation = useMutation({
    mutationFn: (credentials: MicrosoftLogin) => getMicrosoftToken(credentials),
    onSuccess: onSuccessAuth
  });

  const changePasswordMutation = useMutation({
    mutationFn: (credentials: ChangePassword) => changeOldPassword(credentials)
  });

  const updateUserAccountMutation = useMutation({
    mutationFn: (requestData: Partial<User>) => updateAccountSettings(requestData),
    onSuccess: onSuccessAccountSettings
  });

  const loginWithCredentials = credentialsMutation;

  const loginWithGoogle = googleMutation;

  const loginWithMicrosoft = microsoftMutation;

  return {
    logout,
    data: authInfo,
    resetPasswordRequestMutation,
    loginWithCredentials,
    loginWithGoogle,
    loginWithMicrosoft,
    resetPasswordMutation,
    changePasswordMutation,
    getNotificationElements,
    updateUserAccountMutation
  };
};

import { Row, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { INTERVENTION_ELEMENT_TYPE, InterventionElement } from "models/InterventionElement";
import "modules/AppointmentDetail/components/Interventions/components/Subitems/components/SubitemsTable/SubitemsTable.scss";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

type SubitemsTableProps = {
  elements: InterventionElement[];
  t: ITranslation;
  showActionButton: boolean;
  onDeleteSubitem?: (element: Row<InterventionElement>) => void;
  showPriceInVat?: boolean;
};

const interventionElementIcon = (element: InterventionElement) => {
  const iconsArray: JSX.Element[] = [];

  if (element.parent_dms_nr) {
    iconsArray.push(<Icon className="arrow right from line" />);
  }

  switch (element.element_type) {
    case INTERVENTION_ELEMENT_TYPE.Part:
      iconsArray.push(<Icon className="gear" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Labor:
      iconsArray.push(<Icon className="user helmet safety" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Text:
      iconsArray.push(<Icon className="file" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Discount:
      iconsArray.push(<Icon className="percent" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.SmallPart:
      iconsArray.push(<Icon className="gears" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Fee:
      iconsArray.push(<Icon className="dollar sign" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Rent:
      iconsArray.push(<Icon className="key" />);
      break;

    case INTERVENTION_ELEMENT_TYPE.Menu:
      iconsArray.push(<Icon className="bars" />);
      break;

    default:
      break;
  }

  return <>{iconsArray.map(icon => icon)}</>;
};

const columnHelper = createColumnHelper<InterventionElement>();

const SubitemsTable = ({ elements, t, showActionButton, onDeleteSubitem, showPriceInVat }: SubitemsTableProps) => {
  const { selectedLocation: location } = useDealersLocations();

  const sortedElements = useMemo(() => {
    return elements?.sort((a: InterventionElement, b: InterventionElement) => {
      if (!a.dms_nr && !b.dms_nr) return (a?.id || 0) - (b?.id || 0);

      if (a?.parent_dms_nr === b.parent_dms_nr) return a.dms_nr.localeCompare(b.dms_nr);

      if ((a.parent_dms_nr === "") !== (b.parent_dms_nr === "")) {
        if (a.parent_dms_nr === b.dms_nr) return 1;
        if (b.parent_dms_nr === a.dms_nr) return -1;
        return (a.parent_dms_nr || a.dms_nr).localeCompare(b.parent_dms_nr || b.dms_nr);
      }

      return a?.parent_dms_nr?.localeCompare(b?.parent_dms_nr || "") || 0;
    });
  }, [elements]);

  const columns = [
    columnHelper.accessor(row => row, {
      id: "element_type",
      header: t("v8_type").message || "Type",
      size: 20,
      cell: info => interventionElementIcon(info.row.original)
    }),
    columnHelper.accessor(row => row.id, {
      id: "id",
      cell: info => info.getValue(),
      size: 40,
      header: () => "#"
    }),
    columnHelper.accessor(row => row.description, {
      id: "description",
      header: () => t("v8_description").message || "Description",
      size: 520,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.unit, {
      id: "unit",
      header: () => `${t("v8_unit").message || "Unit"} €`,
      size: 60,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: () => t("v8_quantity").message || "Quantity",
      size: 40,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.price, {
      id: "price",
      header: () => `${t("v8_price").message || "Price"} €`,
      size: 60,
      cell: info => {
        const price = Number(info.renderValue() || "0");
        return showPriceInVat ? getPriceInVAT(price, location).toFixed(2) : price.toFixed(2);
      }
    }),
    columnHelper.accessor(row => row, {
      id: "action",
      header: "",
      size: showActionButton ? 60 : 0,
      cell: info =>
        showActionButton ? (
          <div className="subitem-delete" onClick={() => onDeleteSubitem && onDeleteSubitem(info.row)}>
            <Icon className="trash grey" />
          </div>
        ) : null
    })
  ];

  const table = useReactTable({
    data: sortedElements || [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className="SubitemsTable">
      {elements ? (
        <table className="ReactTable">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th style={{ width: header.column.columnDef.size }} key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="nodata">
          <p>{t("v8_no_data_to_show").message || "No data to show"}</p>
        </div>
      )}
    </div>
  );
};
export default withTranslation()(SubitemsTable);

export enum DMS {
  Unknown = 1,
  NTCar,
  Eva,
  WinCar,
  AnonEva,
  AutoLine,
  AutoLineEFNefkens,
  TestAPI,
  TestSlow,
  MyEngine,
  TruckVersion,
  ICarWo,
  CarIT,
  AutoLineOpelMenu,
  KeyLoopMenus,
  CustomQueries,
  KeyLoopJobs,
  AutoLineEFTerwolde,
  ICar_Planning,
  AutoFlex,
  AutoFlexQAStaging,
  NextLane
}

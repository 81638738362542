import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CheckboxProps, Icon, Modal } from "semantic-ui-react";

import CustomerDetail from "components/CustomerModal/components/CustomerDetail";
import CustomerEntities from "components/CustomerModal/components/CustomerEntities";
import "components/CustomerModal/CustomerModal.scss";
import useCustomer from "components/CustomerModal/hooks/useCustomer";
import { Customer } from "models";
import { ITranslation } from "util/interfaces";

type CustomerModalProps = {
  customerID: number | null;
  onClose: () => void;
  isOpen: boolean;
};

export const CustomerModal = ({ customerID, onClose, isOpen }: CustomerModalProps) => {
  const t = useTranslation().t as ITranslation;
  const [customer, setCustomer] = useState<Customer | undefined>();
  const { customerInfoMutation, editCustomerInfoMutation } = useCustomer();

  const getCustomerDetails = () => {
    if (customerID) {
      customerInfoMutation.mutate({ id: customerID });
    }
  };

  useEffect(() => {
    if (isOpen) {
      getCustomerDetails();
    }
  }, [isOpen]);

  useEffect(() => {
    if (customerInfoMutation.data) {
      setCustomer(customerInfoMutation.data);
    }
  }, [customerInfoMutation.data]);

  const handleChangeCheckbox = (_e: FormEvent<HTMLInputElement>, { name, checked }: CheckboxProps) => {
    setCustomer(
      (prevCustomer: Customer | undefined) =>
        ({
          ...prevCustomer,
          [name as string]: checked
        }) as Customer
    );
  };

  const handleChangePreferredCommunicationChannel = (preferred_communication_channel: number) => {
    setCustomer(
      (prevCustomer: Customer | undefined) =>
        ({
          ...prevCustomer,
          preferred_communication_channel
        }) as Customer
    );
  };

  const handleUpdateCustomer = async () => {
    const { id: customer_id, block_communication, is_internal, preferred_communication_channel } = customer as Customer;
    editCustomerInfoMutation.mutate({ customer_id, block_communication, is_internal, preferred_communication_channel });
  };

  useEffect(() => {
    if (editCustomerInfoMutation.isSuccess) {
      onClose();
    }
  }, [editCustomerInfoMutation.isSuccess]);

  const renderDetailsAndEntities = () => {
    if (!customer) return null;

    let name = "";
    if (customer.title) name = customer.title;

    if (customer.firstname) name += " " + customer.firstname;

    if (customer.surname) name += " " + customer.surname;

    name = name.trim();
    if (!name) name = customer.company;

    return (
      <Modal
        open={isOpen}
        size="large"
        onClose={onClose}
        closeOnDimmerClick={false}
        style={{
          paddingTop: 0,
          margin: 0
        }}
        className="CustomerModal"
      >
        <Modal.Header>
          <div className="CustomerModal-header">
            <span>{name}</span>

            <Button className="-appointment-status" size="small" onClick={onClose}>
              <Icon className="xmark grey" size="big" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <div className="customer_sys">
            <div className="customer_sys_info">
              <strong>DMS : </strong>
              {customer.dms_nr}
            </div>
            <div className="customer_sys_info">
              <strong>CRM : </strong>
              {customer.customer_crm_nr ? customer.customer_crm_nr : "-"}
            </div>
            {customer.dealer_name && (
              <div className="customer_sys_info">
                <strong>{customer.dealer_name}</strong>
              </div>
            )}
            {customer.location_name && (
              <div className="customer_sys_info">
                <strong>{customer.location_name}</strong>
              </div>
            )}
          </div>

          {customerInfoMutation.isPending && (
            <div className="Loader-Placeholder">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
              <section>{t("v8_loading_customer").message || "Loading customer"}</section>
            </div>
          )}

          {!customerInfoMutation.isPending && (
            <>
              <CustomerDetail
                customer={customer}
                isUpdatingCustomer={editCustomerInfoMutation.isPending}
                loading={customerInfoMutation.isPending}
                onChangeCheckbox={handleChangeCheckbox}
                onChangePreferredCommunicationChannel={handleChangePreferredCommunicationChannel}
              />
              <CustomerEntities appointments={customer.appointments} cars={customer.cars} />
            </>
          )}
        </Modal.Content>

        <Modal.Actions>
          <div className="CustomerModal-footer">
            <Button
              color="green"
              form="new-intervention"
              onClick={handleUpdateCustomer}
              loading={editCustomerInfoMutation.isPending}
              disabled={editCustomerInfoMutation.isPending || customerInfoMutation.isPending}
            >
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  };

  return renderDetailsAndEntities();
};

import moment from "moment";

import { DatePicker, useCan } from "components";
import { Appointment } from "models";
import "modules/AppointmentDetail/components/AppointmentDate/AppointmentDate.scss";

type AppointmentDateProps = {
  appointment: Appointment;
  onChangeDate: (date: Date) => void;
};

export const AppointmentDate = ({ appointment, onChangeDate }: AppointmentDateProps) => {
  const canUpdateAppointmentDate = useCan("update-appointment-date", "appointments");

  if (!appointment.time_car_app || appointment.time_car_app.startsWith("0001-01-01T00:00:00")) return null;

  return (
    <div className="AppointmentDate">
      {appointment.dms_nr?.startsWith("claire_") && canUpdateAppointmentDate ? (
        <DatePicker date={moment(appointment.time_car_app).toDate()} onDateChange={onChangeDate} withPortal showMonthDropdown showYearDropdown />
      ) : (
        moment(appointment.time_car_app).format("DD-MM-YYYY")
      )}
    </div>
  );
};

import { Fragment } from "react";
import { withTranslation } from "react-i18next";

import ROBListData from "components/ROB/components/ROBListData";
import "components/ROB/components/RobSection/ROBSection.scss";
import { convertCamelCaseToWords } from "util/common";
import { ITranslation } from "util/interfaces";

type RobSectionProps = {
  t: ITranslation;
  title?: string;
  data: Record<string, any>;
  showDivider: boolean;
};

const getValueForDisplay = (value: Record<string, string | boolean | undefined | null>) => {
  if (typeof value === "string") return value;
  if (typeof value === "boolean") return value ? "Yes" || value : "No" || value;

  return value;
};

const ROBSection = ({ t, title, data, showDivider }: RobSectionProps) => {
  if (!data) {
    return null;
  }
  const keyValues = Object.entries(data);

  return (
    <>
      <div className="RobSection">
        {title && <div className="segment_title">{t(title).message || title}</div>}

        {keyValues.map(([key, value], idx) => {
          if (Array.isArray(value)) {
            return <ROBListData key={key} title={t(key).message || title} data={value} onGetValueForDisplay={getValueForDisplay} showDivider={showDivider} />;
          }
          const secondLevelKeyValues = Object.entries(value);
          const sectionTitle = convertCamelCaseToWords(key);

          return (
            <Fragment key={idx}>
              <div className={`section_title ${idx > 0 ? "divider" : ""}`}>{t(sectionTitle).message || sectionTitle}</div>
              {secondLevelKeyValues.map(([secondLevelKey, secondLevelValue], id) => {
                const rowTitle = convertCamelCaseToWords(secondLevelKey);
                const valueToDisplay = getValueForDisplay(secondLevelValue as any);

                return (
                  <div className="data_row" key={id}>
                    <div className="title">{rowTitle}</div>
                    <div className="value">{valueToDisplay as string}</div>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>
      {showDivider && <hr className="divider" />}
    </>
  );
};

export default withTranslation()(ROBSection);

import { faCheck, faCircleExclamation, faNoteSticky, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Icon } from "semantic-ui-react";

import { useCan } from "components";
import { Intervention, QUESTION_STATUSES, QuestionResult } from "models";
import { useChecklist, useIntervention } from "modules/AppointmentDetail/hooks";

const options = [
  { key: QUESTION_STATUSES.OK, icon: <Icon className="check" color={"green"} />, value: QUESTION_STATUSES.OK },
  { key: QUESTION_STATUSES.OKWithRemarks, icon: <Icon className="note sticky" color={"green"} />, value: QUESTION_STATUSES.OKWithRemarks },
  { key: QUESTION_STATUSES.Advice, icon: <Icon className="triangle exclamation" color={"orange"} />, value: QUESTION_STATUSES.Advice },
  { key: QUESTION_STATUSES.Necessary, icon: <Icon className="circle exclamation" color={"red"} />, value: QUESTION_STATUSES.Necessary },
  { key: QUESTION_STATUSES.NVT, icon: <Icon className="x mark" color={"grey"} />, value: QUESTION_STATUSES.NVT }
];

export const CurrentStatus = ({ status }: { status: number }) => {
  switch (status) {
    case QUESTION_STATUSES.OK:
      return <FontAwesomeIcon icon={faCheck} color="green" className="icon-container" />;
    case QUESTION_STATUSES.OKWithRemarks:
      return <FontAwesomeIcon icon={faNoteSticky} color="green" className="icon-container" />;
    case QUESTION_STATUSES.Advice:
      return <FontAwesomeIcon icon={faTriangleExclamation} color="orange" className="icon-container" />;
    case QUESTION_STATUSES.Necessary:
      return <FontAwesomeIcon icon={faCircleExclamation} color="red" className="icon-container" />;
    default:
      return <FontAwesomeIcon icon={faXmark} color="grey" className="icon-container" />;
  }
};

type AnswerStatusSelectorProps = {
  data: QuestionResult | Intervention;
  type?: "question_result" | "intervention";
  disabled?: boolean;
};

export const AnswerStatusSelector = ({ data, type = "question_result", disabled }: AnswerStatusSelectorProps) => {
  const { updateCheckQuestionItem } = useChecklist(data.appointment_id);
  const { optimisticInterventionUpdate } = useIntervention(data.appointment_id);
  const canUpdateQuestionResults = useCan("update_question_result", "car_checks");

  const handleUpdate = (status: number) => {
    const updatedData = { ...data, status };
    if (type === "intervention") {
      optimisticInterventionUpdate.mutate(updatedData as Intervention);
      return;
    }

    updateCheckQuestionItem.mutate({ data: updatedData as QuestionResult });
  };

  if (disabled || !canUpdateQuestionResults)
    return (
      <div className="view-only">
        <CurrentStatus status={data.status} />
      </div>
    );

  return (
    <Dropdown
      className="status-dropdown"
      options={options}
      onChange={(_e, { value }) => handleUpdate(Number(value))}
      icon={null}
      selectOnBlur={false}
      trigger={
        <div>
          <CurrentStatus status={data.status} />
          <Icon className="caret down grey" size="tiny" />
        </div>
      }
    />
  );
};

import { Customer, CustomerCommunicationIntervention, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum COMMUNICATION_EVENT_TYPE {
  OnlineCheckInSent = 1,
  OnlineCheckInOpened,
  OnlineCheckInAnswered,
  DiagnoseOverviewSent,
  DiagnoseOverviewOpened,
  DiagnoseOverviewAnswered,
  DiagnoseOverviewResent,
  RepairOverviewSent,
  RepairOverviewResent,
  RepairOverviewOpened
}

export class CommunicationEvent extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  type: COMMUNICATION_EVENT_TYPE = COMMUNICATION_EVENT_TYPE.OnlineCheckInSent;
  ip: string = "";
  key_locker_pin: string = "";
  user_id: number | null = null;
  customer_communication_id: number = 0;
  customer_id: number | null = null;
  customer_name: string = "";
  user?: User | null = null;
  customer?: Customer | null = null;
  private _receivers?: CommunicationReceiver[] | null;
  dealer_location_id: number = 0;
  appointment_id: number = 0;
  mailgun_message_id?: string = "";
  is_parking_gate_code_sent: boolean = false;
  private _intervention_results?: CustomerCommunicationIntervention[] | null;
  private _check_in_agreed_interventions?: CheckInAgreedIntervention[] | null;
  private _check_in_declined_interventions?: CheckInDeclinedIntervention[] | null;
  private _diagnose_overview_agreed_interventions?: DiagnoseOverviewAgreedIntervention[] | null;
  private _diagnose_overview_agreed_results?: DiagnoseOverviewAgreedResult[] | null;
  private _diagnose_overview_declined_results?: DiagnoseOverviewDeclinedResult[] | null;
  private _diagnose_overview_contact_results?: DiagnoseOverviewContactResult[] | null;

  get receivers(): CommunicationReceiver[] {
    return this._receivers ? this._receivers : [];
  }

  set receivers(newValue: CommunicationReceiver[] | null | undefined) {
    this._receivers = newValue;
  }

  get check_in_agreed_interventions(): CheckInAgreedIntervention[] {
    return this._check_in_agreed_interventions ? this._check_in_agreed_interventions : [];
  }

  set check_in_agreed_interventions(newValue: CheckInAgreedIntervention[] | null | undefined) {
    this._check_in_agreed_interventions = newValue;
  }

  get check_in_declined_interventions(): CheckInDeclinedIntervention[] {
    return this._check_in_declined_interventions ? this._check_in_declined_interventions : [];
  }

  set check_in_declined_interventions(newValue: CheckInDeclinedIntervention[] | null | undefined) {
    this._check_in_declined_interventions = newValue;
  }

  get diagnose_overview_agreed_interventions(): DiagnoseOverviewAgreedIntervention[] {
    return this._diagnose_overview_agreed_interventions ? this._diagnose_overview_agreed_interventions : [];
  }

  set diagnose_overview_agreed_interventions(newValue: DiagnoseOverviewAgreedIntervention[] | null | undefined) {
    this._diagnose_overview_agreed_interventions = newValue;
  }

  get diagnose_overview_agreed_results(): DiagnoseOverviewAgreedResult[] {
    return this._diagnose_overview_agreed_results ? this._diagnose_overview_agreed_results : [];
  }

  set diagnose_overview_agreed_results(newValue: DiagnoseOverviewAgreedResult[] | null | undefined) {
    this._diagnose_overview_agreed_results = newValue;
  }

  get diagnose_overview_declined_results(): DiagnoseOverviewDeclinedResult[] {
    return this._diagnose_overview_declined_results ? this._diagnose_overview_declined_results : [];
  }

  set diagnose_overview_declined_results(newValue: DiagnoseOverviewDeclinedResult[] | null | undefined) {
    this._diagnose_overview_declined_results = newValue;
  }

  get diagnose_overview_contact_results(): DiagnoseOverviewContactResult[] {
    return this._diagnose_overview_contact_results ? this._diagnose_overview_contact_results : [];
  }

  set diagnose_overview_contact_results(newValue: DiagnoseOverviewContactResult[] | null | undefined) {
    this._diagnose_overview_contact_results = newValue;
  }

  get intervention_results(): CustomerCommunicationIntervention[] {
    return this._intervention_results ? this._intervention_results : [];
  }

  set intervention_results(newValue: CustomerCommunicationIntervention[] | null | undefined) {
    this._intervention_results = newValue;
  }
}

export class CheckInAgreedIntervention extends ApiLoadable {
  check_in_agreed_intervention_id: number = 0;
  title: string = "";
  description: string = "";
  price: number | null = 0;
  vat: number | null = 0;
  communication_event_id: number = 0;
}

export class CheckInDeclinedIntervention extends ApiLoadable {
  checkInDeclinedInterventionId: number = 0;
  title: string = "";
  description: string = "";
  price: number = 0;
  vat: number = 0;
  communicationEventId: number = 0;
}

export class DiagnoseOverviewAgreedIntervention extends ApiLoadable {
  id: number = 0;
  title: string = "";
  description: string = "";
  price: number = 0;
  vat: number = 0;
  communicationEventId: number = 0;
}

export class DiagnoseOverviewAgreedResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communicationEventId: number = 0;
}

export class DiagnoseOverviewDeclinedResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communicationEventId: number = 0;
}

export class DiagnoseOverviewContactResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communicationEventId: number = 0;
}

export enum COMMUNICATION_RECEIVER_STATUS {
  // PendingStatus communication is sent but not received
  PendingStatus = 1,
  // SuccessStatus communication is sent and received
  SuccessStatus,
  // FailedStatus communication is not received due to some email error
  FailedStatus
}

export class CommunicationReceiver extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  channel: number = 0;
  destination: string = "";
  reason: string = "";
  status: COMMUNICATION_RECEIVER_STATUS = COMMUNICATION_RECEIVER_STATUS.PendingStatus;
  mailgun_message_id: string = "";
  chatshipper_conversation_id: number | null = null;
  communication_event_id: number = 0;
}

import moment from "moment";

import { TabData } from "components";

export const mockStatusData = [
  { icon: "calendar check", className: "afBrownBg", isActive: true, value: "s1" },
  { icon: "wrench", className: "afPinkBg", isActive: true, value: "s2" },
  { icon: "square a", className: "afTomatoBg", isActive: true, value: "s3" },
  { icon: "tag", className: "afOrangeBg", isActive: true, value: "s4" },
  { icon: "triangle exclamation", className: "afYellowBg", isActive: true, value: "s5" },
  { icon: "user check", className: "afLemonBg", isActive: true, value: "s6" },
  { icon: "face smile blank", className: "afGreenBg", isActive: true, value: "s7" },
  { icon: "check double", className: "afGrassBg", isActive: true, value: "s8" },
  { icon: "shield check", className: "afSpaceBg", isActive: true, value: "s9" },
  { icon: "car mirrors", className: "afBlueBg", isActive: true, value: "s10" },
  { icon: "circle xmark", className: "afGreyBg", isActive: true, value: "s11" }
];

export const mockToggleData = [
  { icon: "file", className: "tiGreenBg", isActive: true, value: "s1" },
  { icon: "check", className: "tiGreenBg", isActive: true, value: "s2" },
  { icon: "circle exclamation", className: "tiOrangeBg", isActive: false, value: "s1" },
  { icon: "ban", className: "tiRedBg", isActive: false, value: "s1" }
];
export const mockToggleOffData = [
  { icon: "file", className: "tiGreenBg off", isActive: true, value: "s1" },
  { icon: "check", className: "tiGreenBg off", isActive: true, value: "s2" },
  { icon: "circle exclamation", className: "tiOrangeBg off", isActive: false, value: "s1" },
  { icon: "ban", className: "tiRedBg off", isActive: false, value: "s1" }
];

export const defaultData: any[] = [
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "wrench", className: "afPinkBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "calendar check", className: "afBrownBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "square a", className: "afTomatoBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "tag", className: "afOrangeBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "triangle exclamation", className: "afYellowBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "user check", className: "afLemonBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "face smile blank", className: "afGreenBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "check double", className: "afGrassBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "shield check", className: "afSpaceBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "car mirrors", className: "afBlueBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "mechanic 1",
    filter: "last user",
    check_list: ["A"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [],
    updated: moment().format("HH:mm"),
    mechanic: "s 1",
    filter: "last user",
    check_list: ["S", "E"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  },
  {
    date: moment("2022-03-25").format("DD-MM-YYYY"),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878",
    apk: moment("2022-03-25").format("DD-MM-YYYY"),
    driver_name: "name 1",
    make: "make 1",
    note: "",
    accessibility: [
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" },
      { icon: "triangle exclamation", color: "red", bg: "rgba(219, 40, 40, .2)" }
    ],
    updated: moment().format("HH:mm"),
    mechanic: "g 1",
    filter: "last user",
    check_list: ["A", "T"],
    staus: { icon: "circle xmark", className: "afGreyBg" }
  }
];

export const mockTabsData: Map<string, TabData> = new Map([
  ["general", { data: [], id: "general", title: "General" }],
  ["variables", { data: [], id: "variables", title: "Variables" }],
  ["deskCheckIn", { data: [], id: "deskCheckIn", title: "Desk Check in" }],
  ["onlineCheckIn", { data: [], id: "onlineCheckIn", title: "Online Check in" }],
  ["diagnoseOverview", { data: [], id: "diagnoseOverview", title: "Diagnose Overview" }],
  ["repairOverview", { data: [], id: "repairOverview", title: "Repair Overview" }],
  ["support", { data: [], id: "support", title: "Support" }]
]);

import { useEffect } from "react";

import apiInstance from "util/Api";

export const useAxiosProgress = (setProgress: (progress: number) => void) => {
  useEffect(() => {
    apiInstance.addProgressBarHook(setProgress);
    return () => {
      apiInstance.removeProgressBarHook(setProgress);
    };
  }, [setProgress]);
};

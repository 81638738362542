import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData } from "components";
import { CHECKIN_COMMUNICATION_RESULT_STATUS, CheckInCommunicationResult, QuestionResult } from "models";
import { ITranslation } from "util/interfaces";

type CheckInOptionalServiceProps = {
  title: string;
  data: CheckInCommunicationResult[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData) => void;
};

export const CheckInOptionalService = ({ title, data, canCreateIntervention, onSelect }: CheckInOptionalServiceProps) => {
  if (!data) return null;

  const t = useTranslation().t as ITranslation;
  const communicationStatus = [CHECKIN_COMMUNICATION_RESULT_STATUS.Success, CHECKIN_COMMUNICATION_RESULT_STATUS.Failed];

  return (
    <div className="CustomerActions-items">
      {data
        .filter(item => item.visible)
        .map(item => {
          const notificationEmailItemActive = communicationStatus.includes(item.status);
          const actionModelData = {
            title: item.name,
            raw: item.description,
            solution: ""
          };
          return (
            <Grid key={item.id} className="actions-container">
              <Grid.Column width={12}>
                <div className={`icon-container skyblue ${!notificationEmailItemActive ? "disabled" : ""}`}>
                  <Popup
                    disabled={!item.notification_email}
                    content={
                      <div className="InfoPopup">
                        <p className="InfoTitle">{title}</p>
                        {item.status === CHECKIN_COMMUNICATION_RESULT_STATUS.Success ? (
                          <p className="InfoDescription">{`${t("v8_sent_to").message || "Sent to"} ${item.notification_email} ${t("v8_at").message || "at"} ${moment(
                            item.updated_on
                          ).format("HH:mm")} `}</p>
                        ) : (
                          <p className="InfoDescription">{item.notification_email}</p>
                        )}
                      </div>
                    }
                    trigger={<Icon className="envelope" />}
                  />
                </div>
                <p className="title">{item.name}</p>
              </Grid.Column>
              <Grid.Column width={1}>{item.price && <p className="title">€ {item.price.toFixed(2)}</p>}</Grid.Column>
              <Grid.Column width={3}>
                <div className="end-grid">
                  <div className="status-container">
                    <div>
                      <Icon className={item.receptionist_handled ? "check green" : "clock grey"} size="small" />
                    </div>
                    {canCreateIntervention && <ActionModalSelector data={actionModelData as QuestionResult} onSelect={onSelect} onlyIntervention />}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          );
        })}
    </div>
  );
};

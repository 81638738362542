import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import { Appointment, DeskCommunication } from "models";
import { Content } from "modules/AppointmentDetail/components/DeskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

type CommunicationsProps = {
  appointment: Appointment;
  desk_communications: DeskCommunication[];
};

type CommunicationPane = {
  menuItem: string;
  render: () => ReactNode;
};

export const Communications = ({ appointment, desk_communications }: CommunicationsProps) => {
  const panes: CommunicationPane[] = [];
  const t = useTranslation().t as ITranslation;

  desk_communications
    .sort((comm1, comm2) => comm2.created_on.localeCompare(comm1.created_on))
    .forEach((comm: DeskCommunication) => {
      panes.push({
        menuItem: comm.is_checking_out ? t("v8_desk_check_out").message || "Desk Check-out" : t("v8_desk_check_in").message || "Desk Check-in",
        render: () => <Content appointment={appointment} comm={comm} />
      });
    });

  return panes.length > 0 ? <Tab menu={{ secondary: true }} panes={panes} /> : null;
};

import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import FaceIcon from "assets/svg/face.svg";
import { useDealersLocations } from "hooks";
import { APPOINTMENT_STATUSES } from "models";
import "modules/Appointments/components/StatusFilters/StatusFilters.scss";

export type StatusData = {
  icon: string;
  value: APPOINTMENT_STATUSES;
  isActive: boolean;
  id: APPOINTMENT_STATUSES;
};

const statusData: StatusData[] = [
  { icon: "calendar check", isActive: true, value: APPOINTMENT_STATUSES.HandleCheckIn, id: APPOINTMENT_STATUSES.HandleCheckIn },
  { icon: "wrench", isActive: true, value: APPOINTMENT_STATUSES.CarCheck, id: APPOINTMENT_STATUSES.CarCheck },
  { icon: "square a", isActive: true, value: APPOINTMENT_STATUSES.AannameOk, id: APPOINTMENT_STATUSES.AannameOk },
  { icon: "dollar", isActive: true, value: APPOINTMENT_STATUSES.Calculating, id: APPOINTMENT_STATUSES.Calculating },
  { icon: "tag", isActive: true, value: APPOINTMENT_STATUSES.PricingOk, id: APPOINTMENT_STATUSES.PricingOk },
  { icon: "triangle exclamation", isActive: true, value: APPOINTMENT_STATUSES.Diagnose, id: APPOINTMENT_STATUSES.Diagnose },
  { icon: "user check", isActive: true, value: APPOINTMENT_STATUSES.CustomerAnswered, id: APPOINTMENT_STATUSES.CustomerAnswered },
  { icon: "face", isActive: true, value: APPOINTMENT_STATUSES.CustomerOk, id: APPOINTMENT_STATUSES.CustomerOk },
  { icon: "check double", isActive: true, value: APPOINTMENT_STATUSES.CarReady, id: APPOINTMENT_STATUSES.CarReady },
  { icon: "shield check", isActive: true, value: APPOINTMENT_STATUSES.QualityCheck, id: APPOINTMENT_STATUSES.QualityCheck },
  { icon: "right from bracket outOfShop", isActive: true, value: APPOINTMENT_STATUSES.CarOutOfShop, id: APPOINTMENT_STATUSES.CarOutOfShop },
  { icon: "car garage", isActive: true, value: APPOINTMENT_STATUSES.CarInShop, id: APPOINTMENT_STATUSES.CarInShop },
  { icon: "car mirrors", isActive: true, value: APPOINTMENT_STATUSES.NewCar, id: APPOINTMENT_STATUSES.NewCar },
  { icon: "bo", isActive: true, value: APPOINTMENT_STATUSES.BackOrder, id: APPOINTMENT_STATUSES.BackOrder },
  { icon: "circle xmark", isActive: true, value: APPOINTMENT_STATUSES.Canceled, id: APPOINTMENT_STATUSES.Canceled }
];

interface StatusFiltersProps {
  data?: StatusData[];
  onChange?: (e: APPOINTMENT_STATUSES[]) => void;
}

export const StatusFilters: React.FC<StatusFiltersProps> = ({ data = statusData, onChange }: StatusFiltersProps) => {
  const [statusData, setStatusData] = useState<StatusData[]>(data);
  const { selectedLocation } = useDealersLocations();

  const getStatusColor = (identifier: number) => {
    const matchingStatus = selectedLocation?.statuses?.find(status => status.identifier === identifier);
    return matchingStatus ? matchingStatus.color : "";
  };

  const handleClick = (index: number) => {
    const statuses = [...statusData];
    statuses[index].isActive = !statuses[index].isActive;

    setStatusData(statuses);

    if (onChange) {
      const selectedValues = statuses.filter((item: StatusData) => !item.isActive).map(s => s.value);
      if (selectedValues.includes(APPOINTMENT_STATUSES.CarReady)) {
        selectedValues.push(APPOINTMENT_STATUSES.CarOkPlusRepairOverview);
      }
      if (selectedValues.includes(APPOINTMENT_STATUSES.QualityCheck)) {
        selectedValues.push(APPOINTMENT_STATUSES.QualityCheckPlusRepairOverview);
      }
      onChange(selectedValues);
    }
  };

  if (!selectedLocation?.statuses) return null;

  return (
    <div className="status-filters-wrapper">
      {statusData.map((item: StatusData, index: number) => {
        return (
          <div key={`filter-status-${index}`} className="StatusFilters-buttons" onClick={() => handleClick(index)}>
            <Button color="orange" className="af" style={{ backgroundColor: getStatusColor(item.value) }} disabled={!item.isActive}>
              {item.icon === "bo" ? (
                "BO"
              ) : item.icon === "face" ? (
                <div className="filter-status-face-icon">
                  <img src={FaceIcon} />
                </div>
              ) : (
                <Icon className={item.icon} />
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

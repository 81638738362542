import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

type ReassignRequestType = {
  appointment_id: number;
  user_id: number;
  order: number;
};

type MutationContext = {
  queryKey: QueryKey;
  previousAppointments: Appointment[] | undefined;
};

export const useReassignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation<ReassignRequestType, Error, ReassignRequestType, MutationContext>({
    mutationFn: async requestData => ApiInstance.post("/appointments/reassign", { ...requestData }, ENV.dayplannerBaseUrl),
    onMutate: requestData => {
      const queryKey = ["realtime", { ...queryKeys.dayplanner.appointments, params: { dealer_location_id: selectedLocation?.id } }];

      const previousAppointments = queryClient.getQueryData<Appointment[] | undefined>(queryKey);

      if (!previousAppointments) return { queryKey, previousAppointments: [] };

      const draggedAppointment = previousAppointments?.find(app => app.id === requestData.appointment_id);
      if (!draggedAppointment) return { queryKey, previousAppointments };

      queryClient.setQueryData(queryKey, (appointments: Appointment[] | []) => {
        if (!appointments) return [];

        const previousMechanicId = draggedAppointment.assigned_mechanic;
        const previousOrder = draggedAppointment.assigned_mechanic_order;

        const updatedAppointments = appointments.map(app => {
          if (app.id === requestData.appointment_id) return { ...app, assigned_mechanic: requestData.user_id, assigned_mechanic_order: requestData.order };

          if (app.assigned_mechanic === previousMechanicId && app.assigned_mechanic_order && previousOrder && app.assigned_mechanic_order > previousOrder) {
            return { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };
          }

          if (
            app.assigned_mechanic === requestData.user_id &&
            app.assigned_mechanic_order &&
            app.assigned_mechanic_order >= requestData.order &&
            app.id !== requestData.appointment_id
          ) {
            return { ...app, assigned_mechanic_order: app.assigned_mechanic_order + 1 };
          }

          return app;
        });

        return updatedAppointments;
      });

      return { queryKey, previousAppointments };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousAppointments) {
        queryClient.setQueryData(context.queryKey, context.previousAppointments);
      }
    }
  });

  return mutation.mutate;
};

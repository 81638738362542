import { useQuery } from "@tanstack/react-query";
import { Icon } from "semantic-ui-react";

import { Websocket } from "components";
import "components/WebsocketStatus/WebsocketStatus.scss";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

export const WebsocketStatus = () => {
  const { data: websocket } = useQuery({ queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.Websocket) });
  return (
    <div className="WebsocketStatus">
      <Icon className={`screencast i-${(websocket as Websocket)?.isConnected ? "green" : "maroon"}`} style={{ fontSize: 10 }} />
    </div>
  );
};

import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { ReactTable } from "components";
import { QUESTION_STATUSES } from "models";
import "modules/CarDetails/components/ItemsNotRepaired/ItemsNotRepaired.scss";
import { CriticalHistory } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

type NotRepairedItemsProps = {
  data: CriticalHistory[];
};

export const ItemsNotRepaired = ({ data }: NotRepairedItemsProps) => {
  const t = useTranslation().t as ITranslation;

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor(row => row.created_on, {
      id: "date",
      header: t("v8_appointment_date").message || "Appointment Date",
      cell: info => moment(info.renderValue()).format("DD-MM-YYYY"),
      size: 185
    }),
    columnHelper.accessor(row => row.wo_nr, {
      id: "workOrder",
      header: "WO #",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.title, {
      id: "item",
      header: t("v8_item").message || "Item",
      size: 200,
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />
    }),
    columnHelper.accessor(row => row.raw, {
      id: "remark",
      header: t("v8_remarks").message || "Remarks",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />
    }),
    columnHelper.accessor(row => row.price, {
      id: "excl",
      header: t("v8_excl_vat").message || "Excl.VAT",
      cell: info => Number(info.renderValue()).toFixed(2)
    }),
    columnHelper.accessor(row => row.price, {
      id: "incl",
      header: t("v8_incl_vat").message || "Incl.VAT",
      cell: info => Number(info.renderValue()).toFixed(2)
    }),
    columnHelper.accessor(row => row, {
      id: "icons",
      header: "",
      size: 300,
      cell: info => {
        const data = info.renderValue();
        return (
          <div>
            {data.pinned && (
              <Button className="wdRed af" color="orange">
                <Icon className="shield halved" />
              </Button>
            )}
            {data.snoozed && (
              <Button className="wdSkyblue af" color="orange">
                <Icon className="clock" />
              </Button>
            )}
            <div className="item-buttons">
              {data.status === QUESTION_STATUSES.Advice && <Icon className="triangle exclamation i-yellow" />}
              {data.status === QUESTION_STATUSES.Necessary && <Icon className="triangle exclamation i-maroon" />}
              <div>
                <Icon className={`user check i-${data.customer_approved ? "green" : "grey"}`} />
              </div>
            </div>
          </div>
        );
      }
    })
  ];

  return (
    <div className="ItemsNotRepaired">
      <ReactTable columns={columns} data={data} />
    </div>
  );
};

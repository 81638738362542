import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Icon } from "semantic-ui-react";

import { Appointment } from "models";
import { ITranslation } from "util/interfaces";

type AppointmentDetailsProps = {
  appointment: Appointment;
};

export const AppointmentDetails = ({ appointment }: AppointmentDetailsProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="keylocker-comm-section-container">
      <div className="keylocker-comm-section-header">{t("v8_appointment_information").message || "Appointment Information"}</div>

      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <span className="appointment-information">WO </span>
            <Icon name="hashtag" color="green" />
            <span>{appointment.wo_nr}</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <span>{t("v8_reg").message || "Reg"}. </span>
            <Icon name="hashtag" color="green" />
            <span>{appointment.reg_number}</span>
          </Grid.Column>
          {appointment.due_in && (moment.isMoment(appointment.due_in) || !appointment.due_in.startsWith("0001-01-01T00:00:00")) ? (
            <>
              <Grid.Column width={4}>
                <Icon name="calendar alternate" color="green" />
                {moment(appointment.due_in).format("DD-MM-YYYY")}
              </Grid.Column>

              <Grid.Column width={4}>
                <Icon name="time" color="green" />
                {moment(appointment.due_in).format("HH:mm")}
              </Grid.Column>
            </>
          ) : (
            appointment.time_car_app &&
            (moment.isMoment(appointment.time_car_app) || !appointment.time_car_app.startsWith("0001-01-01T00:00:00")) && (
              <Grid.Column width={4}>
                <Icon name="calendar alternate" color="green" />
                {moment(appointment.time_car_app).format("DD-MM-YYYY")}
              </Grid.Column>
            )
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

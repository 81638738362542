import React, { ReactNode, useState } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DAYPLANNER_COLUMNS, useDayplannerContext } from "modules/Dayplanner";
import { AppointmentCard, DAYPLANNER_STATUSES } from "modules/Dayplanner/components";
import "modules/Dayplanner/components/MechanicBox/MechanicBox.scss";
import { MechanicWithAppointments } from "modules/Dayplanner/utils";
import { classNames } from "util/common";

type MechanicBoxProps = {
  mechanic: MechanicWithAppointments;
  numberOfCardShownInMechanicColumn: number;
  activeFilters: DAYPLANNER_STATUSES[];
  searchTerm?: string;
  onToggleExpand: (mechanicId: number) => void;
  expanded: boolean;
  mechanicsPinned: number[];
  onToggleMechanicPin: (mechamic_id: number) => void;
};

type MechanicContentProps = {
  mechanic: MechanicWithAppointments;
  numberOfCardShownInMechanicColumn: number;
  expanded: boolean;
};

type MechanicPlaceholderProps = {
  mechanicId: number;
  order: number;
  isDraggingOver?: boolean;
};

const MechanicPlaceholder: React.FC<MechanicPlaceholderProps> = ({ mechanicId, isDraggingOver, order }) => {
  const { onDropAppointmentToMechanicPlaceholder, onDraggedAppointmentEnterMechanicPlaceholder, onDraggedAppointmentLeaveMechanicPlaceholder } = useDayplannerContext();

  const placeholderClassnames = classNames({
    "-on-drag-hover": isDraggingOver
  });

  return (
    <div
      key={`placeholder-${mechanicId}-${order}`}
      className={`MechanicBox-placeholder ${placeholderClassnames}`}
      data-mechanic-order={order}
      data-mechanic-id={mechanicId}
      onDragEnter={onDraggedAppointmentEnterMechanicPlaceholder}
      onDragLeave={onDraggedAppointmentLeaveMechanicPlaceholder}
      onDrop={onDropAppointmentToMechanicPlaceholder}
      onDragOver={e => e.preventDefault()}
    >
      <div className="MechanicBox-placeholder__number">{order}</div>
    </div>
  );
};

const MechanicContent = ({ mechanic, expanded, numberOfCardShownInMechanicColumn = 3 }: MechanicContentProps) => {
  let rows = 1;
  const content: ReactNode[] = [];

  if (expanded) {
    rows = Math.ceil(mechanic.appointments.length / numberOfCardShownInMechanicColumn);
    if (mechanic.appointments.length < 10 && mechanic.appointments.length % numberOfCardShownInMechanicColumn === 0) rows += 1;
  }

  for (let row = 0; row < rows; row += 1) {
    const rowContent: React.ReactNode[] = [];
    const condition = row * numberOfCardShownInMechanicColumn;
    for (let i = row * numberOfCardShownInMechanicColumn + numberOfCardShownInMechanicColumn; i > condition; i -= 1) {
      if (i > 10) {
        rowContent.unshift(<div key={`empty-placeholder-${i}`} className={`MechanicBox-placeholder -empty`}></div>);
      } else {
        const app = mechanic.appointments?.find(app => app.assigned_mechanic_order === i);
        if (app) rowContent.unshift(<AppointmentCard key={app.id} mechanic={mechanic} appointment={app} column={DAYPLANNER_COLUMNS.Mechanics} />);
        else rowContent.unshift(<MechanicPlaceholder key={`mechanic-placeholder-${i}`} mechanicId={mechanic.id} order={i} />);
      }
    }

    content.push(
      <div key={`row-${row}`} className="MechanicBox-content" style={{ cursor: "auto" }}>
        {rowContent.reverse()}
      </div>
    );
  }

  return <div className="MechanicBox-contentContainer">{content}</div>;
};

export const MechanicBox = ({
  mechanic,
  mechanicsPinned,
  onToggleMechanicPin,
  numberOfCardShownInMechanicColumn,
  activeFilters,
  searchTerm,
  onToggleExpand,
  expanded
}: MechanicBoxProps) => {
  const [isAssigningAppointment, setIsAssigningAppointment] = useState<boolean>(false);
  const mechanicHasAppointments = !!mechanic.appointments?.length;

  const handleToggleExpandMechanic = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    onToggleExpand(mechanic.id);
  };

  const handlePinnedMechanic = (mechanic: MechanicWithAppointments) => {
    onToggleMechanicPin(mechanic.id);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsAssigningAppointment(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const related = e.relatedTarget as HTMLElement | null;
    if (!related || !e.currentTarget.contains(related)) {
      setIsAssigningAppointment(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsAssigningAppointment(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const hasActiveCollapsedAppointments =
    (activeFilters.length || searchTerm) &&
    !expanded &&
    mechanic.appointments.some(app => !app.inactive && Number(app.assigned_mechanic_order) > numberOfCardShownInMechanicColumn);

  return (
    <div
      className={`MechanicBox ${!mechanicHasAppointments && !isAssigningAppointment ? "-no-appointments" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {(mechanicHasAppointments || isAssigningAppointment) && (
        <MechanicContent mechanic={mechanic} numberOfCardShownInMechanicColumn={numberOfCardShownInMechanicColumn} expanded={expanded} />
      )}

      <div className="MechanicBox-side">
        <div className="MechanicBox-profile">
          <Popup
            trigger={
              mechanic.profile_picture ? (
                <img alt={`${mechanic.first_name?.charAt(0).toUpperCase() || ""} ${mechanic.last_name?.charAt(0).toUpperCase() || ""}`} src={mechanic.profile_picture} />
              ) : (
                <div>
                  {mechanic.first_name?.charAt(0).toUpperCase() || ""}
                  {mechanic.last_name?.charAt(0).toUpperCase() || ""}
                </div>
              )
            }
            content={
              <span className="MechanicBox-name">
                {mechanic.first_name} {mechanic.last_name}
              </span>
            }
          />
        </div>
        {!mechanicHasAppointments && !isAssigningAppointment && (
          <>
            <span className="mechanic-name">
              {mechanic.first_name || ""} {mechanic.last_name || ""}
            </span>
          </>
        )}

        <div className="MechanicBox-appointments">
          <Label
            className={`extend-appointments-button ${
              !mechanic.appointments.length ? "-disabled" : mechanic.appointments.length <= numberOfCardShownInMechanicColumn ? "-no-pointer" : ""
            } ${hasActiveCollapsedAppointments ? "-border-green" : ""}`}
            basic
            onClick={e => (mechanic.appointments.length < numberOfCardShownInMechanicColumn ? null : handleToggleExpandMechanic(e))}
          >
            <span>{mechanic.appointments.length}</span>
            {mechanic.appointments.length > numberOfCardShownInMechanicColumn && (
              <span className="extend-appointments">
                <Icon className="ellipsis green" />
              </span>
            )}
          </Label>
        </div>
        <div className="MechanicBox-toggle">
          <div className="MechanicBox-pinned">
            <Icon className={mechanicsPinned.includes(mechanic.id) ? "pin" : "circle regular"} onClick={() => handlePinnedMechanic(mechanic)} />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useDealersLocations, useUpdate } from "hooks";
import { Appointment, DealerLocation } from "models";
import { sortAppointments } from "modules/Appointments/util";
import ApiInstance from "util/Api";
import { currentDate } from "util/common";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

interface AppointmentPinContext {
  appointments: Appointment[] | undefined;
}

interface MutationArgs {
  appointment_id: number;
  is_pinned: boolean;
  is_pinned_manually: boolean;
}

const useAppointmentPin = () => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const { selectedLocation }: { selectedLocation: DealerLocation | undefined } = useDealersLocations();
  const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;

  const mutation = useMutation<MutationArgs, Error, MutationArgs, AppointmentPinContext>({
    mutationFn: async (params: MutationArgs) => {
      const response = await ApiInstance.post(`/appointments/update_pin`, params);
      return response.data;
    },
    onMutate: params => {
      const appointments =
        queryClient.getQueryData<Appointment[]>(["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }]) || [];

      if (appointments?.length) {
        const updatedAppointments: Appointment[] = appointments?.map(app => {
          if (app.id === params.appointment_id) {
            return { ...app, is_pinned: params.is_pinned, is_pinned_manually: params.is_pinned_manually } as Appointment;
          }

          return app;
        });

        const sortedAppointments = sortAppointments(updatedAppointments, selectedLocation?.statuses);

        queryClient.setQueryData(["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }], sortedAppointments);
      }

      const appointmentDetailsViewKey = [
        "realtime",
        {
          ...queryKeys.appointmentDetails.view,
          params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(params.appointment_id) }
        }
      ];

      const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      if (appointmentData) {
        const updatedAppointment = { ...appointmentData, is_pinned: params.is_pinned, is_pinned_manually: params.is_pinned_manually };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }

      return { appointments };
    },
    onError: (error, _variables, context) => {
      console.error(error);

      if (context?.appointments)
        queryClient.setQueryData(["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }], context.appointments);
    }
  });

  return mutation.mutate;
};

export default useAppointmentPin;

import { faRegistered, faShieldHalved, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PIN_TYPE } from "models";

type PinTypeIconProps = {
  pin_type_id: number;
};

export const PinTypeIcon = ({ pin_type_id }: PinTypeIconProps) => {
  if (pin_type_id === PIN_TYPE.Warranty) return <FontAwesomeIcon icon={faShieldHalved} />;
  if (pin_type_id === PIN_TYPE.Recall) return <FontAwesomeIcon icon={faRegistered} />;
  if (pin_type_id === PIN_TYPE.Remarks) return <FontAwesomeIcon icon={faThumbTack} />;
  return <FontAwesomeIcon icon={faThumbTack} />;
};

import ApiInstance from "util/Api";

interface UploadImageResponse {
  data: {
    URL: string;
  };
}

class Service {
  static async uploadImage(url: string, fd: FormData): Promise<UploadImageResponse> {
    return ApiInstance.post(url, fd);
  }
}

export default Service;

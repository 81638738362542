import { useQuery, useQueryClient } from "@tanstack/react-query";

import { WebSocketMessage } from "components";
import { useDealersLocations, useRealTimeQueryOptions } from "hooks";
import { Appointment, AppointmentNote } from "models";
import { MechanicWithAppointments } from "modules/Dayplanner/utils";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

type DayplannerData = {
  mechanics: MechanicWithAppointments[] | [];
  appointments: Appointment[] | [];
  loading: boolean;
  error: Error | null;
};

export const useDayplannerData = (): DayplannerData => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mechanicsQuery = useQuery({
    queryKey: ["realtime", { ...queryKeys.dayplanner.mechanics, params: { dealer_location_id: selectedLocation?.id } }],
    queryFn: async ({ queryKey }) => {
      if (!selectedLocation?.id) throw new Error("invalid location");

      const { baseUrl, endpoint, params } = queryKey[1] as BackendQueryKey;
      const response = await ApiInstance.post(endpoint, params, baseUrl);
      return response?.data;
    },
    enabled: !!selectedLocation?.id,
    refetchOnMount: "always"
  });

  const appointmentsQueryKey = ["realtime", { ...queryKeys.dayplanner.appointments, params: { dealer_location_id: selectedLocation?.id } }];

  const getAppointmentsCache = (): Appointment[] | undefined => queryClient.getQueryData(appointmentsQueryKey);

  const appointmentsQueryListeners = selectedLocation
    ? [
        {
          model: "Appointment",
          filter: { dealer_location_id: selectedLocation.id },
          action: "create",
          callback: (message: WebSocketMessage) => {
            const appointments = getAppointmentsCache() as Appointment[];
            const update = message.data as Appointment;

            if (!appointments || !update) return;

            const updatedAppointments = appointments ? [message.data] : [...appointments, update];

            queryClient.setQueryData(appointmentsQueryKey, updatedAppointments);
          }
        },

        {
          model: "Appointment",
          filter: { dealer_location_id: selectedLocation.id },
          action: "update",
          callback: (message: WebSocketMessage) => {
            const appointments = getAppointmentsCache();
            const update = message.data as Appointment;

            if (!appointments || !update) return;

            const updatedAppointments = appointments.map(appointment => {
              if (appointment.id === message.id) return { ...appointment, ...update };
              return appointment;
            });

            queryClient.setQueryData(appointmentsQueryKey, updatedAppointments);
          }
        },

        {
          model: "AppointmentNote",
          action: "create",
          callback: (message: WebSocketMessage) => {
            const appointments = getAppointmentsCache();
            const update = message.data as AppointmentNote;

            if (!appointments || !update) return;

            const updatedAppointments = appointments.map(appointment => {
              if (appointment.id === update.appointment_id) {
                if (appointment.notes) return { ...appointment, notes: [...appointment.notes, update] };
                return { ...appointment, notes: [update] };
              }
              return appointment;
            });

            queryClient.setQueryData(appointmentsQueryKey, updatedAppointments);
          }
        },

        {
          model: "AppointmentNote",
          action: "update",
          callback: (message: WebSocketMessage) => {
            const appointments = getAppointmentsCache();
            const update = message.data as AppointmentNote;

            if (!appointments || !update) return;

            const updatedAppointments = appointments.map(appointment => {
              if (appointment.id === update.appointment_id && appointment.notes) {
                return { ...appointment, notes: appointment.notes.map(note => (note.id === message.id ? { ...note, ...update } : note)) };
              }
              return appointment;
            });

            queryClient.setQueryData(appointmentsQueryKey, updatedAppointments);
          }
        },

        {
          model: "AppointmentNote",
          action: "delete",
          callback: (message: WebSocketMessage) => {
            const appointments = getAppointmentsCache();
            const update = message.data as AppointmentNote;

            if (!appointments || !update) return;

            const updatedAppointments = appointments.map(appointment => {
              if (appointment.id === update.appointment_id && appointment.notes)
                return { ...appointment, notes: appointment.notes.filter(note => note.id !== update.id) };
              return appointment;
            });

            queryClient.setQueryData(appointmentsQueryKey, updatedAppointments);
          }
        }
      ]
    : [];

  const appointmentsQuery = useRealTimeQueryOptions({
    queryKey: appointmentsQueryKey,
    queryFn: async ({ queryKey }) => {
      if (!selectedLocation?.id) throw new Error("invalid location");

      const { baseUrl, endpoint, params } = queryKey[1] as BackendQueryKey;
      const response = await ApiInstance.post(endpoint, params, baseUrl);
      return response?.data;
    },
    enabled: !!selectedLocation?.id,
    refetchOnMount: "always",
    listeners: appointmentsQueryListeners
  });

  const loading = mechanicsQuery.isFetching || appointmentsQuery.isFetching;
  const error = mechanicsQuery.error || appointmentsQuery.error;

  return {
    mechanics: mechanicsQuery.data || [],
    appointments: appointmentsQuery.data || [],
    loading,
    error
  } as DayplannerData;
};

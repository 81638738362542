import { Message } from "semantic-ui-react";

export const InfoMessages = () => {
  return (
    <>
      <Message color="green">
        <Message.Header>Success Message</Message.Header>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste aliquid iure accusamus soluta dolorem itaque, expedita nesciunt sit incidunt delectus ex quo
          eligendi nisi, animi iusto unde magni perferendis fugiat?
        </p>
      </Message>

      <Message color="blue">
        <Message.Header>Info Message</Message.Header>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste aliquid iure accusamus soluta dolorem itaque, expedita nesciunt sit incidunt delectus ex quo
          eligendi nisi, animi iusto unde magni perferendis fugiat?
        </p>
      </Message>

      <Message color="red">
        <Message.Header>Alert Message</Message.Header>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste aliquid iure accusamus soluta dolorem itaque, expedita nesciunt sit incidunt delectus ex quo
          eligendi nisi, animi iusto unde magni perferendis fugiat?
        </p>
      </Message>

      <Message color="yellow">
        <Message.Header>Warning Message</Message.Header>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste aliquid iure accusamus soluta dolorem itaque, expedita nesciunt sit incidunt delectus ex quo
          eligendi nisi, animi iusto unde magni perferendis fugiat?
        </p>
      </Message>
    </>
  );
};

import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Form, Icon, Input } from "semantic-ui-react";

import { useAuth } from "hooks";
import "modules/Auth/components/ResetPasswordRequest/ResetPasswordRequest.scss";
// import { ResetPasswordRequestProps } from "modules/Auth/interfaces";
import { getBrowserLanguage, validateEmail } from "util/common";
import { ITranslation } from "util/interfaces";

export const ResetPasswordRequest = () => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [credentials, setCredentials] = useState({
    email: ""
  });

  const { resetPasswordRequestMutation } = useAuth();
  const { isPending: isLoading, error: resetPasswordRequestError } = resetPasswordRequestMutation;

  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    setErrorMsg("");
  }, [credentials]);

  useEffect(() => {
    if (resetPasswordRequestError) {
      setErrorMsg((resetPasswordRequestError as AxiosError<any>).response?.data.errors.join(" "));
    }
  }, [resetPasswordRequestError]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (credentials.email) {
      const emailValidation = validateEmail(credentials.email);
      if (emailValidation) {
        let selectedLanguage = localStorage.getItem("locale");
        if (!selectedLanguage) {
          selectedLanguage = getBrowserLanguage();
        }
        const data = await resetPasswordRequestMutation.mutateAsync({ email: credentials.email, language_code: selectedLanguage });
        if (data) {
          setSuccessMsg(t("v8_email_sent_with_instructions").message || "An email with instructions has been sent to the address you provided.");
        }
      } else {
        setErrorMsg(t("v8_check_your_input").message || "Please check your input.");
      }
    }
  };

  return (
    <div className="ResetPasswordRequest">
      <div className="ResetPasswordRequest-header">
        <p>{t("v8_reset_password").message || "Reset Password"}</p>
        <Link to={`/login`} onClick={() => setSuccessMsg("")} className="ResetPasswordRequest-backBtn">
          <Icon className="angle left" />
          {t("v8_back_to_login").message || "Back to Login"}
        </Link>
      </div>
      <div className="mt-15">
        {!successMsg ? (
          <Form onSubmit={onSubmit}>
            <Form.Field error={!!errorMsg}>
              <Input
                name="email"
                id="email"
                value={credentials.email}
                onChange={onInputChange}
                icon={`mail ${errorMsg ? "" : "green"}`}
                iconPosition="left"
                type="text"
                placeholder={t("v8_email").message || "Email"}
                required
                error={!!errorMsg}
              />
            </Form.Field>
            <div className="mt-25">
              <Button disabled={isLoading} loading={isLoading} type="submit" id="submit-reset-password" color="green" fluid>
                {t("v8_reset_password").message || "Reset Password"}
              </Button>
            </div>
          </Form>
        ) : (
          <div className="ResetPasswordRequest-success">{successMsg}</div>
        )}
      </div>

      {errorMsg && (
        <Form>
          <div className="mt-15">
            <Form.Field error>
              <Input value={errorMsg} readOnly />
            </Form.Field>
          </div>
        </Form>
      )}
    </div>
  );
};

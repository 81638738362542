import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import { ITranslation } from "util/interfaces";

import { KeylockerCommAgreement } from ".";

type AgreementsModalProps = {
  agreement: KeylockerCommAgreement;
  onCloseAgreementModal: () => void;
};

export const AgreementsModal = ({ agreement, onCloseAgreementModal }: AgreementsModalProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Modal open={!!agreement} dimmer={true} closeOnDimmerClick={false} onClose={onCloseAgreementModal}>
      <Modal.Header>
        {agreement.name}
        <Button onClick={onCloseAgreementModal} floated="right" color="green">
          {t("v8_close").message || "Close"}
        </Button>
      </Modal.Header>
      <Modal.Content scrolling>
        <div dangerouslySetInnerHTML={{ __html: agreement.text }}></div>
      </Modal.Content>
    </Modal>
  );
};

import { Appointment, Car, CommunicationAgreement, Customer, KeylockerRemark, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum KEYLOCKER_COMMUNICATION_STATUS {
  KeylockerDropPinSent = 1,
  KeylockerCheckInOpened,
  KeylockerCheckInAnswered,
  KeylockerPickUpPinSent,
  KeylockerCheckInClosed
}

export enum KEYLOCKER_COMMUNICATION_EVENT_TYPE {
  KeylockerDropPinSent = 1,
  KeylockerCheckInOpened,
  KeylockerCheckInAnswered,
  KeylockerPickUpPinSent,
  KeylockerCheckInClosed
}

export enum KEYLOCKER_PIN_REQUEST_TYPES {
  Phone = 1,
  RegistrationNumber,
  TwoFaDisabled,
  OnlineCheckIn
}

export class KeylockerCommunicationEvent extends ApiLoadable {
  id: number = 0;
  keylocker_communication_id: number = 0;
  box_number?: number | null = null;
  correct_phone: string = "";
  pin_request_type: number = 0;
  user_id: number | null = null;
  type: KEYLOCKER_COMMUNICATION_EVENT_TYPE = KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent;
  user?: User | null = null;
  created_on: string = "";
  updated_on: string = "";

  private _receivers?: KeyLockerCommunicationReceiver[] | null = null;

  get receivers(): KeyLockerCommunicationReceiver[] {
    return this._receivers ? this._receivers : [];
  }

  set receivers(results: KeyLockerCommunicationReceiver[] | null | undefined) {
    this._receivers = results;
  }
}

export class KeyLockerCommunicationReceiver extends ApiLoadable {
  id: number = 0;
  communication_channel_id: number = 0;
  destination: string = "";
  status: number = 0;
  reason: string = "";
  sms_gateway_id: number | null = null;
  twilio_message_sid: number | null = null;
  mailgun_message_id: string | null = null;
  chatshipper_conversation_id: number | null = null;
  keylocker_communication_id: number = 0;
}

export interface KeylockerIntervention {
  id: number;
  title: string;
  description: string;
  price: number;
  vat?: number;
  keylocker_communication_id: number;
}

export class KeylockerCommunication extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  status: KEYLOCKER_COMMUNICATION_STATUS = KEYLOCKER_COMMUNICATION_STATUS.KeylockerDropPinSent;
  is_checking_out: boolean = false;
  customer_id: number | null = null;
  appointment_id: number = 0;
  car_id: number = 0;
  dealer_location_id: number = 0;
  customer?: Customer | null = null;
  appointment?: Appointment | null = null;
  car?: Car | null = null;
  user?: User | null = null;
  remark?: KeylockerRemark | null = null;

  private _events?: KeylockerCommunicationEvent[] | null = null;

  get events(): KeylockerCommunicationEvent[] {
    return this._events ? this._events : [];
  }

  set events(results: KeylockerCommunicationEvent[] | null | undefined) {
    this._events = results;
  }

  private _agreed_interventions?: KeylockerIntervention[] | null = null;

  get agreed_interventions(): KeylockerIntervention[] {
    return this._agreed_interventions ? this._agreed_interventions : [];
  }

  set agreed_interventions(agreed_interventions: KeylockerIntervention[] | null | undefined) {
    this._agreed_interventions = agreed_interventions;
  }

  private _declined_interventions?: KeylockerIntervention[] | null = null;

  get declined_interventions(): KeylockerIntervention[] {
    return this._declined_interventions ? this._declined_interventions : [];
  }

  set declined_interventions(declined_interventions: KeylockerIntervention[] | null | undefined) {
    this._declined_interventions = declined_interventions;
  }

  private _agreements?: CommunicationAgreement[] | null = null;

  get agreements(): CommunicationAgreement[] {
    return this._agreements ? this._agreements : [];
  }

  set agreements(agreements: CommunicationAgreement[] | null | undefined) {
    this._agreements = agreements;
  }

  settings?: Record<string, any>[] | null = null;
}

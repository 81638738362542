import { withTranslation } from "react-i18next";
import { Button, Form, Icon } from "semantic-ui-react";

import { TabData, Tabs } from "components";
import { DeskCheckIn, DiagnoseOverview, General, OnlineCheckIn, RepairOverview, Support, Variables } from "modules/CustomerCom/components";
import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

const tabsData: Map<string, TabData> = new Map([
  ["general", { data: [], id: "general", title: "General" }],
  ["variables", { data: [], id: "variables", title: "Variables" }],
  ["deskCheckIn", { data: [], id: "deskCheckIn", title: "Desk Check in" }],
  ["onlineCheckIn", { data: [], id: "onlineCheckIn", title: "Online Check in" }],
  ["diagnoseOverview", { data: [], id: "diagnoseOverview", title: "Diagnose Overview" }],
  ["repairOverview", { data: [], id: "repairOverview", title: "Repair Overview" }],
  ["support", { data: [], id: "support", title: "Support" }]
]);

interface CustomerComProps {
  t: ITranslation;
}

const CustomerCom = ({ t }: CustomerComProps) => {
  return (
    <Form>
      <div className="customer-com-container">
        <div className="row center">
          <div className="full customer-com-tabs">
            <Tabs data={tabsData} />
          </div>
          <div className="save">
            <Button color="green">
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </div>
        <General tabId={tabsData.get("general")?.id as string} />
        <Variables tabId={tabsData.get("variables")?.id as string} />
        <OnlineCheckIn tabId={tabsData.get("onlineCheckIn")?.id as string} />
        <DeskCheckIn tabId={tabsData.get("deskCheckIn")?.id as string} />
        <DiagnoseOverview tabId={tabsData.get("diagnoseOverview")?.id as string} />
        <RepairOverview tabId={tabsData.get("repairOverview")?.id as string} />
        <Support tabId={tabsData.get("support")?.id as string} />
      </div>
    </Form>
  );
};

export default withTranslation()(CustomerCom);

import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, GridColumn, Icon } from "semantic-ui-react";

import { Can, DetailsCards, PinTypeIcon, TabData, useCan } from "components";
import InterventionModal from "components/InterventionModal";
import { useDealersLocations, usePinOptions } from "hooks";
import { Appointment, COMMUNICATION_EVENT_TYPE, Intervention, PinOptions, STATUS_IDENTIFIER } from "models";
import InterventionItem, { PinType } from "modules/AppointmentDetail/components/Interventions/InterventionItem";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

type InterventionsProps = {
  isExpanded: boolean;
  appointment: Appointment | null;
  tabData: TabData;
  showVat: boolean;
  hasChanges: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

export const Interventions = ({ isExpanded, appointment, showVat, tabData, hasChanges, setHasChanges }: InterventionsProps) => {
  const [interventionModal, setInterventionModal] = useState(false);
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const canUpdateAppointments = useCan("update", "appointments");
  const t = useTranslation().t as ITranslation;

  const { data: pinOptions } = usePinOptions();
  const onRequestClose = () => {
    setInterventionModal(false);
  };
  const pinTypeData: PinType[] = pinOptions?.pin_types?.map((pinType: PinOptions) => {
    return { key: pinType.name, text: t(`${pinType.name}`).message, value: pinType.id, icon: <PinTypeIcon pin_type_id={pinType.id} /> };
  });

  const { selectedLocation: location } = useDealersLocations();

  const showPriceInVat = location?.include_vat && showVat;

  const total = useMemo(() => {
    if (appointment) {
      const sum = appointment.interventions?.reduce((sum: number, num: Intervention) => sum + num.price, 0) || 0;
      return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
    }
    return 0;
  }, [appointment, location, showVat]);

  const customerApprovedTotal = useMemo(() => {
    if (appointment) {
      const sum = appointment.interventions?.reduce((sum: number, num: Intervention) => sum + (num.customer_ok ? num.price : 0), 0) || 0;
      return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
    }
    return 0;
  }, [appointment, location, showVat]);

  return (
    <>
      <DetailsCards
        id={tabData.id}
        title={`${t("interventions").message || "Interventions"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
        icon="screwdriver wrench"
        isExpanded={isExpanded}
        rightContent={
          appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CarReadyStatus &&
          appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus &&
          canUpdateAppointments && (
            <Can I="add" the="interventions">
              <div className="full between">
                <Button className="-appointment-status icon new-intervention" onClick={() => setInterventionModal(true)}>
                  <Icon className="plus" color="green" size="big" />
                </Button>
              </div>
            </Can>
          )
        }
      >
        <div className="Interventions">
          <div className="Interventions-container">
            {appointment?.interventions?.map((item: Intervention, index: number) => {
              return (
                <InterventionItem
                  activeIntervention={activeTab}
                  onRequestCloseTabs={setActiveTab}
                  key={item.id}
                  intervention={item}
                  index={index}
                  pinTypeData={pinTypeData}
                  showPriceInVat={showPriceInVat}
                  dmsPriceEnabled={location?.dms_price_enabled}
                  diagnose_overview_event={appointment?.customer_communication?.events?.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened)}
                  hasChanges={hasChanges}
                  setHasChanges={setHasChanges}
                />
              );
            })}
          </div>

          <Grid className="total-amount-container">
            <GridColumn width={activeTab ? 7 : 10} />
            <GridColumn width={activeTab ? 4 : 3} className="float-right">
              <p>
                {`${t("v8_total_amount").message || "Total amount"}${showPriceInVat ? `, ${t("v8_including_VAT").message || "including VAT"}:` : ":"}`}{" "}
                <strong>{total} €</strong>
              </p>
            </GridColumn>

            <GridColumn width={activeTab ? 5 : 3} className=" float-right">
              <p>
                {`${t("v8_approved_total").message || "Approved total"}${showPriceInVat ? `, ${t("v8_including_VAT").message || "including VAT"}:` : ":"}`}{" "}
                <strong>{customerApprovedTotal} €</strong>
              </p>
            </GridColumn>
          </Grid>
        </div>
      </DetailsCards>
      <InterventionModal
        key={String(interventionModal)}
        size="large"
        open={interventionModal}
        onClose={onRequestClose}
        closeOnDimmerClick
        className="InterventionModal"
        appointmentId={appointment?.id}
      />
    </>
  );
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { WebSocketComponent } from "components";
import { useApp } from "hooks";
import { PATHS } from "router/paths";
import ApiInstance from "util/Api";
import apiInstance from "util/Api";

export const Auth = () => {
  const history = useHistory();
  useApp();
  useEffect(() => {
    ApiInstance.setHistory(history);

    const broadcastChannel = new BroadcastChannel("auth");

    const handleBroadcastMessage = (event: MessageEvent) => {
      if (event.data.type === "LOGOUT") {
        WebSocketComponent.disconnect();
        apiInstance.clearSessionCookie();

        if (window.location.pathname !== PATHS.LOGIN) {
          history.replace(PATHS.LOGIN);
        }
      }
    };

    broadcastChannel.addEventListener("message", handleBroadcastMessage);

    return () => {
      broadcastChannel.removeEventListener("message", handleBroadcastMessage);
      broadcastChannel.close();
    };
  }, [history]);

  return null;
};

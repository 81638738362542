import { Appointment, Keyloop, NextLane } from "models";

export type KeyloopResults = {
  claire: Appointment[];
  keyloop: Keyloop[];
};

export type NextLaneResults = {
  claire: Appointment[];
  nextlane: NextLane[];
};

export const getFormattedAndSortedKeyloopResults = (keyloopData: KeyloopResults) => {
  const keyloopResults = keyloopData.keyloop.map((item, idx) => ({
    id: item.repairOrderId,
    title: idx + "",
    contact_name: item.contact.name || "",
    make: item.vehicle.description,
    due_in: item.appointment.dueInDateTime,
    due_out: item.appointment.dueOutDateTime,
    wo_nr: item.repairOrderId,
    ref_nr: item.vehicle.vin,
    reg_number: item.vehicle.licensePlate,
    status_identifier: item.status,
    search_category: "keyloop"
  }));
  const sortedKeyloopResults = keyloopResults.sort((a, b) => new Date(a.due_in).valueOf() - new Date(b.due_in).valueOf());

  const claireResults = keyloopData.claire.map(item => ({ ...item, search_category: "claire" }));
  return [...sortedKeyloopResults, ...claireResults];
};

export const getFormattedAndSortedNextlaneResults = (nextLaneData: NextLaneResults) => {
  const nextLaneResults = nextLaneData.nextlane.map((item, idx) => ({
    id: item.InternalFolderID,
    title: idx + "",
    contact_name: item?.ClientOwner?.FirstName || "",
    make: item?.Vehicle?.BrandDescr || "",
    due_in: item?.WorkBeginDate || "",
    due_out: item?.WorkEndDate || "",
    wo_nr: item?.InternalFolderID || "",
    ref_nr: item?.Vehicle?.VIN || "",
    reg_number: item?.Vehicle?.RegistrationNumber || "",
    status_identifier: item?.IsFinished || false,
    search_category: "nextlane"
  }));
  const sortedNextLaneResults = nextLaneResults.sort((a, b) => new Date(a.due_in).valueOf() - new Date(b.due_in).valueOf());

  const claireResults = nextLaneData.claire.map(item => ({ ...item, search_category: "claire" }));
  return [...sortedNextLaneResults, ...claireResults];
};

export const getSortedClaireResults = (data: Appointment[]) => {
  const results = data.map(item => ({ ...item, search_category: "claire" }));
  const sortedClaireResults = results.sort((a, b) => new Date(a.created_on).valueOf() - new Date(b.created_on).valueOf());

  return sortedClaireResults;
};

import { useMemo } from "react";

import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import { useDealersLocations, useUser } from "hooks";
import { Appointment, STATUS_IDENTIFIER } from "models";
import { capitalizeFirstLetter } from "util/common";
import { ITranslation } from "util/interfaces";

type UseGetDropdownOptionsParams = {
  appointment: Appointment;
  t: ITranslation;
};

export type PhoneOption =
  | {
      key: number;
      text: string;
      value: string;
      description: any;
    }
  | { text: any; value: string; key?: undefined; description?: undefined };

export const useGetDropdownOptions = ({ appointment, t }: UseGetDropdownOptionsParams) => {
  const user = useUser();
  const { selectedLocation } = useDealersLocations();
  const keys = Object.keys(APPOINTMENT_NOTE_TYPES).filter(key => {
    if (APPOINTMENT_NOTE_TYPES[key as keyof typeof APPOINTMENT_NOTE_TYPES] === APPOINTMENT_NOTE_TYPES.TIRESCANNER) return false;
    if (APPOINTMENT_NOTE_TYPES[key as keyof typeof APPOINTMENT_NOTE_TYPES] === APPOINTMENT_NOTE_TYPES.INFO && !selectedLocation?.diagnose_status_visible) return false;
    return true;
  });

  const infoNoteOptionDisabled = [STATUS_IDENTIFIER.CarCheckStartedStatus, STATUS_IDENTIFIER.BackOrderStatus].includes(appointment?.appointment_status_identifier);

  const carReadyOptionDisabled = [
    STATUS_IDENTIFIER.CustomerOKStatus,
    STATUS_IDENTIFIER.CarReadyStatus,
    STATUS_IDENTIFIER.QualityCheckStatus,
    STATUS_IDENTIFIER.QualityCheckPlusRepairOverview
  ].includes(appointment?.appointment_status_identifier);

  const getIsDropdownOptionDisabled = (type: number) => {
    if (type === APPOINTMENT_NOTE_TYPES.BACK_ORDER) {
      return (
        ![
          STATUS_IDENTIFIER.CustomerOKStatus,
          STATUS_IDENTIFIER.CarReadyStatus,
          STATUS_IDENTIFIER.QualityCheckStatus,
          STATUS_IDENTIFIER.CarOkPlusRepairOverview,
          STATUS_IDENTIFIER.QualityCheckPlusRepairOverview
        ].includes(appointment?.appointment_status_identifier) || !user?.acl?.some(({ activity }) => activity === "new-status")
      );
    }
    if (type === APPOINTMENT_NOTE_TYPES.BILL_NOTE) {
      return (
        !selectedLocation?.repair_overview_enabled ||
        ![
          STATUS_IDENTIFIER.CustomerOKStatus,
          STATUS_IDENTIFIER.CarReadyStatus,
          STATUS_IDENTIFIER.QualityCheckStatus,
          STATUS_IDENTIFIER.CarOkPlusRepairOverview,
          STATUS_IDENTIFIER.QualityCheckPlusRepairOverview
        ].includes(appointment?.appointment_status_identifier) ||
        !user?.acl?.some(({ activity }) => activity === "create" || activity === "update")
      );
    }
    if (type === APPOINTMENT_NOTE_TYPES.PARKING) {
      return !user?.acl?.some(({ activity, resource }) => resource === "appointment-notes-parking-location" && activity === "create");
    }
    return (
      (type === APPOINTMENT_NOTE_TYPES.INFO && infoNoteOptionDisabled) ||
      (carReadyOptionDisabled && [APPOINTMENT_NOTE_TYPES.BACK_ORDER, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(type))
    );
  };

  const noteTypeOptions = useMemo(
    () =>
      keys.map((key: string) => {
        const value = APPOINTMENT_NOTE_TYPES[key as keyof typeof APPOINTMENT_NOTE_TYPES];

        return {
          key,
          text: t(`v8_${key.toLocaleLowerCase()}`).message || capitalizeFirstLetter(key.toLocaleLowerCase().replace(/_/g, " ")),
          value,
          disabled: getIsDropdownOptionDisabled(value)
        };
      }),
    [keys]
  );

  const phoneOptions = useMemo(() => {
    const options: PhoneOption[] = [];

    if (!appointment) return options;

    if (appointment.customer_driver?.id) {
      if (appointment.customer_driver.tel_mobile_business) {
        options.push({
          key: 1,
          text: appointment.customer_driver.tel_mobile_business,
          value: `${appointment.customer_driver.tel_mobile_business};${appointment.customer_driver.id}`,
          description: t("v8_driver_mobile_business").message || "Driver Mobile Business"
        });
      }

      if (appointment.customer_driver.tel_mobile_private) {
        options.push({
          key: 2,
          text: appointment.customer_driver.tel_mobile_private,
          value: `${appointment.customer_driver.tel_mobile_private};${appointment.customer_driver.id}`,
          description: t("v8_driver_mobile_private").message || "Driver Mobile Private"
        });
      }

      if (appointment.customer_driver.tel_business_nr) {
        options.push({
          key: 3,
          text: appointment.customer_driver.tel_business_nr,
          value: `${appointment.customer_driver.tel_business_nr}";"${appointment.customer_driver.id}`,
          description: t("v8_driver_business").message || "Driver Business"
        });
      }

      if (appointment.customer_driver.tel_private_nr) {
        options.push({
          key: 4,
          text: appointment.customer_driver.tel_private_nr,
          value: `${appointment.customer_driver.tel_private_nr}";"${appointment.customer_driver.id}`,
          description: t("v8_driver_private").message || "Driver Private"
        });
      }
    }

    if (appointment.customer_contract?.id) {
      if (appointment.customer_contract.tel_mobile_business) {
        options.push({
          key: 5,
          text: appointment.customer_contract.tel_mobile_business,
          value: `${appointment.customer_contract.tel_mobile_business}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_mobile_business").message || "Contract Mobile Business"
        });
      }

      if (appointment.customer_contract.tel_mobile_private) {
        options.push({
          key: 6,
          text: appointment.customer_contract.tel_mobile_private,
          value: `${appointment.customer_contract.tel_mobile_private}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_mobile_private").message || "Contract Mobile Private"
        });
      }

      if (appointment.customer_contract.tel_business_nr) {
        options.push({
          key: 7,
          text: appointment.customer_contract.tel_business_nr,
          value: `${appointment.customer_contract.tel_business_nr}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_business").message || "Contract Business"
        });
      }

      if (appointment.customer_contract.tel_private_nr) {
        options.push({
          key: 8,
          text: appointment.customer_contract.tel_private_nr,
          value: `${appointment.customer_contract.tel_private_nr}";"${appointment.customer_contract.id}`,
          description: t("v8_contract_private").message || "Contract Private"
        });
      }
    }

    if (appointment.customer_owner?.id) {
      if (appointment.customer_owner.tel_mobile_business) {
        options.push({
          key: 9,
          text: appointment.customer_owner.tel_mobile_business,
          value: `${appointment.customer_owner.tel_mobile_business}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_mobile_business").message || "Owner Mobile Business"
        });
      }

      if (appointment.customer_owner.tel_mobile_private) {
        options.push({
          key: 10,
          text: appointment.customer_owner.tel_mobile_private,
          value: `${appointment.customer_owner.tel_mobile_private}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_mobile_private").message || "Owner Mobile Private"
        });
      }

      if (appointment.customer_owner.tel_business_nr) {
        options.push({
          key: 11,
          text: appointment.customer_owner.tel_business_nr,
          value: `${appointment.customer_owner.tel_business_nr}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_business").message || "Owner Business"
        });
      }

      if (appointment.customer_owner.tel_private_nr) {
        options.push({
          key: 11,
          text: appointment.customer_owner.tel_private_nr,
          value: `${appointment.customer_owner.tel_private_nr}";"${appointment.customer_owner.id}`,
          description: t("v8_owner_private").message || "Owner Private"
        });
      }
    }

    options.push({
      text: t("v8_other").message || "Other",
      value: "other"
    });

    return options;
  }, []);

  return { phoneOptions, noteTypeOptions };
};

import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Button, Icon, Modal, Tab } from "semantic-ui-react";

import { DetailsCards } from "components";
import { Appointment, COMMUNICATION_EVENT_TYPE, CommunicationAgreement } from "models";
import "modules/AppointmentDetail/components/CommunicationLog/CommunicationLog.scss";
import { AgreementModal, AppointmentInfo, TabContent } from "modules/AppointmentDetail/components/CommunicationLog/components";
import { ITranslation } from "util/interfaces";

type CommunicationLogProps = {
  appointment: Appointment;
};

type TabPaneType = {
  menuItem: string;
  render: () => any;
};

export enum COMMUNICATION_LOG_TAB {
  OnlineCheckin = 1,
  DiagnoseOverview,
  DiagnoseOverviewUpdate,
  RepairOverview
}

export const CommunicationLog = ({ appointment }: CommunicationLogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [agreement, setAgreement] = useState<CommunicationAgreement | null>(null);
  const contentToPrint = useRef(null);
  const t = useTranslation().t as ITranslation;

  const handleToggleLog = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const handleSelectAgreement = (agreement: CommunicationAgreement) => {
    setAgreement(agreement);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `CommunicationLog_${appointment.wo_nr}_${moment().format("DD_MM_YY_HH_mm")}`,
    onAfterPrint: () => setPrintLoading(false),
    onBeforePrint: () => setPrintLoading(true)
  });

  const constructTabs = () =>
    useMemo(() => {
      const panes: TabPaneType[] = [];

      appointment?.customer_communication?.events?.forEach(event => {
        switch (event.type) {
          case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
            panes.push({
              menuItem: t("v8_online_check_in").message || "Online Check-in",
              render: () => <TabContent tab={COMMUNICATION_LOG_TAB.OnlineCheckin} id={event.id} appointment={appointment} onSelectAgreement={handleSelectAgreement} />
            });
            break;

          case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
            panes.push({
              menuItem: t("v8_diagnose_overview").message || "Diagnose Overview",
              render: () => <TabContent tab={COMMUNICATION_LOG_TAB.DiagnoseOverview} id={event.id} appointment={appointment} onSelectAgreement={handleSelectAgreement} />
            });
            break;

          case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
            panes.push({
              menuItem: t("v8_diagnose_overview_update").message || "Diagnose Overview Update",
              render: () => (
                <TabContent tab={COMMUNICATION_LOG_TAB.DiagnoseOverviewUpdate} id={event.id} appointment={appointment} onSelectAgreement={handleSelectAgreement} />
              )
            });
            break;

          case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
            panes.push({
              menuItem: t("v8_repair_overview").message || "Repair overview",
              render: () => <TabContent tab={COMMUNICATION_LOG_TAB.RepairOverview} id={event.id} appointment={appointment} />
            });
            break;

          default:
            break;
        }
      });

      return panes;
    }, [appointment?.customer_communication?.events]);

  const tabs = constructTabs();

  return (
    <>
      <div className="CommunicationLog-button" onClick={handleToggleLog}>
        <DetailsCards title={t("v8_customer_comm_log").message || "Customer Communication Log"} icon="user" rightContent={null} showExpandArrow={false} button />
      </div>
      <Modal closeOnDimmerClick={false} open={isOpen} onOpen={handleToggleLog} onClose={handleToggleLog} className="CommunicationLog-Modal">
        <Modal.Header>
          <div>
            <Icon className="list" />
            {t("v8_customer_comm_log").message || "Customer communication log"}
          </div>

          <div>
            <Button onClick={() => handlePrint(null, () => contentToPrint?.current)} loading={printLoading} className="action-btn" color="green">
              {t("v8_print").message || "Print"}
              <Icon className="print" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <div id="CommunicationLog-Content" ref={contentToPrint}>
            <AppointmentInfo appointment={appointment} />
            {tabs.length > 0 && <Tab menu={{ secondary: true }} panes={tabs} />}
          </div>
        </Modal.Content>
        <Modal.Actions className="CommunicationLog-ModalActions">
          <Button className="action-btn" color="grey" onClick={handleToggleLog}>
            {t("v8_close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
      <AgreementModal isOpen={!!agreement} onClose={() => setAgreement(null)} agreement={agreement} />
    </>
  );
};

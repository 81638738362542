import "components/BounceLoader/BounceLoader.scss";

export const BounceLoader = ({ show }: { show: boolean }) => {
  if (!show) return null;

  return (
    <div className="Bounce-Loader-Placeholder">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

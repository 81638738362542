import { Icon } from "semantic-ui-react";

import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import "modules/AppointmentDetail/components/AppointmentNotes/components/AppointmentNoteEditBtn/AppointmentNoteEditBtn.scss";

type AppointmentNoteEditBtnProps = {
  type: number;
  onSelectNote?: () => void;
};

export const AppointmentNoteEditBtn = ({ type, onSelectNote }: AppointmentNoteEditBtnProps) => {
  switch (type) {
    case APPOINTMENT_NOTE_TYPES.WO:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#2D85D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.MAIN:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#FF020233" }} onClick={onSelectNote}>
          <Icon className="pen to square" color="red" />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21BA4533" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#21BA45" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21BA4533" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#21BA45" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} onClick={onSelectNote}>
          <Icon className="pen to square" color="black" />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "rgba(200, 54, 40, 0.2)" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#C83628" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21A1D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.PARKING:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21A1D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    default:
      return null;
  }
};

import { capitalize } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Label, Loader } from "semantic-ui-react";

import { AppointmentDataProps, SearchListType, StatusCell } from "components";
import "components/SearchList/SearchDropdown/SearchDropdown.scss";
import useSearchDropdown from "components/SearchList/SearchDropdown/useSearchDropdown";
import { ITranslation } from "util/interfaces";

type SearchDropdownProp = {
  data: AppointmentDataProps[];
  onSelect: (id: number) => void;
  onClose?: () => void;
  t: ITranslation;
};

const SearchDropdown = ({ data = [], onSelect, onClose, t }: SearchDropdownProp) => {
  const { dropdownRef, importAppointment, isPending } = useSearchDropdown({ onClose, onSelect });
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const handleOnClick = (id: number, searchCategory: SearchListType) => {
    if (searchCategory === "claire") onSelect(id);
    else {
      setSelectedId(id);
      importAppointment({ id });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickAboveThreshold = event.clientY <= 50;
      const isOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target as Node);
      if (isOutsideDropdown && !isClickAboveThreshold) {
        onClose && onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="SearchDropdown-wrapper" ref={dropdownRef}>
      {data.length > 0 ? (
        <>
          {data.map(item => {
            const date = item.due_in ? item.due_in : item.time_car_app ? item.time_car_app : null;

            const appDate = date ? `${moment(date).format("DD-MM-YYYY")} | ` : "";
            const woNr = item.wo_nr ? `${item.wo_nr} | ` : "";
            const regNr = item.reg_number ? `${item.reg_number} | ` : "";

            return (
              <div key={item.id} className="search-list" onClick={() => handleOnClick(item.id, item.search_category)}>
                {item.search_category === "claire" ? (
                  <p className="search-title">{`${item.car_make} ${item.car_model}`}</p>
                ) : (
                  <Label>
                    <span>{capitalize(item.search_category)}</span>
                  </Label>
                )}
                <div className="search-row">
                  <p>{`${appDate}${woNr}${regNr} ${item.dealer_location?.name || ""}`}</p>
                  {isPending && selectedId === item.id ? (
                    <Loader active={isPending} inline size="small" />
                  ) : (
                    <StatusCell
                      value={{
                        id: item.appointment_status_identifier,
                        customcom_status: item.customcom_status,
                        check_paused_at: item.check_paused_at,
                        car_out_of_shop: item.car_out_of_shop
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="no-result">
          <p>{t("v8_no_results_found").message || "No results found"}</p>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SearchDropdown);

import { Appointment as AppointmentModel, CHECKLIST_TYPE, STATUS_IDENTIFIER, User } from "models";
import { MECHANIC_SORTING } from "modules/Dayplanner";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";

export interface Appointment extends AppointmentModel {
  inactive?: boolean;
  _shifted?: boolean;
}

export type MechanicWithAppointments = User & {
  appointments: Appointment[];
  expanded?: boolean;
  auto_expand?: boolean;
};

export interface DayplannerColumnsInterface {
  appointmentsColumn: Appointment[];
  unassignedColumn: Appointment[];
  mechanicsColumn: MechanicWithAppointments[];
  carReadyColumn: Appointment[];
  qualityControlColumn: Appointment[];
}

type DayplannerData = {
  columns: DayplannerColumnsInterface;
  dayplannerAppointments: Appointment[];
};

export const ascendingSort = (a: MechanicWithAppointments, b: MechanicWithAppointments): number => {
  return (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name);
};

export const descendingSort = (a: MechanicWithAppointments, b: MechanicWithAppointments): number => {
  return (b.first_name + b.last_name).localeCompare(a.first_name + a.last_name);
};

export const sortMechanics = (mechanics: MechanicWithAppointments[], mechanicsSorting: MECHANIC_SORTING, mechanicsPinned: number[]): MechanicWithAppointments[] => {
  const pinnedMechanics: MechanicWithAppointments[] = [];
  const mechanicWithAppointments: MechanicWithAppointments[] = [];
  const mechanicWithoutAppointments: MechanicWithAppointments[] = [];

  mechanics.forEach(mechanic => {
    if (mechanicsPinned.includes(mechanic.id)) {
      pinnedMechanics.push(mechanic);
    } else if (mechanic.appointments?.length > 0) {
      mechanicWithAppointments.push(mechanic);
    } else {
      mechanicWithoutAppointments.push(mechanic);
    }
  });

  if (mechanicsSorting === MECHANIC_SORTING.Ascending) {
    pinnedMechanics.sort(ascendingSort);
    mechanicWithAppointments.sort(ascendingSort);
    mechanicWithoutAppointments.sort(ascendingSort);
  } else {
    pinnedMechanics.sort(descendingSort);
    mechanicWithAppointments.sort(descendingSort);
    mechanicWithoutAppointments.sort(descendingSort);
  }

  const sortedMechanics = [...pinnedMechanics, ...mechanicWithAppointments, ...mechanicWithoutAppointments];
  return sortedMechanics;
};

export const sortAppointments = (appointments: Appointment[]) => {
  appointments.sort((ac1: Appointment, ac2: Appointment) => {
    if (ac1.appointment_status_identifier === STATUS_IDENTIFIER.BackOrderStatus && ac2.appointment_status_identifier !== STATUS_IDENTIFIER.BackOrderStatus) return 1;
    if (ac2.appointment_status_identifier === STATUS_IDENTIFIER.BackOrderStatus && ac1.appointment_status_identifier !== STATUS_IDENTIFIER.BackOrderStatus) return -1;

    const ac1Time = ac1.car_return_time || ac1.planning_work_stop;
    const ac2Time = ac2.car_return_time || ac2.planning_work_stop;

    if (ac1Time && ac2Time) {
      return new Date(ac1Time).getTime() - new Date(ac2Time).getTime();
    }

    return Number(ac1.wo_nr) - Number(ac2.wo_nr);
  });
};

export const prepareDayplannerData = (
  mechanics: MechanicWithAppointments[],
  appointments: Appointment[],
  mechanicsSorting: MECHANIC_SORTING,
  mechanicsPinned: number[]
): DayplannerData => {
  const columns: DayplannerColumnsInterface = {
    appointmentsColumn: [],
    unassignedColumn: [],
    mechanicsColumn: mechanics.map(mechanic => ({ ...mechanic, appointments: [] as Appointment[] }) as MechanicWithAppointments),
    carReadyColumn: [],
    qualityControlColumn: []
  };

  const dayplannerAppointments: Appointment[] = [];

  sortAppointments(appointments);

  appointments.map(updateAppointmentStatusIdentifier).forEach(appointment => {
    if (!appointment.assigned_mechanic) {
      switch (appointment.appointment_status_identifier) {
        case STATUS_IDENTIFIER.CarReadyStatus:
        case STATUS_IDENTIFIER.CarOkPlusRepairOverview:
          columns.carReadyColumn.push(appointment);
          dayplannerAppointments.push(appointment);
          break;

        case STATUS_IDENTIFIER.QualityCheckStatus:
        case STATUS_IDENTIFIER.QualityCheckPlusRepairOverview:
          columns.qualityControlColumn.push(appointment);
          dayplannerAppointments.push(appointment);
          break;

        default:
          if (
            [STATUS_IDENTIFIER.CarCheckStartedStatus].includes(appointment.appointment_status_identifier) &&
            appointment.status_history?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())?.[0].checklist_type ===
              CHECKLIST_TYPE.QualityChecklist
          ) {
            columns.carReadyColumn.push(appointment);
            dayplannerAppointments.push(appointment);
          } else if (appointment.car_in_shop && !appointment.car_out_of_shop) {
            columns.unassignedColumn.push(appointment);
            dayplannerAppointments.push(appointment);
          } else if (appointment.car_out_of_shop) {
            columns.appointmentsColumn.push(appointment);
            dayplannerAppointments.push(appointment);
          } else {
            switch (appointment.appointment_status_identifier) {
              case STATUS_IDENTIFIER.CarCheckStatus:
              case STATUS_IDENTIFIER.BackOrderStatus:
              case STATUS_IDENTIFIER.PricingOKStatus:
              case STATUS_IDENTIFIER.ContactStatus:
              case STATUS_IDENTIFIER.CustomerOKStatus:
              case STATUS_IDENTIFIER.DiagnoseStatus:
                columns.unassignedColumn.push(appointment);
                dayplannerAppointments.push(appointment);
                break;

              default:
                columns.appointmentsColumn.push(appointment);
                dayplannerAppointments.push(appointment);
                break;
            }
          }
      }
    } else {
      const assignedMechanic = columns.mechanicsColumn.find(mechanic => mechanic.id === appointment.assigned_mechanic);
      if (assignedMechanic) {
        assignedMechanic.appointments.push(appointment);
        dayplannerAppointments.push(appointment);
      }
    }
  });
  columns.mechanicsColumn = sortMechanics(columns.mechanicsColumn, mechanicsSorting, mechanicsPinned);
  return { columns, dayplannerAppointments };
};

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, COMMUNICATION_STATUSES, STATUS_IDENTIFIER } from "models";
import { StatusIcon } from "modules/AppointmentDetail/components";
import "modules/AppointmentDetail/components/AppointmentStatus/AppointmentStatus.scss";
import { useAppointmentData, useKiosk, useUpdateAppointment } from "modules/AppointmentDetail/hooks";
import { isCarReady } from "modules/AppointmentDetail/utils";
import { getAppointmentStatusName } from "util/common";
import { ITranslation } from "util/interfaces";

export type StatusData = {
  new_status_identifier?: number;
  car_in_shop?: string | null;
  car_out_of_shop?: string | null;
};

type AppointmentStatusProps = {
  data: Appointment | null;
  onClick: (statusData: StatusData) => void;
  isUpdating?: boolean;
};
type CarInShop = {
  payload: StatusData;
};
export const AppointmentStatus = ({ data, onClick, isUpdating }: AppointmentStatusProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDetachKioskModel, setShowDetachKioskModel] = useState(false);
  const appointmentStatus: STATUS_IDENTIFIER | undefined = data?.appointment_status_identifier;
  const { selectedLocation } = useDealersLocations();
  const { restore } = useAppointmentData(String(data?.id));
  const { appointmentCarInShopMutation, appointmentCarOutOfShopMutation } = useUpdateAppointment(Number(data?.id));
  const { detachKioskMutation } = useKiosk(Number(data?.id));
  const canRestoreAppointments = useCan("restore", "appointments");
  const t = useTranslation().t as ITranslation;

  const toggleConfirmModal = () => {
    setShowConfirmModal(prev => !prev);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClick({ new_status_identifier: STATUS_IDENTIFIER.CarReadyStatus });
    toggleConfirmModal();
  };

  const isActive = (appointmentStatusId: STATUS_IDENTIFIER | undefined, statusId: number) => {
    return appointmentStatusId === statusId;
  };

  const carInShopStatus = (appointment: Appointment | null) => {
    return appointment?.car_in_shop !== null && appointment?.car_out_of_shop === null;
  };

  const carInShop: CarInShop = {
    payload: {}
  };

  const onRequestConfirmCloseDetachKioskModal = () => {
    setShowDetachKioskModel(false);
    onClick(carInShop.payload);
  };

  const handleDetachKiosk = () => {
    detachKioskMutation.mutate();
    onClick(carInShop.payload);
    setShowDetachKioskModel(false);
  };

  const handleConfirmDetachKioskModal = () => {
    setShowDetachKioskModel(true);
  };

  if (appointmentStatus === STATUS_IDENTIFIER.CanceledStatus && !canRestoreAppointments) return null;

  const carInShopDisabled =
    appointmentStatus === STATUS_IDENTIFIER.NewCarStatus ? !useCan("update-car-in-shop", "appointment") : !useCan("update-car-out-of-shop", "appointment");

  return (
    <div className="AppointmentStatus">
      <Popup
        content={() => getAppointmentStatusName(STATUS_IDENTIFIER.CarInShop, selectedLocation?.statuses)}
        trigger={
          <StatusIcon
            name={carInShopStatus(data) ? "car garage" : "car mirrors"}
            active={carInShopStatus(data)}
            disabled={carInShopDisabled}
            onClick={() => {
              if (!isUpdating) {
                if (appointmentStatus === STATUS_IDENTIFIER.CanceledStatus) {
                  restore.mutate();
                } else {
                  if (carInShopStatus(data) && data?.kiosk_label_number) {
                    handleConfirmDetachKioskModal();
                  } else {
                    if (carInShopStatus(data)) {
                      const payload = {
                        car_in_shop: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                        car_out_of_shop: null
                      };
                      appointmentCarInShopMutation.mutate(payload);
                    } else {
                      appointmentCarOutOfShopMutation.mutate({ car_out_of_shop: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]") });
                    }
                  }
                }
              }
            }}
          />
        }
      />

      <Popup
        content={() => getAppointmentStatusName(STATUS_IDENTIFIER.CalculatingStatus, selectedLocation?.statuses)}
        trigger={
          <StatusIcon
            name="dollar sign"
            disabled={
              appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
              appointmentStatus === STATUS_IDENTIFIER.BackOrderStatus ||
              appointmentStatus !== STATUS_IDENTIFIER.CarCheckStatus
            }
            active={isActive(appointmentStatus, STATUS_IDENTIFIER.CalculatingStatus)}
            onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.CalculatingStatus })}
          />
        }
      />

      <Popup
        content={() => getAppointmentStatusName(STATUS_IDENTIFIER.PricingOKStatus, selectedLocation?.statuses)}
        trigger={
          <StatusIcon
            name="tag"
            disabled={
              appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
              appointmentStatus === STATUS_IDENTIFIER.BackOrderStatus ||
              appointmentStatus !== STATUS_IDENTIFIER.CalculatingStatus
            }
            active={isActive(appointmentStatus, STATUS_IDENTIFIER.PricingOKStatus)}
            onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.PricingOKStatus })}
          />
        }
      />

      <Popup
        content={() => getAppointmentStatusName(STATUS_IDENTIFIER.ContactStatus, selectedLocation?.statuses)}
        trigger={
          <StatusIcon
            name={`${Number(data?.customcom_status) >= COMMUNICATION_STATUSES.DiagnoseOverviewSent ? "envelope" : "phone flip"}`}
            disabled={
              appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
              appointmentStatus === STATUS_IDENTIFIER.BackOrderStatus ||
              appointmentStatus !== STATUS_IDENTIFIER.PricingOKStatus
            }
            active={isActive(appointmentStatus, STATUS_IDENTIFIER.ContactStatus)}
            onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.ContactStatus })}
          />
        }
      />

      {selectedLocation?.diagnose_overview_enabled && <StatusIcon name="user check" disabled active={appointmentStatus === STATUS_IDENTIFIER.CustomerAnsweredStatus} />}

      <Popup
        content={() => getAppointmentStatusName(STATUS_IDENTIFIER.CustomerOKStatus, selectedLocation?.statuses)}
        trigger={
          <StatusIcon
            name="face smile regular"
            active={isActive(appointmentStatus, STATUS_IDENTIFIER.CustomerOKStatus)}
            disabled={
              appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
              (appointmentStatus !== STATUS_IDENTIFIER.ContactStatus &&
                appointmentStatus !== STATUS_IDENTIFIER.PricingOKStatus &&
                appointmentStatus !== STATUS_IDENTIFIER.CustomerAnsweredStatus &&
                appointmentStatus !== STATUS_IDENTIFIER.BackOrderStatus)
            }
            onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.CustomerOKStatus })}
          />
        }
      />

      {selectedLocation?.car_ready_btn_visible && (
        <StatusIcon
          name="double check"
          active={isCarReady(appointmentStatus as STATUS_IDENTIFIER)}
          disabled={
            appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
            isUpdating ||
            (appointmentStatus !== STATUS_IDENTIFIER.BackOrderStatus && isCarReady(appointmentStatus as STATUS_IDENTIFIER))
          }
          onClick={toggleConfirmModal}
        />
      )}

      <div className="horizontal-line" />
      <CustomConfirm
        isLoading={!!isUpdating}
        isOpen={showConfirmModal}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        handleConfirm={handleConfirm}
        handleCancel={toggleConfirmModal}
        confirmMsg={t("v8_are_you_sure_car_ok_status").message || "Are you sure you want to change the status to Car Ready?"}
      />
      <CustomConfirm
        customTitle={t("v8_key_label_warning").message || "Key Label Warning"}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        allowCustomReason
        isOpen={showDetachKioskModel}
        customClass={"CancelAppointmentModal"}
        handleCancel={onRequestConfirmCloseDetachKioskModal}
        handleConfirm={handleDetachKiosk}
        isLoading={detachKioskMutation.isPending}
        confirmMsg={t("v8_confirm_disconnect_key_from_appointment").message || "Do you want to disconnect the key label from this WO?"}
        cancelButtonText={t("v8_no").message || "No"}
        confirmButtonText={t("v8_yes").message || "Yes"}
      />
    </div>
  );
};

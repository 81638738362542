import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CheckList, CustomConfirm, DetailsCards } from "components";
import { Appointment, Check } from "models";
import { ChecklistPDF } from "modules/AppointmentDetail/components";
import "modules/AppointmentDetail/components/StandardList/StandardList.scss";
import { useChecklist } from "modules/AppointmentDetail/hooks";
import { ITranslation } from "util/interfaces";

type StandardListProps = {
  title: string;
  isExpanded: boolean;
  data: Check;
  appointment: Appointment;
  id?: string;
  icon?: string;
  latestChecklist: number;
};

export const StandardList = ({ id, isExpanded, title, data, appointment, icon, latestChecklist }: StandardListProps) => {
  if (!data.question_items) return null;

  const { deleteCheckList, canDeleteCheck } = useChecklist(appointment.id);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const t = useTranslation().t as ITranslation;

  const toggleShowAll = () => {
    setShowAll(prev => !prev);
  };
  const isLatestCheckList = latestChecklist === data.id;

  const getTitle = (data: Check) => {
    return (
      <div className="StandardList-title">
        {data.purpose ? `${title} - ${data.purpose}` : title}
        {!data.checklist.template?.hide_timestamp && data.mechanic && (
          <span className="mechanic-info">
            <span>{`${data.mechanic.first_name} ${data.mechanic.last_name}`}</span>
            <span className="separator">-</span>
            <span className="grey-font">{`${moment(data.created_on).subtract(data.duration, "seconds").format("HH:mm DD-MM-YY")}`}</span>
            <Icon className="arrow right" />
            <span className="time-data">{`${moment(data.created_on).format("HH:mm DD-MM-YY")}`}</span>
          </span>
        )}
      </div>
    );
  };

  return (
    <DetailsCards
      id={id}
      title={getTitle(data)}
      icon={icon || "list"}
      isExpanded={isExpanded}
      rightContent={
        <div className="full end">
          {isLatestCheckList && canDeleteCheck && (
            <Button className="-appointment-status icon" size="small" onClick={() => setConfirmDeleteIsOpen(true)}>
              <Icon className="trash" color="green" />
            </Button>
          )}

          <Button className="-appointment-status icon" size="small" onClick={toggleShowAll}>
            <Icon className="bars" color={showAll ? "green" : "grey"} />
          </Button>

          {data.checklist?.template?.total_pdf_enabled && (
            <ChecklistPDF appointment={appointment} checkId={data.id} isPreviewable={data.checklist.template?.is_previewable} />
          )}

          <CustomConfirm
            isOpen={confirmDeleteIsOpen}
            handleCancel={() => setConfirmDeleteIsOpen(false)}
            handleConfirm={() => deleteCheckList.mutate(data.appointment_id)}
            confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
            isLoading={deleteCheckList.isPending}
            type={CUSTOM_CONFIRM_TYPES.Warning}
          />
        </div>
      }
    >
      <CheckList data={data.question_items} template={data.checklist?.template} showAll={showAll} appointment={appointment} />
    </DetailsCards>
  );
};

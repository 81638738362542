import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { QuestionImagesObj } from "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ChecklistSection/useChecklistSection";
import "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ImageLetter/ImageLetter.scss";

type TProps = {
  images: QuestionImagesObj[];
  questionItemId: number;
  checklistId: number;
};

export const ImageLetters = ({ images, questionItemId, checklistId }: TProps) => {
  if (!images?.length) return null;

  const checklistImages = images.find(checklist => checklist.checklistId === checklistId);
  if (!checklistImages) return null;

  const questionImageLetters = checklistImages.questionImages.filter(image => image.questionId === questionItemId);
  if (!questionImageLetters.length) return null;

  return (
    <div className="ImageLetters">
      <div className="img-icon-wrapper">
        <FontAwesomeIcon icon={faCamera} size="sm" />
        <span className="report-car-images-letter">{questionImageLetters.map(item => item.letterCode).join(",")}</span>
      </div>
    </div>
  );
};

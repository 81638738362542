import NoAccess from "components/Can/components/NoAccess";
import { useUser } from "hooks";
import { RoleACLs } from "models";

type CanProps = {
  I: string | string[];
  a?: string;
  the: string | string[];
  renderNoAccess?: boolean;
  children: JSX.Element;
};

const checkPermissions = (roleACLs: RoleACLs | null | undefined, resource: CanProps["the"], activity: CanProps["I"]): boolean => {
  if (!roleACLs || !resource?.length || !activity?.length) return false;

  if (Array.isArray(resource) && Array.isArray(activity)) {
    return resource.some(resource => roleACLs && roleACLs[resource] && roleACLs[resource].some(roleACL => activity.includes(roleACL.activity)));
  } else if (Array.isArray(resource)) {
    return resource.some(resource => roleACLs && roleACLs[resource] && roleACLs[resource].some(roleACL => activity === roleACL.activity));
  } else if (Array.isArray(activity)) {
    return roleACLs && roleACLs[resource] && roleACLs[resource].some(roleACL => activity.includes(roleACL.activity));
  }

  return roleACLs && roleACLs[resource] && roleACLs[resource].some(roleACL => activity === roleACL.activity);
};

export const Can = ({ I, a, the, renderNoAccess, children }: CanProps) => {
  const roleACLs = useUser()?.roleACLs;
  const resource = the || a || "";

  if (!roleACLs) return null;

  if (checkPermissions(roleACLs, resource, I)) return children;

  return renderNoAccess ? <NoAccess /> : null;
};

export const useCan = (activity: CanProps["I"], resources: CanProps["the"]) => {
  const roleACLs = useUser()?.roleACLs;

  if (!roleACLs) return false;

  return checkPermissions(roleACLs, resources, activity);
};

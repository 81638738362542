import ENV from "config/Env";
import { queryClient } from "index";
import { User } from "models";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

type Filter = Record<string, number>;

export type WebSocketMessage = {
  model: string;
  action?: string;
  id?: number;
  data?: unknown;
  filter?: Filter;
};

export type WebSocketStatus = {
  isConnected: boolean;
};

export interface WebsocketMessageListener extends WebSocketMessage {
  callback: (message: WebSocketMessage) => void;
  permanent?: boolean;
}

export type Websocket = {
  isConnected: boolean;
};

export type Subscription = {
  model: string;
  action?: string;
  id?: number;
  filter?: Filter;
  callback: (message: WebSocketMessage) => void;
  permanent?: boolean;
};

interface SubscriptionWithCount extends Subscription {
  count: number;
}

enum WEBSOCKET {
  Subscribe = 1,
  Unsubscribe
}

export class WebSocketComponent {
  private static websocket: WebSocket | null = null;

  private static Subscriptions: SubscriptionWithCount[] = [];

  private static matchCallbackFilters(filter: Filter | undefined, data: any) {
    if (!filter) return true;

    for (const [name, value] of Object.entries(filter)) {
      if (data?.[name] !== value) {
        return false;
      }
    }

    return true;
  }

  private static handleWebSocketMessage(message: WebSocketMessage) {
    WebSocketComponent.Subscriptions.forEach(Subscription => {
      const { model, action, callback, id, filter } = Subscription;
      if (model === message.model && message.data && (!action || action === message.action) && (!id || id === message.id)) {
        try {
          if (WebSocketComponent.matchCallbackFilters(filter, message.data)) {
            callback(message);
          }
        } catch (error) {
          console.warn(error);
        }
      }
    });
  }

  static addMessageListener({ model, callback, action, id, filter, permanent = false }: Subscription) {
    const existingSubscriber = WebSocketComponent.Subscriptions.find(subscriber => subscriber.model === model && subscriber.action === action && subscriber.id === id);

    if (existingSubscriber) {
      existingSubscriber.count += 1;
    } else {
      WebSocketComponent.Subscriptions.push({
        model,
        action,
        id,
        count: 1,
        callback,
        filter,
        permanent
      });
    }

    return (newSub: WebSocketMessage) => WebSocketComponent.removeMessageListener(newSub);
  }

  private static removeMessageListener(newSub: WebSocketMessage) {
    const index = WebSocketComponent.Subscriptions.findIndex(
      subscriber => subscriber.model === newSub.model && subscriber.action === newSub.action && subscriber.id === newSub.id
    );

    if (index !== -1) {
      WebSocketComponent.Subscriptions[index].count -= 1;
      if (WebSocketComponent.Subscriptions[index].count <= 0 && !WebSocketComponent.Subscriptions[index].permanent) {
        WebSocketComponent.Subscriptions.splice(index, 1);
      }
    }
  }

  static connect(userId: string) {
    WebSocketComponent.websocket = new WebSocket(`${ENV.webSocketEndpoint}/connect?client_id=${userId}`);
    WebSocketComponent.websocket.onopen = () => {
      queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.Websocket), () => {
        return {
          isConnected: true
        };
      });
    };

    WebSocketComponent.websocket.onmessage = event => {
      try {
        WebSocketComponent.handleWebSocketMessage(JSON.parse(event.data));
      } catch (e) {
        console.warn(e);
      }
    };

    WebSocketComponent.websocket.onclose = () => {
      setTimeout(() => {
        WebSocketComponent.connect(userId);
      }, 500);
    };
  }

  static subscribeToLocationQueue(notifierKey: string) {
    if (WebSocketComponent.websocket) {
      const subscribeMessage = {
        _type: WEBSOCKET.Subscribe,
        _queues: `v2-location-${notifierKey}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(subscribeMessage));
    }
  }

  static subscribeToAppointmentQueue(appointmentId: number) {
    if (WebSocketComponent.websocket) {
      const subscribeMessage = {
        _type: WEBSOCKET.Subscribe,
        _queues: `appointment-${appointmentId}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(subscribeMessage));
    }
  }

  static unsubscribeFromLocationQueue(notifierKey: string) {
    if (WebSocketComponent.websocket) {
      const unsubscribeMessage = {
        _type: WEBSOCKET.Unsubscribe,
        _queues: `v2-location-${notifierKey}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(unsubscribeMessage));
    }
  }

  static unsubscribeFromAppointmentQueue(appointmentId: number) {
    if (WebSocketComponent.websocket) {
      const unsubscribeMessage = {
        _type: WEBSOCKET.Unsubscribe,
        _queues: `appointment-${appointmentId}`
      };
      WebSocketComponent.websocket.send(JSON.stringify(unsubscribeMessage));
    }
  }

  static sendAppointmentActiveUserPing(appointmentId: number, user: User) {
    if (WebSocketComponent.websocket) {
      const message = {
        id: appointmentId,
        _queues: `appointment-${appointmentId}`,
        model: "_ActiveUsersOnAppointmentDetailPage",
        action: "ping",
        data: {
          first_name: user.first_name,
          last_name: user.last_name,
          id: user.id,
          profile_picture: user.profile_picture
        }
      };
      WebSocketComponent.websocket.send(JSON.stringify(message));
    }
  }

  static disconnect() {
    if (WebSocketComponent.websocket) {
      WebSocketComponent.websocket.close();
      queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.Websocket), () => {
        return {
          isConnected: false
        };
      });
    }
  }
}

import { ReactElement } from "react";
import { Checkbox, Dropdown, Form, Icon, Input, TextArea } from "semantic-ui-react";

export const Inputs = (): ReactElement => {
  return (
    <div className="inputs__styleguide">
      <h2>Inputs</h2>

      <div className="inputs__styleguide-container">
        <Form>
          <Form.Field>
            <label>Form Dropdown Elements Disabled</label>
            <Dropdown disabled options={[]} placeholder="Disabled" selection />
          </Form.Field>

          <Form.Field>
            <label>Input Field</label>
            <Input value="Placeholder" />
          </Form.Field>

          <Form.Field disabled>
            <label>Input Field Disabled</label>
            <Input value="Placeholder" />
          </Form.Field>

          <Form.Field error>
            <label>Input Field Error</label>
            <Input value="Placeholder" />
          </Form.Field>

          <Form.Field>
            <label>Text</label>
            <TextArea value="Placeholder" />
          </Form.Field>

          <Form.Field error>
            <label>Text</label>
            <TextArea value="Placeholder" />
          </Form.Field>

          <Form.Field>
            <label>Checkbox</label>
            <Checkbox />
          </Form.Field>

          <Form.Field>
            <label>Checkbox toggle</label>
            <Checkbox toggle />
          </Form.Field>

          <Form.Field>
            <label>Checkbox slider</label>
            <Checkbox slider />
          </Form.Field>

          <Form.Field>Select your favorite fruit:</Form.Field>
          <Form.Field>
            <Form.Radio label="Apples" name="radioGroup" value="this" checked={true} />
          </Form.Field>
          <Form.Field>
            <Form.Radio label="Oranges" name="radioGroup" value="that" checked={false} />
          </Form.Field>
          <Form.Field>
            <Form.Radio label="Pears" name="radioGroup" value="that" checked={false} />
          </Form.Field>
          <Form.Field>
            <Form.Radio label="Grapefruit" name="radioGroup" value="that" checked={false} />
          </Form.Field>

          <Form.Field>
            <label>Form Dropdown Elements Default</label>
            <Dropdown
              placeholder="Placeholder"
              fluid
              search
              label={"Country"}
              selection
              multiple
              options={[
                { text: "Afghanistan", key: "AF", value: "Afghanistan" },
                { text: "Åland Islands", key: "AX", value: "Åland Islands" },
                { text: "Albania", key: "AL", value: "Albania" },
                { text: "Algeria", key: "DZ", value: "Algeria" }
              ]}
            />
          </Form.Field>

          <Form.Field>
            <label>Dropdown with icons</label>
            <Dropdown
              placeholder="Placeholder"
              fluid
              selection
              options={[
                {
                  key: "User",
                  text: "User",
                  value: "User",
                  icon: "user"
                },
                {
                  key: "Settings",
                  text: "Settings",
                  value: "Settings",
                  icon: "settings"
                }
              ]}
            />
          </Form.Field>

          <Form.Field>
            <label>Dropdown Elements</label>
            <Dropdown text="Filter" icon="filter regular" floating labeled selection>
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown icon="filter regular" floating button className="icon">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown text="Filter" icon="filter regular" floating labeled button className="icon">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown icon="filter regular" floating button className="icon green inverted">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown icon="filter regular" floating button className="icon green">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown text="Filter" icon="filter regular" floating labeled button className="icon green">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>

          <Form.Field>
            <Dropdown text="Filter" icon="filter regular" floating labeled button className="icon green inverted">
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Filter by tag" />
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Icon name="attention" className="right floated" />
                  Important
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="comment" className="right floated" />
                  Announcement
                </Dropdown.Item>
                <Dropdown.Item>
                  <Icon name="conversation" className="right floated" />
                  Discussion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
        </Form>
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Grid, Icon, Label } from "semantic-ui-react";

import { CommunicationAgreement } from "models";
import { ITranslation } from "util/interfaces";

type AgreementsProps = {
  agreements: CommunicationAgreement[] | null;
  onSelectAgreement?: (agreement: CommunicationAgreement) => void;
};

export const Agreements = ({ agreements, onSelectAgreement }: AgreementsProps) => {
  if (!agreements?.length) return null;
  const t = useTranslation().t as ITranslation;

  return (
    <div className="info-section">
      <div className="section-header">{t("v8_agreements").message || "Agreements"}</div>
      <div className="section-content">
        <Grid>
          <Grid.Column width={16}>
            {agreements.map((item, key) => (
              <div className="agreements-label" key={key} onClick={() => (onSelectAgreement ? onSelectAgreement(item) : null)}>
                <Label>
                  <Icon name="check square" />
                </Label>
                <span className="ml-10">{item.name}</span>
              </div>
            ))}
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

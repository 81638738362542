import moment from "moment";

import "modules/Appointments/components/AppointmentTable/components/AppointmentTableDateColumnCell/AppointmentTableDateColumnCell.scss";

type AppointmentTableDateColumnCellProps = {
  dateColumnAccessor: string;
  value: { date: string | null; isPinned: boolean };
  isTodaysList: boolean;
};

export const AppointmentTableDateColumnCell = ({ dateColumnAccessor, value, isTodaysList }: AppointmentTableDateColumnCellProps) => {
  const dateDiff = Number(moment(Date.now()).startOf("day").diff(moment(value.date).startOf("day"), "days"));

  const renderDateDiff = () => (value.isPinned && dateDiff && isTodaysList ? (dateDiff > 0 ? `+${dateDiff}` : dateDiff) : null);

  switch (dateColumnAccessor) {
    case "time_car_app":
      return (
        <div className="AppointmentTableDateColumnCellTimeCarApp">
          {moment(value.date).format("DD-MM-YY")}
          <span className="date-diff-value">{renderDateDiff()}</span>
        </div>
      );
    case "car_return_time":
      return (
        <span style={moment(new Date()).isAfter(value.date) ? { color: "red" } : {}}>
          {value.date ? (
            <span>
              <span>{moment(value.date).format("DD-MM-YYYY")}</span>
              <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;{moment(value.date).format("HH:mm")}</span>
            </span>
          ) : (
            ""
          )}
        </span>
      );
    default:
      return null;
  }
};

import { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { Menu } from "semantic-ui-react";

import "components/Tabs/Tabs.scss";

export type TabData = {
  id: string;
  title?: string;
  data?: any;
  icon?: React.ReactNode;
};

type TabsProps = {
  data: Map<string, TabData>;
  offset?: number;
  hideBorder?: boolean;
};

export const Tabs = ({ data, offset = -100, hideBorder }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<string>("");
  const [canSpy, setCanSpy] = useState(true);

  const handleScrollEnd = () => {
    setCanSpy(true);
  };

  const scrollTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimer?.current) {
        clearTimeout(scrollTimer.current);
      }
      scrollTimer.current = setTimeout(handleScrollEnd, 250);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTimer?.current) {
        clearTimeout(scrollTimer.current);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleActiveTab = (id: string) => {
    setActiveTab(id);
    setCanSpy(false);
  };

  return (
    <div className={`tabs-container ${hideBorder ? "hideBorder" : ""}`}>
      <Menu pointing secondary color="green">
        {[...data.entries()]
          .filter(([_key, value]) => !!value.data)
          .map(([_k, item]) => {
            const count = Array.isArray(item.data) ? item.data.length : Object.keys(item.data).length;
            return item.icon || item.title ? (
              <Link
                key={item.id}
                to={item.id}
                spy={canSpy}
                smooth={true}
                offset={offset}
                duration={500}
                onSetActive={() => setActiveTab(item.id)}
                containerId="containerId"
              >
                <Menu.Item active={item.id === activeTab} onClick={() => handleActiveTab(item.id)}>
                  {item.title ? (
                    `${item.title} ${count > 0 ? `(${count})` : ""}`
                  ) : (
                    <>
                      {item.icon} {count > 0 && <span>({count})</span>}
                    </>
                  )}
                </Menu.Item>
              </Link>
            ) : null;
          })}
      </Menu>
    </div>
  );
};

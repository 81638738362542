import ApiLoadable from "models/ApiLoadable";

export enum TYRE_SEASONS {
  NoSeason = -1,
  AllSeason,
  Winter,
  Summer,
  NotAvailable
}

export enum TYRE_TEAM_CHANNEL {
  WheelTyre = 1,
  BandenExpress
}

export enum TYRE_POSITIONS {
  NotTyre = 0,
  CarFrontLeft,
  CarFrontRight,
  CarRearLeft,
  CarRearRight,
  StorageFrontLeft,
  StorageFrontRight,
  StorageRearLeft,
  StorageRearRight,
  TyrePositionLast,
  TruckLeft,
  TruckRight,
  TruckExteriorLeft,
  TruckExteriorRight,
  TruckInteriorLeft,
  TruckInteriorRight,
  TruckSpare
}

export enum TYRE_OPERATIONS {
  ValidOnCarSet = TYRE_POSITIONS.CarFrontRight + TYRE_POSITIONS.CarFrontLeft + TYRE_POSITIONS.CarRearRight + TYRE_POSITIONS.CarRearLeft,
  ValidInStorageSet = TYRE_POSITIONS.StorageFrontRight + TYRE_POSITIONS.StorageFrontLeft + TYRE_POSITIONS.StorageRearRight + TYRE_POSITIONS.StorageRearLeft,
  TyrePositionShift = TYRE_POSITIONS.CarRearRight,
  TyreSetCount = TYRE_POSITIONS.CarRearRight,
  OnCarStart = TYRE_POSITIONS.CarFrontLeft,
  OnCarEnd = TYRE_POSITIONS.CarRearRight,
  InStorageStart = TYRE_POSITIONS.StorageFrontLeft,
  InStorageEnd = TYRE_POSITIONS.StorageRearRight
}

export class Tyre extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  manufacturer: string = "";
  description: string = "";
  width: number = 0;
  height: number = 0;
  size: number = 0;
  speed: string = "";
  ean: string | null = null;
  run_flat_tire: boolean = false;
  carry_capacity: number = 0;
  season: number = 0;
  deleted: boolean = false;
}

export class TyreResult extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  tyre_id: number = 0;
  profile: number = 0;
  status: number = 0;
  remarks: string = "";
  customer_ok: boolean = false;
  mechanic_fixed: boolean = false;
  mechanic_notes: string = "";
  price: number = 0;
  season: number = 0;
  question_result_id: number = 0;
  position: number = 0;
  tyre?: Tyre | null = null;

  private _replacements?: TyreReplacement[] | null = null;

  get replacements(): TyreReplacement[] {
    return this._replacements ? this._replacements : [];
  }

  set replacements(replacements: TyreReplacement[] | null | undefined) {
    this._replacements = replacements;
  }
}

export class TyreReplacement extends ApiLoadable {
  created_on: string = "";
  updated_on: string = "";
  tyre_replacement_id: number = 0;
  tyre_result_id: number = 0;
  tyre_id: number = 0;
  price: number = 0;
  customer_answer: boolean = false;
  remark: string = "";
  mechanic_fixed: boolean = false;
  selected: boolean = false;
  profile: boolean = false;
  tyre?: Tyre | null = null;
}

export class CarTyres extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  front_tyres_id: number | null = null;
  rear_tyres_id: number | null = null;
  fr_profile: number = 0;
  fl_profile: number = 0;
  rr_profile: number = 0;
  rl_profile: number = 0;
  fr_picture: string = "";
  fl_picture: string = "";
  rr_picture: string = "";
  rl_picture: string = "";
  season: number = 0;
  location: number = 0;
  from_lizeo: boolean = false;
  dbb_state: string = "";
  dbb_fr_system_code: string = "";
  dbb_fl_system_code: string = "";
  dbb_rr_system_code: string = "";
  dbb_rl_system_code: string = "";
  dbb_location_name: string = "";
  dbb_sub_location_name: string = "";
  dbb_location_token: string = "";
  dbb_fl_comment: string = "";
  dbb_fr_comment: string = "";
  dbb_rl_comment: string = "";
  dbb_rr_comment: string = "";
  car_id: number = 0;
  front_tyres?: Tyre | null = null;
  rear_tyres?: Tyre | null = null;
}

export class TruckTyre extends ApiLoadable {
  axle: number = 0;
  car_id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  picture: string = "";
  position: number = 0;
  profile: number = 0;
  tyre?: Tyre | null = null;
  tyre_id: number = 0;
  season: TYRE_SEASONS = TYRE_SEASONS.NotAvailable;
}

export class SearchTyreReplacement extends ApiLoadable {
  "3pmsf": number = 0;
  carry_capacity: number = 0;
  channel: number = 0;
  created_by_id: null = null;
  created_on: string = "";
  currency: string = "";
  deleted: boolean = false;
  delivery: string = "";
  description: string = "";
  ean: string = "";
  gross_price: number = 0;
  height: number = 0;
  id: number = 0;
  manufacturer: string = "";
  net_price: number = 0;
  run_flat_tire: boolean = false;
  season: number = 0;
  size: number = 0;
  speed: string = "";
  stock: string = "";
  system_number: string = "";
  updated_on: string = "";
  width: number = 0;
  price?: number | null = null;
  discount: number = 0;
  price_reduction: number = 0;
  customerPrice: number = 0;
}

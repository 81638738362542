import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { Car } from "models";
import "modules/CarDetails/components/Details/Details.scss";
import { ITranslation } from "util/interfaces";

type DetailsProps = {
  data: Car | null;
};

export const Details = ({ data }: DetailsProps) => {
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const printData = (date: string | null | undefined) => {
    if (date) {
      return moment(date).format("DD-MM-YYYY");
    }
    return "";
  };

  return (
    <div className="Details">
      <div className="Details-img">
        {data?.profile_picture ? <img src={data.profile_picture} className="Details-img" /> : <Icon className="image i-grey" size="huge" />}
      </div>
      <div className="Details-container">
        <div className="group">
          <div>
            <p className="title">{t("v8_license_plate").message || "License Plate"}</p>
            <p>{data?.reg_nr}</p>
          </div>
          <div>
            <p className="title">{t("v8_brand").message || "Brand"}</p>
            <p className="title-data">{data?.brand}</p>
          </div>
        </div>

        <div className="group">
          <div>
            <p className="title">{t("v8_model").message || "Model"}</p>
            <Popup content={data?.model} trigger={<p>{data?.model}</p>} />
          </div>
          <div>
            <p className="title">{t("v8_engine_number").message || "Engine Number"}</p>
            <Popup content={data?.engine_number} trigger={<p className="data">{data?.engine_number || "NA"}</p>} />
          </div>
        </div>

        {selectedLocation?.apk_visible && (
          <div className="group">
            <div>
              <p className="title">{t("v8_apk").message || "APK"}</p>
              <p className="title-data">{printData(data?.apk_date_dms)}</p>
            </div>
          </div>
        )}

        <div className="group">
          <div>
            <p className="title">{t("v8_vin_chasis_number").message || "Vin/Chasis number"}</p>
            <Popup content={data?.vin_nr} trigger={<p>{data?.vin_nr || "NA"}</p>} />
          </div>
          <div>
            <p className="title">{t("v8_dms_registration").message || "DMS registration"}</p>
            <p className="title-data">{printData(data?.dms_registration)}</p>
          </div>
        </div>

        <div className="group">
          <div>
            <p className="title">{t("v8_warranty_start").message || "Warranty start"}</p>
            <p className="title-data">{printData(data?.warranty_date_start)}</p>
          </div>
          <div>
            <p className="title">{t("v8_warranty_end").message || "Warranty end"}</p>
            <p className="title-data">{printData(data?.warranty_date_end)}</p>
          </div>
        </div>

        <div className="group">
          <div>
            <p className="title">{t("v8_extended_warranties").message || "Extended warranties"}</p>
            <p className="title-data">{printData(data?.warranty_extended)}</p>
          </div>
          <div>
            <p className="title">{t("v8_extended_road_warranties").message || "Extended road warranties"}</p>
            <p className="title-data">{printData(data?.warranty_extended_road)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import ApiInstance from "util/Api";

type UseUploadAppointmentNoteAttachmentRequest = {
  formData: FormData;
};

type UseUploadAppointmentNoteAttachment = {
  isUploadingAppointmentAttachment: boolean;
  attachmentUrl: string;
  handleUploadAppointmentNoteAttachment: ({ formData }: UseUploadAppointmentNoteAttachmentRequest) => Promise<string>;
};

export const useUploadAppointmentNoteAttachment = (): UseUploadAppointmentNoteAttachment => {
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const mutationFn = async ({ formData }: UseUploadAppointmentNoteAttachmentRequest) => {
    const response = await ApiInstance.post("/files/upload_appointment_note_attachment", formData);

    return response.data.URL as string;
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: (response: string) => {
      setAttachmentUrl(response);
    }
  });

  return { attachmentUrl, isUploadingAppointmentAttachment: mutation.isPending, handleUploadAppointmentNoteAttachment: mutation.mutateAsync };
};

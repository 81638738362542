import moment from "moment";
import { Icon, Image, Label, Modal } from "semantic-ui-react";

import { DealerDocumentation } from "models";
import { ReadingModalImage } from "modules/DealerDocumentations/components";
import "modules/DealerDocumentations/components/DealerDocumentationReadingModal/DealerDocumentationReadingModal.scss";
import { getUserNameInitials } from "util/common";

interface PropTypes {
  documentation: DealerDocumentation;
  getCategoryName: (id: number) => string | undefined;
  onCloseDocumentation: () => void;
}

export const DealerDocumentationReadingModal = ({ documentation, getCategoryName, onCloseDocumentation }: PropTypes) => {
  const categoryName = getCategoryName(documentation.category_id);
  const publisherProfilePicture = documentation.created_by?.profile_picture;
  const publisherInitials = getUserNameInitials(`${documentation.created_by?.first_name || ""} ${documentation.created_by?.last_name || ""}`.trim());
  const publisherFullName = `${documentation.created_by?.first_name} ${documentation.created_by?.last_name} `;
  const updatedOn = documentation.updated_by ? moment(documentation.updated_on).format("DD-MM-YYYY HH:mm") : false;
  const updateUser = `${documentation.updated_by?.first_name} ${documentation.updated_by?.last_name}`;
  const publishDate = moment(documentation.publish_date).format("MM-DD-YYYY");

  return (
    <>
      <Modal className="DealerDocumentationReadingModal" open={true} size="large">
        <Modal.Header className="dealer-modal-header">
          <div className="document-info">
            <span>{documentation.title}</span>
            {documentation.is_pinned && <Icon className="pin" />}
            <Label className="category-name-dealer">{categoryName}</Label>
          </div>
          <button className="exitModal" onClick={onCloseDocumentation}>
            <Icon className="x" size="large" />
          </button>
        </Modal.Header>
        <Modal.Content>
          <div className="modal-first-row">
            <div className="img-name-wrapper">
              {publisherProfilePicture && <Image src={publisherProfilePicture} size="tiny" className="profilePic" />}
              {!publisherProfilePicture && <div className="publisher-initials">{publisherInitials}</div>}
              {publisherFullName && <b>{publisherFullName}</b>}
            </div>
            {updatedOn && (
              <b>
                (Updated on {updatedOn} by {updateUser})
              </b>
            )}
            <span>{publishDate}</span>
          </div>
          {documentation.url && <ReadingModalImage url={documentation.url} />}
          <div className="dealer-modal-content">
            <div dangerouslySetInnerHTML={{ __html: documentation.content }}></div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

import { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import usePSA from "components/PSA/hooks/usePSA";
import { useDealersLocations } from "hooks";

type PSAProps = {
  car_id?: number;
  appointment_id?: number;
};

type WidgetProps = {
  ButtonLabel?: string;
  RedirectURL?: string;
};

export const PSA = ({ car_id, appointment_id }: PSAProps) => {
  const [widgetData, setWidgetData] = useState<WidgetProps | null>();
  const { selectedLocation } = useDealersLocations();
  const { getAppointmentWidgetData, getCarWidgetData } = usePSA();

  useEffect(() => {
    async function fetchWidgetPI() {
      if (selectedLocation?.id) {
        let data = null;
        if (appointment_id) data = await getAppointmentWidgetData({ location_id: Number(selectedLocation.id), appointment_id: appointment_id });
        if (car_id) data = await getCarWidgetData({ location_id: Number(selectedLocation.id), car_id: car_id });
        setWidgetData(data);
      }
    }

    fetchWidgetPI();
  }, [selectedLocation?.id]);

  const handlePSALink = (url: string) => {
    window.open(url, "_blank");
  };

  return (selectedLocation?.service_box_visible_on_wo && appointment_id && widgetData?.RedirectURL) ||
    (selectedLocation?.service_box_visible_on_car && car_id && widgetData?.RedirectURL) ? (
    <Button className="-appointment-status" size="small" onClick={() => handlePSALink(widgetData.RedirectURL as string)}>
      {widgetData?.ButtonLabel || "S"}
      <Icon className="arrow up right from square" color="green" />
    </Button>
  ) : null;
};

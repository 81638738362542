import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import { NoteProps } from "modules/Dayplanner/components/AppointmentCard/components/NotificationIcons";
import { ITranslation } from "util/interfaces";

export const MainNote = ({ note: mainNote }: NoteProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Popup
      wide
      basic
      content={
        <div className="AppointmentNotePopup">
          <div className="AppointmentNoteTitle">
            <Icon className="note sticky red" color="red" />
            <span>{t("v8_main_note").message || "Main Note"}</span>
          </div>
          <div className="AppointmentNoteDescription">
            {mainNote?.updated_by
              ? `${mainNote.updated_by.first_name} ${mainNote.updated_by.last_name} ${t("v8_updated_main_note").message || "updated Main Note"} `
              : `${mainNote?.user?.first_name} ${mainNote?.user?.last_name} ${t("v8_added_main_note").message || "added Main Note"} `}
            {`${moment(mainNote?.updated_on).format("DD-MM-YYYY HH:mm")}`}
          </div>
          <div className="AppointmentNoteContent">{mainNote?.note}</div>
        </div>
      }
      trigger={
        <Label circular color="red">
          <Icon className="note sticky white" />
        </Label>
      }
    />
  );
};

import { useEffect, useState } from "react";

import { useDealersLocations } from "hooks";

export const useDealerLocations = () => {
  const { selectedLocation, locations } = useDealersLocations();
  const [dealerLocationIds, setDealerLocationIds] = useState<number[]>([]);
  const activeLocations = locations.filter(location => location.active);

  useEffect(() => {
    if (selectedLocation) setDealerLocationIds([selectedLocation.id]);
  }, [selectedLocation]);

  return { dealerLocationIds, setDealerLocationIds, locations: activeLocations };
};

import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { Icon } from "semantic-ui-react";

import "components/DetailsCards/DetailsCards.scss";
import { classNames } from "util/common";

type DetailsCardsProps = {
  icon: string;
  title: string | React.ReactNode;
  id?: string;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
  isExpanded?: boolean;
  showExpandArrow?: boolean;
  button?: boolean;
  containerStyle?: string;
};

export const DetailsCards: React.FC<DetailsCardsProps> = ({
  icon,
  title,
  id,
  rightContent,
  children,
  isExpanded = true,
  showExpandArrow = true,
  button = false,
  containerStyle = ""
}) => {
  const [showCardDetails, setShowCardDetails] = useState(isExpanded);

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  const toggleCardDetails = () => {
    setShowCardDetails(p => !p);
  };

  const detailsCardClasses = classNames({
    button
  });

  return (
    <Element name={String(id)} className={`DetailsCards-container ${detailsCardClasses} ${containerStyle}`}>
      <div className="row">
        <Icon className={icon} />
        <h3>{title}</h3>
        {showExpandArrow && <Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />}
        {rightContent}
      </div>
      <div className={showCardDetails ? "show" : "hide"}>{children}</div>
    </Element>
  );
};

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AttachmentPreview } from "components";
import { CheckInRemark, CustomComRemark, KeylockerRemark, QuestionResult } from "models";
import { ITranslation } from "util/interfaces";

type CCRemarksProps = {
  title: string;
  data: (CheckInRemark | CustomComRemark | KeylockerRemark)[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData) => void;
  isKeyLocker?: boolean;
};

export const CCRemarks = ({ title, data = [], canCreateIntervention, onSelect, isKeyLocker }: CCRemarksProps) => {
  if (!data.length) return null;

  const [showMedia, setShowMedia] = useState(false);
  const t = useTranslation().t as ITranslation;

  const toggleShowMedia = () => {
    setShowMedia(prev => !prev);
  };

  return (
    <div className="CustomerActions-items">
      {data.map(item => {
        if (!item) return null;
        const actionModelData = {
          title: item.title,
          raw: item.description,
          solution: ""
        };
        return (
          <Grid key={item.id} className="actions-container">
            <Grid.Column width={13}>
              <div className={`icon-container ${isKeyLocker ? "green" : "blue"}`}>
                <Popup
                  wide
                  disabled={!item.customer_name}
                  basic
                  content={
                    <div className="InfoPopup">
                      <div className="InfoTitle">{title}</div>
                      <div className="InfoDescription">
                        {t("v8_made_by").message || "Made by"} {item.customer_name}
                      </div>
                      <div className="InfoContent">
                        <span>{t("v8_on").message || "On"}</span> {`${moment(item.created_on).format("DD-MM-YYYY HH:mm")}`}
                      </div>
                    </div>
                  }
                  trigger={<Icon className={isKeyLocker ? "key" : "note"} />}
                />
              </div>
              {item.title && <p className="title">{item.title}</p>}
              {item.description && <p className="description">{item.description}</p>}
            </Grid.Column>
            <Grid.Column width={3}>
              <div className="end-grid">
                <div className="status-container">
                  {!isKeyLocker && item.attachments?.length > 0 && (
                    <div onClick={toggleShowMedia}>
                      <Icon className="images grey pointer" size="small" />
                      <p>
                        <small>{item.attachments.length}</small>
                      </p>
                    </div>
                  )}
                  <div>
                    <Icon className={item.receptionist_handled ? "check green" : "clock grey"} size="small" />
                  </div>

                  {canCreateIntervention && !item.receptionist_handled && (
                    <ActionModalSelector data={actionModelData as QuestionResult} onSelect={onSelect} onlyIntervention />
                  )}
                </div>
              </div>
            </Grid.Column>
            {showMedia && (
              <Grid.Column width={16}>
                <AttachmentPreview attachments={item.attachments} />
              </Grid.Column>
            )}
          </Grid>
        );
      })}
    </div>
  );
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Segment, SidebarPushable, SidebarPusher } from "semantic-ui-react";

import { MenuView, NavBar, useCan } from "components";
import { useDealersLocations } from "hooks";
import "layouts/PrivateRouteLayout/PrivateRouteLayout.scss";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type PrivateRouteLayoutProps = {
  children: React.ReactNode;
};

export const PrivateRouteLayout = ({ children }: PrivateRouteLayoutProps) => {
  const [showSidebarMenu, setSidebarMenu] = useState(false);
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const hideSidebar = () => {
    setSidebarMenu(false);
  };

  const canBrowseDocumentationPage = useCan("browse", "dealer-documentation-page");
  const menuItems = [{ title: t("v8_appointment_list").message || "Appointment List", icon: "list", link: PATHS.APPOINTMENTS, name: "appointments-page" }];

  if (selectedLocation?.is_dayplanner_enabled) {
    menuItems.push({
      title: t("v8_dayplanner").message || "Dayplanner",
      icon: "square kanban regular",
      link: PATHS.DAY_PLANNER,
      name: "dayplanner-page"
    });
  }
  if (canBrowseDocumentationPage) {
    menuItems.push({
      title: t("v8_dealer_documentation").message || "Dealer Documentation",
      icon: "file",
      link: PATHS.DEALER_DOCUMENTATION,
      name: "documentations-page"
    });
  }

  return (
    <div className="main-container">
      <SidebarPushable as={Segment} className="main-container-pushable">
        <MenuView isSideBarOpen={showSidebarMenu} hideSidebar={hideSidebar}>
          <MenuView.Section items={menuItems} hideSidebar={hideSidebar} />
        </MenuView>
        <SidebarPusher dimmed={showSidebarMenu} className="main-container-pusher-dimmed">
          <Segment basic className="main-container-segment">
            <NavBar openSidebar={() => setSidebarMenu(true)} />
            <div id="containerId" className="content-container">
              {children}
            </div>
          </Segment>
        </SidebarPusher>
      </SidebarPushable>
    </div>
  );
};

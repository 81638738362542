import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Checkbox, Dropdown, Form, Input, TextArea } from "semantic-ui-react";

import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface RepairOverviewProps {
  tabId: string;
}

export const RepairOverview = ({ tabId }: RepairOverviewProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_repair_overview").message || "REPAIR OVERVIEW"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <Input value="" placeholder={t("v8_email").message || "E-mail"} fluid />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <Input value="" placeholder={t("v8_phone").message || "Phone"} fluid type="Phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <TextArea value="" placeholder={`${t("v8_hello").message || "Hello"}!`} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_pdf_template").message || "PDF template"}</label>
            <Dropdown options={[]} placeholder={t("v8_select_template").message || "Select template"} selection />
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations, useUpdate, useUser } from "hooks";
import { Appointment, COMMUNICATION_EVENT_TYPE, COMMUNICATION_RECEIVER_STATUS, COMMUNICATION_STATUSES, CommunicationReceiver } from "models";
import { CUSTOMER_COMMUNICATIONS, Receivers } from "modules/AppointmentDetail/components";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

type CustomerCommunicationMutationArgs = {
  appointment_id: number;
  customer_id: number | null;
  receivers: Receivers[];
  note?: string;
  send?: CUSTOMER_COMMUNICATIONS;
  showPreviewURL?: string;
};

type CustomerCommunicationMutationResultArgs = {
  communication: CUSTOMER_COMMUNICATIONS;
  receivers: Receivers[];
  receptionistKey: string;
  customer_id: number | null;
  showPreviewURL?: string;
};

export const useCustomerCommunication = (appointmentId: number) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();
  const user = useUser();
  const { selectedLocation } = useDealersLocations();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointmentId) }
    }
  ];

  const updateAppointment = (data: CustomerCommunicationMutationResultArgs) => {
    if (data.showPreviewURL) {
      return { showPreviewURL: data.showPreviewURL };
    }
    const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
    if (appointmentSnapshot) {
      const receivers: CommunicationReceiver[] =
        data.receivers?.map((receiver: Receivers) => {
          return {
            id: 0,
            created_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
            updated_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
            reason: "",
            status: COMMUNICATION_RECEIVER_STATUS.PendingStatus,
            mailgun_message_id: "",
            chatshipper_conversation_id: null,
            communication_event_id: 0,
            ...receiver
          };
        }) || [];
      const communicationEvent: any = {
        type:
          data.communication === CUSTOMER_COMMUNICATIONS.DiagnoseOverview ? COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent : COMMUNICATION_EVENT_TYPE.RepairOverviewSent,
        user_id: user ? user.id : 0,
        customer_communication_id: appointmentSnapshot.customer_communication ? appointmentSnapshot.customer_communication.customer_communication_id : 0,
        customer_id: data.customer_id,
        receivers: receivers,
        dealer_location_id: selectedLocation ? selectedLocation.id : 0,
        appointment_id: appointmentSnapshot.id
      };

      let customerCommunication = {
        customer_id: data.customer_id,
        status: data.communication === CUSTOMER_COMMUNICATIONS.DiagnoseOverview ? COMMUNICATION_STATUSES.DiagnoseOverviewSent : COMMUNICATION_STATUSES.RepairOverviewSent,
        receptionist_key: data.receptionistKey,
        events: appointmentSnapshot?.customer_communication?.events ? appointmentSnapshot.customer_communication.events.concat(communicationEvent) : [communicationEvent]
      };
      if (appointmentSnapshot.customer_communication) {
        customerCommunication = {
          ...appointmentSnapshot.customer_communication,
          ...customerCommunication
        };
      }
      const updatedAppointmentData = { ...appointmentSnapshot, customer_communication: customerCommunication };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
      requestUpdate();
    }
    return { appointment: appointmentSnapshot, showPreviewURL: null };
  };

  const handleError = (e: any, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(context.appointmentDetailsViewKey, context.AppointmentSnapshot);
      requestUpdate();
    }
  };

  const createCommunication = async (data: CustomerCommunicationMutationArgs) => {
    const send = data.send;
    const showPreviewURL = data.showPreviewURL;
    let url = "/repair_overview/preview/url";
    let baseURL = ENV.appointmentBaseURL;
    if (showPreviewURL) {
      url = "/repair_overview/create";
      baseURL = ENV.appointmentBaseURLv2;
    }
    if (send === CUSTOMER_COMMUNICATIONS.DiagnoseOverview) {
      url = "/diagnose_overview/create";
      baseURL = ENV.appointmentBaseURLv2;
    }
    delete data.send;
    delete data.showPreviewURL;
    const res = await ApiInstance.post(url, data, baseURL);
    return {
      communication: send as CUSTOMER_COMMUNICATIONS,
      receivers: data.receivers,
      customer_id: data.customer_id,
      receptionistKey: res.data.key || "",
      showPreviewURL: res.data.url || ""
    };
  };

  const saveCustomerCommunicationMutation = useMutation({
    mutationFn: createCommunication,
    onSuccess: updateAppointment,
    onError: handleError
  });

  const getRepairOverviewPDF = async (receptionistKey: string) => {
    const config = { responseType: "blob" };
    const payload = { communication_key: receptionistKey };
    return ApiInstance.post("/repair_overview/download", payload, ENV.ccBaseURL, config);
  };

  return { saveCustomerCommunicationMutation, getRepairOverviewPDF };
};

import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Button, Icon, Modal } from "semantic-ui-react";

import { DetailsCards } from "components";
import { Appointment } from "models";
import { Communications } from "modules/AppointmentDetail/components/DeskCommunicationLog/components";
import "modules/AppointmentDetail/components/DeskCommunicationLog/DeskCommunicationLog.scss";
import { ITranslation } from "util/interfaces";

type DeskCommunicationLogProps = {
  appointment: Appointment;
};

export enum DESK_COMMUNICATION_EVENTS {
  CheckInSent = 1,
  CheckInReceived,
  CheckInFailedToOpen,
  CheckInOpened,
  CheckInCanceled,
  CheckInAnswered
}

export const DeskCommunicationLog = ({ appointment }: DeskCommunicationLogProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const contentToPrint = useRef(null);
  const t = useTranslation().t as ITranslation;

  const handleToggleIsModalOpen = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    setIsModalOpen(!isModalOpen);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `DeskCommunicationLog_${appointment.wo_nr}_${moment().format("DD_MM_YY_HH_mm")}`,
    onAfterPrint: () => setPrintLoading(false),
    onBeforePrint: () => setPrintLoading(true)
  });

  const { desk_communications } = appointment;

  if (!desk_communications?.length) return null;

  return (
    <>
      <div className="DeskCommunicationLog" onClick={handleToggleIsModalOpen}>
        <DetailsCards title={t("v8_desk_comm_log").message || "Desk Communication Log"} icon="user" rightContent={null} showExpandArrow={false} button />
      </div>
      <Modal open={isModalOpen}>
        <Modal.Header className="DeskCommunicationLogHeader">
          <div className="header-title">
            <Icon name="list" />
            <div>{t("v8_desk_comm_log").message || "Desk communication log"}</div>
          </div>
          <Button onClick={() => handlePrint(null, () => contentToPrint?.current)} loading={printLoading} className="action-btn" color="green">
            {t("v8_print").message || "Print"}
            <Icon className="print" />
          </Button>
        </Modal.Header>

        <Modal.Content className="DeskCommunicationLogContent" scrolling>
          <div id="DeskCommunicationLog-Content" ref={contentToPrint}>
            <Communications appointment={appointment} desk_communications={desk_communications} />
          </div>
        </Modal.Content>

        <Modal.Actions className="DeskCommunicationLogActions">
          <Button className="action-btn" color="grey" onClick={handleToggleIsModalOpen} floated="right">
            {t("v8_close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

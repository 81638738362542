import ENV from "config/Env";
import ApiInstance from "util/Api";

const getChecklistReportUrl = async (check_id: number) => {
  return ApiInstance.post("/checks/report/url", { check_id }, ENV.appointmentBaseURL);
};

const getChecklistReport = async (check_id: number) => {
  return ApiInstance.get(`/checks/report/download?check_id=${check_id}`, ENV.appointmentBaseURL);
};

export { getChecklistReport, getChecklistReportUrl };

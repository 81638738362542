import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomerModal, ReactTable } from "components";
import { Customer } from "models";
import "modules/CarDetails/components/CustomerInfo/CustomerInfo.scss";
import { CustomerInfoProps } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

interface CustomerType extends Customer {
  label: string;
}
export const CustomerInfo = ({ data }: CustomerInfoProps) => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerID, setCustomerId] = useState<number | null>(null);
  const t = useTranslation().t as ITranslation;

  const handleCustomerModalOpen = (customer_id: number | undefined) => {
    if (customer_id) {
      setCustomerId(customer_id);
      setShowCustomerModal(true);
    }
  };

  const handleCustomerModalClose = () => {
    setCustomerId(null);
    setShowCustomerModal(false);
  };

  const customersData = (data: { [key: string]: Customer }) => {
    const customersArray: Customer[] = [];
    const labels = Object.keys(data);
    labels.forEach(item => {
      const customerData: CustomerType = { ...data[item] } as CustomerType;
      customerData.label = item;
      customersArray.push(customerData);
    });
    return customersArray;
  };

  const columnHelper = createColumnHelper<CustomerType>();
  const columns = [
    columnHelper.accessor(row => row.label, {
      id: "label",
      header: "",
      size: 80,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row, {
      id: "name",
      header: t("v8_name").message || "Name",
      cell: info => {
        const customer = info.renderValue();
        return <span onClick={() => handleCustomerModalOpen(customer?.id)}>{`${customer?.title || ""} ${customer?.firstname || ""} ${customer?.surname || ""}`}</span>;
      }
    }),
    columnHelper.accessor(row => `${row.tel_mobile_business || row.tel_mobile_private || ""}`, {
      id: "contact",
      header: t("v8_phone_number").message || "Phone Number",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.email_private, {
      id: "email",
      header: t("v8_email").message || "Email",
      cell: info => info.renderValue()
    })
  ];

  if (data) {
    return (
      <div className="CustomerInfo">
        <ReactTable columns={columns} data={customersData(data)} />
        <CustomerModal isOpen={showCustomerModal} onClose={handleCustomerModalClose} customerID={customerID} />
      </div>
    );
  }
  return null;
};

import moment from "moment";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Modal, ModalProps } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import printJS from "lib/print-js";
import { Appointment, QuestionResult, TYRE_POSITIONS, TYRE_SEASONS } from "models";
import "modules/AppointmentDetail/components/Tyre/DBBLabelsModal.scss";
import { PRINT_SIZES, getPreference, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";

interface DBBLabelsModalProps extends ModalProps {
  data: QuestionResult[];
  t: ITranslation;
  appointment: Appointment;
  onRequestClose?: () => void;
}

const DBBLabelsModal = ({ t, open, onRequestClose, appointment, data }: DBBLabelsModalProps) => {
  const { selectedLocation: location, selectedDealer: dealer } = useDealersLocations();

  const [printSize, setPrintSize] = useState(getPreference("preferences-dbb-print-size", PRINT_SIZES.A4));

  const getDBBComment = (position: TYRE_POSITIONS) => {
    switch (position) {
      case TYRE_POSITIONS.CarFrontLeft:
        return appointment.car?.on_car_tyres?.dbb_fl_comment;
      case TYRE_POSITIONS.CarFrontRight:
        return appointment.car?.on_car_tyres?.dbb_fr_comment;
      case TYRE_POSITIONS.CarRearLeft:
        return appointment.car?.on_car_tyres?.dbb_rl_comment;
      case TYRE_POSITIONS.CarRearRight:
        return appointment.car?.on_car_tyres?.dbb_rr_comment;
      case TYRE_POSITIONS.StorageFrontLeft:
        return appointment.car?.in_storage_tyres?.dbb_fl_comment;
      case TYRE_POSITIONS.StorageFrontRight:
        return appointment.car?.in_storage_tyres?.dbb_fr_comment;
      case TYRE_POSITIONS.StorageRearLeft:
        return appointment.car?.in_storage_tyres?.dbb_rl_comment;
      case TYRE_POSITIONS.StorageRearRight:
        return appointment.car?.in_storage_tyres?.dbb_rr_comment;
      default:
        return "";
    }
  };

  const getPositionInitialsDutch = (position: TYRE_POSITIONS) => {
    switch (position) {
      case TYRE_POSITIONS.CarFrontLeft:
      case TYRE_POSITIONS.StorageFrontLeft:
        return "LV";

      case TYRE_POSITIONS.CarFrontRight:
      case TYRE_POSITIONS.StorageFrontRight:
        return "RV";

      case TYRE_POSITIONS.CarRearLeft:
      case TYRE_POSITIONS.StorageRearLeft:
        return "LA";

      case TYRE_POSITIONS.CarRearRight:
      case TYRE_POSITIONS.StorageRearRight:
        return "RA";
      default:
        return "";
    }
  };

  const getSeasonName = (season: TYRE_SEASONS) => {
    switch (season) {
      case TYRE_SEASONS.AllSeason:
        return "All season";
      case TYRE_SEASONS.Winter:
        return "Winter";
      case TYRE_SEASONS.Summer:
        return "Zomer";
      default:
        return "";
    }
  };

  const shortenText = (text: string, delimiter: number) => {
    if (printSize === PRINT_SIZES.A4) return text;

    if (text.length > delimiter) return `${text.slice(0, delimiter).trim()}...`;
    return text;
  };

  const handlePrint = () => {
    printJS({
      printable: "dbb_label_content_container",
      type: "html",
      copyStyles: true
    });
  };

  return (
    <Modal className="DBBLabelsModal" closeOnEscape size="large" dimmer="blurring" open={open} onClose={onRequestClose} closeOnDimmerClick={false}>
      <Modal.Actions
        style={{
          textAlign: "right",
          position: "relative",
          zIndex: 1000
        }}
      >
        <div className="dbb_label_dropdown">
          <span>{t("v8_select_paper_size").message || "Select paper size"}</span>
          <Dropdown
            selection
            options={[
              { text: "A4", value: PRINT_SIZES.A4 },
              { text: "90mm", value: PRINT_SIZES.Label }
            ]}
            value={printSize}
            placeholder={t("v8_select_paper_size").message || "Select paper size"}
            onChange={(_evt, { value }) => {
              setPrintSize(value);
              setPreference("preferences-dbb-print-size", value);
            }}
          />
        </div>
        <div className="dbb_actions">
          <Button color="green" onClick={() => handlePrint()}>
            {t("v8_print").message || "PRINT"}
          </Button>
          <Button color="green" onClick={onRequestClose}>
            {t("v8_close").message || "CLOSE"}
          </Button>
        </div>
      </Modal.Actions>
      <Modal.Content scrolling id={"dbb_label_content_container"}>
        <div className={`dbb_wrapper ${printSize === PRINT_SIZES.Label ? "dbb_wrapper_label_size" : ""}`}>
          {data &&
            data.map((item: QuestionResult, i) => {
              const hasTyreSpecificationsInDescription = item.tyre?.description.includes("R") && item.tyre?.description.includes("/");

              return (
                <div className={`dbb_label_box ${printSize === PRINT_SIZES.Label ? "dbb_label_box_label_size" : ""}`} key={i}>
                  <h1 className={`${printSize === PRINT_SIZES.Label ? "h1_label_size" : ""}`}>{appointment.reg_number_escaped}</h1>
                  <div className={`dbb_label_content ${printSize === PRINT_SIZES.Label ? "dbb_label_content_label_size" : ""}`}>
                    <div className="dbb_label_tyre">
                      <div className="dbb_label_tyre_position">
                        <span>{getPositionInitialsDutch(item.tyre_position as TYRE_POSITIONS)}</span>:
                      </div>
                      <div className="dbb_label_tyre_size">{(item.tyre_profile as unknown as number)?.toFixed(1)}mm</div>
                      <div className="dbb_label_tyre_description">
                        {item.tyre?.manufacturer} {shortenText(item.tyre?.description as string, hasTyreSpecificationsInDescription ? 32 : 24)}
                        {!hasTyreSpecificationsInDescription && ` ${item.tyre?.width}/${item.tyre?.height}R${item.tyre?.size} ${item.tyre?.speed}`}
                      </div>
                    </div>
                    <div>
                      <span className="dbb_label_name">Soort:</span>
                      <span className="float-right">{getSeasonName(item.tyre?.season as TYRE_SEASONS)}: Band + stalen velg</span>
                    </div>
                    <div className={`dbb_label_comment ${printSize === PRINT_SIZES.Label ? "mb-28_label_size" : ""}`}>
                      <span className="dbb_label_name">Opm:</span>
                      <span className="float-right">{shortenText(getDBBComment(item.tyre_position as TYRE_POSITIONS) || "", 80)}</span>
                    </div>
                    <div className={`dbb_label_section mb-26 ${printSize === PRINT_SIZES.Label ? "mb-26_label_size" : ""}`}>
                      <div>
                        <span className="dbb_label_name">Dealer: </span>
                        <span className="float-right">{shortenText(dealer?.name as string, 35)}</span>
                      </div>
                      <div>
                        <span className="dbb_label_name">Plaats: </span>
                        <span className="float-right">{shortenText(String(location?.name), 35)}</span>
                      </div>
                      <div>
                        <span className="dbb_label_name">Eigenaar:</span>
                        <span className="float-right">{shortenText(`${appointment.owner_title} ${appointment.owner_firstname} ${appointment.owner_surname}`, 33)}</span>
                      </div>
                    </div>
                    <div>
                      <span className="dbb_label_name">Berijder:</span>
                      <span className="float-right">{shortenText(`${appointment.driver_title} ${appointment.driver_firstname} ${appointment.driver_surname}`, 35)}</span>
                    </div>
                    <div className={`mb-24 ${printSize === PRINT_SIZES.Label ? "mb-24_label_size" : ""}`}>
                      <span className="dbb_label_name">{t("v8_appointment_date").message || "Appointment Date"}:</span>
                      <span className="float-right">{moment(appointment.time_car_app).format("DD-MM-YYYY")}</span>
                    </div>
                    <div>
                      <span className="dbb_label_name">Opslaglocatie: </span>
                      <div className="dbb_location_name float-right">
                        {appointment.car?.in_storage_tyres && (
                          <>
                            {shortenText(appointment.car.in_storage_tyres.dbb_location_name, 28)}

                            <br />
                            {appointment.car.in_storage_tyres.dbb_sub_location_name && `(${shortenText(appointment.car.in_storage_tyres.dbb_sub_location_name, 27)})`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(DBBLabelsModal);

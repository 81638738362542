import { DefaultError, useQuery } from "@tanstack/react-query";

import ENV from "config/Env";
import { PinDropdownOptions } from "models";
import ApiInstance from "util/Api";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

type UsePinOptionsType = {
  loading: boolean;
  data: PinDropdownOptions;
  error: DefaultError | null;
};

export const usePinOptions = (): UsePinOptionsType => {
  const query = useQuery({
    queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.PinDropdownOptions),
    queryFn: () => ApiInstance.get("/pins/list_options", ENV.appointmentBaseURL)
  });
  return { data: query.data?.data?.pin_options, loading: query.isFetching, error: query.error };
};

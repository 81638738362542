import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import useAttachments from "components/Logs/hooks/useAttachments";
import ApiInstance from "util/Api";

const useLogs = (appointment_id: number) => {
  const [logs, setLogs] = useState(null);
  const { attachments, getAttachments } = useAttachments(appointment_id);

  const logsMutation = useMutation({
    mutationFn: async () => {
      const res = await ApiInstance.post("/appointments/view_log", { appointment_id });
      const rawData = res.data.split("\n");
      rawData.pop();
      return rawData.map((item: string, index: number) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const [date, _year, name, affectedElement, _n1, _n2, description] = item.split(",");
        return { date, name, affectedElement, description, id: index };
      });
    },
    onSuccess: data => {
      setLogs(data);
      getAttachments.mutate();
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  return { fetchLogs: logsMutation.mutate, logs, attachments };
};

export default useLogs;

import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { COMMUNICATION_EVENT_TYPE, DESK_COMMUNICATION_STATUS, DealerLocation, KEYLOCKER_COMMUNICATION_STATUS, STATUS_IDENTIFIER } from "models";
import { ACTIVITY_TYPE_FILTERS } from "modules/AppointmentDetail/components/ActivityLog";
import "modules/AppointmentDetail/components/ActivityLog/components/ActivityType/ActivityType.scss";
import { getAppointmentStatusName } from "util/common";
import { ITranslation } from "util/interfaces";

type ActivityProps = {
  activityType: ACTIVITY_TYPE_FILTERS;
  statusIdentifier?: STATUS_IDENTIFIER;
  communicationEventType?: COMMUNICATION_EVENT_TYPE;
  communicationStatusType?: DESK_COMMUNICATION_STATUS | KEYLOCKER_COMMUNICATION_STATUS;
};

const AppointmentStatusIconAndText = ({ statusIdentifier, selectedLocation }: { statusIdentifier: STATUS_IDENTIFIER; selectedLocation: DealerLocation | undefined }) => {
  const statusName = getAppointmentStatusName(statusIdentifier, selectedLocation?.statuses);
  let classname = "";
  switch (statusIdentifier) {
    case STATUS_IDENTIFIER.NewCarStatus:
      classname = "car activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CarInShop:
      classname = "car garage activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CarOutOfShop:
      classname = "car activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CarCheckStatus:
      classname = "wrench activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CarCheckStartedStatus:
      classname = "clock three activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.DiagnoseStatus:
      classname = "triangle exclamation activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CarReadyStatus:
      classname = "check double activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CalculatingStatus:
      classname = "file activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.PricingOKStatus:
      classname = "check activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.BackOrderStatus:
      classname = "bo-icon";
      break;

    case STATUS_IDENTIFIER.ContactStatus:
      classname = "phone flip activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.QualityCheckStatus:
      classname = "shield check activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CustomerAnsweredStatus:
      classname = "user check activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CustomerOKStatus:
      classname = "face smile regular activity-step-icon-color";
      break;

    case STATUS_IDENTIFIER.CanceledStatus:
      classname = "circle xmark activity-step-icon-color";
      break;

    default:
      classname = "";
      break;
  }

  if (classname) {
    return classname === "bo-icon" ? (
      <div>
        <div className="bo-icon">BO</div> {statusName}
      </div>
    ) : (
      <div>
        <Icon className={classname} /> {statusName}
      </div>
    );
  }
  return null;
};

const CustomerCommunicationIconAndTitle = ({ communicationEventType, t }: { communicationEventType: COMMUNICATION_EVENT_TYPE; t: ITranslation }) => {
  switch (communicationEventType) {
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened:
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_online_checkin").message || "Online check-in"}
        </div>
      );
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_diagnose_overview").message || "Diagnose overview"}
        </div>
      );

    case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewResent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewOpened:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_repair_overview").message || "Repair overview"}
        </div>
      );
    default:
      return (
        <div>
          <Icon className="envelope activity-step-icon-color" /> {t("v8_customer_communication").message || "Customer communication"}
        </div>
      );
  }
};

const DeskCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="tablet activity-step-icon-color" /> {t("v8_desk_checkin").message || "Desk check-in"}
    </div>
  );
};

const KeylockerCommunicationIconAndTitle = ({ t }: { t: ITranslation }) => {
  return (
    <div>
      <Icon className="key activity-step-icon-color" /> {t("v8_keylocker_communication").message || "Keylocker communication"}
    </div>
  );
};

export const ActivityType = ({ activityType, communicationEventType, statusIdentifier }: ActivityProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();

  switch (activityType) {
    case ACTIVITY_TYPE_FILTERS.Appointment:
      return <AppointmentStatusIconAndText statusIdentifier={statusIdentifier as STATUS_IDENTIFIER} selectedLocation={selectedLocation} />;
    case ACTIVITY_TYPE_FILTERS.Customer:
      return <CustomerCommunicationIconAndTitle communicationEventType={communicationEventType as COMMUNICATION_EVENT_TYPE} t={t} />;
    case ACTIVITY_TYPE_FILTERS.Desk:
      return <DeskCommunicationIconAndTitle t={t} />;
    case ACTIVITY_TYPE_FILTERS.Keylocker:
      return <KeylockerCommunicationIconAndTitle t={t} />;
    default:
      return null;
  }
};

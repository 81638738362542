import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

type ReassignRequestType = {
  appointment_id: number;
};

type MutationContext = {
  queryKey: QueryKey;
  previousAppointments: Appointment[] | undefined;
};

export const useUnassignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation<ReassignRequestType, Error, ReassignRequestType, MutationContext>({
    mutationFn: async requestData => ApiInstance.post("/appointments/unassign", { ...requestData }, ENV.dayplannerBaseUrl),
    onMutate: requestData => {
      const queryKey = ["realtime", { ...queryKeys.dayplanner.appointments, params: { dealer_location_id: selectedLocation?.id } }];

      const previousAppointments = queryClient.getQueryData<Appointment[] | undefined>(queryKey);
      const prevAppointment = previousAppointments?.find(app => app.id === requestData.appointment_id);

      if (!prevAppointment) return { queryKey, previousAppointments };

      queryClient.setQueryData(queryKey, (appointments: Appointment[] | []) => {
        const updatedAppointments = appointments.map(app => {
          if (app.id === prevAppointment.id) return { ...app, assigned_mechanic: null, assigned_mechanic_order: null, last_assigned_mechanic: app.assigned_mechanic };

          if (
            app.assigned_mechanic === prevAppointment.assigned_mechanic &&
            app.assigned_mechanic_order &&
            prevAppointment.assigned_mechanic_order &&
            app.assigned_mechanic_order > prevAppointment.assigned_mechanic_order
          ) {
            return {
              ...app,
              assigned_mechanic_order: app.assigned_mechanic_order - 1
            };
          }
          return app;
        });

        return updatedAppointments;
      });

      return { queryKey, previousAppointments };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousAppointments) {
        queryClient.setQueryData(context.queryKey, context.previousAppointments);
      }
    }
  });

  return mutation.mutate;
};

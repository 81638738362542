export const APPOINTMENT_NOTE_TYPES = {
  WO: 1,
  MAIN: 2,
  CALL_CUSTOMER: 3,
  BACK_ORDER: 4,
  INFO: 5,
  ATTACHMENT: 6,
  BILL_NOTE: 7,
  TIRESCANNER: 8,
  TEMPORARY_DRIVER_NOTE: 9,
  RECURRING_CAR: 10,
  PARKING: 11
};

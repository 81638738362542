import { Loader } from "semantic-ui-react";

import "modules/Styleguide/components/Loaders/Loaders.scss";

export const Loaders = () => (
  <>
    <div style={{ marginBottom: "10px", textAlign: "center" }}>
      <Loader active inline />
    </div>

    <div className="progress-bar-container progress-bar-top">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div className="progress-bar-container progress-bar-bottom">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </>
);

import { Icon } from "semantic-ui-react";

import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import "modules/AppointmentDetail/components/AppointmentNotes/components/AppointmentNoteTypeIconLabel/AppointmentNoteTypeIconLabel.scss";

type AppointmentNoteTypeIconLabelProps = {
  type: number;
};

export const AppointmentNoteTypeIconLabel = ({ type }: AppointmentNoteTypeIconLabelProps) => {
  switch (type) {
    case APPOINTMENT_NOTE_TYPES.INFO:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#2185D0" }}>
          <Icon className="info" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BACK_ORDER:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#6C0699" }}>
          <span style={{ color: "#FFF" }}>BO</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.WO:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#2185D0" }}>
          <Icon className="file alt" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.MAIN:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#FF0202" }}>
          <Icon className="sticky note" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21BA45" }}>
          <Icon className="phone alt" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#46B046" }}>
          <Icon className="file invoice dollar" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TIRESCANNER:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#FF8800" }}>
          <Icon className="tile" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#5C5C5C" }}>
          <Icon className="steering wheel" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#C83628" }}>
          <Icon className="exclamation triangle" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21A1D0" }}>
          <Icon className="paperclip" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.PARKING:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21A1D0" }}>
          <Icon className="square parking" style={{ color: "#FFF" }} />
        </div>
      );
    default:
      return null;
  }
};

import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";

import { Can, DealersLocationsDropdown, ProgressBar, WebsocketStatus } from "components";
import "components/NavBar/NavBar.scss";
import { useAuth, useDealersLocations, useUser } from "hooks";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type NavBarProps = {
  openSidebar?: () => void;
};

export const NavBar = ({ openSidebar }: NavBarProps) => {
  const location = useLocation();
  const user = useUser();
  const history = useHistory();
  const { selectedLocation } = useDealersLocations();
  const { logout } = useAuth();
  const carDetailRegex = /\/cardetails\/\d+/;
  const appointmentDetailRegex = /\/appointments\/\d+/;
  const t = useTranslation().t as ITranslation;

  const pathsToHideDealerLocationsDropdowns = [appointmentDetailRegex];
  const hideDealerLocationsDropdowns = pathsToHideDealerLocationsDropdowns.some(path => path.test(location.pathname));

  const routesConfig = [
    {
      path: PATHS.APPOINTMENTS,
      titleKey: "v8_appointment_list",
      defaultMessage: "Appointment List",
      defaultClassname: "AppointmentList-Navbar",
      icon: <WebsocketStatus />
    },
    {
      regex: appointmentDetailRegex,
      titleKey: "v8_appointment_details",
      defaultMessage: "Appointment Details",
      defaultClassname: "AppointmentDetails-Navbar",
      icon: <WebsocketStatus />
    },
    {
      regex: carDetailRegex,
      titleKey: "v8_car_details",
      defaultMessage: "Car Details",
      defaultClassname: "CarDetails-Navbar",
      icon: <WebsocketStatus />
    },
    {
      path: PATHS.DAY_PLANNER,
      titleKey: "v8_dayplanner",
      defaultMessage: "",
      defaultClassname: "Dayplanner-Navbar"
    },
    {
      path: PATHS.ACCOUNT,
      titleKey: "v8_user",
      defaultMessage: "User",
      defaultClassname: "AccountSettings-Navbar"
    },
    {
      path: PATHS.STYLE_GUIDE,
      titleKey: "v8_claire_v8",
      defaultMessage: "Claire V8",
      defaultClassname: "Styleguide-Navbar"
    },
    {
      path: PATHS.DEALER_DOCUMENTATION,
      titleKey: "v8_dealer_documentations",
      defaultMessage: "Dealer Documentations",
      defaultClassname: "Documentations-Navbar",
      icon: <WebsocketStatus />
    }
  ];

  const getRouteProperties = () => {
    for (const { path, regex, titleKey, defaultMessage, defaultClassname, icon } of routesConfig) {
      if (location.pathname === path || (regex && regex.test(location.pathname))) {
        return {
          title: (
            <>
              {defaultMessage && <h4>{t(titleKey)?.message || defaultMessage}</h4>}
              {icon}
            </>
          ),
          defaultClassname: defaultClassname
        };
      }
    }
    return {
      title: <h4>Claire V8</h4>,
      defaultClassname: "ClaireV8"
    };
  };

  const openDocumentation = () => {
    window.open(PATHS.DEALER_DOCUMENTATION, "_blank");
  };
  const openZohoWidget = () => {
    const ZohohcAsapWebLauncherbox = document.getElementById("zohohc-asap-web-launcherbox");
    if (ZohohcAsapWebLauncherbox) ZohohcAsapWebLauncherbox.click();
  };

  const profilePicture = user?.profile_picture;
  const profileName = `${user?.first_name || ""} ${user?.last_name || ""}`;

  const isDayPlannerEnabled = !carDetailRegex.test(location.pathname) && selectedLocation?.is_dayplanner_enabled;
  const isNotExcludedPath = ![PATHS.DAY_PLANNER, PATHS.ACCOUNT, PATHS.DEALER_DOCUMENTATION].includes(location.pathname);
  return (
    <div className={`nav-bar-wrapper ${getRouteProperties().defaultClassname}`}>
      <div className="nav-bar-content">
        <ProgressBar />
        <div className="nav-bar-start">
          <div className="pointer">
            <Icon name="bars" color="black" onClick={openSidebar} />
          </div>
          {getRouteProperties().title}
        </div>

        <div id="navbar-portal-container" />

        <div className="nav-bar-start">
          {!hideDealerLocationsDropdowns && <DealersLocationsDropdown />}
          {appointmentDetailRegex.test(location.pathname) && (
            <div className="pointer" onClick={openDocumentation}>
              <Icon className="book open cover" color="black" />
            </div>
          )}
          {isDayPlannerEnabled && isNotExcludedPath && (
            <Can I="browse" the="dayplanner-page">
              <Link className="button" to={PATHS.DAY_PLANNER}>
                <Icon className="square kanban regular i-grey" />
              </Link>
            </Can>
          )}
          {location.pathname === PATHS.DAY_PLANNER && (
            <Link className="button" to={PATHS.APPOINTMENTS}>
              <Icon className="list solid regular i-grey" />
            </Link>
          )}{" "}
          <div className="pointer">
            <Dropdown
              trigger={
                profilePicture ? (
                  <img title={profileName} className="small_profile_icon" src={profilePicture} />
                ) : (
                  <Icon title={profileName} className="circle user small_icon" />
                )
              }
              icon={null}
            >
              <DropdownMenu className="account_dropdown" direction="left">
                <DropdownItem className="profile_details">
                  {profilePicture ? (
                    <img title={profileName} className="profile_avatar" src={profilePicture} />
                  ) : (
                    <Icon title={profileName} size="huge" className="circle user" />
                  )}
                  <h1 className="mt-15">{profileName}</h1>
                  <p>{user?.email}</p>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem text={t("v8_account_settings").message || "Account Settings"} value="account_settings" onClick={() => history.push(PATHS.ACCOUNT)} />
                <DropdownDivider />
                <DropdownItem text={t("tickets_help_center").message || "Tickets & Help Center"} value="tickets_help_center" onClick={openZohoWidget} />
                <DropdownDivider />
                <DropdownItem text={t("v8_logout").message || "Log out"} value="Logout" onClick={logout} />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import { ITranslation } from "util/interfaces";

type AgreementModalProps = {
  isOpen: boolean;
  onClose: () => void;
  agreement: Record<string, any> | null;
};

export const AgreementModal = ({ isOpen, onClose, agreement }: AgreementModalProps) => {
  if (!agreement) return null;

  const t = useTranslation().t as ITranslation;

  return (
    <Modal dimmer={true} closeOnDimmerClick={false} open={isOpen} onClose={onClose} className="CommunicationLog-AgreementModal">
      <Modal.Header>
        {agreement.name}
        <Button onClick={onClose} color="green">
          {t("v8_close").message || "Close"}
        </Button>
      </Modal.Header>
      <Modal.Content scrolling>
        <div dangerouslySetInnerHTML={{ __html: agreement.text }}></div>
      </Modal.Content>
    </Modal>
  );
};

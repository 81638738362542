import { faRectangleList, faShieldHalved, faShop, faThumbTack, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Grid, GridColumn, Icon } from "semantic-ui-react";

import { getShopIconColor } from "components";
import "components/PinLog/PinLog.scss";
import { PIN_STATUS, PinModel } from "models";
import { ITranslation } from "util/interfaces";

type PinOptions = {
  key: string;
  text: string | undefined;
  value: number;
};

type PinLogProps = {
  pinTypeData: PinOptions[];
  pinWarrantyData: PinOptions[];
  pinStatusData: PinOptions[];
  pinOrderData: PinOptions[];
  pinLog: PinModel[];
  showActionButtons?: boolean;
  showMechanic?: boolean;
  showTitle?: boolean;
  isPrintModal?: boolean;
};

export const PinLog = ({
  pinTypeData,
  pinWarrantyData,
  pinStatusData,
  pinOrderData,
  pinLog,
  showMechanic,
  showTitle = true,
  showActionButtons = true,
  isPrintModal = false
}: PinLogProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="PinLog">
      {showTitle && (
        <Grid className="PinLogHistoryHeading">
          <GridColumn width={14}>
            <p>{t("v8_log_history").message || "Log history"}</p>
          </GridColumn>
          {showMechanic && (
            <GridColumn width={2} className="right-align">
              <p>{t("v8_mechanic").message || "Mechanic"}</p>
            </GridColumn>
          )}
        </Grid>
      )}
      <div className="PinLogHistory">
        {pinLog
          .sort((a, b) => moment(b.created_on).diff(moment(a.created_on)))
          .map((pinData: PinModel, i: number) => {
            const pinType = pinTypeData?.find(item => item.value === pinData.pin_type_id)?.text;
            const pinWarrantyType = pinWarrantyData?.find(item => item.value === pinData.warranty_type_id)?.text;
            const pinStatusType = pinStatusData?.find(item => item.value === pinData.pin_status_id)?.text;
            const pinOrderType = pinOrderData?.find(item => item.value === pinData.order_status)?.text;

            return (
              <Grid key={pinData.id}>
                <GridColumn width={14}>
                  <p>
                    <strong>
                      {t("v8_edited_by").message || "Edited by"} {pinData.is_dms ? "DMS " : `${pinData.user?.first_name} ${pinData.user?.last_name} on `}
                    </strong>{" "}
                    {`${moment(pinData.updated_on).format("DD-MM-YYYY HH:mm")}`}
                  </p>

                  <div className={`logInfo ${isPrintModal && "print-modal"}`}>
                    <>
                      {pinData.manufacturer_id && pinData.manufacturer_id > 0 && (
                        <Button basic color="aquamarine" className="nonInteractive">
                          <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                          <strong>
                            {t("v8_granted_access_to_the_manufacturer").message || "granted access to the manufacturer"} {pinData.manufacturer?.first_name}{" "}
                            {pinData.manufacturer?.last_name}
                          </strong>
                        </Button>
                      )}
                      {pinData.visible_mechanic && (
                        <Button basic color="aquamarine" className="nonInteractive">
                          <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                          <strong>{t("v8_visible_mechanic_text").message || "Visible for mechanic"}</strong>
                        </Button>
                      )}
                      {pinData.pin_status_id === PIN_STATUS.Deleted && <>{<strong style={{ color: "red" }}>{t("v8_deleted").message || "Deleted"}</strong>}</>}
                      {pinData.mechanic_fixed && !pinLog?.[i + 1]?.mechanic_fixed && (
                        <Button basic color="aquamarine" className="nonInteractive">
                          <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                          <strong style={{ color: "blue" }}>{t("v8_marked_as_fixed").message || "Marked as fixed"}</strong>
                        </Button>
                      )}
                      {pinData.info && (
                        <Button basic color="aquamarine" className="nonInteractive">
                          <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                          <strong style={{ color: "blue" }}>{t("v8_marked_as_info").message || "Marked as info"}</strong>
                        </Button>
                      )}
                    </>

                    <Button basic color="aquamarine" className="nonInteractive">
                      <FontAwesomeIcon icon={pinWarrantyType ? faShieldHalved : faThumbTack} className="i-grey mr-5" />
                      {pinWarrantyType || pinType}
                    </Button>
                    {pinOrderType && pinData.order_status && (
                      <Button basic color="aquamarine" className="nonInteractive">
                        <FontAwesomeIcon icon={faShop} color={getShopIconColor(pinData.order_status)} className="i-grey mr-5" />
                        {pinOrderType}
                      </Button>
                    )}
                    {pinStatusType && (
                      <Button basic color="aquamarine" className="nonInteractive">
                        <FontAwesomeIcon icon={faRectangleList} className="i-grey mr-5" />
                        {pinStatusType}
                      </Button>
                    )}
                    {pinData.claim_nr && (
                      <Button basic color="aquamarine" className="nonInteractive">
                        # {t("v8_claim").message || "Claim"}: {pinData.claim_nr}
                      </Button>
                    )}
                    {pinData.support_nr && (
                      <Button basic color="aquamarine" className="nonInteractive">
                        # {t("v8_support").message || "Support"}: {pinData.support_nr}
                      </Button>
                    )}
                    {pinData.ref_nr && (
                      <Button basic color="aquamarine" className="nonInteractive">
                        # {t("v8_reference").message || "Reference"}: {pinData.ref_nr}
                      </Button>
                    )}
                    <p className="logHistoryParagraph">
                      {pinData.pin_status_id === PIN_STATUS.Deleted
                        ? t("v8_deleted").message || "Deleted"
                        : pinData.keep_parts && pinData.pin_status_id !== PIN_STATUS.Deleted
                          ? `${t("v8_keep_parts").message || "Keep parts"}: ${pinData.note}`
                          : pinData.note}
                    </p>
                  </div>
                </GridColumn>
                {showActionButtons && (
                  <GridColumn width={2}>
                    {pinData.note && (
                      <div className="icons-right">
                        <Icon className={`eye ${pinData.visible_mechanic ? "green" : "red"}`} />
                      </div>
                    )}
                  </GridColumn>
                )}
              </Grid>
            );
          })}
      </div>
    </div>
  );
};

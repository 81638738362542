import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import ENV from "config/Env";
import { getBrowserLanguage } from "util/common";

const defaultLanguage = getBrowserLanguage();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: false,
      preload: [defaultLanguage],
      returnObjects: true,
      lng: defaultLanguage,
      ns: ["translations"],
      defaultNS: "translations",
      debug: false,
      interpolation: {
        escapeValue: false
      },
      load: "currentOnly",
      backend: {
        loadPath: `${ENV.apiBase}/translations/list_by_language_id?language={{lng}}`,
        crossDomain: true
      },
      react: {
        useSuspense: false
      }
    },
    (_err, _t) => {
      i18n.reloadResources();
    }
  );

export default i18n;

import { useDealersLocations } from "hooks";
import { Appointment, Customer } from "models";

const useHeaderSection = () => {
  const { selectedLocation } = useDealersLocations();

  const getCustomerEmail = (appointment: Appointment | null) => {
    if (!appointment) return "";

    const getEmailFrom = (customer: Customer | null) => customer && (customer.email_business || customer.email_private);
    return getEmailFrom(appointment.customer_owner) || getEmailFrom(appointment.customer_driver) || getEmailFrom(appointment.customer_contract);
  };

  const getCustomerPhone = (appointment: Appointment | null) => {
    if (!appointment) return "";

    const getPhones = (customer: Customer | null) => [customer?.tel_private_nr, customer?.tel_business_nr, customer?.tel_mobile_private, customer?.tel_mobile_business];
    return [...getPhones(appointment.customer_owner), ...getPhones(appointment.customer_driver), ...getPhones(appointment.customer_contract)].find(phone => phone);
  };

  const customerFirstName = (appointment: Appointment | null) => {
    if (!appointment) return "";
    return appointment.customer_owner?.firstname || appointment.customer_driver?.firstname || appointment.customer_contract?.firstname;
  };

  const customerSurnameName = (appointment: Appointment | null) => {
    if (!appointment) return "";
    return appointment.customer_owner?.surname || appointment.customer_driver?.surname || appointment.customer_contract?.surname;
  };

  return { selectedLocation, getCustomerEmail, getCustomerPhone, customerFirstName, customerSurnameName };
};

export default useHeaderSection;

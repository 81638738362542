import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Checkbox, Form, Input, TextArea } from "semantic-ui-react";

import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface DiagnoseOverviewProps {
  tabId: string;
}

export const DiagnoseOverview = ({ tabId }: DiagnoseOverviewProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_diagnose_overview").message || "DIAGNOSE OVERVIEW"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <Input value="" placeholder={t("v8_email").message || "E-mail"} fluid />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <Input value="" placeholder={t("v8_phone").message || "Phone"} fluid type="Phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <TextArea value="" placeholder={`${t("v8_hello").message || "Hello"}!`} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_success_text").message || "Success text"}</label>
            <TextArea value="" placeholder={`${t("v8_thank_you").message || "Thank You"}!`} />
          </Form.Field>
        </Form.Group>

        <div className="row mt-24">
          <div className="full">
            <Form.Field>
              <p className="items-center">
                <span>
                  <Checkbox toggle />
                </span>
                {t("v8_enable_remarks").message || "Enable remarks"}
              </p>
            </Form.Field>

            <Form.Field>
              <p className="items-center">
                <span>
                  <Checkbox toggle />
                </span>
                {t("v8_ask_the_customer_to_enter_his_name").message || "Ask the Customer to enter his name"}
              </p>
            </Form.Field>
          </div>

          <div className="full">
            <Form.Field>
              <p className="title">{t("v8_dont_fix_button").message || "Don't Fix Button"}</p>
              <p className="items-center">
                <span>
                  <Checkbox toggle />
                </span>
                {t("v8_enable_for_advised_items").message || "Enable for Advised Items"}
              </p>
            </Form.Field>

            <Form.Field>
              <p className="items-center">
                <span>
                  <Checkbox toggle />
                </span>
                {t("v8_enable_for_critical_items").message || "Enable for Critical Items"}
              </p>
            </Form.Field>
          </div>
        </div>
      </div>
    </Element>
  );
};

import ApiInstance from "util/Api";

type AppointmentWidgetArgs = {
  location_id: number;
  appointment_id: number;
};

type CarWidgetArgs = {
  location_id: number;
  car_id: number;
};

const usePSA = () => {
  const getAppointmentWidgetData = async (data: AppointmentWidgetArgs) => {
    try {
      const res = await ApiInstance.post("/servicebox/wowidget", data);
      return res.data;
    } catch (e) {
      console.warn("PSA WO widget error", e);
      return null;
    }
  };

  const getCarWidgetData = async (data: CarWidgetArgs) => {
    try {
      const res = await ApiInstance.post("/servicebox/carwidget", data);
      return res.data;
    } catch (e) {
      console.warn("PSA Car widget error", e);
      return null;
    }
  };

  return {
    getAppointmentWidgetData,
    getCarWidgetData
  };
};

export default usePSA;

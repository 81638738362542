import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, MessageHeader } from "semantic-ui-react";

import "components/ErrorFallback/ErrorFallback.scss";
import { ITranslation } from "util/interfaces";

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorFallback: FC<Props> = ({ resetErrorBoundary }) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="error-fallback-container">
      <Message>
        <MessageHeader>{t("v8_something_went_wrong").message || "Something went wrong"}</MessageHeader>
        <Button onClick={resetErrorBoundary}> {t("v8_try_again").message || "Try again"}</Button>
      </Message>
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

type CustomerDetailsMutationArgs = {
  id: number;
};

type EditCustomerDetailsMutationArgs = {
  customer_id: number;
  block_communication: boolean;
  is_internal: boolean;
  preferred_communication_channel: number;
};

const useCustomer = () => {
  const getCustomerData = async (data: CustomerDetailsMutationArgs) => {
    const res = await ApiInstance.post("/customers/service_details", data);
    return res.data;
  };

  const editCustomerData = async (data: EditCustomerDetailsMutationArgs) => {
    const res = await ApiInstance.post("/customers/update", data);
    return res.data;
  };

  const customerInfoMutation = useMutation({
    mutationFn: getCustomerData,
    onError: e => toast.error(e.message)
  });

  const editCustomerInfoMutation = useMutation({
    mutationFn: editCustomerData,
    onError: e => toast.error(e.message)
  });

  return { customerInfoMutation, editCustomerInfoMutation };
};

export default useCustomer;

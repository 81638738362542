import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import "components/NavBarSearch/NavBarSearch.scss";
import { ITranslation } from "util/interfaces";

type NavBarSearchProps = {
  searchQueryKey?: QueryKey;
  children?: React.ReactNode;
};

export const NavBarSearch = ({ searchQueryKey, children }: NavBarSearchProps) => {
  const { data: searchTerm } = useQuery({ queryKey: searchQueryKey as QueryKey });
  const queryClient = useQueryClient();
  const t = useTranslation().t as ITranslation;

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    queryClient.setQueryData(searchQueryKey as QueryKey, e.target.value);
  }

  return (
    <div className="NavBarSearch">
      <Input
        icon="magnifying glass"
        iconPosition="left"
        placeholder={`${t("v8_search").message || "Search"}...`}
        className="nav-bar-search"
        onChange={handleSearchChange}
        value={searchTerm || ""}
      />
      {children}
    </div>
  );
};

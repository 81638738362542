import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Grid, GridColumn, Icon, Input, TextArea } from "semantic-ui-react";

import { PinLog, useCan } from "components";
import { usePinOptions, useUser } from "hooks";
import { Appointment, Intervention, PIN_ORDER, PIN_TYPE, PinModel, PinOptions, STATUS_IDENTIFIER } from "models";
import { PinType } from "modules/AppointmentDetail/components";
import { PinOverview } from "modules/AppointmentDetail/components/Interventions/components";
import "modules/AppointmentDetail/components/Interventions/components/PinCategory/PinCategory.scss";
import { useIntervention } from "modules/AppointmentDetail/hooks";
import { ITranslation } from "util/interfaces";

type PinCategoryProps = {
  data: Intervention;
  appointment: Appointment | null;
  latestPinData: PinModel;
  pinType: PinType;
  pinTypeData: PinType[];
};

export const PinCategory = ({ data, appointment, latestPinData, pinType, pinTypeData }: PinCategoryProps) => {
  const { addPinItem } = useIntervention(data.appointment_id);
  const user = useUser();
  const { data: pinOptions } = usePinOptions();
  const t = useTranslation().t as ITranslation;

  const getShopIconColor = (orderStatus: number) => {
    switch (orderStatus) {
      case PIN_ORDER.ToOrder:
        return "blue";
      case PIN_ORDER.SameDay:
        return "green";
      case PIN_ORDER.NextDay:
        return "orange";
      case PIN_ORDER.BackOrder:
        return "red";
      default:
        return "";
    }
  };

  const pinStatusData = pinOptions?.pin_statuses?.map((pinStatus: PinOptions) => {
    return { key: pinStatus.name, text: t(`${pinStatus.name}`).message, value: pinStatus.id };
  });

  const pinOrderData = pinOptions?.pin_order_statuses?.map((pinOrder: PinOptions) => {
    return {
      key: pinOrder.name,
      text: t(`${pinOrder.name}`).message,
      value: pinOrder.id,
      icon: <Icon className="shopping cart" color={getShopIconColor(pinOrder.id)} />
    };
  });

  const pinWarrantyData = pinOptions?.warranty_types?.map((pinWarranty: PinOptions) => {
    return { key: pinWarranty.name, text: t(`${pinWarranty.name}`).message, value: pinWarranty.id };
  });

  const canUpdateInterventions = useCan("update", "appointments");

  const [pinWarrantyID, setPinWarrantyID] = useState(latestPinData?.warranty_type_id || null);
  const [supportNr, setSupportNr] = useState(latestPinData?.support_nr || "");
  const [claimNr, setClaimNr] = useState(latestPinData?.claim_nr || "");
  const [refNr, setRefNr] = useState(latestPinData?.ref_nr || "");
  const [note, setNote] = useState("");
  const [visibleMechanic, setVisibleMechanic] = useState(latestPinData?.visible_mechanic || false);
  const [keepParts, setKeepParts] = useState(latestPinData?.keep_parts || false);

  const [status, setStatus] = useState(latestPinData?.pin_status_id || null);
  const [orderStatus, setOrderStatus] = useState(latestPinData?.order_status || null);
  const [showPrintOverview, setShowPrintOverview] = useState(false);

  const openModal = () => setShowPrintOverview(true);
  const closeModal = () => setShowPrintOverview(false);

  useEffect(() => {
    setPinWarrantyID(latestPinData?.warranty_type_id || null);
    setSupportNr(latestPinData?.support_nr || "");
    setClaimNr(latestPinData?.claim_nr || "");
    setRefNr(latestPinData?.ref_nr || "");
    setStatus(latestPinData?.pin_status_id || null);
    setOrderStatus(latestPinData?.order_status || null);
  }, [latestPinData]);

  const hasUpdated = () => {
    if (!canUpdateInterventions) return false;
    if (latestPinData?.support_nr !== supportNr) return true;
    if ((latestPinData?.warranty_type_id || null) !== pinWarrantyID) return true;
    if ((latestPinData?.pin_status_id || null) !== status) return true;
    if ((latestPinData?.order_status || null) !== orderStatus) return true;
    if (latestPinData?.claim_nr !== claimNr) return true;
    if (latestPinData?.ref_nr !== refNr) return true;
    if (note !== "") return true;
    if (!supportNr && !claimNr && !refNr && !note) return false;
    return false;
  };

  const onDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    switch (name) {
      case "warranty_type_id":
        setPinWarrantyID(Number(value));
        break;
      case "pin_status_id":
        setStatus(Number(value));
        break;
      case "order_status":
        setOrderStatus(Number(value));
        break;
      default:
        break;
    }
  };

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.name) {
      case "support_nr":
        setSupportNr(evt.target.value);
        break;
      case "claim_nr":
        setClaimNr(evt.target.value);
        break;
      case "ref_nr":
        setRefNr(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onTextAreaChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (evt.target.name) {
      case "note":
        setNote(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    switch (data.name) {
      case "visible_mechanic":
        setVisibleMechanic(!!data.checked);
        break;
      case "keep_parts":
        setKeepParts(!!data.checked);
        break;
      default:
        break;
    }
  };

  const handleNewPinItem = async () => {
    const pin: PinModel = {
      warranty_type_id: pinWarrantyID,
      support_nr: supportNr,
      pin_status_id: status,
      pin_type_id: pinType.value,
      order_status: orderStatus,
      ref_nr: refNr,
      claim_nr: claimNr,
      intervention_id: data?.id,
      appointment_id: data?.appointment_id,
      intervention: data,
      user_id: user ? user.id : 0,
      question_result: null,
      note_attachments: [],
      note,
      keep_parts: keepParts,
      visible_mechanic: visibleMechanic
    };
    await addPinItem.mutate(pin);
  };

  useEffect(() => {
    if (addPinItem.isSuccess) {
      setNote("");
    }
  }, [addPinItem.isSuccess]);

  return (
    <div className="PinType">
      <Form>
        <Grid columns={4}>
          <GridColumn>
            <Form.Field>
              <label>{t("v8_support").message || "Support"} #</label>
              <Input value={supportNr} fluid name="support_nr" onChange={onInputChange} />
            </Form.Field>
          </GridColumn>
          <GridColumn>
            <Form.Field>
              <label>{t("v8_claim").message || "Claim"} #</label>
              <Input value={claimNr} fluid name="claim_nr" onChange={onInputChange} />
            </Form.Field>
          </GridColumn>
          <GridColumn>
            <Form.Field>
              <label>{t("v8_ref").message || "Ref"} #</label>
              <Input value={refNr} fluid name="ref_nr" onChange={onInputChange} />
            </Form.Field>
          </GridColumn>
          <GridColumn>
            <Form.Field>
              <label>{t("v8_order_status").message || "Order status"}</label>
              <Dropdown
                value={orderStatus as number}
                onChange={(_, { name, value }) => onDropdownChange(name, value)}
                name="order_status"
                icon={null}
                placeholder={t("v8_select_order_status").message || "Select order status"}
                fluid
                selection
                options={pinOrderData}
                trigger={
                  orderStatus ? (
                    <div>
                      <span>{pinOrderData.find(orderData => orderData.value === orderStatus)?.icon}</span>
                      <span className="orderStatusTitle">{pinOrderData.find(orderData => orderData.value === orderStatus)?.text}</span>
                    </div>
                  ) : null
                }
              />
            </Form.Field>
          </GridColumn>
        </Grid>

        <Grid columns={2}>
          {pinType.value === PIN_TYPE.Warranty && (
            <GridColumn>
              <Form.Field>
                <label>{t("v8_warranty_type").message || "Warranty type"}</label>
                <Dropdown
                  value={pinWarrantyID || undefined}
                  onChange={(_, { name, value }) => onDropdownChange(name, value)}
                  name="warranty_type_id"
                  placeholder={t("v8_select_type").message || "Select type"}
                  fluid
                  selection
                  options={pinWarrantyData}
                />
              </Form.Field>
            </GridColumn>
          )}
          {pinType.value !== PIN_TYPE.Remarks && (
            <GridColumn>
              <Form.Field>
                <label>{t("v8_pin_status").message || "Pin Status"}</label>
                <Dropdown
                  value={status || undefined}
                  onChange={(_, { name, value }) => onDropdownChange(name, value)}
                  name="pin_status_id"
                  placeholder={t("v8_select_status").message || "Select status"}
                  fluid
                  selection
                  options={pinStatusData}
                />
              </Form.Field>
            </GridColumn>
          )}
        </Grid>

        <Grid columns={1}>
          <GridColumn>
            <Form.Field>
              <label>{t("v8_note").message || "Note"}</label>
              <TextArea name="note" rows={2} placeholder={t("v8_note").message || "Note"} value={note} onChange={onTextAreaChange} />
            </Form.Field>
            <Form.Field>
              <div className="items-center">
                <div>
                  <span>
                    <Checkbox toggle disabled={!note} name="visible_mechanic" onChange={onCheckboxChange} checked={visibleMechanic} />
                  </span>
                  {t("v8_note_visible_for_mechanic").message || "Note Visible for mechanic"}
                </div>
                <div>
                  <span>
                    <Checkbox toggle disabled={!note} name="keep_parts" onChange={onCheckboxChange} checked={keepParts} />
                  </span>
                  {t("v8_keep_parts").message || "Keep Parts"}
                </div>
              </div>
            </Form.Field>
          </GridColumn>
        </Grid>

        {data.pin_history?.length > 0 && (
          <>
            <PinLog
              pinLog={data.pin_history}
              showMechanic
              pinTypeData={pinTypeData}
              pinOrderData={pinOrderData}
              pinStatusData={pinStatusData}
              pinWarrantyData={pinWarrantyData}
            />
          </>
        )}

        <div className="warranty-actions">
          <Button
            color="green"
            type="button"
            disabled={!hasUpdated() || appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus}
            onClick={handleNewPinItem}
          >
            {t("v8_update").message || "Update"}
            <Icon className="check" />
          </Button>
          <Button color="grey" className="-appointment-status" onClick={openModal}>
            <Icon className="print black" />
            {t("v8_print_overview").message || "Print Overview"}
          </Button>
        </div>
      </Form>

      <PinOverview
        showPrintOverview={showPrintOverview}
        pin={latestPinData}
        pinLog={data.pin_history}
        appointment={appointment}
        intervention={data}
        pinStatusOptions={pinStatusData}
        pinTypeData={pinTypeData}
        pinOrderData={pinOrderData}
        pinWarrantyData={pinWarrantyData}
        onClose={closeModal}
      />
    </div>
  );
};

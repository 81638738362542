import { Appointment, Check, Intervention } from "models";
import { useChecklist } from "modules/AppointmentDetail/hooks";

type Props = {
  appointment: Appointment | null;
  intervention: Intervention;
};

export type QuestionImages = {
  questionId: number;
  url: string;
  letterCode: string;
};

export type QuestionImagesObj = {
  checklistId: number;
  questionImages: QuestionImages[];
};

const CURRENT_LETTER = 97;

const useChecklistSection = ({ appointment, intervention }: Props) => {
  const { checklistQuery } = useChecklist(appointment?.id as number);
  const checklist: Check[] = checklistQuery.data || [];
  const questionImages: QuestionImagesObj[] = [];

  const filteredChecklist: Check[] = checklist.filter(item => item.intervention_id === intervention.id);

  const checklistWithActiveImages = filteredChecklist.map(checklistElement => ({
    ...checklistElement,
    question_items: checklistElement.question_items
      ?.filter(qItem => qItem.images && qItem.images.length)
      .map(item => ({ ...item, images: item.images.filter(el => el.active) }))
  }));

  checklistWithActiveImages.forEach(checklist => {
    checklist.question_items?.forEach(qItem => {
      const urls = qItem.images.map(qItem => qItem.url);
      const imageObject = urls.map(url => ({
        questionId: qItem.id,
        url,
        letterCode: String.fromCharCode(CURRENT_LETTER)
      }));

      questionImages.push({
        checklistId: checklist.id as number,
        questionImages: imageObject
      });
    });
  });

  return { filteredChecklist, questionImages };
};

export default useChecklistSection;

import { InvalidateQueryFilters, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useUpdate } from "hooks";
import { Appointment, Intervention, InterventionElement, PinModel } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export type InterventionData = {
  title: string;
  description: string;
  price?: number;
  customer_ok: boolean;
  solution?: string;
  appointment_id?: number;
  elements?: InterventionElement[];
  question_result_id?: number | null;
};

type UsePinVisibilityRequest = {
  intervention_id: number;
  pin_id: number;
  visible_mechanic: boolean;
};

type DeletePinRequest = {
  question_result_id?: number;
  intervention_id: number;
  note: string;
};

export const useIntervention = (id: number) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(id) }
    }
  ];

  const newIntervention = async (data: InterventionData) => {
    const res = await ApiInstance.post("/interventions/add", data, ENV.appointmentBaseURL);
    return res.data;
  };

  const newPinItem = async (data: PinModel) => {
    const res = await ApiInstance.post("/pins/append", data, ENV.appointmentBaseURL);
    return res.data.id;
  };

  const removePinItem = async (data: DeletePinRequest) => {
    await ApiInstance.post("/pins/delete", data, ENV.appointmentBaseURL);
  };

  const addIntervention = useMutation({
    mutationFn: newIntervention,
    onSuccess: () => {
      queryClient.invalidateQueries(appointmentDetailsViewKey as InvalidateQueryFilters);
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const updateIntervention = useMutation({
    mutationFn: async (data: InterventionData) => {
      const res = await ApiInstance.post("/interventions/update", data, ENV.appointmentBaseURL);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(appointmentDetailsViewKey as InvalidateQueryFilters);
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const updateInterventionData = (data: Partial<Intervention>) => {
    const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
    if (appointmentSnapshot?.interventions) {
      const updatedInterventions = appointmentSnapshot.interventions.map(intervention => {
        if (intervention.id === data.id) {
          return { ...intervention, ...data };
        }
        return intervention;
      });
      const updatedAppointmentData = { ...appointmentSnapshot, interventions: updatedInterventions };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
      requestUpdate();
    }
    return { appointmentSnapshot };
  };

  const optimisticInterventionUpdate = useMutation({
    mutationFn: async (data: Partial<Intervention>) => {
      const res = await ApiInstance.post("/interventions/update", data, ENV.appointmentBaseURL);
      return res.data;
    },
    onMutate: updateInterventionData,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.appointmentSnapshot) {
        queryClient.setQueryData(appointmentDetailsViewKey, context.appointmentSnapshot);
        requestUpdate();
      }
    }
  });

  const addPinItem = useMutation({
    mutationFn: newPinItem,
    onSuccess: () => {
      queryClient.invalidateQueries(appointmentDetailsViewKey as InvalidateQueryFilters);
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const deletePinItem = useMutation({
    mutationFn: removePinItem,
    onSuccess: () => {
      queryClient.invalidateQueries(appointmentDetailsViewKey as InvalidateQueryFilters);
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const updatePinVisibility = useMutation({
    mutationFn: async (data: UsePinVisibilityRequest) => {
      await ApiInstance.post("/pins/visibility", data, ENV.appointmentBaseURL);
      return data;
    },
    onMutate: (data: UsePinVisibilityRequest) => {
      const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      if (appointmentSnapshot?.interventions) {
        const updatedInterventions = appointmentSnapshot.interventions.map(intervention => {
          if (intervention.id === data.intervention_id) {
            const updatedInterventionPinHistory = intervention.pin_history?.map(pin => {
              if (pin.id === data.pin_id) {
                return { ...pin, visible_mechanic: !pin.visible_mechanic };
              }
              return pin;
            });
            return { ...intervention, pin_history: updatedInterventionPinHistory };
          }
          return intervention;
        });
        const updatedAppointmentData = { ...appointmentSnapshot, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
        requestUpdate();
      }
      return { appointmentSnapshot };
    },
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.appointmentSnapshot) {
        queryClient.setQueryData(appointmentDetailsViewKey, context.appointmentSnapshot);
        requestUpdate();
      }
    }
  });

  return { addIntervention, updateIntervention, addPinItem, deletePinItem, optimisticInterventionUpdate, updatePinVisibility };
};

import { useTranslation } from "react-i18next";

import { TYRE_POSITIONS } from "models";
import { ITranslation } from "util/interfaces";

const tyrePositionTitle = [
  { position: TYRE_POSITIONS.CarFrontLeft, translationKey: "v8_front_left", defaultText: "Front Left" },
  { position: TYRE_POSITIONS.CarFrontRight, translationKey: "v8_front_right", defaultText: "Front Right" },
  { position: TYRE_POSITIONS.CarRearLeft, translationKey: "v8_rear_left", defaultText: "Rear Left" },
  { position: TYRE_POSITIONS.CarRearRight, translationKey: "v8_rear_right", defaultText: "Rear Right" },
  { position: TYRE_POSITIONS.StorageFrontLeft, translationKey: "v8_front_left", defaultText: "Front Left" },
  { position: TYRE_POSITIONS.StorageFrontRight, translationKey: "v8_front_right", defaultText: "Front Right" },
  { position: TYRE_POSITIONS.StorageRearLeft, translationKey: "v8_rear_left", defaultText: "Rear Left" },
  { position: TYRE_POSITIONS.StorageRearRight, translationKey: "v8_rear_right", defaultText: "Rear Right" }
];

export const useTyreName = () => {
  const t = useTranslation().t as ITranslation;

  const getTyreName = (position: TYRE_POSITIONS | undefined) => {
    const title = tyrePositionTitle.find(title => title.position === position);
    return title ? t(title.translationKey).message || title.defaultText : "";
  };

  return { getTyreName };
};

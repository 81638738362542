import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { ChecklistTemplate, QuestionResult } from "models";

type SnoozeActionsProps = {
  data: QuestionResult;
  template?: ChecklistTemplate | null;
  onSelect: (data: ActionModalSelectorData) => void;
  toggleShowMedia?: () => void;
};

const TyreActions = ({ data, template, onSelect, toggleShowMedia }: SnoozeActionsProps) => {
  const images = data?.images?.filter(item => item.active) || [];
  const videos = data?.video?.filter(item => item.active) || [];

  return (
    <div className="tyre-action">
      <div className="tyre-container">
        <AnswerStatusSelector data={data} />
        {(images.length > 0 || videos.length > 0) && (
          <div onClick={toggleShowMedia}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{images.length + videos.length}</small>
            </p>
          </div>
        )}
        <ActionModalSelector data={data} template={template} onSelect={onSelect} editSnooze />
      </div>
    </div>
  );
};

export default TyreActions;

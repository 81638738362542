import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import {
  CheckInAgreedIntervention,
  CheckInDeclinedIntervention,
  DiagnoseOverviewAgreedIntervention,
  DiagnoseOverviewAgreedResult,
  DiagnoseOverviewContactResult,
  DiagnoseOverviewDeclinedResult
} from "models";
import { getPriceForDisplay } from "modules/AppointmentDetail/utils";
import { ITranslation } from "util/interfaces";

type ServicesProps = {
  title: string;
  results:
    | DiagnoseOverviewAgreedResult[]
    | DiagnoseOverviewContactResult[]
    | DiagnoseOverviewDeclinedResult[]
    | CheckInDeclinedIntervention[]
    | CheckInAgreedIntervention[]
    | DiagnoseOverviewAgreedIntervention[]
    | null
    | undefined;
};

export const Services = ({ results, title }: ServicesProps) => {
  if (!results?.length) return null;

  const t = useTranslation().t as ITranslation;
  const { vat } = results[0];

  return (
    <div className="info-section">
      <div className="section-header">{title}</div>
      <div className="section-content">
        <Grid>
          <Grid.Column width={16}>
            <div className="services-header">
              <span>{t("v8_title").message || "Title"}</span>
              <span>{vat ? t("v8_price_inc_vat").message || "Price (incl. VAT)" : t("v8_price_excl_vat").message || "Price (excl. VAT)"}</span>
            </div>
            {results.map((i, key) => (
              <div className="service" key={key}>
                <span>{i.title}</span>
                <span>{vat && i.price ? getPriceForDisplay(i.price * (1 + vat / 100)) : getPriceForDisplay(i.price)} €</span>
              </div>
            ))}
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

import { InvalidateQueryFilters, useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import { WebsocketMessageListener } from "components";
import ENV from "config/Env";
import { useDealersLocations, useRealTimeQueryOptions, useUpdate } from "hooks";
import { Appointment, AppointmentNote, DealerLocation } from "models";
import {
  KeyloopResults,
  NextLaneResults,
  getFormattedAndSortedKeyloopResults,
  getFormattedAndSortedNextlaneResults,
  getSortedClaireResults
} from "modules/Appointments/hooks";
import ApiInstance from "util/Api";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";
import { currentDate } from "util/common";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

interface SearchAppointmentMutationContext {
  previousSearchTerm?: string;
}

interface DateMutationContext {
  previousDate?: Date;
}

export const useAppointmentsQuery = () => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();
  const { selectedLocation }: { selectedLocation: DealerLocation | undefined } = useDealersLocations();
  const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;

  const appointmentListQueryKey = ["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }];

  const listeners: WebsocketMessageListener[] = [
    {
      model: "Appointment",
      filter: {
        dealer_location_id: Number(selectedLocation?.id)
      },
      permanent: true,
      callback: message => {
        const appointment = updateAppointmentStatusIdentifier(message.data as Appointment);

        const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;
        const appointmentListQueryKey = ["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }];

        const appointmentsSnapshot: Appointment[] | undefined = queryClient.getQueryData(appointmentListQueryKey);
        let updatedAppointmentList = [appointment];
        if (appointmentsSnapshot?.length) {
          let found = false;
          const updatedAppointments: Appointment[] = [...appointmentsSnapshot].map(app => {
            if (app.id === appointment.id && !found) {
              found = true;
              return { ...app, ...appointment } as Appointment;
            }
            return app;
          });
          if (found) {
            updatedAppointmentList = [...updatedAppointments];
          } else {
            updatedAppointmentList = [...appointmentsSnapshot].concat(appointment);
          }
        }
        queryClient.setQueryData(appointmentListQueryKey, updatedAppointmentList);
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "update",
      permanent: true,
      callback: message => {
        const appointmentNote = message.data as AppointmentNote;

        const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;
        const appointmentListQueryKey = ["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }];

        const appointmentsSnapshot: Appointment[] | undefined = queryClient.getQueryData(appointmentListQueryKey);
        if (appointmentsSnapshot?.length) {
          const updatedAppointments: Appointment[] = [...appointmentsSnapshot].map(app => {
            if (app.notes && app.id === appointmentNote.appointment_id) {
              const updatedNotes = [...app.notes].map(note => {
                if (note.id === appointmentNote.id) {
                  return { ...note, ...appointmentNote };
                }
                return note;
              });
              return { ...app, notes: updatedNotes } as Appointment;
            }
            return app;
          });
          queryClient.setQueryData(appointmentListQueryKey, updatedAppointments);
          requestUpdate();
        }
      }
    },
    {
      model: "AppointmentNote",
      action: "delete",
      permanent: true,
      callback: message => {
        const appointmentNote = message.data as AppointmentNote;

        const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;
        const appointmentListQueryKey = ["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }];

        const appointmentsSnapshot: Appointment[] | undefined = queryClient.getQueryData(appointmentListQueryKey);
        if (appointmentsSnapshot?.length) {
          const updatedAppointments: Appointment[] = [...appointmentsSnapshot].map(app => {
            if (app.notes && app.id === appointmentNote.appointment_id) {
              const updatedNotes = [...app.notes].filter(note => note.id !== appointmentNote.id);
              return { ...app, notes: updatedNotes } as Appointment;
            }
            return app;
          });
          queryClient.setQueryData(appointmentListQueryKey, updatedAppointments);
          requestUpdate();
        }
      }
    },
    {
      model: "AppointmentNote",
      action: "create",
      permanent: true,
      callback: message => {
        const appointmentNote = message.data as AppointmentNote;

        const date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || currentDate;
        const appointmentListQueryKey = ["realtime", { ...queryKeys.appointments.list, params: { location_id: selectedLocation?.id, date } }];

        const appointmentsSnapshot: Appointment[] | undefined = queryClient.getQueryData(appointmentListQueryKey);
        if (appointmentsSnapshot?.length) {
          const updatedAppointments: Appointment[] = [...appointmentsSnapshot].map(app => {
            if (app.id === appointmentNote.appointment_id) {
              const updatedNotes = app.notes?.length ? app.notes.concat(appointmentNote) : [appointmentNote];
              return { ...app, notes: updatedNotes } as Appointment;
            }
            return app;
          });
          queryClient.setQueryData(appointmentListQueryKey, updatedAppointments);
          requestUpdate();
        }
      }
    }
  ];

  const fetchAppointments = async (queryKey: any): Promise<Appointment[]> => {
    const [_key, { endpoint, params }] = queryKey;
    const response = await ApiInstance.post(`/${endpoint}`, params);
    return response?.data?.appointments?.map((app: Appointment) => updateAppointmentStatusIdentifier(app)) || [];
  };

  return useRealTimeQueryOptions({
    queryKey: appointmentListQueryKey,
    queryFn: ({ queryKey }) => fetchAppointments(queryKey),
    listeners,
    enabled: !!selectedLocation?.id && !!date
  });
};

export const useSearchAppointmentsMutation = () => {
  const queryClient = useQueryClient();
  const { isKeyLoopLocation, isNextLaneLocation, selectedLocation } = useDealersLocations();
  let baseUrl = ENV.apiBase;

  if (isKeyLoopLocation) baseUrl = ENV.keyloopBaseURL;
  else if (isNextLaneLocation) baseUrl = ENV.nextLaneBaseUrl;

  const fetchSearch = async (searchTerm: string) => {
    if (!selectedLocation) {
      return toast.error("please select location");
    }
    const res = await ApiInstance.post(
      (queryKeys.appointments.searchData as BackendQueryKey).endpoint,
      {
        dealer_id: selectedLocation.dealer_id,
        dealer_location_id: selectedLocation.id,
        limit: 20,
        page: 0,
        term: searchTerm
      },
      baseUrl
    );

    return res.data;
  };

  const mutation = useMutation<Appointment[] | KeyloopResults | NextLaneResults, Error, string, SearchAppointmentMutationContext>({
    mutationFn: fetchSearch,
    onSuccess: data => {
      let results;

      if (isKeyLoopLocation) results = getFormattedAndSortedKeyloopResults(data as KeyloopResults);
      else if (isNextLaneLocation) results = getFormattedAndSortedNextlaneResults(data as NextLaneResults);
      else results = getSortedClaireResults(data as Appointment[]);

      queryClient.setQueryData([queryKeys.appointments.searchData], results);
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  return mutation.mutate;
};

export const useDateAppointmentsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Date, Error, Date, DateMutationContext>({
    mutationFn: async newDate => Promise.resolve(newDate),
    onMutate: newDate => {
      const previousDate = queryClient.getQueryData<Date>([{ origin: "local", component: "Appointments", data: "date" }]);
      queryClient.setQueryData([{ origin: "local", component: "Appointments", data: "date" }], moment(newDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss[Z]"));
      return { previousDate };
    },
    onSuccess: (_newDate, _variables, context) => {
      queryClient.invalidateQueries(["realtime", { ...queryKeys.appointments.list, params: { date: context?.previousDate } }] as InvalidateQueryFilters);
    },
    onError: (error, _newDate, context) => {
      queryClient.setQueryData([{ origin: "local", component: "Appointments", data: "date" }], context?.previousDate);
      console.error(error);
    }
  });

  return mutation.mutate;
};

import { Icon } from "semantic-ui-react";

import { FinalCheckImage } from "models";
// import { ImageDragDrop } from "../../../../../../components";
import "modules/AppointmentDetail/components/Interventions/components/Images/Images.scss";

type ImagesProps = {
  data: FinalCheckImage[];
};

export const Images = ({ data = [] }: ImagesProps) => {
  return (
    <div className="Images">
      {/* <ImageDragDrop id="intervention-image" onAddImage={_e => null} /> */}

      <div className="image-container">
        {data.map((item: FinalCheckImage) => {
          return (
            <div key={item.id} className="image-view">
              <img src={item.url} />
              <div className="image-controls">
                <div className="image-delete">
                  <Icon className="trash grey" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Button, Icon, Modal } from "semantic-ui-react";

import { DetailsCards } from "components";
import { Appointment } from "models";
import { AppointmentDetails, Communications } from "modules/AppointmentDetail/components/KeylockerCommunicationLog/components";
import "modules/AppointmentDetail/components/KeylockerCommunicationLog/KeylockerCommunicationLog.scss";
import { ITranslation } from "util/interfaces";

type KeylockerCommunicationLogProps = {
  appointment: Appointment;
};

export const KeylockerCommunicationLog = ({ appointment }: KeylockerCommunicationLogProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const contentToPrint = useRef(null);
  const t = useTranslation().t as ITranslation;

  const handleToggleIsModalOpen = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    setIsModalOpen(!isModalOpen);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `DeskCommunicationLog_${appointment.wo_nr}_${moment().format("DD_MM_YY_HH_mm")}`,
    onAfterPrint: () => setPrintLoading(false),
    onBeforePrint: () => setPrintLoading(true)
  });

  const { keylocker_communications } = appointment;

  if (!keylocker_communications?.length) return null;

  return (
    <>
      <div className="KeylockerCommunicationLog" onClick={handleToggleIsModalOpen}>
        <DetailsCards title={t("v8_keylocker_comm_log").message || "Keylocker Communication Log"} icon="user" rightContent={null} showExpandArrow={false} button />
      </div>
      <Modal open={isModalOpen}>
        <Modal.Header className="KeylockerCommunicationLogHeader">
          <div className="header-title">
            <Icon name="list" />
            <div>{t("v8_keylocker_comm_log").message || "Keylocker communication log"}</div>
          </div>
          <Button onClick={() => handlePrint(null, () => contentToPrint?.current)} loading={printLoading} className="action-btn" color="green">
            {t("v8_print").message || "Print"}
            <Icon className="print" />
          </Button>
        </Modal.Header>

        <Modal.Content className="KeylockerCommunicationLogContent" scrolling>
          <div id="KeylockerCommunicationLog-Content" ref={contentToPrint}>
            <AppointmentDetails appointment={appointment} />
            <Communications appointment={appointment} keylocker_communications={keylocker_communications} />
          </div>
        </Modal.Content>

        <Modal.Actions className="KeylockerCommunicationLogActions">
          <Button className="action-btn" color="grey" onClick={handleToggleIsModalOpen} floated="right">
            {t("v8_close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

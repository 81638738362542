import { useTranslation } from "react-i18next";

import { AnswerStatusSelector } from "components";
import { Intervention } from "models";
import "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/InterventionSection/InterventionSection.scss";
import { ITranslation } from "util/interfaces";

type Props = {
  intervention: Intervention;
};

export const InterventionSection = ({ intervention }: Props) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="intervention-section">
      <div className="title">{t("v8_intervention").message || "Intervention"} </div>
      <div className="status-name-container">
        <h2 className="name-text">{intervention.title}</h2>
        <AnswerStatusSelector disabled={true} data={intervention} />
      </div>
      <div className="description">{intervention.description}</div>
    </div>
  );
};

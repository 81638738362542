import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import SearchDropdown from "components/SearchList/SearchDropdown";
import { Appointment } from "models";

export type SearchListType = "claire" | "keyloop" | "nextlane";

export interface AppointmentDataProps extends Appointment {
  search_category: SearchListType;
}

type SearchListProps = {
  searchQueryKey: QueryKey;
  searchDataQueryKey: QueryKey;
  onSearch: (searchTerm: string) => void;
};

export const SearchList = ({ searchQueryKey, searchDataQueryKey, onSearch }: SearchListProps) => {
  const [canSearch, setCanSearch] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: searchTerm } = useQuery({ queryKey: searchQueryKey });
  const { data: searchData } = useQuery({ queryKey: searchDataQueryKey });

  useEffect(() => {
    if (searchTerm && (searchTerm as string).length > 3) {
      setCanSearch(true);
    } else {
      setCanSearch(false);
    }
    queryClient.setQueryData(searchDataQueryKey as QueryKey, null);
  }, [searchTerm]);

  const closeSearch = () => {
    setCanSearch(false);
    queryClient.setQueryData(searchDataQueryKey as QueryKey, null);
  };

  const clearSearch = () => {
    queryClient.setQueryData(searchQueryKey as QueryKey, "");
    closeSearch();
  };

  const handleNav = (id: number) => {
    clearSearch();
    history.push(`/appointments/${id}`);
  };

  return (
    <>
      {canSearch && searchData == null && <Icon className="ellipsis grey list-view" onClick={() => onSearch(searchTerm as string)} />}
      {canSearch && (searchData as Appointment[]) && (
        <>
          <Icon className="xmark grey list-view" onClick={clearSearch} />
          <SearchDropdown data={searchData as AppointmentDataProps[]} onSelect={handleNav} onClose={closeSearch} />
        </>
      )}
    </>
  );
};

import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { WebSocketComponent, Websocket, WebsocketMessageListener } from "components";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

interface ExtendedUseQueryOptions<T, E = unknown> extends UseQueryOptions<T, E> {
  listeners: WebsocketMessageListener[];
}

export const useRealTimeQueryOptions = <T>(options: ExtendedUseQueryOptions<T>) => {
  const { listeners, ...restOptions } = options;
  const unsubscribeFunctions: (() => void)[] = [];
  const { data: websocket } = useQuery({ queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.Websocket) });
  const query = useQuery({ ...restOptions });

  useEffect(() => {
    if (listeners.length > 0 && query.isFetched) {
      listeners.forEach(listener => {
        const unsubscribe = WebSocketComponent.addMessageListener(listener);
        unsubscribeFunctions.push(() => unsubscribe(listener));
      });
    }

    return () => {
      if ((websocket as Websocket)?.isConnected) {
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
      }
    };
  }, [listeners, query.isFetched]);

  return query;
};

import { NProgress } from "@tanem/react-nprogress";
import React, { useState } from "react";

import "components/ProgressBar/ProgressBar.scss";
import { useAxiosProgress } from "hooks";

export const ProgressBar: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);

  useAxiosProgress(setProgress);

  return (
    <NProgress isAnimating={progress > 0} incrementDuration={100}>
      {({ isFinished, progress }) => (
        <div
          style={{
            opacity: isFinished ? 0 : 1,
            transform: `translate(${(-1 + progress) * 100}%, 0)`
          }}
          className="ProgressBar-container"
        >
          <div className="indicator" />
        </div>
      )}
    </NProgress>
  );
};

import moment from "moment";

import { Appointment, COMMUNICATION_STATUSES, STATUS_IDENTIFIER } from "models";

export const updateAppointmentStatusIdentifier = (appointment: Appointment): Appointment => {
  let currentStatus = null;

  const isStatusIn = (statuses: STATUS_IDENTIFIER[]) => statuses.includes(appointment.appointment_status_identifier);
  const isCurrentTimeBefore = (time: string) => moment(time).isBefore(moment());
  const isCurrentTimeAfter = (time: string) => moment(time).isAfter(moment());

  if (appointment.check_in_com_to_handle && isStatusIn([STATUS_IDENTIFIER.NewCarStatus])) {
    currentStatus = STATUS_IDENTIFIER.CheckInCar;
  } else if (isStatusIn([STATUS_IDENTIFIER.CarInShop]) && appointment.car_out_of_shop) {
    currentStatus = STATUS_IDENTIFIER.NewCarStatus;
  } else if (
    isStatusIn([STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CheckInDone]) &&
    appointment.car_in_shop &&
    isCurrentTimeBefore(appointment.car_in_shop) &&
    (!appointment.car_out_of_shop || isCurrentTimeAfter(appointment.car_out_of_shop))
  ) {
    currentStatus = STATUS_IDENTIFIER.CarInShop;
  } else if (isStatusIn([STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CheckInCar]) && appointment.check_in_com_answered && !appointment.check_in_com_to_handle) {
    currentStatus = STATUS_IDENTIFIER.CheckInDone;
  } else if (
    isStatusIn([STATUS_IDENTIFIER.CarReadyStatus, STATUS_IDENTIFIER.QualityCheckStatus]) &&
    (appointment.customcom_status === COMMUNICATION_STATUSES.RepairOverviewSent || appointment.customcom_status === COMMUNICATION_STATUSES.RepairOverviewOpened)
  ) {
    currentStatus =
      appointment.appointment_status_identifier === STATUS_IDENTIFIER.CarReadyStatus
        ? STATUS_IDENTIFIER.CarOkPlusRepairOverview
        : STATUS_IDENTIFIER.QualityCheckPlusRepairOverview;
  }

  return currentStatus ? ({ ...appointment, appointment_status_identifier: currentStatus } as Appointment) : appointment;
};

import { CircleIcon, ColorKeys } from "components";
import "modules/Appointments/components/SpecialIndicators/SpecialIndicators.scss";

export type IndicatorFilters = {
  icon: ColorKeys;
  active: boolean;
  filter: string;
  borderColor?: string;
};

type SpecialIndicatorsProps = {
  data: IndicatorFilters[];
  onSelect: (index: number) => void;
};

export const SpecialIndicators = ({ data, onSelect }: SpecialIndicatorsProps) => {
  return (
    <div className="SpecialIndicators">
      {data.map((item, index: number) => {
        return (
          <CircleIcon
            key={item.filter}
            icon={item.icon}
            disabled={!item.active}
            borderColor={item.borderColor}
            color={item.borderColor}
            onClick={() => onSelect(index)}
          />
        );
      })}
    </div>
  );
};

import { Icon, Table } from "semantic-ui-react";

import "components/TableView/TableView.scss";

interface Props {
  cells: string[];
  nrOfCells: number;
  enableCopy?: boolean;
}

export const TableView = ({ cells, nrOfCells, enableCopy }: Props) => {
  const tableRows = [];

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Something went wrong, cannot copy the value", err);
    }
  };

  for (let i = 0; i < cells.length; i += nrOfCells) {
    let cellsToRender = cells.slice(i, nrOfCells + i);
    if (cellsToRender.length < nrOfCells) cellsToRender = cellsToRender.concat(Array(nrOfCells - cellsToRender.length).fill(""));

    tableRows.push(
      <Table.Row key={cells[i]}>
        {cellsToRender.map(cell => (
          <Table.Cell
            content={
              cell ? (
                <div className="row">
                  <p>{`{{.${cell}}}`}</p>
                  {enableCopy && (
                    <div className="clipboard-copy i-grey" onClick={() => handleCopy(cell)}>
                      <Icon className="copy" />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )
            }
            key={cell}
          />
        ))}
      </Table.Row>
    );
  }

  return (
    <div className="table-view">
      <Table celled>
        <Table.Body>{[...tableRows]}</Table.Body>
      </Table>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import "modules/AppointmentDetail/components/AppointmentNotes/components/AddUpdateAppointmentNoteHeader/AddUpdateAppointmentNoteHeader.scss";
import { ITranslation } from "util/interfaces";

type AddUpdateAppointmentNoteHeaderProps = {
  type: number | null;
  isEditting: boolean;
};

export const AddUpdateAppointmentNoteHeader = ({ type, isEditting }: AddUpdateAppointmentNoteHeaderProps) => {
  const t = useTranslation().t as ITranslation;

  switch (type) {
    case APPOINTMENT_NOTE_TYPES.WO:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="file alt" style={{ color: "#4283CA" }} />
          <span>{isEditting ? t("v8_edit_note").message || "Edit Note" : t("v8_add_note").message || "Add Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.INFO:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="Info" style={{ color: "#4283CA" }} />
          <span>{t("v8_set_info_status").message || "Set Info status"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BACK_ORDER:
      return (
        <div className="Add-update-appointment-note-header">
          <div className="backorder-icon">
            <span>BO</span>
          </div>
          <span>{t("v8_set_back_order").message || "Set Back Order"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.MAIN:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="sticky note" color="red" />
          <span>{isEditting ? t("v8_edit_main_note").message || "Edit Main Note" : t("v8_add_main_note").message || "Add Main Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="phone alt" style={{ color: "#21BA45" }} />
          <span>
            {isEditting ? t("v8_edit_call_customer_note").message || "Edit Call Customer Note" : t("v8_add_call_customer_note").message || "Add Call Customer Note"}
          </span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="paperclip" style={{ color: "#4283CA" }} />
          <span>{isEditting ? t("v8_edit_attachment_note").message || "Edit Attachment Note" : t("v8_add_attachment_note").message || "Add Attachment Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="file invoice dollar" style={{ color: "#46B046" }} />
          <span>{isEditting ? t("v8_edit_customer_bill").message || "Edit Customer Bill" : t("v8_add_customer_bill").message || "Add Customer Bill"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="steering wheel" color="black" />
          <span>{isEditting ? t("v8_edit_temporary_driver").message || "Edit Temporary Driver" : t("v8_add_temporary_driver").message || "Add Temporary Driver"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="exclamation triangle" style={{ color: "#C83628" }} />
          <span>{isEditting ? t("v8_edit_recurring_car").message || "Edit recurring car" : t("v8_add_recurring_car").message || "Add Recurring Car"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.PARKING:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="square parking" style={{ color: "#C83628" }} />
          <span>{isEditting ? t("v8_edit_parking_location").message || "Edit parking location" : t("v8_add_parking_location").message || "Add parking location"}</span>
        </div>
      );

    default:
      return <span>{t("v8_add_note").message || "Add Note"}</span>;
  }
};

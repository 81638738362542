import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";

import { DatePicker, UploadFilesZone, UploadedFilesPreview } from "components";
import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import { AppointmentNote } from "models";
import { AppointmentNoteAttachment } from "modules/AppointmentDetail/components";
import { AddUpdateAppointmentNoteHeader } from "modules/AppointmentDetail/components/AppointmentNotes/components";
import "modules/AppointmentDetail/components/AppointmentNotes/components/AddUpdateAppointmentNote/AddUpdateAppointmentNote.scss";
import { PhoneOption } from "modules/AppointmentDetail/components/AppointmentNotes/hooks";
import { ITranslation } from "util/interfaces";

type AddUpdateAppointmentNoteProps = {
  selectedNote: AppointmentNote | null;
  note: string;
  noteType: number | null;
  visibleToMechanic?: boolean;
  date?: Date | null;
  phoneNr?: string;
  name?: string;
  email?: string;
  attachments: AppointmentNoteAttachment[] | null;
  phoneOptions?: PhoneOption[];
  hasValidationError: boolean;
  onInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onTextAreaChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onCheckBoxChange: (checked: boolean | undefined) => void;
  onChangeDate: (date: Date | null) => void;
  onDropdownChange: (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => void;
  onCloseAddUpdateNoteModal: () => void;
  onUnselectNote: () => void;
  onFileInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onFileDragOver: (evt: React.DragEvent<HTMLInputElement>) => void;
  onFileDrop: (evt: React.DragEvent<HTMLInputElement>) => void;
  onDeleteAttachment: (url: string) => void;
  onCreateNote: (type: number | null) => void;
  onUpdateNote: (type: number | null) => void;
  onDeleteNote: (noteID: number) => void;
  isCreatingAppointmentNote: boolean;
  isUpdatingAppointmentNote: boolean;
  isDeletingAppointmentNote: boolean;
};

export const AddUpdateAppointmentNote = ({
  selectedNote,
  note,
  noteType,
  visibleToMechanic,
  date,
  phoneNr,
  name,
  email,
  attachments,
  phoneOptions,
  hasValidationError,
  onInputChange,
  onTextAreaChange,
  onCheckBoxChange,
  onChangeDate,
  onDropdownChange,
  onCloseAddUpdateNoteModal,
  onUnselectNote,
  onFileInputChange,
  onFileDragOver,
  onFileDrop,
  onDeleteAttachment,
  onCreateNote,
  onUpdateNote,
  onDeleteNote,
  isCreatingAppointmentNote,
  isUpdatingAppointmentNote,
  isDeletingAppointmentNote
}: AddUpdateAppointmentNoteProps) => {
  const [defaultPhoneDropdownValue, setDefaultPhoneDropdownValue] = useState("");
  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    const contact = phoneOptions?.find(item => item.text === phoneNr);
    if (contact) {
      setDefaultPhoneDropdownValue(contact?.value);
    } else if (phoneOptions && phoneOptions.length > 0) {
      setDefaultPhoneDropdownValue(phoneOptions[phoneOptions?.length - 1].value || "other");
    }
  }, [phoneNr, phoneOptions]);

  const VisibleToMechanic =
    noteType && [APPOINTMENT_NOTE_TYPES.WO, APPOINTMENT_NOTE_TYPES.MAIN].includes(noteType) ? (
      <div className="add-update-appointment-note-checkbox-field">
        <Checkbox
          toggle
          checked={visibleToMechanic}
          name="visibleToMechanic"
          label={t("v8_note_visible_to_mechanic").message || "Note visible to mechanic"}
          onChange={(_, { checked }) => onCheckBoxChange(checked)}
        />
      </div>
    ) : null;

  const AddPhoneNumber =
    noteType === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER ? (
      <div className="add-update-appointment-center-container">
        <Dropdown
          className="add-update-appointment-note-phone-options-dropdown"
          selectOnBlur={false}
          selection
          name="phoneNr"
          options={phoneOptions}
          value={defaultPhoneDropdownValue}
          onChange={(_, { name, value }) => {
            if (value === "other") {
              onDropdownChange(name, "");
            } else {
              onDropdownChange(name, value);
            }
          }}
        />{" "}
        {defaultPhoneDropdownValue === "other" && (
          <Form.Field error={hasValidationError && !name} className="add-update-appointment-note-phone-input" required>
            <Input value={phoneNr} onChange={onInputChange} name="phoneNr" placeholder={t("v8_enter_phone_number").message || "Enter phone number"} />
          </Form.Field>
        )}
      </div>
    ) : null;
  const AddDate = noteType === APPOINTMENT_NOTE_TYPES.BACK_ORDER ? <DatePicker date={date} onDateChange={onChangeDate} showLabel /> : null;

  const TemporaryDriverNoteField =
    noteType === APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE ? (
      <Form.Group>
        <Form.Field error={hasValidationError && !name} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_name").message || "Name"}</label>
          <Input className="add-update-appointment-note-input-field" value={name} name="name" onChange={onInputChange} />
        </Form.Field>
        <Form.Field error={hasValidationError && !phoneNr} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_phone_number").message || "Phone Number"}</label>
          <Input className="add-update-appointment-note-input-field" value={phoneNr} name="phoneNr" onChange={onInputChange} />
        </Form.Field>
        <Form.Field error={hasValidationError && !email} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_email").message || "Email"}</label>
          <Input className="add-update-appointment-note-input-field" value={email} name="email" onChange={onInputChange} />
        </Form.Field>
      </Form.Group>
    ) : null;

  return (
    <Modal open className="AddUpdateAppointmentNote">
      <Modal.Header className="add-update-appointment-note-modal-header">
        <AddUpdateAppointmentNoteHeader type={noteType} isEditting={!!selectedNote} />{" "}
      </Modal.Header>
      <Modal.Content>
        <Form>
          {TemporaryDriverNoteField}
          <Form.Field
            error={hasValidationError && !note && ![APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(Number(noteType))}
            required={![APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(Number(noteType))}
          >
            <label>{t("v8_note").message || "Note"}</label>
            <TextArea
              className="add-update-appointment-note-textare-field"
              rows={5}
              value={note}
              name="note"
              onChange={onTextAreaChange}
              placeHolder={`${t("v8_type_note_here").message || "Type note here"}...`}
            />
          </Form.Field>
          {VisibleToMechanic}
          {AddPhoneNumber}
          {AddDate}
          {noteType === APPOINTMENT_NOTE_TYPES.BILL_NOTE && attachments?.length ? (
            <div className="attachment-uploaded">{t("v8_file_uploaded").message || "File Uploaded"}</div>
          ) : (
            <Form.Field
              error={hasValidationError && !attachments?.length && [APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(Number(noteType))}
              required={[APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(Number(noteType))}
            >
              <UploadFilesZone
                hasError={hasValidationError && !attachments?.length && [APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(Number(noteType))}
                display={noteType !== APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE}
                onFileInputChange={onFileInputChange}
                onFileDragOver={onFileDragOver}
                onFileDrop={onFileDrop}
              />
            </Form.Field>
          )}
          <UploadedFilesPreview attachments={attachments} onDeleteAttachment={onDeleteAttachment} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <div className={`${selectedNote ? "update-appointment-note-modal-actions" : "add-appointment-note-modal-actions"}`}>
          {!!selectedNote && (
            <div>
              <Button
                onClick={() => onDeleteNote(selectedNote?.id)}
                color="red"
                loading={isDeletingAppointmentNote}
                disabled={isCreatingAppointmentNote || isUpdatingAppointmentNote}
              >
                {t("v8_delete").message || "Delete"}
              </Button>
            </div>
          )}

          <div>
            <Button color="light" onClick={selectedNote && selectedNote.id > 0 ? onUnselectNote : onCloseAddUpdateNoteModal}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button
              type="submit"
              color="green"
              onClick={selectedNote && selectedNote.id > 0 ? () => onUpdateNote(noteType) : () => onCreateNote(noteType)}
              disabled={isDeletingAppointmentNote}
              loading={isCreatingAppointmentNote || isUpdatingAppointmentNote}
            >
              {t("v8_save").message || "Save"}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

import { QRCodeSVG } from "qrcode.react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { queryClient } from "index";
import { Auth } from "models";
import { IQRLoginProps } from "modules/Auth/interfaces";
import Service from "modules/Auth/service";
import { PATHS } from "router/paths";
import ApiInstance from "util/Api";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

const TIMEOUT: number = 60;
let interval: number;
let timeoutHandler: any;

class QrLogin extends Component<IQRLoginProps> {
  state = {
    qrToken: "",
    timer: -1,
    showServerError: false,
    generatingCode: false
  };

  componentWillUnmount() {
    clearInterval(interval);
    clearTimeout(timeoutHandler);
  }

  resetData = () => {
    clearInterval(interval);
    this.setState({ timer: -1 });
  };

  handleQRGenerate = () => {
    if (this.state.generatingCode) return;

    this.resetData();

    this.setState({ generatingCode: true }, async () => {
      try {
        const result = await Service.generateQRCode();
        if (result?.data?.data) {
          this.setState({ qrToken: result.data.data.qr_code, timer: TIMEOUT, showServerError: false, generatingCode: false }, () => {
            this.handleTimer();
            this.handleVerifyQRCode();
          });
        } else this.setState({ showServerError: true, generatingCode: false });
      } catch (error) {
        this.setState({ showServerError: true, generatingCode: false });
        console.error(error);
      }
    });
  };

  handleTimer = () => {
    clearInterval(interval);

    interval = window.setInterval(() => {
      let { timer } = this.state;
      timer -= 1;
      this.setState({ timer }, () => {
        if (timer < 1) {
          this.resetData();
        }
      });
    }, 1000);
  };

  handleVerifyQRCode = async () => {
    clearTimeout(timeoutHandler);

    const { timer } = this.state;

    if (timer > 0) {
      try {
        const result = await Service.verifyQR({ token: this.state.qrToken });
        if (result?.logged_in || result?.was_logged_in) {
          const data: Auth = {
            token: result?.token,
            zoho_asap_jwt: result?.zoho_asap_jwt,
            was_logged_in: result?.was_logged_in
          };
          queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo), () => data);
          ApiInstance.authToken = data.token;
          sessionStorage.setItem("_zoho_asap_jwt", data.zoho_asap_jwt);
          await Service.getUser();
          clearInterval(interval);
          this.props.history.push(result.appointment_id ? `${PATHS.APPOINTMENTS}/${result.appointment_id}` : PATHS.APPOINTMENTS);
        } else {
          timeoutHandler = window.setTimeout(this.handleVerifyQRCode, 1000);
        }
      } catch (error) {
        clearInterval(interval);
        this.setState({ showServerError: true });
        console.error(error);
      }
    }
  };

  render() {
    const { qrToken, timer, showServerError, generatingCode } = this.state;
    const { t }: any = this.props;
    const timerFinished = timer < 0;

    return (
      <div>
        <Button fluid color="blue" onClick={this.handleQRGenerate} className="qr-login" loading={generatingCode}>
          {qrToken ? t("v8_generate_another_code").message || "Generate another code" : t("v8_login_with_qr_code").message || "Login with QR code"}
        </Button>
        <div className="QRCode_Container">
          {qrToken && (
            <>
              <QRCodeSVG
                value={qrToken}
                fgColor={timerFinished || showServerError ? "#e03f3f" : "#000000"}
                style={{ filter: timerFinished || showServerError ? "blur(7px)" : "" }}
              />

              <div className={"-margin-top-10"}>
                {!showServerError &&
                  (timerFinished ? (
                    <span className="TextRed">
                      <Icon name="info circle" /> {t("v8_code_expired").message || "Code expired"}
                    </span>
                  ) : (
                    <>
                      <span style={{ color: "gray" }}>{t("v8_your_code_will_expire_in").message || "Your code will expire in"}</span> <b>{timer} sec</b>
                    </>
                  ))}
              </div>
            </>
          )}
          {showServerError && (
            <span className="TextRed">
              <Icon name="exclamation circle" /> {t("v8_an_error_occurred").message || "An error occurred"}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(QrLogin));

import { Grid, Icon } from "semantic-ui-react";

import { DeskCommunication, DeskCommunicationEvent } from "models";
import { DESK_COMMUNICATION_EVENTS } from "modules/AppointmentDetail/components/DeskCommunicationLog";

type EventInfoProps = {
  event: DeskCommunicationEvent;
  comm: DeskCommunication;
};

export const EventInfo = ({ event, comm }: EventInfoProps) => {
  const fullname =
    (event.type as unknown as DESK_COMMUNICATION_EVENTS) === DESK_COMMUNICATION_EVENTS.CheckInSent && comm.user && `${comm.user.first_name} ${comm.user.last_name}`;

  return (
    <Grid.Row width={16} className="event-info-rows">
      {fullname && (
        <span>
          <Icon name="user" color="green" />
          {fullname}
        </span>
      )}

      {event.customer_name && (
        <span>
          <Icon name="write" color="green" />
          {event.customer_name}
        </span>
      )}
    </Grid.Row>
  );
};

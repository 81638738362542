import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import "components/ROB/components/ROBListData/ROBListData.scss";
import { convertCamelCaseToWords } from "util/common";

interface ROBListingProps {
  data: Record<string, any>;
  title?: string;
  onGetValueForDisplay: (v: any) => any;
  showDivider: boolean;
}

const ROBListData = ({ data, title, onGetValueForDisplay, showDivider }: ROBListingProps) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handleGetNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleGetPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  if (!data?.length) return null;

  const dataToRender = Object.entries(data[currentPage]);

  return (
    <>
      <div className="ROBListData">
        <div className="section-title">{title}</div>
        {dataToRender.map(([key, value], idx) => {
          const words = convertCamelCaseToWords(key);
          const valueToDisplay = onGetValueForDisplay(value);

          return (
            <div className="data_row" key={idx}>
              <div className="title">{words}</div>
              <div className="value">{valueToDisplay}</div>
            </div>
          );
        })}

        {dataToRender.length > 1 && (
          <div className="controls">
            <Button disabled={currentPage <= 0} className="left-btn" onClick={handleGetPreviousPage}>
              <Icon className="angle left" />
            </Button>
            <Button disabled={currentPage >= data.length - 1} className="right-btn" onClick={handleGetNextPage}>
              <Icon className="angle right" />
            </Button>
          </div>
        )}
      </div>
      {showDivider && <hr className="divider" />}
    </>
  );
};

export default ROBListData;

import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { QuestionResult } from "models/QuestionResult";

type SnoozeActionsProps = {
  data: QuestionResult;
  onSelect: (data: ActionModalSelectorData) => void;
  toggleShowImages: () => void;
};

const SnoozeActions = ({ data, onSelect, toggleShowImages }: SnoozeActionsProps) => {
  return (
    <div className="snooze-action">
      <div className="status-container">
        <AnswerStatusSelector data={data} />
        {data.images?.length > 0 && (
          <div onClick={toggleShowImages}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{data.images.length}</small>
            </p>
          </div>
        )}
        <ActionModalSelector data={data} onSelect={onSelect} editSnooze />
      </div>
    </div>
  );
};

export default SnoozeActions;

import { User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum DOCUMENTATION_CATEGORIES {
  Warranty = 1,
  Technical,
  Cosmetic,
  Commercial,
  Internal,
  External,
  Manual
}

export class DealerDocumentation extends ApiLoadable {
  id: number = 0;
  title: string = "";
  content: string = "";
  url?: string = "";
  publish_date?: Date | null = null;
  is_pinned: boolean = false;
  visible_to_mechanic: boolean = false;
  visible_to_all: boolean = false;
  category_id: DOCUMENTATION_CATEGORIES = DOCUMENTATION_CATEGORIES.Manual;
  created_by_id?: number = 0;
  updated_by_id?: number = 0;
  updated_on?: Date | null = null;
  created_by?: User | null = null;
  updated_by?: User | null = null;

  private _dealer_ids?: number[] | null = null;
  private _dealer_location_ids?: number[] | null = null;

  set dealer_location_ids(newValue: number[] | null) {
    this._dealer_location_ids = newValue;
  }

  get dealer_location_ids(): number[] | null {
    return this._dealer_location_ids ? this._dealer_location_ids : [];
  }

  set dealer_ids(newValue: number[] | null) {
    this._dealer_ids = newValue;
  }

  get dealer_ids(): number[] | null {
    return this._dealer_ids ? this._dealer_ids : [];
  }
}

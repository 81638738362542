import moment from "moment";

import { APPOINTMENT_NOTE_TYPES } from "constants/global";
import { Appointment, AppointmentStatus, STATUS_IDENTIFIER } from "models";

export const sortAppointmentsByStatus = (appointments: Appointment[], statuses: AppointmentStatus[] | undefined): Appointment[] => {
  if (!appointments) return [];
  const orderMap = new Map<STATUS_IDENTIFIER, number>();
  statuses?.forEach(s => orderMap.set(s.identifier, s.order));

  return appointments.sort((a, b) => {
    if (statuses) {
      const aStatusOrder = orderMap.get(a.appointment_status_identifier) || 0;
      const bStatusOrder = orderMap.get(b.appointment_status_identifier) || 0;
      if (aStatusOrder < bStatusOrder) return -1;
      if (aStatusOrder > bStatusOrder) return 1;
    }

    return moment(a.last_timestamp).format("HH:mm A") === moment(b.last_timestamp).format("HH:mm A")
      ? 0
      : moment(a.last_timestamp).format("HH:mm A") > moment(b.last_timestamp).format("HH:mm A")
        ? -1
        : 1;
  });
};

export const sortAppointments = (appointments: Appointment[], statuses: AppointmentStatus[] | undefined): Appointment[] => {
  const panics: Appointment[] = [];
  const lockers: Appointment[] = [];
  const others: Appointment[] = [];

  appointments.forEach(appointment => {
    if (appointment.has_panic) {
      panics.push(appointment);
    } else if (
      (appointment.key_dropped_at && !appointment.key_picked_up_at) ||
      (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
      (appointment.acses_key_dropped_at && !appointment.acses_key_picked_up_at) ||
      (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
      (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at) ||
      (appointment.acses_key_dropped_back_at && !appointment.acses_key_picked_up_back_at)
    ) {
      lockers.push(appointment);
    } else {
      others.push(appointment);
    }
  });

  const sortedPanics = sortAppointmentsByStatus(panics, statuses);
  const sortedLockers = sortAppointmentsByStatus(lockers, statuses);
  const sortedOthers = sortAppointmentsByStatus(others, statuses);

  return [...sortedPanics, ...sortedLockers, ...sortedOthers];
};

export const filterAppointmentsBySearch = (appointments: Appointment[], searchTerm: string = "") => {
  const filteredAppointments = [];
  for (const appointment of appointments) {
    const matchFound = Object.values(appointment).some(value => {
      if (typeof value === "object" && value !== null) {
        return Object.values(value).some(nestedValue => String(nestedValue).toLowerCase().includes(searchTerm.toLowerCase()));
      }
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (matchFound) {
      filteredAppointments.push(appointment);
    }
  }
  return filteredAppointments;
};

export const filterSpecialIndicators = (appointments: Appointment[], specialIndicators: unknown | Record<string, string>) => {
  const specialIndicatorsKeys = Object.keys(specialIndicators || {});
  if (appointments.length && specialIndicatorsKeys.length) {
    return appointments.filter((appointment: Appointment) => {
      const onlyWithoutMoney = specialIndicatorsKeys.length === 1 && (specialIndicators as unknown as Appointment).is_money;
      let visible = onlyWithoutMoney;
      for (const icon of specialIndicatorsKeys) {
        switch (icon) {
          case "is_money":
            if (appointment.is_money) return false;
            break;

          case "pin":
            if (appointment.recall_pin_count || appointment.remark_pin_count || appointment.warranty_pin_count) visible = true;
            break;

          case "is_shop_green":
            if (appointment.is_shop_color === "green") visible = true;
            break;

          case "is_shop_orange":
            if (appointment.is_shop_color.startsWith("#")) visible = true;
            break;

          case "is_shop_blue":
            if (appointment.is_shop_color === "blue") visible = true;
            break;

          case "is_shop_red":
            if (appointment.is_shop_color === "red") visible = true;
            break;

          case "is_recurring":
            if (appointment.is_recurring || appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RECURRING_CAR)) visible = true;
            break;

          default:
            if (appointment[icon as keyof typeof appointment]) visible = true;
            break;
        }
      }

      return visible;
    });
  }
  return appointments;
};

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import ApiInstance from "util/Api";

type UseUploadSnoozeItemAttachmentRequest = {
  formData: FormData;
};

type UseUploadSnoozeItemAttachment = {
  isUploadingSnoozeItemAttachment: boolean;
  attachmentUrl: string;
  handleUploadSnoozeItemAttachment: ({ formData }: UseUploadSnoozeItemAttachmentRequest) => Promise<string>;
};

export const useUploadSnoozeItemAttachment = (): UseUploadSnoozeItemAttachment => {
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const mutationFn = async ({ formData }: UseUploadSnoozeItemAttachmentRequest) => {
    const response = await ApiInstance.post("/files/upload_snooze_note_attachment", formData);
    return response?.data?.URL as string;
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: (response: string) => {
      setAttachmentUrl(response);
    }
  });

  return { attachmentUrl, isUploadingSnoozeItemAttachment: mutation.isPending, handleUploadSnoozeItemAttachment: mutation.mutateAsync };
};

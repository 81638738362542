import { Icon } from "semantic-ui-react";

import "components/CircleIcons/CircleIcon.scss";
import { classNames } from "util/common";

const CircleIconColorMap = {
  is_star: { icon: "star", color: "green" },
  is_money: { icon: "dollar sign", color: "green" },
  "shopping cart": { icon: "shopping cart", color: "green" },
  "thumb tack": { icon: "thumb tack", color: "blue" },
  "triangle exclamation": { icon: "triangle exclamation", color: "red" },
  wrench: { icon: "wrench", color: "orange" },
  fill: { icon: "fill", color: "blue" },
  "tire orange": { icon: "tire orange", color: "orange" },
  "tire green": { icon: "tire green", color: "green" },
  coffee: { icon: "mug saucer", color: "cup-brown" },
  is_shop: { icon: "shopping cart", color: "blue" },
  shield: { icon: "shield", color: "blue" },
  pin: { icon: "pin", color: "blue" }
} as const;

export type ColorKeys = keyof typeof CircleIconColorMap;

type CircleIconProps = {
  icon: ColorKeys;
  disabled?: boolean;
  onClick?: () => void;
  color?: string;
  borderColor?: string;
};

export const CircleIcon = ({ icon, disabled, onClick, color, borderColor }: CircleIconProps) => {
  const customColor = color?.startsWith("#") ? "orange" : color;

  const circleIconContainerClassNames = classNames({
    [`${CircleIconColorMap[icon].color}bg`]: !customColor,
    [`${customColor}bg`]: !!customColor,
    [`${borderColor}-border`]: !!borderColor,
    disabled: disabled
  });

  const iconClassNames = classNames({
    [CircleIconColorMap[icon].color]: !customColor,
    [`${customColor}`]: !!customColor
  });

  return (
    <div className="circle-icon-container" onClick={onClick}>
      <div className={`circle-icon ${circleIconContainerClassNames}`}>
        <Icon className={`${CircleIconColorMap[icon].icon} ${iconClassNames}`} size="small" />
      </div>
    </div>
  );
};

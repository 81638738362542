import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useUpdate } from "hooks";
import { Appointment } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useIndicator = (appointmentId: number) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointmentId) }
    }
  ];

  const updateAppointment = (data: Partial<Appointment>) => {
    const AppointmentSnapshot = queryClient.getQueryData(appointmentDetailsViewKey);
    if (AppointmentSnapshot) {
      const updatedData = { ...(AppointmentSnapshot as Appointment), ...data };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedData);
      requestUpdate();
    }
    return { AppointmentSnapshot, appointmentDetailsViewKey };
  };

  const handleError = (e: any, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(context.appointmentDetailsViewKey, context.AppointmentSnapshot);
      requestUpdate();
    }
  };

  const appointmentMoneyIndicatorMutation = useMutation({
    mutationFn: ({ is_money }: { is_money: boolean }) =>
      ApiInstance.post(
        "/appointments/update_billing",
        {
          id: appointmentId,
          is_money
        },
        ENV.appointmentBaseURL
      ),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentStarIndicatorMutation = useMutation({
    mutationFn: ({ is_star, is_star_color }: { is_star: boolean; is_star_color: string }) =>
      ApiInstance.post(
        "/appointments/update_star",
        {
          id: appointmentId,
          is_star,
          is_star_color
        },
        ENV.appointmentBaseURL
      ),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentShopIndicatorMutation = useMutation({
    mutationFn: ({ is_shop, is_shop_color }: { is_shop: boolean; is_shop_color: string }) =>
      ApiInstance.post(
        "/appointments/update_shopping_cart",
        {
          id: appointmentId,
          is_shop,
          is_shop_color
        },
        ENV.appointmentBaseURL
      ),
    onMutate: updateAppointment,
    onError: handleError
  });

  return { appointmentMoneyIndicatorMutation, appointmentStarIndicatorMutation, appointmentShopIndicatorMutation };
};

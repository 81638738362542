import { useMemo } from "react";

import { STATUS_IDENTIFIER } from "models";
import { StatusButton } from "modules/Dayplanner/components/StatusFilters/components";
import "modules/Dayplanner/components/StatusFilters/StatusFilters.scss";
import { Appointment } from "modules/Dayplanner/utils";

export enum DAYPLANNER_STATUSES {
  NewCar = 1,
  CarInShop,
  Panic,
  InitialCheckDone,
  HandleCheckin,
  CarCheck,
  Diagnose,
  CustomerAnswered,
  CustomerOK,
  BackOrder
}

type StatusFilterProps = {
  appointments: Appointment[];
  activeFilters: DAYPLANNER_STATUSES[];
  onSelect: (filter: DAYPLANNER_STATUSES) => void;
};

export const filtersMatchingStatuses = [
  STATUS_IDENTIFIER.NewCarStatus,
  STATUS_IDENTIFIER.CarInShop,
  STATUS_IDENTIFIER.AannameOKStatus,
  STATUS_IDENTIFIER.CheckInCar,
  STATUS_IDENTIFIER.CarCheckStatus,
  STATUS_IDENTIFIER.DiagnoseStatus,
  STATUS_IDENTIFIER.CustomerAnsweredStatus,
  STATUS_IDENTIFIER.CustomerOKStatus,
  STATUS_IDENTIFIER.BackOrderStatus
];

export const ShouldBeFiltered = (appointment: Appointment, filterValue: DAYPLANNER_STATUSES | null): boolean => {
  switch (filterValue) {
    case DAYPLANNER_STATUSES.NewCar:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.NewCarStatus;
    case DAYPLANNER_STATUSES.CarInShop:
      return !!(appointment.appointment_status_identifier === STATUS_IDENTIFIER.CarInShop && appointment.car_in_shop && !appointment.car_out_of_shop);
    case DAYPLANNER_STATUSES.Panic:
      return appointment.has_panic;
    case DAYPLANNER_STATUSES.InitialCheckDone:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.AannameOKStatus;
    case DAYPLANNER_STATUSES.HandleCheckin:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.CheckInCar;
    case DAYPLANNER_STATUSES.CarCheck:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.CarCheckStatus;
    case DAYPLANNER_STATUSES.Diagnose:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.DiagnoseStatus;
    case DAYPLANNER_STATUSES.CustomerAnswered:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.CustomerAnsweredStatus;
    case DAYPLANNER_STATUSES.CustomerOK:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.CustomerOKStatus;
    case DAYPLANNER_STATUSES.BackOrder:
      return appointment.appointment_status_identifier === STATUS_IDENTIFIER.BackOrderStatus;
    default:
      return false;
  }
};

const getFilterColor = (status: DAYPLANNER_STATUSES | null) => {
  switch (status) {
    case DAYPLANNER_STATUSES.NewCar:
      return "#F49523";
    case DAYPLANNER_STATUSES.CarInShop:
      return "#EFC56B";
    case DAYPLANNER_STATUSES.Panic:
      return "#DB2828";
    case DAYPLANNER_STATUSES.InitialCheckDone:
      return "#ff5722";
    case DAYPLANNER_STATUSES.HandleCheckin:
      return "#5F7D8B";
    case DAYPLANNER_STATUSES.CarCheck:
      return "#F8C02B";
    case DAYPLANNER_STATUSES.Diagnose:
      return "#69BEC0";
    case DAYPLANNER_STATUSES.CustomerAnswered:
      return "#EA2D62";
    case DAYPLANNER_STATUSES.CustomerOK:
      return "#2D85D0";
    case DAYPLANNER_STATUSES.BackOrder:
      return "#6C0699";
    default:
      return "#FFFFFF";
  }
};

export const StatusFilters = ({ appointments, activeFilters, onSelect }: StatusFilterProps) => {
  const statuses = Object.keys(DAYPLANNER_STATUSES)
    .filter(key => Number.isNaN(Number(key)))
    .map(key => DAYPLANNER_STATUSES[key as keyof typeof DAYPLANNER_STATUSES]) as DAYPLANNER_STATUSES[];

  const filtersCount = useMemo(() => {
    const counts = statuses.reduce(
      (acc, status) => {
        acc[status] = 0;
        return acc;
      },
      {} as Record<DAYPLANNER_STATUSES, number>
    );

    appointments.forEach(appointment => {
      statuses.forEach(status => {
        if (ShouldBeFiltered(appointment, status)) {
          counts[status] += 1;
        }
      });
    });

    return counts;
  }, [appointments]);

  return (
    <div className="DayplannerStatusFilters">
      {statuses.map(value => {
        const status = Number(value) as DAYPLANNER_STATUSES;
        const isDisabled = !activeFilters.some(e => e === status);

        return (
          <StatusButton key={status} color={getFilterColor(status)} count={filtersCount[status]} filter={status} onClick={() => onSelect(status)} disabled={isDisabled} />
        );
      })}
    </div>
  );
};

import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import AceEditor from "react-ace";
import { useTranslation } from "react-i18next";

import "components/TextEditor/TextEditor.scss";
import { ITranslation } from "util/interfaces";

interface TextEditorProps {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  name?: string;
}

export const TextEditor = ({ value, onValueChange, placeholder, name = "UNIQUE_ID_OF_DIV" }: TextEditorProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <AceEditor
      placeholder={placeholder || t("v8_enter_message").message || "Enter Message"}
      value={value}
      mode="java"
      theme="chrome"
      onChange={onValueChange}
      name={name}
      editorProps={{ $blockScrolling: true }}
      style={{ width: "100%", height: "300px", border: "1px solid #f0f0f0", fontSize: 14, lineHeight: 2 }}
    />
  );
};

// eslint-disable-next-line import/order
import "semantic-ui-less/semantic.less";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";

import ENV from "config/Env";
import "util/i18n";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import App from "./App";
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import "./index.scss";

const container = document.getElementById("root");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000 * 60 * 24,
      refetchOnReconnect: false
    }
  }
});

if (container) {
  const root = createRoot(container);
  root.render(
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={ENV.clientID || ""}>
        <App />
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Checkbox, Form, Icon, Input, Popup } from "semantic-ui-react";

import { UploadImage } from "components";
import "modules/CustomerCom/components/General/General.scss";
import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface GeneralProps {
  tabId: string;
}

export const General = ({ tabId }: GeneralProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_general").message || "GENERAL"}</h3>
      <div>
        <Form.Group className="mt-18">
          <Form.Field width={8}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <Input value="" placeholder={t("v8_email").message || "E-mail"} fluid />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <Input value="" placeholder={t("v8_phone").message || "Phone"} fluid type="Phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_website").message || "Website"}</label>
            <Input value="" placeholder={t("v8_website").message || "Website"} fluid />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_layout").message || "Layout"}</label>
            <Input value="" placeholder={t("v8_layout").message || "Layout"} fluid />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_external_CSS").message || "External CSS"}</label>
            <Input value="" placeholder={t("v8_external_CSS").message || "External CSS"} fluid />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_external_JS").message || "External JS"}</label>
            <Input value="" placeholder={t("v8_external_JS").message || "External JS"} fluid />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8} className="pr-12">
            <label>{t("v8_google_analytics_ID").message || "Google Analytics ID"}</label>
            <Input value="" placeholder={t("v8_google_analytics_ID").message || "Google Analytics ID"} fluid />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-16">
          <Form.Field width={8} className="row items-center">
            <p>{t("v8_pick_a_colour_for_customer_communication").message || "Pick a colour for customer communication"}</p>
            <div className="color-picker">
              <div className="color" />
            </div>
          </Form.Field>
          <Form.Field width={8}>
            <label className="big">{t("v8_confirmation_emails").message || "Confirmation Emails"}</label>

            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_enable_sending_confirmation_emails").message || "Enable sending confirmation emails"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-18">
          <Form.Field width={8}>
            <p className="title">{t("v8_heading_position").message || "Heading position"}</p>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_logo_and_info_in_header").message || "Logo and info in Header"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="title">{t("v8_lease").message || "Lease"}</p>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_hide_price_of_interventions_for_lease_appointments").message || "Hide price of interventions for Lease appointments"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_logo_and_info_in_footer").message || "Logo and info in Footer"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_online_checkin_lease_customer").message || "Do not send Online check-in or other automated mail to Lease customer"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-25 file">
          <Form.Field width={8}>
            <label className="small">
              <span>{t("v8_upload_logo").message || "Upload Logo"} (.jpg, .jpeg, .png)</span>
              <Popup content="Min size of Logo 100x100 px" position="right center" trigger={<Icon className="circle info" />} />
            </label>
            <UploadImage path="/files/upload_welcome_pic_customcom" formId="logo-image" />
          </Form.Field>

          <Form.Field width={8}>
            <label className="small">
              <span>{t("v8_upload_welcome_image").message || "Upload welcome image"} (.jpg, .jpeg, .png) </span>
              <Popup content="Min size of Logo 100x100 px" position="right center" trigger={<Icon className="circle info" />} />
            </label>
            <UploadImage path="/files/upload_welcome_pic_customcom" formId="welcome-image" />
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};

import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import React from "react";
import { Pagination } from "semantic-ui-react";

import { Person, makeData } from "modules/Styleguide/components/TablePagination/makeData";

export function TablePagination() {
  const columns = [
    {
      accessorKey: "firstName",
      cell: (info: { getValue: () => any }) => info.getValue(),
      footer: (props: { column: { id: any } }) => props.column.id
    },
    {
      accessorFn: (row: { lastName: any }) => row.lastName,
      id: "lastName",
      cell: (info: { getValue: () => any }) => info.getValue(),
      header: () => "Last Name",
      footer: (props: { column: { id: any } }) => props.column.id
    },
    {
      accessorKey: "age",
      header: () => "Age",
      footer: (props: { column: { id: any } }) => props.column.id
    },
    {
      accessorKey: "visits",
      header: () => "Visits",
      footer: (props: { column: { id: any } }) => props.column.id
    },
    {
      accessorKey: "status",
      header: "Status",
      footer: (props: { column: { id: any } }) => props.column.id
    },
    {
      accessorKey: "progress",
      header: "Profile Progress",
      footer: (props: { column: { id: any } }) => props.column.id
    }
  ];

  function Table({ data, columns }: { data: Person[]; columns: ColumnDef<Person>[] }) {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      debugTable: true
    });

    return (
      <div>
        <table className="ReactTable">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <Pagination
          totalPages={table.getPageCount() - 1}
          onPageChange={(_, { activePage }) => {
            if (!activePage || typeof activePage === "string") return;
            const pageNumber: number = activePage;
            table.setPageIndex(pageNumber);
          }}
        />
      </div>
    );
  }

  const [data] = React.useState(() => makeData(100000));

  return (
    <>
      <Table
        {...{
          data,
          columns
        }}
      />
    </>
  );
}

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ReactNode } from "react";

import "components/ReactTable/ReactTable.scss";

interface ReactTableProps {
  data: any;
  columns: any[];
  renderEmptyRow?: boolean;
  emptyRowMessage?: string;
  customHeader?: ReactNode;
  state?: { columnVisibility: Record<string, boolean> };
  onRowClick?: (d: any) => void;
}
export const ReactTable = ({ data, columns, renderEmptyRow, customHeader, emptyRowMessage = "", state, onRowClick }: ReactTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state
  });

  return (
    <table className="ReactTable">
      <thead>
        {customHeader ||
          table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th style={{ width: header.column.columnDef.size }} key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => {
          if (onRowClick) {
            return (
              <tr key={row.id} className="hover" onClick={() => onRowClick(row.original)}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            );
          }
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          );
        })}
        {renderEmptyRow && (
          <tr>
            <td colSpan={columns.length} className="text-center">
              {emptyRowMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

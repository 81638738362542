import { useTranslation } from "react-i18next";

import { MilageChart } from "modules/CarDetails/components";
import "modules/CarDetails/components/CarDrivingTrend/CarDrivingTrend.scss";
import { ITranslation } from "util/interfaces";

type CarDrivingTrendProps = {
  chartData: StateData;
  car_lead_prediction?: { predicted_date: string };
};

export type KmChartColors = {
  backgroundColors: string[];
};
export type BubbleData = {
  x: Date;
  y: number;
  r: number;
};

export type RelevantData = {
  km: number;
  time: Date;
};

export type StateData = {
  isMilageChartVisible: boolean;
  totalAverageKM: {
    color: string;
    day: number;
    month: number;
    year: number;
  };
  kmChartColors: {
    backgroundColors: string[];
  };
  bubbleData: BubbleData[];
  lineData: BubbleData[];
};

export const CarDrivingTrend = ({ chartData, car_lead_prediction }: CarDrivingTrendProps) => {
  const { isMilageChartVisible, totalAverageKM, kmChartColors, bubbleData, lineData } = chartData;
  const t = useTranslation().t as ITranslation;

  return (
    <div className="CarDrivingTrend">
      {car_lead_prediction && (
        <p>
          {t("v8_predicted_date").message || "Predicted next maintenance"}: {car_lead_prediction.predicted_date}
        </p>
      )}

      {!isMilageChartVisible && <div className="NoDataAvailable">{t("v8_no_data_available").message || "No data available"}</div>}

      {isMilageChartVisible && (
        <div className="MileageChart-Wrapper">
          <div style={{ marginBottom: "0.5em", fontSize: "1.2em" }}>
            {t("v8_total_car_driving_trend").message || "Total driving trend"} ({t("v8_average").message || "Average"}&nbsp;
            <span style={{ color: totalAverageKM.color }}>{totalAverageKM.day}</span> km {t("v8_per_day").message || "Per day"},&nbsp;
            <span style={{ color: totalAverageKM.color }}>{totalAverageKM.month}</span> km {t("v8_per_month").message || "Per month"},&nbsp;
            <span style={{ color: totalAverageKM.color }}>{totalAverageKM.year}</span> km {t("v8_per_year").message || "Per year"} )
          </div>
          <MilageChart colors={kmChartColors} bubbleData={bubbleData} lineData={lineData} />
        </div>
      )}
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { WO_FILTERS } from "modules/Appointments";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

type ColumnDataSelectorType = {
  selectedColumn: string | string[];
  column: string;
  multiple?: boolean;
  setSelectedColumn: (value: string) => void;
};

export type ColumnDataSelectorChildMethods = {
  toggleDropdown: () => void;
};

export const ColumnDataSelector = forwardRef<ColumnDataSelectorChildMethods, ColumnDataSelectorType>(
  ({ selectedColumn, multiple = false, column, setSelectedColumn }, ref) => {
    const { data } = useQuery({ queryKey: [queryKeys.appointments.carMakes] });
    const t = useTranslation().t as ITranslation;

    const carMakeValues = data as string[];

    const DropdownOptions = {
      date: [
        { key: "time_car_app", value: "time_car_app", text: t("v8_appointment_date").message || "Appointment Date" },
        { key: "car_return_time", value: "car_return_time", text: t("v8_scheduled_out").message || "Scheduled Out" }
      ],
      customer: [
        { key: "driver", value: "driver", text: t("v8_driver_name").message || "Driver Name" },
        { key: "contractor", value: "contractor", text: t("v8_contractor_name").message || "Contractor Name" },
        { key: "owner", value: "owner", text: t("v8_owner_name").message || "Owner Name" }
      ],
      lastUser: [
        { key: "last_user", value: "last_user", text: t("v8_last_updated_by").message || "Last Updated By" },
        { key: "customer_owner", value: "customer_owner", text: t("v8_customer_owner").message || "Customer Owner" },
        { key: "receptionist_changed", value: "last_receptionist_update_by", text: t("v8_receptionist").message || "Receptionist" }
      ],
      wo: [
        { key: WO_FILTERS.ALL, value: WO_FILTERS.ALL, text: t("v8_all").message || "All" },
        { key: WO_FILTERS.WO, value: WO_FILTERS.WO, text: t("v8_WO").message || "WO" },
        { key: WO_FILTERS.INTERNAL, value: WO_FILTERS.INTERNAL, text: t("v8_internal").message || "Internal" }
      ],
      carMake: carMakeValues?.length
        ? [{ key: "All", value: "", text: t("v8_all").message || "All" }].concat(...carMakeValues.map(make => ({ key: make, value: make, text: make })))
        : []
    };

    type DropdownOptionType = Record<string, string>;

    const [open, setOpen] = useState<boolean>(false);

    const toggleFilterDropdown = () => {
      setOpen(p => !p);
    };

    useImperativeHandle(ref, () => ({
      toggleDropdown: toggleFilterDropdown
    }));

    const DropdownOptionKey = column as keyof typeof DropdownOptions;

    const handleSelection = (selectedValue: string): boolean => {
      if (Array.isArray(selectedColumn)) {
        return selectedValue ? selectedColumn.includes(selectedValue) : selectedColumn.length === 0;
      }
      return selectedValue === selectedColumn;
    };

    return (
      <div className="appointment-table-flex divider" onClick={toggleFilterDropdown}>
        <Dropdown multiple={multiple} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} icon="caret down" className="appointment-table-dd-margin">
          <Dropdown.Menu>
            {DropdownOptions[DropdownOptionKey]?.map((option: DropdownOptionType, index: number) => {
              return (
                <Dropdown.Item
                  selected={handleSelection(option.value)}
                  key={`filter-appointment-table-${index}`}
                  onClick={() => {
                    setOpen(false);
                    setSelectedColumn(option.key);
                  }}
                >
                  <div className="last-user-dropdown-selection">
                    <p className="text">{option.text}</p>
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
);

ColumnDataSelector.displayName = "ColumnDataSelector";

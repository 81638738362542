import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment, { Moment } from "moment";
import { useEffect } from "react";

import { WebSocketComponent, WebSocketMessage, WebSocketStatus } from "components";
import { useUser } from "hooks";
import { User } from "models";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

interface UserWithTimeOut extends User {
  timeOut: Moment;
}

const liveUsersQueryKey = staticLocalKey("liveUsers");

export const useLiveUsers = (appointmentId: number) => {
  const { data: websocket } = useQuery({ queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.Websocket) });
  const { data: users } = useQuery({ queryKey: liveUsersQueryKey });
  const user = useUser();
  const queryClient = useQueryClient();
  const listeners = [
    {
      model: "_ActiveUsersOnAppointmentDetailPage",
      action: "ping",
      id: appointmentId,
      callback: (message: WebSocketMessage) => {
        if (message) {
          const userData = message.data as Partial<User>;
          if (userData?.id !== user?.id) {
            const usersSnapshot: Partial<UserWithTimeOut[]> | undefined = queryClient.getQueryData(liveUsersQueryKey);
            let updatedUsers = [];
            let foundUser = false;
            updatedUsers =
              usersSnapshot?.map(prevUser => {
                if (prevUser?.id === userData.id) {
                  foundUser = true;
                  return {
                    ...prevUser,
                    timeOut: moment().add(3, "seconds")
                  };
                }
                return prevUser;
              }) || [];

            if (!foundUser) {
              updatedUsers = usersSnapshot?.concat({ ...userData, timeOut: moment().add(3, "seconds") } as UserWithTimeOut) || [];
            }
            queryClient.setQueryData(liveUsersQueryKey, updatedUsers);
          }
        }
      }
    }
  ];

  useEffect(() => {
    if (user && (websocket as WebSocketStatus)?.isConnected) {
      const unsubscribeFunctions: (() => void)[] = [];
      WebSocketComponent.subscribeToAppointmentQueue(appointmentId);

      const interval = setInterval(() => {
        WebSocketComponent.sendAppointmentActiveUserPing(appointmentId, user);
        const usersSnapshot: Partial<UserWithTimeOut[]> | undefined = queryClient.getQueryData(liveUsersQueryKey);
        if (usersSnapshot?.length) {
          const updatedUsers = usersSnapshot.filter(prevUser => moment().diff(prevUser?.timeOut, "seconds") <= 3);
          queryClient.setQueryData(liveUsersQueryKey, updatedUsers);
        }
      }, 1000);

      listeners.forEach(listener => {
        const unsubscribe = WebSocketComponent.addMessageListener(listener);
        unsubscribeFunctions.push(() => unsubscribe(listener));
      });

      return () => {
        clearInterval(interval);
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        WebSocketComponent.unsubscribeFromAppointmentQueue(appointmentId);
      };
    }
  }, [websocket, user]);

  return { users: (users || []) as Partial<User[]> };
};

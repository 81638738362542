import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Icon, Modal, TextArea } from "semantic-ui-react";

import { FileDropdown } from "components";
import { AppointmentNoteAttachment } from "models";
import "modules/CarDetails/components/Notes/Notes.scss";
import { Note } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

type DMSNote = {
  created_on: string;
  note: string;
  id: number;
};

type NotesProps = {
  dmsNotes?: DMSNote[];
  appointmentNotes?: Note[];
  showNoteModal?: boolean;
  onRequestClose?: () => void;
  onRequestOpen?: () => void;
};

export const Notes = ({ dmsNotes = [], appointmentNotes = [], showNoteModal, onRequestClose }: NotesProps) => {
  const t = useTranslation().t as ITranslation;

  const handleFile = (evt: any) => {
    evt.preventDefault();
  };

  const handleAddFile = () => {
    const input = document.getElementById("note-image");
    input?.click();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleEditNote = () => {
  //   onRequestOpen && onRequestOpen();
  // };

  return (
    <>
      <div className="Notes">
        {appointmentNotes.map((item: Note) => {
          return (
            <div className="Notes-container" key={item.id}>
              <div className="row">
                <div className="row nogap note">
                  <div className="icon-only">
                    <Icon className="car mirrors" />
                  </div>
                  {/* <div className="edit" onClick={handleEditNote}>
                    <Icon className="pen to square" />
                  </div> */}
                </div>

                <FileDropdown attachments={item.attachments as unknown as AppointmentNoteAttachment[]} />

                <p>
                  <small>{item.note}</small>
                </p>
              </div>
              <p className="italic">{`${t("v8_added_by").message || "Added by"} ${item.user.first_name} ${item.user.last_name} ${t("v8_on").message || "on"} ${moment(
                item.created_on
              ).format("DD-MM-YYYY HH:mm")}`}</p>
            </div>
          );
        })}

        {dmsNotes.map((item: DMSNote) => {
          return (
            <div className="Notes-container" key={item.id}>
              <p>
                <span>
                  <Icon className="desktop" />
                </span>
                <small>{item.note}</small>
              </p>
              <p className="italic">{`${t("v8_added_by").message || "Added by"} ${t("v8_dms_note").message || "DMS NOTE"} ${t("v8_on").message || "on"} ${moment(
                item.created_on
              ).format("DD-MM-YYYY HH:mm")}`}</p>
            </div>
          );
        })}
      </div>
      <Modal size="large" open={showNoteModal} onClose={onRequestClose} closeOnDimmerClick>
        <Modal.Header>
          <div className="notes-modal-header-icon">
            <Icon className="car mirrors red" />
          </div>
          <p>{t("v8_add_car_details_note").message || "Add Car Details Note"} </p>
        </Modal.Header>
        <Modal.Content>
          <div>
            <Form>
              <Form.Field>
                <label>{t("v8_note").message || "Note"}</label>
                <TextArea placeHolder={`${t("v8_type_note_here").message || "Type note here"}...`} />
              </Form.Field>

              <Form.Field width={16}>
                <p className="notes-items-center">
                  <span>
                    <Checkbox toggle />
                  </span>
                  {t("v8_note_visible_for_mechanic").message || "Note visible for mechanic"}
                </p>
              </Form.Field>
            </Form>
            <div className="notes-drag-image" onDrop={handleFile} onDragEnter={e => e.preventDefault()} onDragOver={e => e.preventDefault()}>
              <p>
                {t("v8_drag_image_here_or").message || "Drag image here or"}{" "}
                <span onClick={handleAddFile}>
                  <input accept="image/png, image/gif, image/jpeg" type="file" id="note-image" />
                  {t("v8_browse").message || "Browse"}
                </span>
              </p>
              <p>
                <small>{t("v8_maximum_file_size_32MB").message || "Maximum file size: 32MB"}</small>
              </p>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className="delete-button" />
          <div className="action-buttons">
            <Button color="light" onClick={onRequestClose}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button color="green">
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { Button, Icon, Popup } from "semantic-ui-react";

import { Can, CustomerModal, DBB, Editable, Logs, MCC, PSA, RDW, ROB, TabData, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, STATUS_IDENTIFIER } from "models";
import { CopyContent } from "modules/AppointmentDetail/components";
import "modules/AppointmentDetail/components/CarDetails/CarDetails.scss";
import CrossIconImage from "modules/AppointmentDetail/components/CarDetails/cross-icon.svg";
import { useLiveUsers, useUpdateAppointment } from "modules/AppointmentDetail/hooks";
import { classNames, getCustomerName, getUserNameInitials, isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type CarDetailsProps = {
  title: string;
  isExpanded: boolean;
  data: Appointment;
  tabData: TabData;
  handleCancelAppointment: () => void;
};

export const CarDetails = ({ title, isExpanded, data, handleCancelAppointment, tabData }: CarDetailsProps) => {
  const [showCardDetails, setShowCardDetails] = useState(isExpanded);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerID, setCustomerId] = useState<number | null>(null);

  const t = useTranslation().t as ITranslation;
  const { appointmentCurrentKmMutation } = useUpdateAppointment(data.id);
  const { selectedLocation } = useDealersLocations();

  const { users } = useLiveUsers(data.id);
  const canBrowseCarsPage = useCan("browse", "appointments-car-page");
  const canUpdateAppointments = useCan("update-mileages", "appointments");

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  const toggleCardDetails = () => {
    setShowCardDetails(p => !p);
  };

  const handleCustomerModalOpen = (customer_id: number | undefined) => {
    if (customer_id) {
      setCustomerId(customer_id);
      setShowCustomerModal(true);
    }
  };

  const handleCustomerModalClose = () => {
    setCustomerId(null);
    setShowCustomerModal(false);
  };

  // const handlePDFLink = (event: MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation();
  // };
  // const handlePDF = () => {};

  const handleCurrentKmUpdate = (current_km: string) => {
    appointmentCurrentKmMutation.mutate({ current_km: Number(current_km) });
  };

  return (
    <>
      <Element name={title} className="DetailsCards-container" id={tabData.id}>
        <div className="row">
          <Icon className="circle exclamation" />
          <h3>{title}</h3>
          {<Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />}
          <div className="full end">
            {[STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CarInShop, STATUS_IDENTIFIER.CheckInCar, STATUS_IDENTIFIER.CheckInDone].includes(
              data.appointment_status_identifier
            ) && (
              <Can I="cancel" the="appointments">
                <Button className="-appointment-status icon cancel-appointment" size="small" onClick={handleCancelAppointment}>
                  <img src={CrossIconImage} />
                </Button>
              </Can>
            )}

            {users.map((user, index) => {
              const fullName = `${user?.first_name || ""} ${user?.last_name || ""}`.trim();
              return (
                <Popup
                  key={index}
                  content={fullName}
                  trigger={
                    user?.profile_picture ? (
                      <img src={user.profile_picture} className="active-user" />
                    ) : (
                      <div className="active-user" key={index}>
                        {getUserNameInitials(fullName)}
                      </div>
                    )
                  }
                />
              );
            })}

            {/* <Button className="-appointment-status icon" size="small">
            <Icon className="tablet" color="green" />
          </Button>

          <Button className="-appointment-status icon" size="small">
            <Icon className="note sticky sharp" color="green" />
          </Button> */}
          </div>
        </div>
        <div className="CarDetails">
          <div className="car-image-container">
            {data?.car?.profile_picture ? <img src={data?.car?.profile_picture} className="car-image" /> : <Icon className="image i-grey" size="huge" />}
          </div>
          <div className="car-container">
            <div className="car-header">
              <div className="car-items">
                <Icon className="car mirrors" />
                <p>{t("v8_car_details").message || "Car Details"}</p>
              </div>

              <div className="car-items">
                {data.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && (
                  <>
                    {selectedLocation?.dbb_enabled && selectedLocation?.dbb_user && data && (
                      <Can I="dbb" the="appointments">
                        <DBB appointment={data} />
                      </Can>
                    )}
                    <Can I="wo_widget" the="servicebox">
                      <PSA appointment_id={Number(data?.id)} />
                    </Can>
                    {selectedLocation?.mcc_button_visible && data?.car && <MCC carDetails={data?.car} />}
                    <Can I="rdw" the="cars">
                      <RDW regNumber={data?.car?.reg_nr} country={selectedLocation?.country} />
                    </Can>
                    <Can I="get-contract" the="robnet">
                      <ROB car_id={Number(data?.car?.id)} />
                    </Can>
                  </>
                )}
                <Can I="read_log" the="appointments">
                  <Logs appointment_id={Number(data?.id)} />
                </Can>
              </div>
            </div>
            <div className="car-info">
              <div className="info-data">
                <p>{t("v8_registration_number").message || "Registration Number"}</p>
                <p>
                  <CopyContent content={data?.car?.reg_nr ?? ""}>
                    {canBrowseCarsPage ? (
                      <Link to={`/cardetails/${data?.car?.id}`} className="info-link">
                        {data?.car?.reg_nr}{" "}
                      </Link>
                    ) : (
                      <span>{data?.car?.reg_nr} </span>
                    )}
                  </CopyContent>
                </p>
              </div>

              <div className="info-data">
                <p>{t("v8_vin_chassis_number").message || "Vin / Chassis Number"}</p>
                <CopyContent content={data?.car?.vin_nr ?? ""}>
                  <p className="car-info-value">{data?.car?.vin_nr}</p>
                </CopyContent>
              </div>

              <div className="info-data">
                <p>{t("v8_make").message || "Make"}</p>
                <p className="car-info-value">{data?.car?.make}</p>
              </div>

              <div className="info-data">
                <p>{t("v8_model").message || "Model"}</p>
                <p className="car-info-value">{data?.car?.model}</p>
              </div>

              <div className="info-data">
                <p>{t("v8_engine_number").message || "Engine number"}</p>
                <p className="car-info-value">{data?.car?.engine_number}</p>
              </div>

              <div className="info-data">
                <p>{t("v8_current_km").message || "Current Km"}</p>
                <Editable
                  isNumberInput
                  valueClassName="car-info-value"
                  value={String(data?.current_km)}
                  onSubmit={handleCurrentKmUpdate}
                  disabled={!canUpdateAppointments || isCarReadyOrQualityCheckDoneOrCanceled(data)}
                />
              </div>
              {selectedLocation?.apk_visible && (
                <div className="info-data">
                  <p>{t("v8_apk").message || "APK"}</p>
                  {data?.car?.apk_date_dms && <p className="car-info-value">{moment(data.car.apk_date_dms).format("DD-MM-YYYY")}</p>}
                </div>
              )}
            </div>

            <div
              className={`customer-details ${classNames({
                hide: !showCardDetails
              })}`}
            >
              <div className="car-header">
                <div className="car-items">
                  <Icon className="user person" />
                  <p>{t("v8_customer_details").message || "Customer Details"}</p>
                </div>
              </div>
              <div className="customer-details-info">
                {selectedLocation?.dp_driver_visible && data?.customer_driver && (
                  <div className="info-data">
                    <p>
                      {t("v8_driver").message || "Driver"}
                      <span onClick={() => handleCustomerModalOpen(data.customer_driver?.id)}>{getCustomerName("customer", data.customer_driver)}</span>
                    </p>
                  </div>
                )}
                {selectedLocation?.dp_contractor_visible && data?.customer_contract && (
                  <div className="info-data">
                    <p>
                      {t("v8_contractor").message || "Contractor"}
                      <span onClick={() => handleCustomerModalOpen(data.customer_contract?.id)}>{getCustomerName("customer", data.customer_contract)}</span>
                    </p>
                  </div>
                )}
                {selectedLocation?.dp_owner_visible && data?.customer_owner && (
                  <div className="info-data">
                    <p>
                      {t("v8_owner").message || "Owner"}
                      <span onClick={() => handleCustomerModalOpen(data.customer_owner?.id)}>{getCustomerName("customer", data.customer_owner)}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Element>
      <CustomerModal isOpen={showCustomerModal} onClose={handleCustomerModalClose} customerID={customerID} />
    </>
  );
};

import { PublicClientApplication } from "@azure/msal-browser";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Form, Icon, Input } from "semantic-ui-react";

import ENV from "config/Env";
import { useAuth } from "hooks";
import "modules/Auth/components/Login/Login.scss";
import QrLogin from "modules/Auth/components/QrLogin";
// import { LoginProps } from "modules/Auth/interfaces";
import { PATHS } from "router/paths";
import { getBrowserLanguage } from "util/common";
import { ITranslation } from "util/interfaces";

export const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [googleButtonWidth, setGoogleButtonWidth] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  });

  const t = useTranslation().t as ITranslation;
  const clientId = ENV.microsoftClientID;
  const redirectUri = window.location.href;

  const msalInstance = new PublicClientApplication({
    auth: {
      ...(redirectUri && { redirectUri }),
      clientId,
      navigateToLoginRequestUrl: false
    },
    cache: {
      ...{ cacheLocation: "sessionStorage" }
    }
  });

  const { loginWithCredentials, loginWithGoogle, loginWithMicrosoft } = useAuth();
  const { mutate: credentialsLogin, isPending: credentialsLoading, error: credentialsError } = loginWithCredentials;
  const { mutate: googleLogin } = loginWithGoogle;
  const { mutate: microsoftLogin, isPending: microsoftLoading } = loginWithMicrosoft;

  const onSetButtonWidth = () => {
    const loginButton = document.getElementById("google-login-button");
    setGoogleButtonWidth(loginButton ? loginButton.clientWidth : 341);
  };

  useEffect(() => {
    setErrorMsg("");
  }, [credentials]);

  useEffect(() => {
    onSetButtonWidth();
    window.addEventListener("resize", onSetButtonWidth);
    return () => {
      window.removeEventListener("resize", onSetButtonWidth);
    };
  }, []);

  useEffect(() => {
    if (credentialsError) {
      setErrorMsg((credentialsError as AxiosError<any>).response?.data.errors.join(" "));
    }
  }, [credentialsError]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (credentials.password && credentials.username) {
      credentialsLogin(credentials);
    }
  };

  const handleGoogleResponse = (token: CredentialResponse) => {
    if (!token) {
      return;
    }
    if (token.credential) {
      googleLogin({ id_token: token.credential });
    }
  };

  const handleMicrosoftResponse = async () => {
    try {
      const scopes = ["openid", "email", "profile", "user.read"];

      if (!msalInstance) {
        return null;
      }

      await msalInstance.initialize();
      const data = await msalInstance.loginPopup({
        scopes
      });

      if (data?.idToken) {
        microsoftLogin({ id_token: data.idToken });
      }
    } catch (e: Error | unknown) {
      let errMessage = "";
      if (e instanceof Error) {
        errMessage = e.message;
      } else {
        errMessage = t("v8_something_went_wrong").message || "Something went wrong, please contact to administrator.";
      }
      toast.error(errMessage);
    }
  };

  return (
    <div className="LoginContainer">
      <Form onSubmit={onSubmit}>
        <Form.Field error={!!errorMsg}>
          <Input
            name="username"
            id="username"
            value={credentials.username}
            onChange={onInputChange}
            icon={`user ${errorMsg ? "" : "green"}`}
            iconPosition="left"
            type="text"
            placeholder={t("v8_login").message || "Login"}
            required
            error={!!errorMsg}
          />
        </Form.Field>
        <div className="mt-15">
          <Form.Field className="password" error={!!errorMsg}>
            <Input
              name="password"
              id="password"
              value={credentials.password}
              onChange={onInputChange}
              icon={`key ${errorMsg ? "" : "green"}`}
              iconPosition="left"
              placeholder={t("v8_password").message || "Password"}
              type={showPassword ? "text" : "password"}
              required
              error={!!errorMsg}
            />
            <Icon
              className={showPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
            />
          </Form.Field>
        </div>

        <div className="mt-15">
          <Link to={PATHS.RESET_PASSWORD_REQUEST}>{t("v8_forgot_username_password").message || "Forgot username / password?"}</Link>
        </div>
        <div className="mt-25">
          <Button disabled={credentialsLoading} loading={credentialsLoading} type="submit" id="submit-login" color="green" fluid>
            {t("v8_sign_in").message || "Sign in"}
          </Button>
        </div>
      </Form>

      <div className="mt-15">
        <Divider />
      </div>
      <div className="mt-15" id="google-login-button">
        <GoogleLogin width={googleButtonWidth} onSuccess={handleGoogleResponse} locale={getBrowserLanguage()} />
      </div>
      <div className="mt-15" id="microsoft-login-button">
        <Button color="light" loading={microsoftLoading} fluid onClick={handleMicrosoftResponse}>
          {t("v8_sign_with_microsoft").message || "Sign in with Microsoft"}
        </Button>
      </div>
      <div className="mt-15">
        <QrLogin loginViaQR />
      </div>
      {errorMsg && (
        <Form>
          <div className="mt-15">
            <Form.Field error>
              <Input value={errorMsg} readOnly />
            </Form.Field>
          </div>
        </Form>
      )}
    </div>
  );
};

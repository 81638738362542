import { PinItem, SnoozeItem } from "models";
import { Appointment } from "modules/CarDetails/hooks";

export interface PinItemWithHistory extends PinItem {
  history: PinItem[];
  appointment?: Appointment;
}

export interface SnoozeItemWithHistory extends SnoozeItem {
  history: SnoozeItem[];
  appointment?: Appointment;
}

export const getPinWithHistory = (pinItems: PinItem[] | null) => {
  if (!pinItems) return [];

  let grouped: PinItemWithHistory[] = [];

  const interventions: PinItemWithHistory[] = [],
    questions: PinItemWithHistory[] = [],
    interventionsOffsets: number[] = [],
    questionsOffsets: number[] = [];
  let interventionOffset = 0,
    questionOffset = 0;

  pinItems.forEach((i: PinItem) => {
    if (i.intervention_id) {
      if (interventionsOffsets[i.intervention_id] === undefined) {
        interventionsOffsets[i.intervention_id] = interventionOffset++;

        interventions[interventionsOffsets[i.intervention_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > interventions[interventionsOffsets[i.intervention_id]].created_on)
          interventions[interventionsOffsets[i.intervention_id]] = {
            ...i,

            history: interventions[interventionsOffsets[i.intervention_id]].history.concat(i)
          };
        else interventions[interventionsOffsets[i.intervention_id]].history.push(i);
      }
    } else {
      if (questionsOffsets[i.question_result_id] === undefined) {
        questionsOffsets[i.question_result_id] = questionOffset++;

        questions[questionsOffsets[i.question_result_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > questions[questionsOffsets[i.question_result_id]].created_on)
          questions[questionsOffsets[i.question_result_id]] = { ...i, history: questions[questionsOffsets[i.question_result_id]].history.concat(i) };
        else questions[questionsOffsets[i.question_result_id]].history.push(i);
      }
    }
  });

  grouped = [...interventions, ...questions];

  return grouped.sort((a, b) => ((b.appointment?.time_car_app ?? 0) > (a.appointment?.time_car_app ?? 0) ? 1 : -1));
};

export const getSnoozeWithHistory = (pinItems: SnoozeItem[] | null) => {
  if (!pinItems) return [];

  let grouped: SnoozeItemWithHistory[] = [];

  const interventions: SnoozeItemWithHistory[] = [],
    questions: SnoozeItemWithHistory[] = [],
    interventionsOffsets: number[] = [],
    questionsOffsets: number[] = [];
  let interventionOffset = 0,
    questionOffset = 0;

  pinItems.forEach((i: SnoozeItem) => {
    if (i.intervention_id) {
      if (interventionsOffsets[i.intervention_id] === undefined) {
        interventionsOffsets[i.intervention_id] = interventionOffset++;

        interventions[interventionsOffsets[i.intervention_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > interventions[interventionsOffsets[i.intervention_id]].created_on)
          interventions[interventionsOffsets[i.intervention_id]] = {
            ...i,

            history: interventions[interventionsOffsets[i.intervention_id]].history.concat(i)
          };
        else interventions[interventionsOffsets[i.intervention_id]].history.push(i);
      }
    } else {
      if (questionsOffsets[i.question_result_id] === undefined) {
        questionsOffsets[i.question_result_id] = questionOffset++;

        questions[questionsOffsets[i.question_result_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > questions[questionsOffsets[i.question_result_id]].created_on)
          questions[questionsOffsets[i.question_result_id]] = { ...i, history: questions[questionsOffsets[i.question_result_id]].history.concat(i) };
        else questions[questionsOffsets[i.question_result_id]].history.push(i);
      }
    }
  });

  grouped = [...interventions, ...questions];

  return grouped.sort((a, b) => ((b.appointment?.time_car_app ?? 0) > (a.appointment?.time_car_app ?? 0) ? 1 : -1));
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

const useGetRDWData = (regNumber: string | undefined) => {
  const mutation = useMutation({
    mutationFn: () => ApiInstance.post("/cars/rdw", { reg_number: regNumber }),
    onError: e => toast.error(e.message)
  });

  return { data: mutation.data?.data, fetchRDWData: mutation.mutate, loading: mutation.isPending };
};

export default useGetRDWData;

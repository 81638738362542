import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Icon } from "semantic-ui-react";

type Person = {
  time_car_app: string;
  wo: string;
  reg_nr: string;
  model: string;
};

const defaultData: Person[] = [
  {
    time_car_app: new Date("2022-03-25").toLocaleDateString(),
    wo: "89087",
    reg_nr: "00-5TK-11",
    model: "Audi RTX 9878"
  },
  {
    time_car_app: new Date("2022-03-25").toLocaleDateString(),
    wo: "98756",
    reg_nr: "11-7Yt-21",
    model: "Audi RTX 9878"
  },
  {
    time_car_app: new Date("2022-03-25").toLocaleDateString(),
    wo: "90856",
    reg_nr: "12-8RE-21",
    model: "Audi RTX 9878"
  }
];

const columnHelper = createColumnHelper<Person>();

const columns = [
  columnHelper.accessor("time_car_app", {
    id: "time_car_app",
    header: "Appointment Date",
    size: 100,
    cell: function appDate(info) {
      return (
        <span>
          <Icon name="info circle" />
          {info.renderValue()}
        </span>
      );
    }
  }),
  columnHelper.accessor(row => row.wo, {
    id: "wo",
    cell: info => info.getValue(),
    header: () => "WO #",
    size: 100
  }),
  columnHelper.accessor("reg_nr", {
    id: "reg_nr",
    header: () => "Registration Nr.",
    size: 50,
    cell: function regNR(info) {
      return (
        <span>
          <Icon name="info circle" />
          {info.renderValue()}
        </span>
      );
    }
  }),
  columnHelper.accessor("model", {
    id: "model",
    header: () => "Model",
    size: 70,
    cell: info => info.renderValue()
  })
];

export const Table = () => {
  const table = useReactTable({
    data: defaultData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <table className="ReactTable">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th style={{ width: header.column.columnDef.size }} key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

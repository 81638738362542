import moment from "moment";
import { useState } from "react";

import { InterventionSubitemsModal } from "components";
import { INTERVENTION_ELEMENT_TYPE, Intervention } from "models";
import SubitemsTable from "modules/AppointmentDetail/components/Interventions/components/Subitems/components/SubitemsTable";
import "modules/AppointmentDetail/components/Interventions/components/Subitems/Subitems.scss";
import { InterventionData, useIntervention } from "modules/AppointmentDetail/hooks";

type SubitemsProps = {
  data: Intervention;
  showPriceInVat?: boolean;
};

export const Subitems = ({ data, showPriceInVat }: SubitemsProps) => {
  const [interventionSubItemModal, showInterventionSubItemModal] = useState(false);
  const { updateIntervention } = useIntervention(data.appointment_id);

  const toggleInterventionSubitem = () => {
    showInterventionSubItemModal(prev => !prev);
  };

  const handleAddSubitem = async (description: string) => {
    const intervention: InterventionData = {
      ...data,
      elements: [
        ...data.elements,
        {
          intervention_id: data.id,
          description,
          element_type: INTERVENTION_ELEMENT_TYPE.Text,
          dms_nr: `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}`
        }
      ]
    };
    await updateIntervention.mutate(intervention);
    toggleInterventionSubitem();
  };

  return (
    <div className="Subitems">
      {interventionSubItemModal && <InterventionSubitemsModal onSubmit={handleAddSubitem} onClose={toggleInterventionSubitem} />}
      <SubitemsTable elements={data.elements} showActionButton={false} showPriceInVat={showPriceInVat} />
      {/* <Button color="green" type="button" className="-appointment-statu left-icon mt-8" onClick={toggleInterventionSubitem}>
        <Icon className="plus" />
        {t("v8_subitems").message || "Subitems"}
      </Button> */}
    </div>
  );
};

import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { Appointment } from "models";
import "modules/Dayplanner/components/AppointmentCard/components/CarQuickView/CarQuickView.scss";
import { removeGenerationParam } from "util/common";
import { ITranslation } from "util/interfaces";

type CarQuickViewProps = {
  appointment: Appointment;
  popupTrigger: ReactElement;
};

export const CarQuickView = ({ appointment, popupTrigger }: CarQuickViewProps) => {
  const t = useTranslation().t as ITranslation;

  const content = (
    <div className="CarQuickView-container">
      <div className="CarQuickView-container__inner">
        <div className="car-image-container">
          {appointment.car_profile_picture ? (
            <img src={removeGenerationParam(appointment.car_profile_picture)} alt={t("v8_car_profile").message || "Car Profile"} className="car-image" />
          ) : (
            <Icon className="car" />
          )}
        </div>
        <div className="car-details">
          <div className="detail-item">
            <span className="detail-title">{t("v8_brand").message || "Brand"}</span>
            <span className="detail-value">{appointment.car_make}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("v8_driver").message || "Driver"}</span>
            <span className="detail-value">{`${appointment.driver_firstname || ""} ${appointment.driver_surname || ""}`.trim()}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("v8_model").message || "Model"}</span>
            <span className="detail-value">{appointment.car_model}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("v8_contractor").message || "Contractor"}</span>
            <span className="detail-value">{`${appointment.contractor_firstname || ""} ${appointment.contractor_surname || ""}`.trim()}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("v8_dayplanner_car_vin_nr").message || "Vin number"}</span>
            <span className="detail-value">{appointment.vin_nr}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("v8_owner").message || "Owner"}</span>
            <span className="detail-value">{`${appointment.owner_firstname || ""} ${appointment.owner_surname || ""}`.trim()}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return <Popup trigger={popupTrigger} content={content} hoverable style={{ padding: "0 5px" }} />;
};

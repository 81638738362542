import { useQuery } from "@tanstack/react-query";

import { User } from "models";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

export const useUser = () => {
  const query = useQuery({
    queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.UserData)
  });

  return query?.data as User | undefined;
};

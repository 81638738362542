import { CHECKLIST_TYPE, Check, QuestionResult, STATUS_IDENTIFIER } from "models";

export function getHighestId(checklistItems: QuestionResult[] | null) {
  if (!checklistItems) return 0;
  const sortedChecklist = checklistItems.slice().sort((a, b) => a.id - b.id);
  const lastItem = sortedChecklist[sortedChecklist.length - 1];
  return lastItem?.id;
}

export const isTyreChecklist = (questionItems: QuestionResult[]) => {
  return !questionItems.some(obj => obj.tyre);
};

export function filterTyreChecklist(checklist: Check[] = []) {
  const tyreChecklist: Check[] = [];
  checklist.forEach(check => {
    const hasTyre = check.question_items?.some(item => item.tyre);
    if (hasTyre) {
      tyreChecklist.push(check);
    }
  });
  return tyreChecklist.sort((a, b) => a.created_on.localeCompare(b.created_on)) || [];
}

export function sortChecklistByOrder(checklist: Check[] = []) {
  const filteredArray = checklist.filter(
    item => item.checklist.checklist_type !== CHECKLIST_TYPE.ExtraPartsChecklist && isTyreChecklist(item.question_items as QuestionResult[])
  );

  return filteredArray.sort((a: Check, b: Check) => {
    if (a.checklist.display_order === b.checklist.display_order) {
      return a.checklist.id - b.checklist.id;
    }
    return a.checklist.display_order - b.checklist.display_order;
  });
}

export const getPriceForDisplay = (price: number | null) => {
  if (!price) return Number(price).toFixed(2);
  return price.toFixed(2);
};

export const isCarReady = (appointmentStatus: STATUS_IDENTIFIER) => {
  return (
    appointmentStatus === STATUS_IDENTIFIER.CarReadyStatus ||
    appointmentStatus === STATUS_IDENTIFIER.QualityCheckStatus ||
    appointmentStatus === STATUS_IDENTIFIER.CarOkPlusRepairOverview ||
    appointmentStatus === STATUS_IDENTIFIER.QualityCheckPlusRepairOverview
  );
};

import { useState } from "react";

import { ActionModalSelectorData, ExtendedInterventionData, MODAL_TYPE, QuestionResultData } from "components";
import "components/CheckList/CheckList.scss";
import CheckListItem from "components/CheckList/CheckListItem";
import InterventionModal from "components/InterventionModal";
import SnoozeModal from "components/SnoozeModal";
import { Appointment, ChecklistTemplate, QUESTION_STATUSES, QuestionResult } from "models";
import { Tyre } from "modules/AppointmentDetail/components/Tyre";
import { InterventionData } from "modules/AppointmentDetail/hooks/useIntervention";

type CheckListProps = {
  template?: ChecklistTemplate | null;
  data?: QuestionResult[] | null;
  showAll?: boolean;
  showDropdownActions?: boolean;
  hideTitle?: boolean;
  appointment: Appointment;
};

export const CheckList = ({ data, template, showAll, showDropdownActions = true, hideTitle, appointment }: CheckListProps) => {
  if (!data?.length) return null;

  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setShowSnoozeModal(false);
    setModalData(null);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
      setShowInterventionModal(true);
    } else {
      setModalData(data as QuestionResultData);
      setShowSnoozeModal(true);
    }
  };

  const sortedGroup = () => {
    const groups: Record<string, QuestionResult[]> = {};
    data
      .sort((a, b) => {
        if (a.group_order === b.group_order) {
          if (a.question_order < b.question_order) return -1;
          if (a.question_order > b.question_order) return 1;
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        } else {
          return a.group_order < b.group_order ? -1 : 1;
        }
      })
      .forEach(item => {
        groups[item.group_name] = groups[item.group_name] ? [...groups[item.group_name], item] : [item];
      });
    return groups;
  };

  const getDataByGroup = (group: string) => {
    return sortedGroup()[group].filter(item => (showAll ? true : item.status !== QUESTION_STATUSES.OK && item.status !== QUESTION_STATUSES.NVT));
  };

  return (
    <>
      <div className={`CheckList ${hideTitle ? "no-border" : ""}`}>
        {Object.keys(sortedGroup()).map((group: string) => {
          const tyreByGroup = sortedGroup()[group];
          const dataByGroup = getDataByGroup(group).filter(item => !item.tyre);
          return (
            <div key={group} className="CheckList-group">
              {!hideTitle && dataByGroup.length > 0 && (
                <div className="CheckList-group_header">
                  <p>{group}</p>
                </div>
              )}
              {dataByGroup.map(item => (
                <CheckListItem
                  data={item}
                  template={template}
                  key={item.id}
                  onSelect={handleSelect}
                  showDropdownActions={showDropdownActions}
                  appointment={appointment}
                />
              ))}
              {tyreByGroup.some(item => item.tyre) && <Tyre data={tyreByGroup} template={template} appointment={appointment} />}
            </div>
          );
        })}
      </div>
      <InterventionModal size="large" open={showInterventionModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
      <SnoozeModal size="large" open={showSnoozeModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
    </>
  );
};

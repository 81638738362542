import { ReactElement } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { PATHS } from "router/paths";
import apiInstance from "util/Api";

export const PrivateRoute = (props: RouteProps): ReactElement => {
  if (!apiInstance.authToken) {
    return <Redirect to={PATHS.LOGIN} />;
  }

  return <Route {...props} />;
};

import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

type AssignRequestType = {
  appointment_id: number;
  user_id: number;
  order: number;
};

type MutationContext = {
  queryKey: QueryKey;
  previousAppointments: Appointment[] | undefined;
};

export const useAssignMutation = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mutation = useMutation<AssignRequestType, Error, AssignRequestType, MutationContext>({
    mutationFn: async requestData => ApiInstance.post("/appointments/assign", { ...requestData }, ENV.dayplannerBaseUrl),
    onMutate: async requestData => {
      const queryKey = ["realtime", { ...queryKeys.dayplanner.appointments, params: { dealer_location_id: selectedLocation?.id } }];

      const previousAppointments = queryClient.getQueryData<Appointment[] | undefined>(queryKey);

      queryClient.setQueryData(queryKey, (appointments: Appointment[] | []) => {
        const updatedAppointments = appointments.map(app => {
          if (app.id === requestData.appointment_id) return { ...app, assigned_mechanic: requestData.user_id, assigned_mechanic_order: requestData.order };

          if (app.assigned_mechanic_order && app.assigned_mechanic === requestData.user_id && app.assigned_mechanic_order > requestData.order) {
            return {
              ...app,
              assigned_mechanic_order: app.assigned_mechanic_order - 1
            };
          }
          return app;
        });

        return updatedAppointments;
      });

      return { queryKey, previousAppointments };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousAppointments) {
        queryClient.setQueryData(context.queryKey, context.previousAppointments);
      }
    }
  });

  return mutation.mutate;
};

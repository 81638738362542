import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import ApiInstance from "util/Api";

const useAttachments = (appointment_id: number) => {
  const [attachments, setAttachments] = useState(null);

  const fetchAttachments = async () => {
    const res = await ApiInstance.post("/files/list_appointment_attachments", { appointment_id });
    return res.data.data;
  };

  const getAttachments = useMutation({
    mutationFn: fetchAttachments,
    onSuccess: data => {
      setAttachments(data);
    }
  });

  return { attachments, getAttachments };
};

export default useAttachments;

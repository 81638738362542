import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ActionModalSelectorData,
  CUSTOM_CONFIRM_TYPES,
  CustomConfirm,
  DetailsCards,
  ExtendedInterventionData,
  MODAL_TYPE,
  QuestionResultData,
  TabData
} from "components";
import InterventionModal from "components/InterventionModal";
import { useDealersLocations } from "hooks";
import { Appointment, DMS, KeylockerCommunication, KeylockerRemark } from "models";
import { CCRemarks, CheckInOptionalService } from "modules/AppointmentDetail/components/CustomerActions/components";
import "modules/AppointmentDetail/components/CustomerActions/CustomerActions.scss";
import { InterventionData } from "modules/AppointmentDetail/hooks/useIntervention";
import { ITranslation } from "util/interfaces";

type CustomerActionsProps = {
  isExpanded: boolean;
  appointment: Appointment;
  tabData: TabData;
};

export const CustomerActions = ({ isExpanded, appointment, tabData }: CustomerActionsProps) => {
  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();
  const canCreateIntervention = selectedLocation ? selectedLocation.dms_id === DMS.KeyLoopJobs : false;

  const keylockerRemarks =
    appointment?.keylocker_communications
      ?.filter((comm: KeylockerCommunication) => comm.remark)
      ?.map((communication: KeylockerCommunication) => {
        return communication.remark as KeylockerRemark;
      }) || [];

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setShowConfirmModal(false);
    setModalData(null);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
      setShowConfirmModal(true);
    }
  };

  const handleConfirm = () => {
    setShowInterventionModal(true);
  };

  return (
    <>
      <DetailsCards
        id={tabData.id}
        title={`${t("customer_actions").message || "Customer Actions"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
        icon="calendar check regular"
        isExpanded={isExpanded}
      >
        <div className="CustomerActions">
          {appointment.customer_communication?.check_in_results && (
            <CheckInOptionalService
              title={t("v8_check_in_results").message || "Check-in results"}
              data={appointment.customer_communication.check_in_results}
              onSelect={handleSelect}
              canCreateIntervention={canCreateIntervention}
            />
          )}
          {appointment.customer_communication?.check_in_remarks && (
            <CCRemarks
              title={t("v8_check_in_remarks").message || "Check-in remarks"}
              data={appointment.customer_communication.check_in_remarks}
              onSelect={handleSelect}
              canCreateIntervention={canCreateIntervention}
            />
          )}
          {appointment.customer_communication?.customcom_remarks && (
            <CCRemarks
              title={t("v8_diagnose_overview_remarks").message || "Diagnose overview remarks"}
              data={appointment.customer_communication.customcom_remarks}
              onSelect={handleSelect}
              canCreateIntervention={false}
            />
          )}
          {keylockerRemarks?.length > 0 && (
            <CCRemarks
              title={t("v8_keylocker_remarks").message || "Keylocker remarks"}
              data={keylockerRemarks}
              onSelect={handleSelect}
              canCreateIntervention={false}
              isKeyLocker
            />
          )}
        </div>
      </DetailsCards>
      <CustomConfirm
        isLoading={false}
        isOpen={showConfirmModal}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMsg={`${t("v8_create_intervention_from_this_service").message || "Do you want to create intervention from this service"}?`}
      />
      <InterventionModal size="large" open={showInterventionModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
    </>
  );
};

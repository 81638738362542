import { ReactElement } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { PATHS } from "router/paths";
import apiInstance from "util/Api";

export const PublicRoute = (props: RouteProps): ReactElement => {
  if (apiInstance.authToken && (props.path === PATHS.LOGIN || props.path === PATHS.HOME || props.path === PATHS.RESET_PASSWORD)) {
    return <Redirect to={PATHS.APPOINTMENTS} />;
  }
  return <Route {...props} />;
};

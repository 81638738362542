import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { DeskCommunication, DeskCommunicationEvent } from "models";
import { DESK_COMMUNICATION_EVENTS } from "modules/AppointmentDetail/components/DeskCommunicationLog";
import { EventInfo } from "modules/AppointmentDetail/components/DeskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

const getEventName = (event: DeskCommunicationEvent, t: ITranslation) => {
  switch (event.type as unknown as DESK_COMMUNICATION_EVENTS) {
    case DESK_COMMUNICATION_EVENTS.CheckInSent:
      return t("v8_sent").message || "Sent";

    case DESK_COMMUNICATION_EVENTS.CheckInReceived:
      return t("v8_received").message || "received";

    case DESK_COMMUNICATION_EVENTS.CheckInFailedToOpen:
      return t("v8_failed_to_open").message || "Failed to open";

    case DESK_COMMUNICATION_EVENTS.CheckInOpened:
      return t("v8_opened").message || "Opened";

    case DESK_COMMUNICATION_EVENTS.CheckInCanceled:
      return t("v8_canceled").message || "Canceled";

    case DESK_COMMUNICATION_EVENTS.CheckInAnswered:
      return t("v8_answered").message || "Answered";

    default:
      return "";
  }
};

type CommunicationEventsProps = {
  events: DeskCommunicationEvent[];
  comm: DeskCommunication;
};

export const CommunicationEvents = ({ events, comm }: CommunicationEventsProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="desk-comm-section-container">
      <div className="desk-comm-section-header">{t("v8_desk_communication_events").message || "Desk Communication Events"}</div>

      <Grid>
        {events.map((event, key) => {
          return (
            <Grid.Row key={key} className="section-rows">
              <Grid.Column width={12}>{getEventName(event, t)}</Grid.Column>

              <Grid.Column width={4} className="event-timestamp">
                {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
              </Grid.Column>

              <EventInfo comm={comm} event={event} />
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
};

import React from "react";
import { Icon } from "semantic-ui-react";

import FaceIcon from "assets/svg/face.svg";
import "components/StatusCell/StatusCell.scss";
import { useDealersLocations } from "hooks";
import { AppointmentStatus, COMMUNICATION_STATUS, COMMUNICATION_STATUSES, STATUS_IDENTIFIER } from "models";

type AppointmentStatusValue = {
  id: STATUS_IDENTIFIER;
  customcom_status?: COMMUNICATION_STATUS | COMMUNICATION_STATUSES | null;
  check_paused_at?: string | null;
  car_out_of_shop?: string | null;
  car_in_shop?: string | null;
};

interface StatusCellProps {
  value: AppointmentStatusValue;
  shadow?: boolean;
}

type StatusIconProps = {
  value: AppointmentStatusValue;
  status: AppointmentStatus;
};

const carInShopStatus = (appointment: AppointmentStatusValue) => {
  if (appointment.car_in_shop === undefined && appointment.car_out_of_shop === undefined) return false;
  return appointment?.car_in_shop !== null && appointment?.car_out_of_shop === null;
};

const StatusIcon = ({ status, value }: StatusIconProps) => {
  switch (status.identifier) {
    case STATUS_IDENTIFIER.CanceledStatus:
      return <Icon className="circle xmark" />;

    case STATUS_IDENTIFIER.NewCarStatus:
      return <Icon className={carInShopStatus(value) ? "car garage" : "car mirrors"} />;

    case STATUS_IDENTIFIER.CarCheckStatus:
      return <Icon className="wrench" />;

    case STATUS_IDENTIFIER.PricingOKStatus:
      return <Icon className="tag" />;

    case STATUS_IDENTIFIER.CustomerOKStatus:
      return (
        <div className="svg-face-icon">
          <img src={FaceIcon} />
        </div>
      );

    case STATUS_IDENTIFIER.CarReadyStatus:
      return <Icon className="check double" />;

    case STATUS_IDENTIFIER.DiagnoseStatus:
      return <Icon className="triangle exclamation" />;

    case STATUS_IDENTIFIER.AannameOKStatus:
      return <Icon className="square a" />;

    case STATUS_IDENTIFIER.CalculatingStatus:
      return <Icon className="dollar sign" />;

    case STATUS_IDENTIFIER.ContactStatus:
      if (value.customcom_status && value.customcom_status >= COMMUNICATION_STATUSES.DiagnoseOverviewSent) {
        return <Icon className="mail" />;
      }
      return <Icon className="phone volume" />;

    case STATUS_IDENTIFIER.CarCheckStartedStatus:
      if (value.check_paused_at) {
        return <Icon className="circle pause" />;
      }

      return <Icon className="circle quarter stroke" />;

    case STATUS_IDENTIFIER.QualityCheckStatus:
      return <Icon className="shield check" />;

    case STATUS_IDENTIFIER.CustomerAnsweredStatus:
      return <Icon className="user check" />;

    case STATUS_IDENTIFIER.BackOrderStatus:
      return <div className="bo-icon">BO</div>;

    case STATUS_IDENTIFIER.CheckInCar:
      return <Icon className="calendar check" />;

    case STATUS_IDENTIFIER.CheckInDone:
      return (
        <span className="fa-layers fa-fw">
          <Icon className="car mirrors" />
          <Icon className="calendar check top-right" />
        </span>
      );

    case STATUS_IDENTIFIER.CarInShop:
      return <Icon className="car garage" />;

    case STATUS_IDENTIFIER.CarOkPlusRepairOverview:
      return (
        <span className="fa-layers fa-fw">
          <Icon className="check double" />
          <Icon className="envelope top-right" />
        </span>
      );

    case STATUS_IDENTIFIER.QualityCheckPlusRepairOverview:
      return (
        <span className="fa-layers fa-fw">
          <Icon className="shield check" />
          <Icon className="envelope top-right" />
        </span>
      );

    default:
      return null;
  }
};

export const StatusCell = ({ value, shadow = true }: StatusCellProps) => {
  const { selectedLocation } = useDealersLocations();

  const statuses = selectedLocation?.statuses || [];

  if (!statuses) return null;

  const findStatus = (): AppointmentStatus => {
    const defaultStatus: AppointmentStatus = {
      id: 0,
      identifier: 1,
      order: 0,
      dealer_location_id: 0,
      created_on: "",
      updated_on: "",
      icon: "settings",
      color: "",
      name: ""
    };
    const status = statuses.find(s => s.identifier === value.id) || defaultStatus;

    if (value.id === STATUS_IDENTIFIER.ContactStatus) {
      status.icon = value.customcom_status && value.customcom_status >= COMMUNICATION_STATUSES.DiagnoseOverviewSent ? "mail" : "phone";
    }

    return status;
  };

  const status = findStatus();

  const commonStyle: React.CSSProperties = {
    position: "relative",
    backgroundColor: status.color,
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0em",
    boxShadow: shadow ? "-1px 1px 3px #00000029" : "none"
  };

  const carOutOfShopIcon = <Icon className="right from bracket small-icon" />;

  return (
    <div className="StatusCellIcon" style={commonStyle} title={status.name}>
      {value.car_out_of_shop && carOutOfShopIcon}
      <StatusIcon status={status} value={value} />
    </div>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Appointment, COMMUNICATION_EVENT_TYPE, CommunicationAgreement, CommunicationEvent } from "models";
import { COMMUNICATION_LOG_TAB } from "modules/AppointmentDetail/components/CommunicationLog";
import { Agreements, CheckinResults, CommunicationEvents, Services } from "modules/AppointmentDetail/components/CommunicationLog/components";
import { ITranslation } from "util/interfaces";

type TabContentType = {
  id: number;
  tab: COMMUNICATION_LOG_TAB;
  appointment: Appointment;
  onSelectAgreement?: (agreement: CommunicationAgreement) => void;
};

const onlineCheckinEventTypes = [
  COMMUNICATION_EVENT_TYPE.OnlineCheckInSent,
  COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened,
  COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered
];

const repairOverviewEventTypes = [
  COMMUNICATION_EVENT_TYPE.RepairOverviewSent,
  COMMUNICATION_EVENT_TYPE.RepairOverviewResent,
  COMMUNICATION_EVENT_TYPE.RepairOverviewOpened
];

const diagnoseOverviewEventTypes = [
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered
];

export const TabContent = ({ tab, id, appointment, onSelectAgreement }: TabContentType) => {
  const [events, setEvents] = useState<CommunicationEvent[]>([]);
  const [agreements, setAgreements] = useState<CommunicationAgreement[]>([]);
  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    let tempEvents: CommunicationEvent[] = [];
    let tempAgreements: CommunicationAgreement[] = [];

    if (tab === COMMUNICATION_LOG_TAB.OnlineCheckin) {
      tempEvents = appointment?.customer_communication?.events.filter(e => onlineCheckinEventTypes.includes(e.type)) || [];
      tempAgreements = appointment.online_checkin_agreements;
    } else if (tab === COMMUNICATION_LOG_TAB.RepairOverview) {
      tempEvents = appointment?.customer_communication?.events.filter(e => repairOverviewEventTypes.includes(e.type)) || [];
    } else {
      const diagnoseEvents = appointment?.customer_communication?.events.filter(e => diagnoseOverviewEventTypes.includes(e.type)) || [];

      let eventIndex = diagnoseEvents.findIndex(e => e.id === id);

      if (eventIndex > -1) {
        const pushAgreements = (agreement: CommunicationAgreement) => {
          if (agreement.communication_event_id === diagnoseEvents[eventIndex].id) tempAgreements.push(agreement);
        };

        do {
          tempEvents.push(diagnoseEvents[eventIndex]);
          if (appointment.diagnose_overview_agreements) appointment.diagnose_overview_agreements.forEach(pushAgreements);
          eventIndex += 1;
        } while (eventIndex < diagnoseEvents.length && diagnoseEvents[eventIndex].type !== COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent);
      }
    }

    setEvents(tempEvents);
    setAgreements(tempAgreements);
  }, [tab]);

  const agreedInterventions = events.find(e => [COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered, COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered].includes(e.type));
  const declinedInterventions = events.find(e => COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered === e.type)?.check_in_declined_interventions || null;
  const acceptedExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_agreed_results || null;
  const declinedExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_declined_results || null;
  const contactExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_contact_results || null;

  return (
    <>
      <CommunicationEvents events={events} />
      <Services
        results={
          agreedInterventions?.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered
            ? agreedInterventions?.diagnose_overview_agreed_interventions
            : agreedInterventions?.check_in_agreed_interventions
        }
        title={t("v8_agreed_interventions").message || "Agreed interventions"}
      />
      <Services results={declinedInterventions} title={t("v8_declined_interventions").message || "Declined interventions"} />
      <Services results={acceptedExtraServices} title={t("v8_accepted_extra_services").message || "Accepted extra services"} />
      <Services results={declinedExtraServices} title={t("v8_declined_extra_services").message || "Declined extra services"} />
      <Services results={contactExtraServices} title={t("v8_contact_extra_services").message || "Contact extra services"} />
      {appointment.customer_communication?.check_in_results && <CheckinResults results={appointment.customer_communication.check_in_results} />}
      <Agreements agreements={agreements} onSelectAgreement={onSelectAgreement} />
    </>
  );
};

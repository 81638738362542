import { Icon } from "semantic-ui-react";

import { DAYPLANNER_STATUSES } from "modules/Dayplanner/components";

type StatusFilterProps = {
  color: string;
  count: number;
  filter: DAYPLANNER_STATUSES;
  onClick: (filter: DAYPLANNER_STATUSES | null) => void;
  disabled: boolean;
};

type StatusIconProps = {
  filter: DAYPLANNER_STATUSES;
};

const StatusIcon = ({ filter }: StatusIconProps) => {
  switch (filter) {
    case DAYPLANNER_STATUSES.NewCar:
      return <Icon className="car" />;
    case DAYPLANNER_STATUSES.CarInShop:
      return <Icon className="car garage" />;
    case DAYPLANNER_STATUSES.Panic:
      return <Icon className="circle exclamation" />;
    case DAYPLANNER_STATUSES.InitialCheckDone:
      return <Icon className="square a" />;
    case DAYPLANNER_STATUSES.HandleCheckin:
      return <Icon className="calendar check" />;
    case DAYPLANNER_STATUSES.CarCheck:
      return <Icon className="wrench" />;
    case DAYPLANNER_STATUSES.Diagnose:
      return <Icon className="triangle exclamation" />;
    case DAYPLANNER_STATUSES.CustomerAnswered:
      return <Icon className="user check" />;
    case DAYPLANNER_STATUSES.CustomerOK:
      return <Icon className="user hair" />;
    case DAYPLANNER_STATUSES.BackOrder:
      return (
        <div className="backorder-icon">
          <span>BO</span>
        </div>
      );
    default:
      return null;
  }
};

export const StatusButton = ({ color, count, filter, onClick, disabled }: StatusFilterProps) => {
  return (
    <div className={`DayplannerStatusFilter ${disabled ? "disabled" : "clickable"}`} style={{ borderColor: color }} onClick={() => onClick(filter)}>
      <div style={{ backgroundColor: color }} className="DayplannerStatusFilter-icon">
        <StatusIcon filter={filter} />
      </div>
      <div className="DayplannerStatusFilter-count">{count}</div>
    </div>
  );
};

import { Dealer, DealerLocation } from "models";
import { ITranslation } from "util/interfaces";

type DealerType = DealerLocation | Dealer;
type NamesByIdProps = {
  items: DealerType[];
  itemIds: number[];
  translation: string;
  defaultMessage: string;
  t: ITranslation;
};

type TOption = {
  key: number;
  value: number;
  text: string;
};

export const addSelectAllOption = (options: TOption[], t: ITranslation) => {
  const selectAllOption = {
    key: -1,
    value: -1,
    text: t("v8_all").message || "All",
    icon: "house chimney"
  };
  return [selectAllOption, ...options];
};

export const getNamesById = ({ items, defaultMessage, itemIds, translation, t }: NamesByIdProps) => {
  const existingItems = items.filter(item => itemIds.includes(item.id)).map(item => item.name);
  return existingItems.length === 0 ? [t(translation).message || defaultMessage] : existingItems;
};

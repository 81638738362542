import { useEffect, useState } from "react";

import { useDealersLocations } from "hooks";

export const useDealers = () => {
  const [dealerIds, setDealerIds] = useState<number[]>([]);
  const { dealers, selectedDealer } = useDealersLocations();

  const getDealerOptionsFormatted = () => {
    const activeDealers = dealers.filter(dealer => dealer.active);
    const formattedDealers = activeDealers.map(dealer => ({
      key: dealer.id,
      value: dealer.id,
      text: dealer.name,
      icon: "location dot"
    }));
    const sortedDealers = formattedDealers.sort((a, b) => a.text.localeCompare(b.text));
    return sortedDealers;
  };

  useEffect(() => {
    if (selectedDealer) setDealerIds([selectedDealer.id]);
  }, [selectedDealer]);

  return { dealersDropdownOptions: getDealerOptionsFormatted(), setDealerIds, dealerIds, dealers };
};

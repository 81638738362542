import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";

import { CircleIcon, ReactTable } from "components";
import { defaultData } from "modules/Styleguide/mockData";

export type Appointment = {
  wo: string;
  date: string;
  reg_nr: string;
  apk: string;
  driver_name: string;
  make: string;
  model: string;
  note: string;
  accessibility: { icon: string; color: string; bg: string }[];
  updated: string;
  mechanic: string;
  filter: string;
  check_list: string[];
  staus: { icon: string; className: string };
};

const FilterList = () => {
  const filters = [
    { name: "Last Changed By", value: "lu" },
    { name: "Receptionist", value: "r" },
    { name: "Customer Owner", value: "co" }
  ];

  const [selected, setSelected] = useState(filters[0]);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="tbl-flex divider" onClick={() => setOpen(p => !p)}>
      <div className={`tbl ${selected.value}`}>
        <p className="text">{selected.value.toUpperCase()}</p>
      </div>
      <Dropdown open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} icon="caret down" className="tbl-dd-margin">
        <Dropdown.Menu>
          {filters.map((item: any, index: number) => {
            return (
              <Dropdown.Item
                key={`filter-tbl-${index}`}
                onClick={() => {
                  setSelected(item);
                  setOpen(false);
                }}
              >
                <div className="tbl-flex">
                  <div className={`tbl ${item.value}`}>
                    <p className="text">{item.value.toUpperCase()}</p>
                  </div>
                  <p className="text ml-12">{item.name}</p>
                </div>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const columnHelper = createColumnHelper<Appointment>();

const columns = [
  columnHelper.accessor(row => row.wo, {
    id: "wo",
    header: "WO #14",
    size: 120,
    cell: function appDate(info) {
      return (
        <p>
          <span>
            <Icon name="pin" className="pin-grey" />
          </span>
          {info.renderValue()}
        </p>
      );
    }
  }),
  columnHelper.accessor(row => row.date, {
    id: "date",
    cell: info => {
      const initial = info.renderValue();
      return <p>{initial}</p>;
    },
    header: () => {
      return (
        <div className="tbl-wrapper pointer">
          <p>Appointment Date</p>
          <Icon name="caret down" className="margin" />
        </div>
      );
    },
    size: 180
  }),
  columnHelper.accessor(row => row.reg_nr, {
    id: "reg_nr",
    header: () => "Reg #",
    size: 160,
    cell: info => {
      const initial = info.renderValue();
      return <p>{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.apk, {
    id: "apk",
    header: () => "APK",
    size: 160,
    cell: info => {
      const initial = info.renderValue();
      return <p>{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.driver_name, {
    id: "driver_name",
    header: () => {
      return (
        <div className="tbl-wrapper pointer">
          <p>Driver Name</p>
          <Icon name="caret down" className="margin" />
        </div>
      );
    },
    size: 150,
    cell: info => {
      const initial = info.renderValue();
      return <p className="ellipsis">{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.make, {
    id: "make",
    header: () => {
      return (
        <div className="tbl-wrapper pointer">
          <p>Make</p>
          <Icon name="caret down" className="margin" />
        </div>
      );
    },
    size: 120,
    cell: info => {
      const initial = info.renderValue();
      return <p className="ellipsis">{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.model, {
    id: "model",
    header: () => "Model",
    size: 100,
    cell: info => {
      const initial = info.renderValue();
      return <p className="ellipsis">{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.note, {
    id: "note",
    header: () => "Note",
    // size: 100,
    cell: info => {
      const initial = info.renderValue();
      return <p className="ellipsis">{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.accessibility, {
    id: "accessibility",
    header: () => {
      return (
        <div className="tbl-wrapper">
          <CircleIcon icon="is_star" />
          <CircleIcon icon="is_money" />
          <CircleIcon icon="shopping cart" />
          <CircleIcon icon="thumb tack" />
          <CircleIcon icon="triangle exclamation" />
        </div>
      );
    },
    // size: 70,
    cell: info => {
      const items = info.getValue();
      return (
        <div className="tbl-wrapper end">
          {items.map((item: any, index: number) => {
            return <CircleIcon key={`circle-${index}`} icon={item.icon} />;
          })}
        </div>
      );
    }
  }),
  columnHelper.accessor(row => row.updated, {
    id: "updated",
    header: () => "Updated",
    size: 60,
    cell: info => {
      const initial = info.renderValue();
      return <p>{initial}</p>;
    }
  }),
  columnHelper.accessor(row => row.mechanic, {
    id: "mechanic",
    header: () => {
      return (
        <div className="tbl m">
          <p className="text">M</p>
        </div>
      );
    },
    size: 30,
    cell: info => {
      const initial = info.getValue();
      return (
        <div className="tbl m">
          <p className="text">{initial.charAt(0).toUpperCase()}</p>
        </div>
      );
    }
  }),
  columnHelper.accessor(row => row.filter, {
    id: "filter",
    header: () => <FilterList />,
    size: 40,
    cell: info => {
      const initial = info.getValue();
      return (
        <div className="tbl r">
          <p className="text">{initial.charAt(0).toUpperCase()}</p>
        </div>
      );
    }
  }),
  columnHelper.accessor(row => row.check_list, {
    id: "check_list",
    header: () => "Checklist",
    // size: 70,
    cell: info => {
      const checks = info.getValue();
      return (
        <div className="tbl-wrapper">
          {checks.map((item: string, index: number) => {
            return (
              <div key={`checklist-${index}`} className="tbl-checklist">
                {item}
              </div>
            );
          })}
        </div>
      );
    }
  }),
  columnHelper.accessor(row => row.staus, {
    id: "staus",
    header: () => "Staus",
    // size: 70,
    cell: info => {
      const item = info.getValue();
      return (
        <div className="small-status">
          {/* <Button color={item.color as SemanticCOLORS}>
            <FontAwesomeIcon icon={iconMap[item.icon] || faCalendarCheck} />
          </Button> */}
          <Button color="orange" className={`${item.className} af`}>
            <Icon className={item.icon} />
          </Button>
        </div>
      );
    }
  })
];

export const AppointmentTable = () => <ReactTable data={defaultData} columns={columns} />;

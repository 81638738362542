import { withTranslation } from "react-i18next";

import "components/Can/components/NoAccess/NoAccess.scss";
import { ITranslation } from "util/interfaces";

const NoAccess = ({ t }: { t: ITranslation }) => {
  return (
    <div className="NoAccess">
      <h1>{t("v8_no_access").message || "You don't have access to see this page"}</h1>
    </div>
  );
};

export default withTranslation()(NoAccess);

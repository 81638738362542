import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";

import { Can, DatePicker, NavBarSearch, NavPortal, SearchList } from "components";
import { useDealersLocations } from "hooks";
import { APPOINTMENT_STATUSES } from "models";
import "modules/Appointments/Appointments.scss";
import { AppointmentTable, NewAppointment, StatusFilters, Timer } from "modules/Appointments/components";
import { useAppointmentsQuery, useDateAppointmentsMutation, useSearchAppointmentsMutation } from "modules/Appointments/hooks";
import { filterAppointmentsBySearch, filterSpecialIndicators, sortAppointments } from "modules/Appointments/util";
import { getPreference, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

type AppointmentsProps = {
  t: ITranslation;
};

export enum WO_FILTERS {
  ALL = "All",
  WO = "WO",
  INTERNAL = "Internal"
}

export const WO_COLUMN_ACCESSOR_KEY = "wo-column-accessor-value";

const Appointments = ({ t }: AppointmentsProps) => {
  const [selectedIdentifiers, setSelectedIdentifiers] = useState<APPOINTMENT_STATUSES[]>([]);
  const [woColumnAccessor, setWoColumnAccessor] = useState<WO_FILTERS>(getPreference(WO_COLUMN_ACCESSOR_KEY, WO_FILTERS.ALL));

  const { data: appointments = [], isLoading } = useAppointmentsQuery();
  const searchAppointments = useSearchAppointmentsMutation();

  const updateDate = useDateAppointmentsMutation();
  const { selectedLocation, isFeatureEnabled } = useDealersLocations();

  const queryClient = useQueryClient();
  const { data: searchTerm } = useQuery({ queryKey: [queryKeys.appointments.searchTerm] });
  const { data: specialIndicators = {} } = useQuery({ queryKey: [queryKeys.appointments.specialIndicators] });
  const { data: carMakeSearchValue = [] } = useQuery<string[]>({ queryKey: [queryKeys.appointments.carMakeSearch] });

  const date: Date = queryClient.getQueryData([{ origin: "local", component: "Appointments", data: "date" }]) || new Date();
  const isTodaysList = moment().isSame(date, "day");

  useEffect(() => {
    const carMakeValues = appointments?.map(({ car_make }) => car_make) || [];
    queryClient.setQueryData([queryKeys.appointments.carMakes], [...new Set(carMakeValues)]);
  }, [appointments]);

  const handleDateChange = (date: Date) => {
    updateDate(date);
  };

  const handleStatusFilterChange = (newIdentifiers: APPOINTMENT_STATUSES[]) => {
    setSelectedIdentifiers(newIdentifiers);
  };

  const filteredAppointments = useMemo(() => {
    if (appointments) {
      let filteredList = appointments?.filter(app => !selectedIdentifiers.includes(app.appointment_status_identifier as unknown as APPOINTMENT_STATUSES)) || [];
      if (specialIndicators) {
        filteredList = filterSpecialIndicators(filteredList, specialIndicators);
      }
      if (selectedIdentifiers.includes(APPOINTMENT_STATUSES.CarOutOfShop)) {
        filteredList = filteredList?.filter(app => !app.car_out_of_shop) || [];
      }
      if (searchTerm) {
        filteredList = filterAppointmentsBySearch(filteredList, searchTerm as string);
      }
      if (carMakeSearchValue.length > 0) {
        filteredList = filteredList.filter(({ car_make }) => carMakeSearchValue.includes(car_make));
      }
      if (woColumnAccessor !== WO_FILTERS.ALL) {
        const showInternal = woColumnAccessor === WO_FILTERS.INTERNAL;
        filteredList = filteredList.filter(app => showInternal === app.internal);
      }

      return sortAppointments(filteredList, selectedLocation?.statuses);
    }

    return [];
  }, [appointments, selectedIdentifiers, searchTerm, specialIndicators, carMakeSearchValue, selectedLocation?.statuses, woColumnAccessor]);

  const handleChangeWoColumnAccessor = (value: string) => {
    if (Object.values(WO_FILTERS).includes(value as WO_FILTERS)) {
      setWoColumnAccessor(value as WO_FILTERS);
      setPreference(WO_COLUMN_ACCESSOR_KEY, value as WO_FILTERS);
    }
  };

  return (
    <div>
      <NavPortal>
        <NavBarSearch searchQueryKey={[queryKeys.appointments.searchTerm]}>
          <SearchList searchQueryKey={[queryKeys.appointments.searchTerm]} searchDataQueryKey={[queryKeys.appointments.searchData]} onSearch={searchAppointments} />
        </NavBarSearch>
      </NavPortal>
      <div className="AppointmentActions">
        <div className="AppointmentActions-wrapper">
          {isFeatureEnabled("_LoadTijden") && <Timer />}
          {selectedLocation?.id && (
            <Can I="import" the="appointments">
              <NewAppointment />
            </Can>
          )}
        </div>

        <div className="AppointmentActions-wrapper">
          <DatePicker onDateChange={handleDateChange} date={date} showLabel />
          <StatusFilters onChange={handleStatusFilterChange} />
        </div>
      </div>
      <div className="AppointmentsTableContainer">
        <>
          {isLoading && <span>{t("v8_loading").message || "Loading"}...</span>}
          <AppointmentTable
            appointments={filteredAppointments}
            isTodaysList={isTodaysList}
            woColumnAccessor={woColumnAccessor}
            handleChangeWoColumnAccessor={handleChangeWoColumnAccessor}
          />
        </>
      </div>
    </div>
  );
};

export default withTranslation()(Appointments);

import ApiLoadable from "models/ApiLoadable";

export enum INTERVENTION_ELEMENT_TYPE {
  Task = 1,
  Labor,
  Part,
  Text,
  Discount,
  SmallPart,
  Fee,
  Rent,
  Menu
}
export class InterventionElement extends ApiLoadable {
  created_on?: string = "";
  updated_on?: string = "";
  id?: number = 0;
  dms_nr: string = "";
  description: string = "";
  price?: number | null = null;
  quantity?: number | null = null;
  private_note?: string = "";
  is_local?: boolean = true;
  element_type: INTERVENTION_ELEMENT_TYPE = INTERVENTION_ELEMENT_TYPE.Text;
  status?: number = 0;
  parent_dms_nr?: string = "";
  intervention_id?: number = 0;
  unit?: number | null = null;
  intervention_dms_nr?: string = "";
}

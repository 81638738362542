import { TYRE_POSITIONS } from "models";

export function isOnCar(position: TYRE_POSITIONS): boolean {
  return position > TYRE_POSITIONS.NotTyre && position < TYRE_POSITIONS.StorageFrontLeft;
}
export function isInStorage(position: TYRE_POSITIONS): boolean {
  return position > TYRE_POSITIONS.CarRearRight && position < TYRE_POSITIONS.TyrePositionLast;
}
export function isOnTruck(position: TYRE_POSITIONS): boolean {
  return position > TYRE_POSITIONS.TyrePositionLast;
}

import { useState } from "react";
import { Icon } from "semantic-ui-react";

import "components/AttachmentPreview/AttachmentPreview.scss";
import { FilePreview } from "components/FilePreview";

type GenericData = {
  id: number;
  url: string;
  name: string;
};

type GenericListProps = {
  attachments: GenericData[] | undefined;
};

export const AttachmentPreview = ({ attachments }: GenericListProps) => {
  if (!attachments?.length) return null;
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);

  const togglePreview = () => {
    setShowPreview((prev: boolean) => !prev);
  };

  const handlePreview = (id: number) => {
    setPreviewIndex(attachments.findIndex(attachment => attachment.id === id));
    togglePreview();
  };

  const images = attachments.filter(attachment => attachment.url.match(/\.(jpeg|jpg|gif|png)/));
  const videos = attachments.filter(attachment => attachment.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));
  const files = attachments.filter(attachment => !attachment.url.match(/\.(jpeg|jpg|gif|png)/) && !attachment.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));

  return (
    <div className="AttachmentPreview-Container">
      {images.map(item => {
        return (
          <div key={item.id} className="media-view" onClick={() => handlePreview(item.id)}>
            <img src={item.url} />
            <div className="media-download">
              <a href={item.url} target="_blank" rel="noreferrer">
                <Icon className="download blue" />
              </a>
            </div>
          </div>
        );
      })}
      {videos.map(item => {
        return (
          <div key={item.id} className="media-view" onClick={() => handlePreview(item.id)}>
            <video controls key={item.url}>
              <source src={item.url} type="video/mp4" />
              <source src={item.url} type="video/quicktime" />
            </video>
            <div className="media-download">
              <a href={item.url} target="_blank" rel="noreferrer">
                <Icon className="download blue" />
              </a>
            </div>
          </div>
        );
      })}
      {files.map(item => {
        return (
          <div key={item.id} className="media-view file-view" onClick={() => handlePreview(item.id)}>
            <div className="media-other">
              <Icon size="huge" className="file" />
              <p>{item.name.length > 14 ? `...${item.name.slice(-14)}` : item.name}</p>
            </div>
            <div className="media-download">
              <a href={item.url} target="_blank" rel="noreferrer">
                <Icon className="download blue" />
              </a>
            </div>
          </div>
        );
      })}
      <FilePreview attachments={attachments} currentIndex={previewIndex} open={showPreview} onClose={togglePreview} />
    </div>
  );
};

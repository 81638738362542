import { RefObject, useEffect, useState } from "react";

export const useHover = (ref: RefObject<HTMLElement>) => {
  const [canHover, setCanHover] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      setCanHover(!(ref.current.scrollWidth > ref.current.clientWidth));
    }
  }, [ref?.current]);

  return { canHover };
};

import { QuestionImages } from "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ChecklistSection/useChecklistSection";

const useImageGallery = () => {
  const chunkLetters = (letters: QuestionImages[], chunkSize: number): QuestionImages[][] => {
    const chunks: QuestionImages[][] = [];
    for (let i = 0; i < letters.length; i += chunkSize) {
      const chunk: QuestionImages[] = letters.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const lastChunk = chunks[chunks.length - 1];
    const paddingSize = chunkSize - lastChunk.length;
    if (paddingSize > 0) {
      for (let i = 0; i < paddingSize; i++) {
        lastChunk.push({ letterCode: "", url: "", questionId: 0 });
      }
    }

    return chunks;
  };

  return { chunkLetters };
};

export default useImageGallery;

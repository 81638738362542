import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Icon, Input, Modal } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, UploadFilesZone } from "components";
import { useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import { ImagePreview } from "modules/DealerDocumentations/components";
import "modules/DealerDocumentations/components/AddEditDealerDocumentationModal/AddEditDealerDocumentationModal.scss";
import {
  createDocumentationMtn,
  deleteDocumentationMtn,
  updateDocumentationMtn,
  useAddEditDealerDocumentationModal,
  useDealerDocumentations
} from "modules/DealerDocumentations/hooks";
import { ITranslation } from "util/interfaces";

// eslint-disable-next-line import/order
import "react-quill/dist/quill.snow.css";

interface PropTypes {
  documentation: DealerDocumentation;
  categoryOptions: { key: string | DOCUMENTATION_CATEGORIES; text: string; value: DOCUMENTATION_CATEGORIES | string }[];
  dealersOptions: { key: number; value: number; text: string }[];
  onSetDealerDocumentation: (documentation: DealerDocumentation | null) => void;
}

export const AddEditDealerDocumentationModal = ({ documentation, categoryOptions, dealersOptions, onSetDealerDocumentation }: PropTypes) => {
  const t = useTranslation().t as ITranslation;

  const {
    title,
    categoryId,
    setCategoryId,
    dealersIds,
    setDealersIds,
    dealerLocationIds,
    setDealerLocationIds,
    visibleToMechanic,
    setVisibleToMechanic,
    publishDate,
    setPublishDate,
    isPinned,
    setIsPinned,
    content,
    setContent,
    url,
    setUrl,
    showDeleteConfirmation,
    onFileDragOver,
    onFileChange,
    setShowDeleteConfirmation,
    handleTitleInputChange
  } = useAddEditDealerDocumentationModal(documentation);

  const { mutate: createMutation } = createDocumentationMtn();
  const { mutate: updateMutation } = updateDocumentationMtn();
  const { mutate: deleteMutation, isPending: isDeleting } = deleteDocumentationMtn();

  const { locationDropdownOptions } = useDealerDocumentations({ t });
  const user = useUser();

  const allowedDealerOptions = dealersOptions.filter(e => e.key !== -1);
  const allowedLocationDropdownOptions = locationDropdownOptions.filter(e => e.key !== -1);

  function handleSave() {
    if (!content.length) return toast.error(t("v8_missing_content").message || "Content is missing");
    if (!dealerLocationIds.length) return toast.error(t("v8_missing_location").message || "Provide the location");
    if (!dealersIds.length) return toast.error(t("v8_missing_dealer").message || "Provide the dealer");

    const newUpdatedDocumentation = {
      title,
      url,
      dealer_location_ids: dealerLocationIds,
      category_id: categoryId,
      content,
      is_pinned: isPinned,
      visible_to_mechanic: visibleToMechanic,
      dealer_ids: dealersIds,
      id: documentation.id,
      visible_to_all: documentation.visible_to_all,
      publish_date: publishDate
    };

    documentation.id === 0
      ? createMutation(newUpdatedDocumentation, { onSuccess: () => onSetDealerDocumentation(null) })
      : updateMutation(newUpdatedDocumentation, { onSuccess: () => onSetDealerDocumentation(null) });
  }

  const modalTitle = documentation.id > 0 ? t("v8_edit_documentation").message || "Edit Documentation" : t("v8_add_documentation").message || "Add documentation";

  return (
    <>
      <Modal className="AddEditDealerDocumentationModal" open={document ? true : false} size="large">
        <Modal.Header>
          <h3 className="documentationModalTitle">{modalTitle}</h3>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Input name="title" required onChange={handleTitleInputChange} defaultValue={title} placeholder={t("v8_title").message || "Title"} />
            </Form.Field>
            <div className="dealer-modal-row-wrapper">
              <Form.Field>
                <Dropdown
                  noResultsMessage={t("v8_no_categories").message || "No categories"}
                  placeholder={t("v8_select_category").message || "Select a category"}
                  fluid
                  selection
                  value={categoryId}
                  options={categoryOptions}
                  onChange={(_, { value }) => setCategoryId(value as DOCUMENTATION_CATEGORIES)}
                />
              </Form.Field>

              <Form.Field>
                <Dropdown
                  className={`DealersLocationsDropdown icon -scrollable-dropdown nav-bar-map`}
                  noResultsMessage={t("v8_no_dealers").message || "No dealers"}
                  placeholder={t("v8_select_dealer").message || "Select a dealer"}
                  options={allowedDealerOptions}
                  fluid
                  multiple
                  clearable
                  selection
                  required
                  search
                  value={dealersIds}
                  onChange={(_, { value }) => setDealersIds(value as number[])}
                />
              </Form.Field>

              <Form.Field>
                <Dropdown
                  noResultsMessage={t("v8_no_locations").message || "No locations"}
                  placeholder={t("v8_select_location").message || "Select a location"}
                  options={allowedLocationDropdownOptions}
                  fluid
                  multiple
                  selection
                  search
                  required
                  value={dealerLocationIds}
                  onChange={(_, { value }) => setDealerLocationIds(value as number[])}
                />
              </Form.Field>

              <Form.Field>
                <DatePicker showYearDropdown showMonthDropdown dateFormat="dd-MM-yyyy" onChange={e => setPublishDate(e)} selected={publishDate} />
              </Form.Field>

              <Form.Field className="flex-item-shrink">
                <div className="flex-form-field">
                  <div onClick={() => setIsPinned(curr => !curr)}>
                    <Icon className={isPinned ? "pin" : "circle regular"} />
                  </div>
                  <span> {t("v8_pin").message || "Pin"} </span>
                </div>
              </Form.Field>

              <Form.Field className="flex-item-shrink">
                <div className="flex-form-field">
                  <Checkbox
                    toggle
                    checked={visibleToMechanic}
                    className="checkbox-is-visible"
                    onChange={(_, { checked }: CheckboxProps) => {
                      setVisibleToMechanic(checked || false);
                    }}
                  />
                  <span> {t("v8_visible_for_mechanic").message || "Visible for mechanic"} </span>
                </div>
              </Form.Field>
            </div>

            <Form.Field>
              <ReactQuill modules={{ toolbar: ["bold", "italic", "underline", "link", "image", "video"] }} value={content} onChange={setContent} />
            </Form.Field>

            <Form.Field>
              <UploadFilesZone onFileInputChange={onFileChange} onFileDragOver={onFileDragOver} onFileDrop={onFileChange} display={!url} hasError={false} />

              {url && (
                <ImagePreview url={url}>
                  <Icon className="trash" onClick={() => setUrl(undefined)} />
                </ImagePreview>
              )}
            </Form.Field>
            <div className="dealer-documentation-modal-btn-wrapper">
              {documentation.id > 0 && user?.role_id && user?.role_id < documentation.created_by_id! && (
                <Button color="red" onClick={() => setShowDeleteConfirmation(true)}>
                  {t("v8_delete").message || "Delete"}
                </Button>
              )}
              <div className="cancel-save ">
                <Button color="light" onClick={() => onSetDealerDocumentation(null)}>
                  {t("v8_cancel").message || "Cancel"} <Icon className="xmark outline" />
                </Button>
                <Can I={["create", "update"]} the="dealer-documentation">
                  <Button onClick={handleSave} color="green">
                    {t("v8_save").message || "Save"} <Icon className="check" />
                  </Button>
                </Can>
              </div>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
      <CustomConfirm
        handleConfirm={() => deleteMutation(documentation.id, { onSuccess: () => onSetDealerDocumentation(null) })}
        handleCancel={() => setShowDeleteConfirmation(false)}
        isLoading={isDeleting}
        isOpen={showDeleteConfirmation}
        type={CUSTOM_CONFIRM_TYPES.Danger}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
      />
    </>
  );
};

import ENV from "config/Env";
import apiInstance from "util/Api";

type LandingPage = {
  id: number;
  name: string;
};

export const useAccountSettings = () => {
  const fetchLandingPages = async (): Promise<LandingPage[]> => {
    const data = await apiInstance.get("/iam/landing_pages", ENV.apiBase);
    return data?.data || [];
  };
  return { fetchLandingPages };
};

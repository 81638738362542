import moment from "moment";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "components/DatePicker/DatePicker.scss";
import { ITranslation } from "util/interfaces";

interface DatePickerProps {
  onDateChange: (date: Date) => void;
  date?: Date | null;
  fluid?: boolean;
  disabled?: boolean;
  withPortal?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  showLabel?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  date,
  fluid,
  disabled,
  withPortal,
  showLabel,
  showMonthDropdown = false,
  showYearDropdown = false
}: DatePickerProps) => {
  const t = useTranslation().t as ITranslation;
  const [open, setOpen] = useState<boolean>(false);

  const selectedDate = useMemo(() => {
    setOpen(false);
    if (date) {
      if (moment(date).isSame(moment(), "day")) {
        return t("v8_today").message || "Today";
      }
      return moment(date).format("DD MM YYYY");
    }
    return t("v8_select_date").message || "Select date";
  }, [date]);

  return (
    <>
      <div className={`datepicker-wrapper ${fluid && "fluid"} ${disabled && "disabled"}`} onClick={() => (disabled ? null : setOpen(true))}>
        {showLabel && (
          <>
            <div className="datepicker-row">
              <p className="label">{t("v8_date").message || "Date"}</p>
              <p className="date">{selectedDate}</p>
            </div>
            <div>
              <Icon name="dropdown" />
            </div>
          </>
        )}
        <ReactDatePicker
          calendarClassName="CalendarDatepicker"
          open={open}
          onClickOutside={() => setOpen(false)}
          todayButton={<span>{t("v8_today").message || "Today"}</span>}
          calendarStartDay={1}
          showPopperArrow={false}
          selected={date}
          onChangeRaw={event => event?.preventDefault()}
          onChange={(newDate: Date | null) => {
            if (newDate === null) return;
            newDate === date ? setOpen(false) : onDateChange(newDate);
          }}
          withPortal={!!withPortal}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
        />
      </div>
    </>
  );
};

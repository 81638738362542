import { SemanticCOLORS } from "semantic-ui-react";

import {
  AppointmentNote,
  AppointmentStatusHistory,
  Car,
  Check,
  Checklist,
  CommunicationAgreement,
  Customer,
  CustomerCommunication,
  DMSNote,
  DealerLocation,
  DeskCommunication,
  FinalCheckImage,
  FinalCheckVideo,
  Intervention,
  KeylockerCommunication,
  KeylockerRemark,
  KioskCommunication,
  STATUS_IDENTIFIER,
  User
} from "models";
import ApiLoadable from "models/ApiLoadable";

export enum APPOINTMENT_STATUSES {
  Canceled = -1,
  NewCar = 1,
  CarCheck = 2,
  PricingOk = 3,
  CustomerOk = 4,
  CarReady = 5,
  Diagnose = 6,
  AannameOk = 7,
  Calculating = 8,
  Contact = 9,
  CarCheckStarted = 10,
  QualityCheck = 11,
  CustomerAnswered = 12,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  LastRealStatus = 12,
  BackOrder = 19,
  HandleCheckIn = 50,
  CheckInDone = 51,
  CarInShop = 52,
  CarOkPlusRepairOverview = 53,
  QualityCheckPlusRepairOverview = 54,
  CarOutOfShop = 55
}

export enum COMMUNICATION_STATUSES {
  OnlineCheckinSent = 1,
  OnlineCheckinOpened,
  OnlineCheckinAnswered,
  OnlineCheckinClosed,
  DiagnoseOverviewSent,
  DiagnoseOverviewOpened,
  DiagnoseOverviewAnswered,
  DiagnoseOverviewClosed,
  RepairOverviewSent,
  RepairOverviewOpened,
  KeylockerCheckinSent,
  KeylockerCheckinOpened,
  KeylockerCheckinAnswered,
  KeylockerCheckinClosed
}

export class Appointment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  dms_nr: string = "";
  dms_created_atd: string = "";
  wo_nr: string = "";
  ref_nr: string = "";
  importer_version: string = "";
  dms_status: string = "";
  car_check_started: boolean = false;
  car_profile_picture: string = "";
  last_user_profile_picture: string = "";
  check_paused_at: string | null = null;
  customer_waiting: boolean = false;
  is_local: boolean = false;
  is_money: boolean = false;
  is_star: boolean = false;
  is_star_color: SemanticCOLORS | string = "";
  is_shop: boolean = false;
  is_shop_color: SemanticCOLORS | string = "";
  is_pinned: boolean = false;
  is_pinned_manually: boolean = false;
  has_panic: boolean = false;
  has_extra_check: boolean = false;
  final_check_has_remarks: boolean = false;
  is_recurring?: boolean = false;
  move_count: number = 0;
  next_date: string = "";
  time_car_app: string = "";
  car_return_time: string | null = null;
  car_in_shop: string | null = null;
  car_out_of_shop: string | null = null;
  car_out_of_shop_set_by?: User | null = null;
  due_in: string | null = null;
  car_return_time_edited: boolean = false;
  dms_billed_updated: boolean = false;
  check_in_com_sent: boolean = false;
  check_in_com_answered: boolean = false;
  check_in_com_to_handle: boolean = false;
  last_dms_update: string | null = null;
  internal: boolean = false;
  extra_parts_list_id: number | null = null;
  extra_check_id: number | null = null;
  appointment_status_id: number = 0;
  customer_driver_id: number | null = null;
  customer_driver: Customer | null = null;
  customer_owner_id: number | null = null;
  customer_owner: Customer | null = null;
  customer_contract_id: number | null = null;
  customer_contract: Customer | null = null;
  check_initiator_id: number | null = null;
  check_initiator: User | null = null;
  dealer_location_id: number = 0;
  dealer_location?: DealerLocation | null = null;
  car_id: number = 0;
  vin_nr: string = "";
  car_model: string = "";
  car_make: string = "";
  reg_number: string = "";
  reg_number_esaped: string = "";
  current_km: number = 0;
  next_km: number = 0;
  car_apk_date: string | null = null;
  driver_initials: string = "";
  driver_title: string = "";
  driver_firstname: string = "";
  driver_surname: string = "";
  contractor_initials: string = "";
  contractor_title: string = "";
  contractor_firstname: string = "";
  contractor_surname: string = "";
  owner_initials: string = "";
  owner_title: string = "";
  owner_firstname: string = "";
  owner_surname: string = "";
  company: string = "";
  appointment_status_identifier: STATUS_IDENTIFIER = STATUS_IDENTIFIER.NewCarStatus;
  last_timestamp: string | null = null;
  last_user: string = "";
  num_approved_items_not_fixed: number = 0;
  has_dbb: boolean = false;
  dbb_appointment_date: string | null = null;
  car_check_dbb_status: number = 0;
  final_car_check_dbb_status: number = 0;
  car?: Car | null = null;
  customcom_id?: number | null = null;
  customcom_receptionist_key?: string | null = null;
  customcom_status?: COMMUNICATION_STATUSES | null = null;
  customcom_correct_phone?: string | null = null;
  customcom_correct_email?: string | null = null;
  customcom_customer_id?: number | null = null;
  communication_note?: string = "";
  customer_communication?: CustomerCommunication | null = null;
  dealer_name?: string = "";
  location_name?: string = "";
  location_phone?: string = "";
  dealer_domain_name?: string = "";
  pin_icon_color: string = "";
  warranty_pin_count: number = 0;
  key_dropped_at: string = "";
  key_picked_up_at: string = "";
  sharebox_key_dropped_at: string = "";
  sharebox_key_picked_up_at: string = "";
  sharebox_key_picked_up_by?: User | null = null;
  key_dropped_back_at: string = "";
  key_picked_up_back_at: string = "";
  sharebox_key_dropped_back_at: string = "";
  sharebox_key_picked_up_back_at: string = "";
  sharebox_key_dropped_back_by?: User | null = null;
  sharebox_key_picked_up_back_by?: User | null = null;
  assigned_mechanic: number | null = null;
  assigned_mechanic_order: number | null = null;
  last_assigned_mechanic: number | null = null;
  planning_work_start: string | null = null;
  planning_work_stop: string | null = null;
  car_in_shop_set_by?: User | null = null;
  keylocker_remark?: KeylockerRemark | null = null;
  reg_number_escaped: string = "";
  is_lease_company: boolean = false;
  planning_mechanic: User | null = null;
  acses_key_dropped_at: string = "";
  acses_key_picked_up_at: string = "";
  acses_key_dropped_back_at: string = "";
  acses_key_picked_up_back_at: string = "";
  acses_key_picked_up_by?: User | null = null;
  acses_key_dropped_back_by?: User | null = null;
  acses_key_picked_up_back_by?: User | null = null;
  car_hu_date: string = "";
  kiosk_label_number: number = 0;
  warranty_pin_signature_missing_count: number = 0;
  warranty_pin_claim_nr_missing_count: number = 0;
  warranty_pin_support_nr_missing_count: number = 0;
  recall_pin_count: number = 0;
  recall_pin_support_nr_missing_count: number = 0;
  recall_pin_claim_nr_missing_count: number = 0;
  remark_pin_count: number = 0;
  last_receptionist_update_by?: User | null = null;
  due_in_time?: string = "";
  customer_owner_name?: string = "";
  customer_owner_profile_picture?: string = "";
  last_user_at: string | null = null;

  private _notes?: AppointmentNote[] | null = null;

  get notes(): AppointmentNote[] {
    return this._notes ? this._notes : [];
  }

  set notes(notes: AppointmentNote[] | null | undefined) {
    this._notes = notes;
  }

  private _dms_notes?: DMSNote[] | null = null;

  get dms_notes(): DMSNote[] {
    return this._dms_notes ? this._dms_notes : [];
  }

  set dms_notes(dms_notes: DMSNote[] | null | undefined) {
    this._dms_notes = dms_notes;
  }

  private _online_checkin_agreements?: CommunicationAgreement[] | null = null;

  get online_checkin_agreements(): CommunicationAgreement[] {
    return this._online_checkin_agreements ? this._online_checkin_agreements : [];
  }

  set online_checkin_agreements(online_checkin_agreements: CommunicationAgreement[] | null | undefined) {
    this._online_checkin_agreements = online_checkin_agreements;
  }

  private _diagnose_overview_agreements?: CommunicationAgreement[] | null = null;

  get diagnose_overview_agreements(): CommunicationAgreement[] {
    return this._diagnose_overview_agreements ? this._diagnose_overview_agreements : [];
  }

  set diagnose_overview_agreements(diagnose_overview_agreements: CommunicationAgreement[] | null | undefined) {
    this._diagnose_overview_agreements = diagnose_overview_agreements;
  }

  private _final_check_images?: FinalCheckImage[] | null = null;

  get final_check_images(): FinalCheckImage[] {
    return this._final_check_images ? this._final_check_images : [];
  }

  set final_check_images(final_check_images: FinalCheckImage[] | null | undefined) {
    this._final_check_images = final_check_images;
  }

  private _final_check_videos?: FinalCheckVideo[] | null = null;

  get final_check_videos(): FinalCheckVideo[] {
    return this._final_check_videos ? this._final_check_videos : [];
  }

  set final_check_videos(final_check_videos: FinalCheckVideo[] | null | undefined) {
    this._final_check_videos = final_check_videos;
  }

  private _attachments?: AppointmentAttachment[] | null = null;

  get attachments(): AppointmentAttachment[] {
    return this._attachments ? this._attachments : [];
  }

  set attachments(attachments: AppointmentAttachment[] | null | undefined) {
    this._attachments = attachments;
  }

  private _status_history?: AppointmentStatusHistory[] | null = null;

  get status_history(): AppointmentStatusHistory[] {
    return this._status_history ? this._status_history : [];
  }

  set status_history(status_history: AppointmentStatusHistory[] | null | undefined) {
    this._status_history = status_history;
  }

  private _interventions?: Intervention[] | null = null;

  get interventions(): Intervention[] {
    return this._interventions ? this._interventions : [];
  }

  set interventions(interventions: Intervention[] | null | undefined) {
    this._interventions = interventions;
  }

  private _checks?: Check[] | null = null;

  get checks(): Check[] {
    return this._checks ? this._checks : [];
  }

  set checks(checks: Check[] | null | undefined) {
    this._checks = checks;
  }

  private _checklists?: Checklist[] | null = null;

  get checklists(): Checklist[] {
    return this._checklists ? this._checklists : [];
  }

  set checklists(checklists: Checklist[] | null | undefined) {
    this._checklists = checklists;
  }

  private _desk_communications?: DeskCommunication[] | null = null;

  get desk_communications(): DeskCommunication[] {
    return this._desk_communications ? this._desk_communications : [];
  }

  set desk_communications(desk_communications: DeskCommunication[] | null | undefined) {
    this._desk_communications = desk_communications;
  }

  private _keylocker_communications?: KeylockerCommunication[] | null = null;

  get keylocker_communications(): KeylockerCommunication[] {
    return this._keylocker_communications ? this._keylocker_communications : [];
  }

  set keylocker_communications(keylocker_communications: KeylockerCommunication[] | null | undefined) {
    this._keylocker_communications = keylocker_communications;
  }

  private _kiosk_communications?: KioskCommunication[] | null = null;

  get kiosk_communications(): KioskCommunication[] {
    return this._kiosk_communications ? this._kiosk_communications : [];
  }

  set kiosk_communications(kiosk_communications: KioskCommunication[] | null | undefined) {
    this._kiosk_communications = kiosk_communications;
  }
}

export class AppointmentAttachment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  url: string = "";
  type: string = "";
  username: string = "";
  user_id: number = 0;
  appointment_id: number = 0;
}

export class CarCheckHistory extends ApiLoadable {
  private _appointments?: CarCheckAppointment[] | null = null;

  get appointments(): CarCheckAppointment[] {
    return this._appointments ? this._appointments : [];
  }

  set appointments(appointments: CarCheckAppointment[] | null) {
    this._appointments = appointments;
  }
}

export class CarCheckAppointment extends ApiLoadable {
  id: number = 0;
  date: string = "";
  current_km: number = 0;
  next_km: number = 0;
  next_date: string = "";
  private _checklists?: CarCheckChecklist[] | null = null;

  get checklists(): CarCheckChecklist[] {
    return this._checklists ? this._checklists : [];
  }

  set checklists(checklists: CarCheckChecklist[] | null) {
    this._checklists = checklists;
  }
}

export class CarCheckChecklist extends ApiLoadable {
  name: string = "";
  photo_count: number = 0;
  a_count: number = 0;
  a_count_sold: number = 0;
  n_count: number = 0;
  n_count_sold: number = 0;
}

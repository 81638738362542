import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar } from "semantic-ui-react";

import { Can } from "components";
import "components/MenuView/MenuView.scss";

type Item = {
  title: string;
  icon?: string;
  link: string;
  name: string;
};

interface MenuViewProps {
  children: React.ReactNode;
  isSideBarOpen: boolean;
  hideSidebar?: () => void;
}

interface MenuViewSectionProps {
  items: Item[];
  heading?: string;
  hideSidebar: () => void;
}

export const MenuView = ({ children, isSideBarOpen, hideSidebar }: MenuViewProps) => (
  <Sidebar as={Menu} animation="overlay" icon="labeled" inverted vertical visible={isSideBarOpen} onHide={hideSidebar} width="thin" className="MenuView">
    {children}
  </Sidebar>
);

const MenuSection = ({ items, heading, hideSidebar }: MenuViewSectionProps) => {
  const [showItems, setShowItems] = useState(!heading);
  const handleChangeShowItems = () => setShowItems(showItems => !showItems);

  return (
    <>
      {heading && (
        <Menu.Item onClick={handleChangeShowItems} className="menu-view-item heading">
          {heading}
          <Icon name={`chevron ${showItems ? "right" : "down"}`} />
        </Menu.Item>
      )}
      {showItems &&
        items.map((item: Item) => (
          <Can I="browse" the={item.name} key={item.title}>
            <Link to={item.link} onClick={hideSidebar}>
              <Menu.Item className="menu-view-item">
                {item.icon && <Icon className={item.icon} />}
                {item.title}
              </Menu.Item>
            </Link>
          </Can>
        ))}
    </>
  );
};

MenuView.Section = MenuSection;

import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { Can, DetailsCards, MCC, PSA, ROB, TabData, Tabs } from "components";
import { useScrollPageTop } from "hooks";
import { Customer } from "models";
import "modules/CarDetails/CarDetails.scss";
import {
  AppointmentHistory,
  BubbleData,
  CarDrivingTrend,
  CustomerInfo,
  Details,
  ItemsNotRepaired,
  KmChartColors,
  Notes,
  PinItems,
  RelevantData,
  SnoozedItems,
  StateData,
  TruckTyres,
  Tyres
} from "modules/CarDetails/components";
import { Appointment, useAppointmentHistory, useCarDetails, useCustomerInfo, usePinItems, useSnoozeItems } from "modules/CarDetails/hooks";
import { getPinWithHistory, getSnoozeWithHistory } from "modules/CarDetails/utils";
import { ITranslation } from "util/interfaces";

type CarDetailsProps = {
  t: ITranslation;
};

const getAverageKM = (first: { time: Date; km: number }, second: { time: Date; km: number }) => {
  const timeDiff = second.time.getTime() - first.time.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const kmDiff = second.km - first.km;
  return Math.floor(kmDiff / diffDays);
};

const getColorByAverageKM = (averageKM: number) => {
  if (averageKM <= 30) {
    return "rgb(135, 206, 235)";
  }
  if (averageKM >= 30 && averageKM < 50) {
    return "rgb(175,196,131)";
  }
  if (averageKM >= 50 && averageKM < 70) {
    return "rgb(252,162,78)";
  }
  if (averageKM >= 70 && averageKM < 100) {
    return "rgb(224,126,96)";
  }
  return "rgb(191,59,59)";
};

const CarDetails = ({ t }: CarDetailsProps) => {
  useScrollPageTop();
  const { id }: { id: string } = useParams();
  const router = useHistory();
  const { data: carDetails } = useCarDetails(id);
  const { data: appointmentHistoryResponse } = useAppointmentHistory(id);
  const { data: pinItems = [] } = usePinItems(id);
  const { data: snoozedItems = [] } = useSnoozeItems(Number(id));

  const [noteModal, setNoteModal] = useState(false);
  const [chartData, setChartData] = useState<StateData>({
    isMilageChartVisible: false,
    totalAverageKM: { color: "", day: 0, month: 0, year: 0 },
    kmChartColors: { backgroundColors: [] },
    bubbleData: [],
    lineData: []
  });

  const pinItemsWithHistory = getPinWithHistory(pinItems);
  const snoozeItemsWithHistory = getSnoozeWithHistory(snoozedItems);

  const { data: customerInfo, fetchCustomerInfo, itemsNotRepaired, notes } = useCustomerInfo();
  const [tabsData, setTabsData] = useState<Map<string, TabData>>(
    new Map([
      ["details", { id: "details", icon: <Icon className="car mirrors" />, data: [] }],
      ["carNotes", { id: "carNotes", icon: <Icon className="note sticky" /> }],
      ["customerInfo", { id: "customerInfo", icon: <Icon className="user tag" /> }],
      ["snoozedItems", { id: "snoozedItems", icon: <Icon className="clock" /> }],
      ["pinItems", { id: "pinItems", icon: <Icon className="bars" /> }],
      ["itemsNotRepaired", { id: "itemsNotRepaired", icon: <Icon className="clock rotate left" /> }],
      ["appointmentHistory", { id: "appointmentHistory", icon: <Icon className="calendar clock" /> }],
      ["tyres", { id: "tyres", icon: <Icon className="tire" /> }],
      ["drivingTrend", { id: "drivingTrend", icon: <Icon className="chart simple" /> }]
    ])
  );

  const [appointmentHistory, setAppointmentHistory] = useState<Appointment[]>([]);

  useEffect(() => {
    const historyData = appointmentHistoryResponse?.sort((a1, a2) => a2.date.localeCompare(a1.date));

    setAppointmentHistory(historyData);
    fetchCustomerInfo(appointmentHistoryResponse);
  }, [appointmentHistoryResponse?.length]);

  const prepareChartData = (history: Appointment[]) => {
    if (!Array.isArray(history) || history.length < 2) return { isMilageChartVisible: false };

    const lineData: BubbleData[] = [];
    const bubbleData: BubbleData[] = [];
    const relevantDataForAverageCalculation: RelevantData[] = [];
    const kmChartColors: KmChartColors = { backgroundColors: [] };
    let currentKm = -1;

    [...history].reverse().forEach(a => {
      const date = new Date(a.date);

      if (a.current_km > currentKm) {
        currentKm = a.current_km;
        kmChartColors.backgroundColors.push("#2D4473");
        lineData.push({ x: date, y: a.current_km, r: 6 });
        relevantDataForAverageCalculation.push({ km: a.current_km, time: date });
      } else {
        kmChartColors.backgroundColors.push("#FF0000");
      }

      bubbleData.push({ x: date, y: a.current_km, r: 6 });
    });

    if (relevantDataForAverageCalculation.length < 2) return { isMilageChartVisible: false };

    const totalAverageKM = getAverageKM(relevantDataForAverageCalculation[0], relevantDataForAverageCalculation[1]);

    return {
      isMilageChartVisible: true,
      kmChartColors,
      bubbleData,
      lineData,
      totalAverageKM: {
        color: getColorByAverageKM(totalAverageKM),
        day: totalAverageKM,
        month: 30 * totalAverageKM,
        year: 365 * totalAverageKM
      }
    };
  };

  useEffect(() => {
    setChartData({ ...prepareChartData(appointmentHistory) } as StateData);
  }, [appointmentHistory]);

  useEffect(() => {
    setTabsData((prev: Map<string, TabData>) => {
      const tabs = new Map<string, TabData>([...prev]);
      const carNotes = [...(carDetails?.dms_notes || []), ...notes];

      if (snoozeItemsWithHistory?.length !== undefined) {
        const snoozedItemsTab = tabs.get("snoozedItems");
        if (snoozedItemsTab) {
          snoozedItemsTab.data = snoozeItemsWithHistory.length ? snoozeItemsWithHistory : null;
        }
      }
      if (pinItemsWithHistory?.length !== undefined) {
        const pinItemsTab = tabs.get("pinItems");
        if (pinItemsTab) {
          pinItemsTab.data = pinItemsWithHistory.length ? pinItemsWithHistory : null;
        }
      }
      if (itemsNotRepaired?.length !== undefined) {
        const itemsNotRepairedTab = tabs.get("itemsNotRepaired");
        if (itemsNotRepairedTab) {
          itemsNotRepairedTab.data = itemsNotRepaired.length ? itemsNotRepaired : null;
        }
      }
      if (carNotes?.length !== undefined) {
        const carNotesTab = tabs.get("carNotes");
        if (carNotesTab) {
          carNotesTab.data = carNotes.length ? carNotes : null;
        }
      }
      if (appointmentHistory?.length !== undefined) {
        const appointmentHistoryTab = tabs.get("appointmentHistory");
        if (appointmentHistoryTab) {
          appointmentHistoryTab.data = appointmentHistory.length ? appointmentHistory : null;
        }
      }
      if (chartData.isMilageChartVisible) {
        const drivingTrendTab = tabs.get("drivingTrend");
        if (drivingTrendTab) {
          drivingTrendTab.data = [];
        }
      }

      const customerInfoTab = tabs.get("customerInfo");
      if (customerInfoTab) {
        customerInfoTab.data = customerInfo || {};
      }

      return tabs;
    });
  }, [snoozeItemsWithHistory?.length, pinItemsWithHistory?.length, appointmentHistory?.length, customerInfo, itemsNotRepaired?.length, chartData.isMilageChartVisible]);

  const [expanded, setExpanded] = useState<boolean>(true);
  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const navigateBack = () => {
    router.goBack();
  };

  return (
    <>
      <div className="carDetail-container">
        <div className="carDetail-NavigationHeader">
          <div className="carDetail-NavigationHeader-container">
            <div className="row">
              <Tabs data={tabsData} offset={-125} hideBorder />
              <div className="sub-row">
                <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                  <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
                </Button>
                <Button className="-appointment-status" size="small" active onClick={navigateBack}>
                  <Icon className="angle left" />
                  {t("v8_back").message || "Back"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <DetailsCards
          containerStyle="mt-15"
          icon="car mirrors"
          title="Details"
          showExpandArrow={false}
          id={tabsData.get("details")?.id}
          rightContent={
            <div className="full end">
              <Can I="wo_widget" the="servicebox">
                <PSA car_id={Number(id)} />
              </Can>
              <MCC carDetails={carDetails} />
              <Can I="get-contract" the="robnet">
                <ROB car_id={Number(id)} />
              </Can>
            </div>
          }
        >
          <Details data={carDetails} />
        </DetailsCards>

        {tabsData.get("carNotes")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            icon="note sticky"
            id={tabsData.get("carNotes")?.id}
            title={`${t("v8_notes").message || "Notes"} ${tabsData.get("carNotes")?.data.length > 0 ? `(${tabsData.get("carNotes")?.data.length})` : ""}`}
            // rightContent={
            //   <Button className="-appointment-status button-note" size="small" onClick={() => setNoteModal(true)}>
            //     <Icon name="plus" color="green" size="big" />
            //   </Button>
            // }
          >
            <Notes
              dmsNotes={carDetails?.dms_notes}
              appointmentNotes={notes}
              showNoteModal={noteModal}
              onRequestClose={() => setNoteModal(false)}
              onRequestOpen={() => setNoteModal(true)}
            />
          </DetailsCards>
        )}

        {tabsData.get("customerInfo")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("customerInfo")?.id}
            title={`${t("v8_customer_info").message || "Customer Info"}`}
            icon="user tag"
          >
            <CustomerInfo data={tabsData.get("customerInfo")?.data as Record<string, Customer>} />
          </DetailsCards>
        )}

        {tabsData.get("snoozedItems")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("snoozedItems")?.id}
            icon="clock"
            title={`${t("v8_snoozed_items").message || "Snoozed Items"} ${tabsData.get("snoozedItems")?.data.length > 0 ? `(${tabsData.get("snoozedItems")?.data.length})` : ""}`}
          >
            {snoozeItemsWithHistory?.length > 0 && <SnoozedItems data={snoozeItemsWithHistory} />}
          </DetailsCards>
        )}

        {tabsData.get("pinItems")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("pinItems")?.id}
            icon="bars"
            title={`${t("v8_pin_items").message || "Pin Items"} ${tabsData.get("pinItems")?.data.length > 0 ? `(${tabsData.get("pinItems")?.data.length})` : ""}`}
          >
            {pinItemsWithHistory?.length > 0 && <PinItems data={pinItemsWithHistory} />}
          </DetailsCards>
        )}

        {tabsData.get("itemsNotRepaired")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("itemsNotRepaired")?.id}
            icon="clock rotate left"
            title={`${t("v8_items_not_repaired").message || "Items not Repaired"} ${tabsData.get("itemsNotRepaired")?.data.length > 0 ? `(${tabsData.get("itemsNotRepaired")?.data.length})` : ""}`}
          >
            {itemsNotRepaired?.length > 0 && <ItemsNotRepaired data={itemsNotRepaired} />}
          </DetailsCards>
        )}

        {tabsData.get("appointmentHistory")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("appointmentHistory")?.id}
            icon="calendar clock"
            title={`${t("v8_appointment_history").message || "Appointment History"} ${tabsData.get("appointmentHistory")?.data.length > 0 ? `(${tabsData.get("appointmentHistory")?.data.length})` : ""}`}
          >
            <AppointmentHistory data={appointmentHistory} />
          </DetailsCards>
        )}

        {tabsData.get("tyres")?.id && (carDetails?.truck_tyres?.length > 0 || carDetails?.on_car_tyres || carDetails?.in_storage_tyres) && (
          <DetailsCards containerStyle="mt-20" isExpanded={expanded} id={tabsData.get("tyres")?.id} icon="tire" title={`${t("v8_tyres").message || "Tyres"}`}>
            {carDetails?.is_truck ? <TruckTyres data={carDetails} /> : <Tyres data={carDetails} lastAppointment={appointmentHistory?.at(0)} />}
          </DetailsCards>
        )}

        {tabsData.get("drivingTrend")?.data && (
          <DetailsCards
            containerStyle="mt-20"
            isExpanded={expanded}
            id={tabsData.get("drivingTrend")?.id}
            icon="chart simple"
            title={`${t("v8_driving_trend").message || "Driving Trend"}`}
          >
            <CarDrivingTrend chartData={chartData} />
          </DetailsCards>
        )}
      </div>
    </>
  );
};

export default withTranslation()(CarDetails);

import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { ITranslation } from "util/interfaces";

type ConfirmReasonType = {
  id: number;
  name: string;
};

type ConfirmReasonDropdownOptionType = {
  key: number;
  text: string;
  value: number;
};

export const CUSTOM_REASON = -1;

type CustomReasonsProps = {
  confirmReasons?: ConfirmReasonType[];
  handleOnChange: (value: number) => void;
  allowCustomReason?: boolean;
  placeholderText?: string;
};

export const CustomReasons = ({ handleOnChange, confirmReasons, allowCustomReason, placeholderText }: CustomReasonsProps) => {
  const t = useTranslation().t as ITranslation;
  let options: ConfirmReasonDropdownOptionType[] = [];
  if (confirmReasons) {
    options = confirmReasons.map((reason: ConfirmReasonType) => ({
      key: reason.id,
      text: t(reason.name).message || reason.name,
      value: reason.id
    }));

    if (allowCustomReason) options.push({ key: CUSTOM_REASON, text: t("v8_custom_reason_other").message || "Other reason", value: CUSTOM_REASON });
  }

  return (
    <Dropdown
      placeholder={placeholderText || t("v8_pick_reason").message || "Pick reason"}
      selection
      options={options}
      onChange={(_, { value }) => {
        handleOnChange(value as number);
      }}
    />
  );
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Label } from "semantic-ui-react";

import { CommunicationAgreement } from "models";
import { AgreementsModal } from "modules/AppointmentDetail/components/KeylockerCommunicationLog/components";
import { ITranslation } from "util/interfaces";

export type KeylockerCommAgreement = {
  name: string;
  text: string;
};

type AgreementsProps = {
  agreements: CommunicationAgreement[];
};

export const Agreements = ({ agreements }: AgreementsProps) => {
  const t = useTranslation().t as ITranslation;
  const [selectedAgreement, setSelectedAgreement] = useState<KeylockerCommAgreement | null>(null);

  return (
    <div className="keylocker-comm-section-container">
      <div className="keylocker-comm-section-header">{t("v8_agreements").message || "Agreements"}</div>

      <Grid>
        <Grid.Column width={16}>
          {agreements?.map((item, key) => (
            <div className="agreements-row" key={key} onClick={() => setSelectedAgreement(item)}>
              <Label>
                <Icon name="check square" />
              </Label>
              <span className="-margin-left-10">{item.name}</span>
            </div>
          ))}
        </Grid.Column>
      </Grid>

      {!!selectedAgreement && <AgreementsModal agreement={selectedAgreement} onCloseAgreementModal={() => setSelectedAgreement(null)} />}
    </div>
  );
};

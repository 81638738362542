import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";

import "components/InterventionSubitemsModal/InterventionSubitemsModal.scss";
import { ITranslation } from "util/interfaces";

type InterventionSubitemsModalProps = {
  onClose?: () => void;
  onSubmit: (description: string) => void;
};

export const InterventionSubitemsModal = ({ onClose, onSubmit }: InterventionSubitemsModalProps) => {
  const [description, setDescription] = useState("");
  const [hasValidationError, setHasValidationError] = useState(false);
  const t = useTranslation().t as ITranslation;

  const onChangeDescription = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(evt.target.value);
  };

  const handleSubmit = () => {
    if (!description) {
      setHasValidationError(true);
    } else {
      setDescription("");
      onSubmit(description);
    }
  };

  return (
    <Modal open size="small" onClose={onClose} className="InterventionSubitemsModal">
      <Modal.Header>
        <p>{t("v8_add_subitem").message || "Add Subitem"}</p>
      </Modal.Header>
      <Modal.Content>
        <div>
          <Form id="new-subitem">
            <Form.Group>
              <Form.Field width={16}>
                <label>{t("v8_title").message || "Title"}</label>
                <Input fluid name="title" placeholder={t("v8_note").message || "Note"} disabled />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field error={hasValidationError && !description} width={16} required>
                <label>{t("v8_description").message || "Description"}</label>
                <TextArea
                  value={description}
                  placeholder={`${t("v8_description").message || "Description"}...`}
                  rows={2}
                  onChange={onChangeDescription}
                  name="description"
                  required
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="action-buttons">
          <Button color="light" onClick={onClose}>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button color="green" form="new-subitem" type="button" onClick={handleSubmit}>
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

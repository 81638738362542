import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment, Car, Customer, DMS, User } from "models";
import { InterventionData } from "modules/AppointmentDetail/hooks";
import apiInstance from "util/Api";

type keyloopCar = {
  description: {
    make: string;
    model: string;
  };
  identification: {
    licensePlate: string;
    vin: string;
  };
  vehicleId: number;
};

export interface AppointmentData extends Omit<Appointment, "interventions"> {
  interventions: InterventionData[] | null;
}

export const useNewAppointment = () => {
  const { selectedLocation } = useDealersLocations();
  const [cars, setCars] = useState<Car[]>([]);
  const [customers, setCustomers] = useState<User[]>([]);
  const keyLoopEnabled = [DMS.KeyLoopMenus, DMS.KeyLoopJobs].includes(Number(selectedLocation?.dms_id));

  const searchCarFn = (term: string) => {
    if (keyLoopEnabled) {
      return apiInstance.post(
        "/vehicles/search",
        {
          term,
          dealer_location_id: selectedLocation?.id
        },
        ENV.keyloopBaseURL
      );
    } else {
      return apiInstance.post("/cars/search_by_regnr", {
        reg_nr: term,
        location_id: selectedLocation?.id
      });
    }
  };

  const searchCar = useMutation({
    mutationFn: searchCarFn,
    onSuccess: res => {
      if (keyLoopEnabled) {
        setCars(
          res.data?.vehicles?.map((car: keyloopCar, index: number) => {
            return {
              key: index,
              id: index,
              title: `${car.description.make || ""} ${car.description.model || ""} - ${car.identification.licensePlate || ""}`,
              car: {
                reg_nr: car.identification.licensePlate,
                vin_nr: car.identification.vin,
                make: car.description.make,
                model: car.description.model,
                dms_nr: car.vehicleId
              }
            };
          })
        );
      } else {
        setCars(
          res.data?.map((car: Car, index: number) => {
            return {
              key: index,
              id: index,
              title: `${car.make || ""} ${car.model || ""} - ${car.reg_nr || ""}`,
              car: {
                reg_nr: car.reg_nr,
                vin_nr: car.vin_nr,
                make: car.make,
                model: car.model,
                dms_nr: car.dms_nr,
                apk_date_dms: car.apk_date_dms
              }
            };
          })
        );
      }
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const searchCustomerFn = (term: string) => {
    return apiInstance.post("/customers/list_by_car", {
      reg_nr: term,
      dealer_location_id: selectedLocation?.id
    });
  };

  const searchCustomer = useMutation({
    mutationFn: searchCustomerFn,
    onSuccess: res =>
      setCustomers(
        res.data?.map((customer_driver: Customer, key: number) => {
          return {
            key: key,
            id: customer_driver.id,
            title: `${customer_driver.title || ""} ${customer_driver.firstname || ""} ${customer_driver.surname || ""} - ${customer_driver.place || ""}`,
            customer_driver
          };
        })
      ),
    onError: e => {
      toast.error(e.message);
    }
  });

  const addAppointmentFn = (data: Partial<AppointmentData>) => {
    return apiInstance.post(
      "/appointments/import",
      {
        appointments: [data],
        dealer_location_id: selectedLocation?.id
      },
      ENV.importerBaseUrl
    );
  };

  const addAppointment = useMutation({
    mutationFn: addAppointmentFn,
    onSuccess: () => toast.success("New appointment created"),
    onError: e => {
      toast.error(e.message);
    }
  });

  return { cars, customers, keyLoopEnabled, searchCar, searchCustomer, addAppointment };
};

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { History } from "history";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths, import/order
import packageJson from "../../package.json";

import { WebSocketComponent } from "components";
import ENV from "config/Env";
import { PATHS } from "router/paths";

type ErrorType = AxiosError | Error | unknown;
class ApiInstance {
  private static _instance: ApiInstance;
  private _token: string = "";
  private history: History | undefined;

  constructor() {
    if (ApiInstance._instance) {
      return ApiInstance._instance;
    }

    ApiInstance._instance = this;
    axios.defaults.headers.common["X-Claire-Clientversion"] = packageJson.version;
    axios.defaults.headers.common["X-Claire-Clientplatform"] = "web";
  }

  private progressBarHooks: ((progress: number) => void)[] = [];

  private updateProgress(progress: number) {
    this.progressBarHooks.forEach(hook => hook(progress));
  }

  public setupInterceptors() {
    axios.interceptors.request.use(config => {
      config.onUploadProgress = progressEvent => {
        const progress = Math.round((progressEvent.loaded * 100) / Number(progressEvent.total));
        this.updateProgress(progress);
      };
      return config;
    });

    axios.interceptors.response.use(
      response => {
        setTimeout(() => {
          this.updateProgress(100);
          setTimeout(() => this.updateProgress(0), 300);
        }, 100);
        return response;
      },
      error => {
        this.updateProgress(0);
        throw error;
      }
    );
  }

  public addProgressBarHook(hook: (progress: number) => void) {
    this.progressBarHooks.push(hook);
  }

  public removeProgressBarHook(hook: (progress: number) => void) {
    this.progressBarHooks = this.progressBarHooks.filter(existingHook => existingHook !== hook);
  }

  private getErrorMessage = (error: AxiosError): string => {
    if (error.response) {
      if (error.response.status === 401) {
        WebSocketComponent.disconnect();
        this.clearSessionCookie();
        sessionStorage.removeItem("_zohoAsapJwt");
        if (this.history) {
          this.history.replace(PATHS.LOGIN);
        }
      }
      if ((error as any).response.data.errors) return (error as any).response.data.errors[0];

      return JSON.stringify(error.response.data);
    }

    return error.message;
  };

  private handleErrorMessage(error: ErrorType, path: string) {
    let errorMessage = "";

    if (axios.isAxiosError(error)) {
      errorMessage = this.getErrorMessage(error);
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = `unknown error for POST ${path}`;
    }

    return errorMessage;
  }

  public clearSessionCookie() {
    document.cookie = "_sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this._token = "";
    sessionStorage.removeItem("_sessionToken");
  }

  get authToken(): string {
    const token = sessionStorage.getItem("_sessionToken") || "";
    this._token = token;

    if (!this._token) {
      this._token =
        document.cookie
          .split(";")
          ?.find(x => x.includes("_sessionToken"))
          ?.trim()
          ?.replace("_sessionToken=", "") || "";
    }
    return this._token;
  }

  set authToken(value: string) {
    this._token = value;
    document.cookie = "_sessionToken=" + value + ";path=/";
    sessionStorage.setItem("_sessionToken", value);
  }

  private requestConfig(): AxiosRequestConfig | undefined {
    if (this.authToken) {
      return {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          Pragma: "no-cache"
        }
      };
    }

    return {};
  }

  setHistory(history: any) {
    this.history = history;
  }

  async get(path: string, baseUrl: string = ENV.apiBase): Promise<any> {
    try {
      const result = await axios.get(baseUrl + path, this.requestConfig());
      return result.data;
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }

  async post<T>(path: string, requestData: T, baseUrl: string = ENV.apiBase, config?: any): Promise<any> {
    try {
      const result = await axios.post(baseUrl + path, requestData, { ...this.requestConfig(), ...config });
      return result.data;
    } catch (error: ErrorType) {
      throw new Error(this.handleErrorMessage(error, path));
    }
  }
}

const apiInstance = new ApiInstance();
apiInstance.setupInterceptors();
export default apiInstance;

import moment from "moment";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Icon } from "semantic-ui-react";

import { DOCUMENTATION_CATEGORIES } from "models";
import { AddEditDealerDocumentationModal, DealerDocumentationReadingModal, DealersDocumentationTable } from "modules/DealerDocumentations/components";
import "modules/DealerDocumentations/DealerDocumentations.scss";
import { useDealerDocumentations } from "modules/DealerDocumentations/hooks";
import { ITranslation } from "util/interfaces";

type DealerDocumentationsProps = {
  t: ITranslation;
};

type OnChangeProps = {
  value: string | number | boolean | (string | number | boolean)[] | undefined;
};

const defaultDocumentation = {
  id: 0,
  title: "",
  content: "",
  publish_date: moment(new Date()).toDate(),
  url: undefined,
  is_pinned: false,
  visible_to_mechanic: false,
  visible_to_all: false,
  category_id: DOCUMENTATION_CATEGORIES.Manual,
  dealer_ids: [],
  dealer_location_ids: []
};

const DealerDocumentations = ({ t }: DealerDocumentationsProps) => {
  const {
    dealerIds,
    categoryId,
    dealersNames,
    dealersDropdownOptions,
    locationsNames,
    categoryOptions,
    visibleToMechanic,
    dealerLocationIds,
    dealersDocumentations,
    locationDropdownOptions,
    setDealerIds,
    onSetDealerDocumentation,
    setCategoryId,
    setDealerLocationIds,
    setVisibleToMechanic,
    addEditDealerDocumentationModal,
    modalDocumentation,
    handleDocumentation,
    getCategoryName,
    onCloseDocumentation,
    isDealerDropdownVisible
  } = useDealerDocumentations({ t });

  const onChangeDealerHandler = ({ value }: OnChangeProps) => {
    const isAllSelected = Array.isArray(value) && value?.includes(-1);
    setDealerIds(isAllSelected ? [-1] : (value as number[]));
    setDealerLocationIds([]);
  };

  const onChangeLocationHandler = ({ value }: OnChangeProps) => {
    const isAllSelected = Array.isArray(value) && value?.includes(-1);
    setDealerLocationIds(isAllSelected ? [-1] : (value as number[]));
  };

  return (
    <>
      <div className="dealer-documentations-container">
        <div className="dealer-toolbar-container">
          {isDealerDropdownVisible() && (
            <Dropdown
              noResultsMessage={t("v8_no_dealers").message || "No dealers"}
              placeholder={t("v8_select_dealer").message || "Select a dealer"}
              options={dealersDropdownOptions}
              fluid
              multiple
              clearable
              selection
              search
              value={dealerIds}
              onChange={(_, { value }) => onChangeDealerHandler({ value })}
              renderLabel={(item, _index, _defaultLabelProps) => ({
                content: <span style={{ marginLeft: "5px" }}>{item.text}</span>,
                icon: item.icon
              })}
            />
          )}

          <Dropdown
            noResultsMessage={t("v8_no_locations").message || "No locations"}
            placeholder={t("v8_select_location").message || "Select a location"}
            options={locationDropdownOptions}
            fluid
            multiple
            selection
            clearable
            search
            value={dealerLocationIds}
            onChange={(_, { value }) => onChangeLocationHandler({ value })}
            renderLabel={(item, _index, _defaultLabelProps) => ({
              content: <span style={{ marginLeft: "5px" }}>{item.text}</span>,
              icon: item.icon
            })}
          />

          <Dropdown
            noResultsMessage={t("v8_no_categories").message || "No categories"}
            placeholder={t("v8_select_category").message || "Select a category"}
            fluid
            selection
            clearable
            value={categoryId}
            options={categoryOptions}
            selectOnBlur={false}
            onChange={(_, { value }) => setCategoryId(value as DOCUMENTATION_CATEGORIES | undefined)}
          />

          <div className="dealer-toggle-container">
            <span> {t("v8_visible_for_mechanic").message || "Visible for mechanic"} </span>
            <Checkbox
              toggle
              className="checkbox-is-visible"
              checked={visibleToMechanic}
              onChange={(_, { checked }: CheckboxProps) => {
                setVisibleToMechanic(checked || false);
              }}
            />
          </div>
          <Button icon labelPosition="left" onClick={() => onSetDealerDocumentation(defaultDocumentation)}>
            <Icon className="plus" />
            {t("v8_add").message || "Add"}
          </Button>
        </div>

        <DealersDocumentationTable
          dealersDocumentation={dealersDocumentations}
          locationsNames={locationsNames}
          dealersNames={dealersNames}
          onSetDealerDocumentation={onSetDealerDocumentation}
          onHandleDocumentation={handleDocumentation}
        />
      </div>
      {addEditDealerDocumentationModal && (
        <AddEditDealerDocumentationModal
          onSetDealerDocumentation={onSetDealerDocumentation}
          categoryOptions={categoryOptions}
          documentation={addEditDealerDocumentationModal}
          dealersOptions={dealersDropdownOptions}
        />
      )}
      {modalDocumentation && (
        <DealerDocumentationReadingModal documentation={modalDocumentation} getCategoryName={getCategoryName} onCloseDocumentation={onCloseDocumentation} />
      )}
    </>
  );
};

export default withTranslation()(DealerDocumentations);

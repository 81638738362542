import { useState } from "react";

import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import { uploadFile } from "modules/DealerDocumentations/hooks";

export const useAddEditDealerDocumentationModal = (documentation: DealerDocumentation) => {
  const [title, setTitle] = useState(documentation.title);
  const [categoryId, setCategoryId] = useState<DOCUMENTATION_CATEGORIES>(documentation.category_id);
  const [dealersIds, setDealersIds] = useState<number[]>(documentation.dealer_ids || []);
  const [dealerLocationIds, setDealerLocationIds] = useState(documentation.dealer_location_ids || []);
  const [visibleToMechanic, setVisibleToMechanic] = useState(documentation.visible_to_mechanic);
  const [publishDate, setPublishDate] = useState<Date | null>(documentation.publish_date as Date);
  const [isPinned, setIsPinned] = useState(documentation.is_pinned);
  const [content, setContent] = useState(documentation.content);
  const [url, setUrl] = useState(documentation.url);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  /*

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const result = await uploadFile(file);
    setUrl(result);
  };*/
  const onFileDragOver = (e: React.SyntheticEvent) => {
    e.preventDefault();
  }; /*
  const onFileDrop = async (e: React.DragEvent) => {
    const file = e.dataTransfer.files[0];
    if (!file) return;
    const result = await uploadFile(file);
    setUrl(result);
  };
*/
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => {
    let file;

    if (e.type === "change") file = (e as React.ChangeEvent<HTMLInputElement>).target.files?.[0] || null;
    if (e.type === "drop") {
      file = (e as React.DragEvent).dataTransfer.files[0] || null;
      e.preventDefault();
    }

    if (!file) return;
    const result = await uploadFile(file);
    setUrl(result);
  };

  const handleTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return {
    title,
    setTitle,
    categoryId,
    setCategoryId,
    dealersIds,
    setDealersIds,
    dealerLocationIds,
    setDealerLocationIds,
    visibleToMechanic,
    setVisibleToMechanic,
    publishDate,
    setPublishDate,
    isPinned,
    setIsPinned,
    content,
    setContent,
    url,
    setUrl,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    onFileDragOver,
    onFileChange,
    handleTitleInputChange
  };
};

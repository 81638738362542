import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "modules/DealerDocumentations/components/ImagePreview/ImagePreview.scss";
import { getNameFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

interface PropTypes {
  url: string | undefined;
  children: ReactNode;
}

export const ImagePreview = ({ url, children }: PropTypes) => {
  if (!url) return null;

  const t = useTranslation().t as ITranslation;
  const fileName = getNameFromURL(url);
  return (
    <div className="file-uploaded">
      <div className="status-message">
        <span>{t("v8_file_uploaded").message || "File uploaded"} </span>
      </div>
      <div className="image-url-delete">
        <div className="image-preview-wrapper">
          <Icon className="image" />
          <a href={url} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        </div>
        {children}
      </div>
    </div>
  );
};

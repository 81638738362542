import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import { Appointment, KEYLOCKER_COMMUNICATION_STATUS, KeylockerCommunication } from "models";
import { Content } from "modules/AppointmentDetail/components/KeylockerCommunicationLog/components";
import { ITranslation } from "util/interfaces";

type CommunicationsProps = {
  appointment: Appointment;
  keylocker_communications: KeylockerCommunication[];
};

type CommunicationPane = {
  menuItem: string;
  render: () => ReactNode;
};

export const Communications = ({ appointment, keylocker_communications }: CommunicationsProps) => {
  const t = useTranslation().t as ITranslation;
  const panes: CommunicationPane[] = [];

  const getMenuItem = (status: number) => {
    if (status === KEYLOCKER_COMMUNICATION_STATUS.KeylockerPickUpPinSent) return t("v8_pick_up_key").message || "Pick up key";

    return t("v8_drop_key").message || "Drop key";
  };

  keylocker_communications
    .sort((comm1, comm2) => comm2.created_on.localeCompare(comm1.created_on))
    .forEach((comm: KeylockerCommunication) => {
      panes.push({
        menuItem: getMenuItem(comm.status),
        render: () => <Content appointment={appointment} comm={comm} />
      });
    });

  return panes.length > 0 ? <Tab menu={{ secondary: true }} panes={panes} /> : null;
};

import { useMutation } from "@tanstack/react-query";

import { ROBResultData } from "components";
import ENV from "config/Env";
import ApiInstance from "util/Api";

const useGetROBData = (car_id: number) => {
  const mutation = useMutation({
    mutationFn: () =>
      ApiInstance.post(
        "/robnet/get_contract",
        {
          car_id
        },
        ENV.carBaseUrl
      )
  });

  return {
    data: mutation.data?.data as ROBResultData,
    fetchROBData: mutation.mutate,
    loading: mutation.isPending,
    error: mutation.error
  };
};

export default useGetROBData;

import axios from "axios";

import { WebSocketComponent } from "components";
import ENV from "config/Env";
import { queryClient } from "index";
import { User } from "models";
import { IQRLogin } from "modules/Auth/interfaces";
import apiInstance from "util/Api";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

class Service {
  static async generateQRCode() {
    return axios.get(`${ENV.authBaseUrl}/qr/generate?x=${Math.floor(Math.random() * 1000)}`);
  }

  static async getUser() {
    const token = apiInstance.authToken;
    try {
      const res = await axios.post(
        `${ENV.authBaseUrl}/session/me`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Pragma: "no-cache"
          }
        }
      );

      const user: User = res.data.data as User;
      user.roleACLs = User.roleACLs(user.acl);

      queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.UserData), () => user);
      WebSocketComponent.connect(user.id.toString());
      return user;
    } catch (e) {
      console.warn("userInfo error", e);
      return null;
    }
  }

  static async verifyQR(requestData: IQRLogin) {
    const res = await axios.post(`${ENV.authBaseUrl}/session/login/qr_code`, requestData);
    return res.data.data;
  }
}

export default Service;

import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, GridColumn, Icon, Label, Menu, MenuItem, MenuItemProps } from "semantic-ui-react";

import { AnswerStatusSelector, CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, Pin, getPinColor, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, COMMUNICATION_RESULT_STATUS, CommunicationEvent, FinalCheckImage, Intervention, PIN_TYPE, PinModel, STATUS_IDENTIFIER } from "models";
import { GeneralInfo, Images, Notes, PinCategory, Subitems } from "modules/AppointmentDetail/components/Interventions/components";
import "modules/AppointmentDetail/components/Interventions/Interventions.scss";
import { useIntervention, useUpdateAppointment } from "modules/AppointmentDetail/hooks";
import { getNameFromURL, getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

export type PinType = {
  key: string;
  text: any;
  value: PIN_TYPE;
  icon: JSX.Element;
};

type InterventionItemProps = {
  intervention: Intervention;
  index: number;
  dmsPriceEnabled: boolean | undefined;
  t: ITranslation;
  pinTypeData: PinType[];
  showPriceInVat?: boolean;
  onRequestCloseTabs: (index: number | null) => void;
  activeIntervention: number | null;
  hasChanges: boolean;
  diagnose_overview_event?: CommunicationEvent;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

enum INTERVENTION_TABS {
  General = "general",
  Subitems = "subitems",
  PinType = "pintype",
  Notes = "notes",
  Images = "images",
  Attachments = "attachments"
}

const imageFilenameRegex: RegExp = /^.*\.(jpg|jpeg|png|gif|bmp|svg|webp)(\?.*)?$/;

const renderDiagnoseOverviewAnswer = (status?: COMMUNICATION_RESULT_STATUS) => {
  let icon: React.ReactNode = null;
  switch (status) {
    case COMMUNICATION_RESULT_STATUS.OkStatus:
      icon = <Icon size="small" className="user check" color="green" />;
      break;
    case COMMUNICATION_RESULT_STATUS.NotOkStatus:
      icon = <Icon size="small" className="user xmark" color="red" />;
      break;
    case COMMUNICATION_RESULT_STATUS.ContactMeStatus:
      icon = <Icon size="small" className="phone" color="green" />;
      break;
    default:
      return null;
  }
  return icon ? (
    <div className="status-container diagnose-overview-icon">
      <div>{icon}</div>
    </div>
  ) : null;
};

const InterventionItem = ({
  intervention,
  index,
  dmsPriceEnabled,
  t,
  showPriceInVat,
  pinTypeData,
  onRequestCloseTabs,
  activeIntervention,
  hasChanges,
  setHasChanges,
  diagnose_overview_event
}: InterventionItemProps) => {
  const latestPinData: PinModel = intervention.pin_history?.sort((a: PinModel, b: PinModel) => {
    const dateA = new Date(String(a.created_on));
    const dateB = new Date(String(b.created_on));
    return dateB.getTime() - dateA.getTime();
  })[0];
  const [showTabs, setShowTabs] = useState(false);
  const [activeTab, setActiveTab] = useState<INTERVENTION_TABS | null>(INTERVENTION_TABS.General);
  const [newActiveTab, setNewActiveTab] = useState<INTERVENTION_TABS | null>(INTERVENTION_TABS.General);
  const [newActiveIntervention, setNewActiveIntervention] = useState<number | null | undefined>();

  const [pinTypeID, setPinTypeID] = useState(latestPinData?.pin_type_id || 0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { appointmentData } = useUpdateAppointment(intervention.appointment_id);
  const { optimisticInterventionUpdate } = useIntervention(intervention.appointment_id);
  const { selectedLocation: location } = useDealersLocations();

  const canUpdateInterventions = useCan("update", "appointments");

  useEffect(() => {
    if (latestPinData?.pin_type_id) {
      setPinTypeID(latestPinData.pin_type_id);
    }
  }, [latestPinData?.pin_type_id]);

  const toggleTabs = () => {
    if (hasChanges) {
      setNewActiveIntervention(activeIntervention === index ? null : index);
      setShowConfirmModal(true);
      return;
    }

    if (activeIntervention === index) {
      onRequestCloseTabs(null);
    } else {
      onRequestCloseTabs(index);
    }
  };

  useEffect(() => {
    if (activeIntervention === index) {
      if (activeTab === null) {
        setActiveTab(INTERVENTION_TABS.General);
      }
      setShowTabs(true);
    } else {
      setActiveTab(null);
      setShowTabs(false);
    }
  }, [activeIntervention]);

  const selectedPin: PinType[] = pinTypeID && pinTypeData ? pinTypeData.filter(pinType => pinType.value === pinTypeID) : [];

  const handleTab = (_e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, menuItem: MenuItemProps) => {
    if (hasChanges) {
      setShowConfirmModal(true);
      setNewActiveTab(menuItem.name as INTERVENTION_TABS);
    } else {
      setActiveTab(menuItem.name as INTERVENTION_TABS);
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    if (newActiveIntervention !== undefined) {
      onRequestCloseTabs(newActiveIntervention);
    }
    setActiveTab(newActiveTab);
    setHasChanges(false);
  };

  const onRequestCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleSubTab = (name: INTERVENTION_TABS) => {
    if (activeTab === name) {
      setShowTabs(false);
      setActiveTab(null);
    } else {
      setShowTabs(true);
      setActiveTab(name);
    }
    onRequestCloseTabs(index);
  };

  const handlePinType = (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
  };

  const attachments = [
    ...(intervention.checkin_attachments || []),
    ...(intervention.diagnose_overview_attachments || []),
    ...(appointmentData?.final_check_images?.filter((item: FinalCheckImage) => item.intervention_index === index) || []),
    ...(latestPinData?.note_attachments?.map(attachment => {
      const name = getNameFromURL(attachment);
      const type = attachment.match(/\.([^?.]+)(\?.*)?$/);

      return {
        url: attachment,
        created_on: latestPinData.created_on,
        updated_on: latestPinData.updated_on,
        appointment_id: latestPinData.appointment_id,
        type,
        name
      };
    }) || [])
  ];
  const images = attachments.filter(image => imageFilenameRegex.test(image.url)) || [];
  const nonImageAttachments = attachments.filter(attachment => !imageFilenameRegex.test(attachment.url)) || [];

  const getCount = (images: FinalCheckImage[]) => {
    if (images.length) return `(${images.length})`;
    return null;
  };

  const toggleVisibleInPDF = () => {
    if (!canUpdateInterventions) return;

    if (appointmentData?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      optimisticInterventionUpdate.mutate({
        ...intervention,
        visible_to_customer: !intervention.visible_to_customer
      });
    }
  };

  const toggleCustomerOk = () => {
    if (appointmentData?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      optimisticInterventionUpdate.mutate({
        ...intervention,
        customer_ok: !intervention.customer_ok
      });
    }
  };

  return (
    <div className="Intervention">
      <Grid>
        <GridColumn width={activeIntervention ? 7 : 10}>
          <div className="title-container">
            {intervention?.question_result_status ? (
              <div className="question-status-container">
                <AnswerStatusSelector
                  data={{ ...intervention, status: intervention.question_result_status as number | null } as Intervention}
                  type="intervention"
                  disabled
                />
              </div>
            ) : null}
            <div className="title-container-button" onClick={toggleTabs}>
              <Icon className="desktop" size="tiny" />
              <p>{intervention.title}</p>
              <Icon className={`chevron ${showTabs ? "down" : "right"}`} size="small" />
            </div>
          </div>
        </GridColumn>
        <GridColumn width={activeIntervention ? 4 : 3}>
          {dmsPriceEnabled && (
            <div className="column-container float-right">
              <p>{`€ ${showPriceInVat ? getPriceInVAT(intervention.price, location).toFixed(2) : intervention.price.toFixed(2)}`}</p>
            </div>
          )}
        </GridColumn>
        <GridColumn width={activeIntervention ? 5 : 3}>
          <div className="column-container float-right">
            <div className="column-container gap">
              {intervention.elements?.length > 0 && (
                <Label className="green" onClick={() => handleSubTab(INTERVENTION_TABS.Subitems)}>
                  {<Icon className="diagram subtask" />} {intervention.elements.length}
                </Label>
              )}
              {intervention.mechanic_notes && (
                <Label className="blue" onClick={() => handleSubTab(INTERVENTION_TABS.Notes)}>
                  {<Icon className="notes" />} {"1"}
                </Label>
              )}
              {images.length > 0 && (
                <Label className="teal" onClick={() => handleSubTab(INTERVENTION_TABS.Images)}>
                  {<Icon className="images" />} {images.length}
                </Label>
              )}
              {nonImageAttachments.length > 0 && (
                <Label className="skyblue" onClick={() => handleSubTab(INTERVENTION_TABS.Attachments)}>
                  {<Icon className="paperclip" />} {nonImageAttachments.length}
                </Label>
              )}
              {latestPinData && selectedPin && selectedPin.length > 0 && (
                <Pin pin={latestPinData} pinType={selectedPin[0]} onClick={() => handleSubTab(INTERVENTION_TABS.PinType)} />
              )}
            </div>
            {renderDiagnoseOverviewAnswer(diagnose_overview_event?.intervention_results?.find(r => r.intervention_id === intervention.id)?.communication_result_status)}
            <div className="status-container">
              <Can I="update" the="interventions">
                <div>
                  <Icon
                    className={`eye ${intervention.visible_to_customer ? "green" : "slash red"} ${canUpdateInterventions ? "pointer" : ""}`}
                    size="small"
                    onClick={toggleVisibleInPDF}
                  />
                </div>
              </Can>
              <div>
                <Icon className={`${intervention.customer_ok ? "check circle green" : "remove circle red"}`} size="large" onClick={() => toggleCustomerOk()} />
              </div>
              <div>
                <Icon
                  className={`screwdriver wrench ${intervention.mechanic_fixed ? "green" : "grey"} pointer`}
                  size="small"
                  onClick={() => handleSubTab(INTERVENTION_TABS.General)}
                />
              </div>
            </div>
          </div>
        </GridColumn>
      </Grid>

      {showTabs && (
        <div className="tabs">
          <Menu tabular>
            <MenuItem name={INTERVENTION_TABS.General} active={activeTab === INTERVENTION_TABS.General} onClick={handleTab}>
              <div className={`tab green ${activeTab === "general" && "active"}`}>
                <Icon className="screwdriver wrench" />
                <p>{t("v8_general_info").message || "General Info"}</p>
              </div>
            </MenuItem>
            <MenuItem name={INTERVENTION_TABS.Subitems} active={activeTab === INTERVENTION_TABS.Subitems} onClick={handleTab}>
              <div className={`tab darkgreen ${activeTab === INTERVENTION_TABS.Subitems && "active"}`}>
                <Icon className="diagram subtask" />
                <p>{t("v8_subitems").message || "Subitems"}</p>
              </div>
            </MenuItem>
            {selectedPin && selectedPin.length > 0 && (
              <MenuItem name={INTERVENTION_TABS.PinType} active={activeTab === INTERVENTION_TABS.PinType} onClick={handleTab}>
                <div
                  className={`tab ${getPinColor(intervention.pin_history?.[0], appointmentData as Appointment, location)} ${activeTab === INTERVENTION_TABS.PinType && "active"}`}
                >
                  {selectedPin[0].icon}
                  <p>{selectedPin[0].text}</p>
                </div>
              </MenuItem>
            )}
            <MenuItem name={INTERVENTION_TABS.Notes} active={activeTab === INTERVENTION_TABS.Notes} onClick={handleTab}>
              <div className={`tab blue ${activeTab === INTERVENTION_TABS.Notes && "active"}`}>
                <Icon className="note sticky" />
                <p>
                  {t("v8_notes").message || "Notes"} {intervention.mechanic_notes ? "(1)" : ""}
                </p>
              </div>
            </MenuItem>
            <MenuItem name={INTERVENTION_TABS.Images} active={activeTab === INTERVENTION_TABS.Images} onClick={handleTab}>
              <div className={`tab teal ${activeTab === INTERVENTION_TABS.Images && "active"}`}>
                <Icon className="images" />
                <p>
                  {t("v8_images").message || "Images"} {getCount(images as FinalCheckImage[])}
                </p>
              </div>
            </MenuItem>
            {/* <MenuItem name={INTERVENTION_TABS.Attachments} active={activeTab === INTERVENTION_TABS.Attachments} onClick={handleTab}>
              <div className={`tab skyblue ${activeTab === INTERVENTION_TABS.Attachments && "active"}`}>
                <Icon className="paperclip" />
                <p>
                  {t("v8_attachments").message || "Attachments"} {getCount(nonImageAttachments as FinalCheckImage[])}
                </p>
              </div>
            </MenuItem> */}
          </Menu>
          <div className="tabs-container">
            {activeTab === INTERVENTION_TABS.General && (
              <GeneralInfo
                pinTypeData={pinTypeData}
                latestPinData={latestPinData}
                typeID={pinTypeID}
                data={intervention}
                onPinTypeSet={handlePinType}
                onSetHasChanges={setHasChanges}
                appointment={appointmentData as Appointment}
              />
            )}
            {activeTab === INTERVENTION_TABS.Subitems && <Subitems data={intervention} showPriceInVat={showPriceInVat} />}
            {selectedPin && selectedPin.length > 0 && activeTab === INTERVENTION_TABS.PinType && (
              <PinCategory
                latestPinData={latestPinData}
                data={intervention}
                pinType={selectedPin[0]}
                pinTypeData={pinTypeData}
                appointment={appointmentData as Appointment}
              />
            )}
            {activeTab === INTERVENTION_TABS.Notes && <Notes data={intervention} />}
            {activeTab === INTERVENTION_TABS.Images && <Images data={images as FinalCheckImage[]} />}
            {/* {activeTab === INTERVENTION_TABS.Attachments && <Attachments data={nonImageAttachments as FinalCheckImage[]} />} */}
          </div>
        </div>
      )}
      <CustomConfirm
        isLoading={false}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        isOpen={showConfirmModal}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
      />
    </div>
  );
};

export default withTranslation()(InterventionItem);

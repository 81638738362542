import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

import { TableView } from "components";
import { ITranslation } from "util/interfaces";

interface VariablesProps {
  tabId: string;
}

export const Variables = ({ tabId }: VariablesProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_variables").message || "VARIABLES"}</h3>
      <div className="mt-25">
        <TableView
          cells={[
            "Appointment.VIN 1",
            "Appointment.VIN 2",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN",
            "Appointment.VIN"
          ]}
          nrOfCells={2}
          enableCopy
        />
      </div>
    </Element>
  );
};

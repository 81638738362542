import { BubbleController, Chart, Filler, LineController, LineElement, LinearScale, PointElement, TimeScale, Tooltip } from "chart.js";
import "chartjs-adapter-moment";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { BubbleData } from "modules/CarDetails/components";
import { ITranslation } from "util/interfaces";

type MilageChartProps = {
  lineData: BubbleData[];
  bubbleData: BubbleData[];
  colors: {
    backgroundColors: string[];
  };
};

Chart.register(LinearScale, LineElement, PointElement, Filler, Tooltip, BubbleController, LineController, TimeScale);

export const MilageChart = ({ lineData, bubbleData, colors }: MilageChartProps) => {
  const chartRef = useRef<any>(null);
  const t = useTranslation().t as ITranslation;

  const createCanvas = () => {
    const canvas = document.createElement("canvas");
    if (chartRef.current) {
      chartRef.current.innerHTML = "";
      chartRef.current.appendChild(canvas);
    }
    return canvas;
  };

  const getColorByAverageKM = (averageKM: number) => {
    if (averageKM <= 30) {
      return "rgba(135, 206, 235,";
    }
    if (averageKM >= 30 && averageKM < 50) {
      return "rgba(175,196,131,";
    }
    if (averageKM >= 50 && averageKM < 70) {
      return "rgba(252,162,78,";
    }
    if (averageKM >= 70 && averageKM < 100) {
      return "rgba(224,126,96,";
    }
    return "rgba(191,59,59,";
  };

  const calculateAverageKMDriven = (datapoint1: any, datapoint2: any) => {
    const timeDiff = datapoint2.x - datapoint1.x;
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const kmDiff = datapoint2.y - datapoint1.y;

    return Math.floor(kmDiff / diffDays);
  };

  const createLineDatasets = () => {
    const lineDatasets: any[] = [];
    const averageKMData: number[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < lineData.length - 1; i++) {
      const averageKM = calculateAverageKMDriven(lineData[i], lineData[i + 1]);
      const color = getColorByAverageKM(averageKM);

      averageKMData.push(averageKM);

      const dataset = {
        type: "line",
        fill: true,
        lineTension: 0.5,
        backgroundColor: `${color}0.4)`,
        borderColor: `${color}1)`,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: `${color}1)`,
        pointBackgroundColor: `${color}1)`,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: `${color}1)`,
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [lineData[i], lineData[i + 1]],
        spanGaps: false
      };

      lineDatasets.push(dataset);
    }

    return { datasets: lineDatasets, averageKMData };
  };

  const getBubbleDataset = () => {
    return {
      type: "bubble",
      data: bubbleData,
      backgroundColor: colors.backgroundColors,
      hoverBackgroundColor: colors.backgroundColors,
      hoverRadius: 0,
      borderWidth: 2
    };
  };

  const setupChart = () => {
    const ctx = createCanvas().getContext("2d");
    const { datasets, averageKMData } = createLineDatasets();
    datasets.unshift(getBubbleDataset());
    const translations = t;

    const dataCfg = {
      datasets
    };

    const legendCfg = {
      display: false
    };

    const xAxisCfg = {
      type: "time",
      unit: "month",
      gridLines: {
        color: "rgba(0,0,0,0)"
      },
      time: {
        displayFormats: {
          millisecond: "MMM, YYYY",
          second: "MMM, YYYY",
          minute: "MMM, YYYY",
          hour: "MMM, YYYY",
          day: "MMM, YYYY",
          week: "MMM, YYYY",
          month: "MMM, YYYY",
          quarter: "MMM, YYYY",
          year: "MMM, YYYY"
        },
        unitStepSize: 1
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20
      }
    };

    const yAxisCfg = {
      type: "linear",
      ticks: {
        beginAtZero: false
      }
    };

    const scalesCfg = {
      x: xAxisCfg,
      y: yAxisCfg
    };

    const tooltipsCfg = {
      callbacks: {
        beforeLabel(tooltipItem: any) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.index !== 1) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return `${translations("previous_period")} - ${result} km / ${translations("day")} (${result * 30} km / ${translations("month")})`;
        },
        label() {
          return null;
        },
        afterLabel(tooltipItem: any) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.datasetIndex > averageKMData.length || tooltipItem.index !== 0) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return `${translations("next_period")} - ${result} km / ${translations("day")} (${result * 30} km / ${translations("month")})`;
        },
        title(tooltipItem: any) {
          return `${new Date(tooltipItem[0].raw.x).toString().substr(4, 12)} - ${tooltipItem[0].raw.y} km`;
        }
      },
      mode: "nearest",
      intersect: true
    };

    const hoverCfg = {
      animationDuration: 0
    };

    const optionsCfg = {
      plugins: {
        legend: legendCfg,
        tooltip: tooltipsCfg
      },
      scales: scalesCfg,
      hover: hoverCfg,
      aspectRatio: 3
    };

    const cfg = {
      type: "line",
      data: dataCfg,
      options: optionsCfg
    };

    // eslint-disable-next-line no-new
    new Chart(ctx as any, cfg as any);
  };

  useEffect(() => {
    setupChart();
  }, [lineData, bubbleData, colors]);

  return (
    <div className="MileageChart">
      <div className="MileageChart__Legend">
        <span
          className="MileageChart__Legend__Text"
          style={{
            backgroundColor: "rgba(135, 206, 235, 0.4)",
            border: "3px solid rgba(135, 206, 235, 1)"
          }}
        >
          &#60; 30 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp; &nbsp;
        </span>

        <span
          className="MileageChart__Legend__Text"
          style={{
            backgroundColor: "rgba(175,196,131,0.4)",
            border: "3px solid rgba(175,196,131,1)"
          }}
        >
          30-50 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp; &nbsp;
        </span>

        <span
          className="MileageChart__Legend__Text"
          style={{
            backgroundColor: "rgba(252,162,78,0.4)",
            border: "3px solid rgba(252,162,78,1)"
          }}
        >
          50-70 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>

        <span
          className="MileageChart__Legend__Text"
          style={{
            backgroundColor: "rgba(224,126,96,0.4)",
            border: "3px solid rgba(224,126,96,1)"
          }}
        >
          70-100 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>

        <span
          className="MileageChart__Legend__Text"
          style={{
            backgroundColor: "rgba(191,59,59,0.4)",
            border: "3px solid rgba(191,59,59,1)"
          }}
        >
          &#62; 100 km / {t("v8_day").message || "day"}
          &nbsp; &nbsp;
        </span>
      </div>
      <div id="MileageChartArea" ref={chartRef}></div>
    </div>
  );
};

import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import {
  ActionModalSelector,
  type ActionModalSelectorData,
  AnswerStatusSelector,
  CUSTOM_CONFIRM_TYPES,
  CustomConfirm,
  Editable,
  Images,
  Videos,
  useCan
} from "components";
import "components/CheckList/CheckList.scss";
import { Appointment, ChecklistTemplate, QuestionResult, QuestionResultImage, QuestionResultVideo, STATUS_IDENTIFIER } from "models";
import { useChecklist } from "modules/AppointmentDetail/hooks";
import { isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type CheckListItemProps = {
  data: QuestionResult;
  template?: ChecklistTemplate | null;
  onSelect: (data: ActionModalSelectorData) => void;
  t: ITranslation;
  showDropdownActions?: boolean;
  appointment: Appointment;
};

const CheckListItem = ({ data, template, onSelect, t, showDropdownActions = true, appointment }: CheckListItemProps) => {
  const [selectedDeleteImage, setSelectedDeleteImage] = useState<QuestionResultImage | null>(null);
  const [showMedia, setShowMedia] = useState(false);
  const { updateCheckQuestionItem } = useChecklist(data.appointment_id);
  const canUpdateCheckListImtes = useCan("update_question_result", "car_checks");
  const [selectedDeleteVideo, setSelectedDeleteVideo] = useState<{ data: QuestionResultVideo; index: number } | null>(null);

  const toggleShowMedia = () => {
    setShowMedia(prev => !prev);
  };

  const handleChangeVisibilityImage = (image: QuestionResultImage) => {
    const updatedImage = { ...image, visible_in_pdf: !image.visible_in_pdf };

    const updatedData = { ...data } as QuestionResult;

    //This is workaround. Status field needs to be removed from payload so backend doesn't return 400 bad request error when updating item.
    delete (updatedData as any).status;

    updatedData.images[updatedData.images.findIndex(e => e.id === image.id)] = updatedImage;
    updateCheckQuestionItem.mutate({ data: updatedData });
  };

  const handleDeleteImage = (image: QuestionResultImage) => {
    const updatedImage = { ...image, active: false };
    const updatedData = { ...data } as QuestionResult;
    //This is workaround. Status field needs to be removed from payload so backend doesn't return 400 bad request error when updating item.
    delete (updatedData as any).status;
    updatedData.images[updatedData.images.findIndex(e => e.id === image.id)] = updatedImage;
    updateCheckQuestionItem.mutate({ data: updatedData });
    setSelectedDeleteImage(null);
  };

  const updateVideoInQuestionItem = (updatedVideo: QuestionResultVideo, index: number) => {
    const updatedData = { ...data };
    (updatedData as QuestionResult).video[index] = updatedVideo;
    updateCheckQuestionItem.mutate({ data: updatedData as QuestionResult });
  };

  const handleDeleteVideo = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, active: false }, index);
    setSelectedDeleteVideo(null);
  };

  const handleVideoUpload = (video: QuestionResultVideo, index: number, url: string) => {
    updateVideoInQuestionItem({ ...video, url: url }, index);
  };

  const handleVideoVisibility = (video: QuestionResultVideo, index: number) => {
    updateVideoInQuestionItem({ ...video, visible: !video.visible }, index);
  };

  const handleUpdateNote = (raw: string) => {
    updateCheckQuestionItem.mutate({ data: { id: data.id, raw } as QuestionResult });
  };

  const handleCancelDeleteMedia = () => {
    setSelectedDeleteImage(null);
    setSelectedDeleteVideo(null);
  };

  const handleConfirmDeleteMedia = () => {
    if (selectedDeleteImage) handleDeleteImage(selectedDeleteImage);
    if (selectedDeleteVideo) handleDeleteVideo(selectedDeleteVideo.data, selectedDeleteVideo.index);
  };

  const onSetSelectedVideo = (data: QuestionResultVideo, index: number) => {
    setSelectedDeleteVideo({ data, index });
  };

  const images = data?.images?.filter(item => item.active) || [];
  const videos = data?.video?.filter(item => item.active) || [];

  return (
    <>
      <div className="CheckListItem">
        <div className="title-container">
          <p>{data.title}</p>
          <div className="status-container">
            <AnswerStatusSelector data={data} />
            {(images.length > 0 || videos.length > 0) && (
              <div onClick={toggleShowMedia}>
                <Icon className="images grey pointer" size="small" />
                <p>
                  <small>{images.length + videos.length}</small>
                </p>
              </div>
            )}
            {showDropdownActions && <ActionModalSelector template={template} data={data} onSelect={onSelect} interventions={appointment.interventions} />}
          </div>
        </div>
        <div className="title-row">
          <p>
            <strong>{t("v8_remarks").message || "Remarks"}:</strong>
          </p>
          <Editable
            value={data.raw}
            disabled={data.is_quality || !canUpdateCheckListImtes || isCarReadyOrQualityCheckDoneOrCanceled(appointment)}
            onSubmit={handleUpdateNote}
          />
        </div>

        {showMedia && (
          <div className="checklist-media">
            <Images data={images} onDeleteRequest={setSelectedDeleteImage} canDelete={canUpdateCheckListImtes} onChangeVisibility={handleChangeVisibilityImage} />
            <Videos
              appointment={appointment}
              data={videos}
              isEditable={appointment.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && canUpdateCheckListImtes}
              handleDeleteVideo={onSetSelectedVideo}
              handleVideoUpload={handleVideoUpload}
              handleVideoVisibility={handleVideoVisibility}
            />
          </div>
        )}
      </div>
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        handleCancel={handleCancelDeleteMedia}
        isOpen={!!(selectedDeleteImage || selectedDeleteVideo)}
        isLoading={updateCheckQuestionItem.isPending}
        confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
        handleConfirm={handleConfirmDeleteMedia}
      />
    </>
  );
};

export default withTranslation()(CheckListItem);

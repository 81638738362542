import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";

import { CircleIcon, ReactTable, StatusCell } from "components";
import { useDealersLocations } from "hooks";
import "modules/CarDetails/components/AppointmentHistory/AppointmentHistory.scss";
import { Appointment, Checklist } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

type AppointmentHistoryProps = {
  data: Appointment[];
};

export const AppointmentHistory = ({ data }: AppointmentHistoryProps) => {
  const { locations } = useDealersLocations();
  const checkListColumnHelper = createColumnHelper<Checklist>();
  const t = useTranslation().t as ITranslation;

  const checkListColumns = [
    checkListColumnHelper.accessor(row => row.name, {
      id: "name",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium">{info.renderValue()}</p>} />
    }),
    checkListColumnHelper.accessor(row => row.name, {
      id: "WO",
      cell: () => null
    }),
    checkListColumnHelper.accessor(row => row.photo_count, {
      id: "photo_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.n_count, {
      id: "n_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.n_count_sold, {
      id: "n_count_sold",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count, {
      id: "a_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count_sold, {
      id: "a_count_sold",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count_sold, {
      id: "icons",
      cell: () => <div />
    })
  ];

  type HeaderProps = {
    id: string;
    appointment: Appointment;
    t: ITranslation;
  };

  const Header = ({ id, appointment, t }: HeaderProps) => {
    const data = String(appointment[id as keyof Appointment]);
    switch (id) {
      case "date":
        return (
          <div>
            <p className="date-wo">
              <div>
                <span className="weak">{t("v8_date").message || "Date"}:</span> {moment(data).format("DD-MM-YYYY")}
              </div>
              <div>
                <span className="weak">{t("v8_wo").message || "WO"}#:</span> {appointment.wo_nr}
              </div>
            </p>
            <p>{t("v8_name").message || "Name"}</p>
          </div>
        );
      case "location_name":
        return (
          <div>
            <p>
              {" "}
              <span className="weak">{t("v8_location").message || "Location"}:</span>{" "}
              {locations?.find(location => location.id === appointment.dealer_location_id)?.name || ""}
            </p>
            <p>{t("v8_photo").message || "Photo"}</p>
          </div>
        );
      case "next_date":
        return (
          <div>
            <p>
              {" "}
              <span className="weak">{t("v8_next_date").message || "Next date"}:</span> {appointment.next_date ? moment(appointment.next_date).format("DD-MM-YYYY") : ""}
            </p>
            <p>{t("v8_critical").message || "Critical"}</p>
          </div>
        );
      case "current_km":
        return (
          <div>
            <p>
              {" "}
              <span className="weak">{t("v8_current_km").message || "Current km"}:</span> {appointment.current_km}
            </p>
            <p>{t("v8_critical_approved").message || "Critical Approved"}</p>
          </div>
        );
      case "next_km":
        return (
          <div>
            <p>
              {" "}
              <span className="weak">{t("v8_next_km").message || "Next km"}:</span> {appointment.next_km}
            </p>
            <p>{t("v8_advisable").message || "Advisable"}</p>
          </div>
        );
      case "reg_number":
        return (
          <div>
            <p>
              {" "}
              <span className="weak">{t("v8_registration").message || "Registration"}:</span> {data}
            </p>
            <p>{t("v8_advisable_approved").message || "Advisable Approved"}</p>
          </div>
        );
      case "id":
        return (
          <>
            <div className="item-buttons">
              {appointment?.is_money && (
                <div className="button-icon">
                  <CircleIcon icon="is_money" />
                </div>
              )}
              <div className="small-status">
                <StatusCell value={{ id: appointment.appointment_status_identifier }} />
              </div>
              <div className="button-icon">
                <Link to={`/appointments/${data}`} className="workorder">
                  <Button className="-appointment-status" size="small">
                    <Icon className="arrow up right from square" color="green" />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="empty" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="AppointmentHistory">
      {data?.length &&
        data.map((appointment: Appointment, index: number) => {
          return (
            <ReactTable
              key={`appointment-checklist-${index}`}
              renderEmptyRow={!appointment?.checklists?.length}
              columns={checkListColumns}
              data={appointment.checklists || []}
              emptyRowMessage={t("v8_no_checklists_in_the_appointment").message || "No checklists in the appointment"}
              customHeader={
                <>
                  <tr>
                    <th>
                      <Header appointment={appointment} id={"date"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"wo_nr"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"location_name"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"next_date"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"current_km"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"next_km"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"reg_number"} t={t} />
                    </th>
                    <th>
                      <Header appointment={appointment} id={"id"} t={t} />
                    </th>
                  </tr>
                  <div className="divider" />
                </>
              }
            />
          );
        })}
    </div>
  );
};

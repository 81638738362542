import { useQuery } from "@tanstack/react-query";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { DealerDocumentation } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

type GetDocumentationsListProps = {
  dealerIds: number[];
  dealerLocationIds: number[];
  visibleToMechanic: boolean;
};

export const useGetDealersDocumentations = ({ dealerIds, dealerLocationIds, visibleToMechanic }: GetDocumentationsListProps) => {
  const { dealers } = useDealersLocations();
  const getDocumentationsList = async () => {
    const isAllDealersSelected = dealerIds.includes(-1);
    const isAllLocationsSelected = dealerLocationIds.includes(-1);

    const dto = {
      dealer_ids: isAllDealersSelected ? dealers.map(dealer => dealer.id) : dealerIds,
      dealer_location_ids: isAllLocationsSelected ? [] : dealerLocationIds,
      visible_to_mechanic: visibleToMechanic
    };
    const response = await ApiInstance.post(`/documentations/list`, dto, ENV.dealerBaseUrl);

    return response.data.documentations as DealerDocumentation[];
  };

  const { data: dealersDocumentations } = useQuery({
    queryKey: [{ ...queryKeys.dealer.documentation }],
    queryFn: getDocumentationsList,
    refetchOnMount: "always",
    enabled: dealerIds.length > 0 && dealerLocationIds.length > 0
  });

  return { dealersDocumentations };
};

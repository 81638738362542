import ENV from "config/Env";

export interface BackendQueryKey {
  origin: "backend";
  baseUrl: string;
  endpoint: string;
  params?: Record<string, unknown>;
}

export interface LocalQueryKey {
  origin: "local";
  component: string;
  data?: Record<string, unknown> | string;
}

export type QueryKeyType = BackendQueryKey | LocalQueryKey;

interface QueryKeys {
  app: {
    user: string[];
    websocket: string[];
  };
  dealers: Record<string, QueryKeyType>;
  appointments: Record<string, QueryKeyType>;
  dealer: Record<string, QueryKeyType>;
  location: Record<string, QueryKeyType>;
  car: Record<string, QueryKeyType>;
  appointmentDetails: Record<string, QueryKeyType>;
  dayplanner: Record<string, QueryKeyType>;
  rdw: Record<string, QueryKeyType>;
}

export enum QUERY_KEY_COMPONENTS {
  Websocket = "websocket",
  UserData = "userData",
  AuthInfo = "authInfo",
  PinDropdownOptions = "pinDropdownOptions"
}

export const queryKeys: QueryKeys = {
  app: {
    user: ["authInfo"],
    websocket: ["websocket"]
  },
  dealers: {
    list: { origin: "backend", baseUrl: ENV.dealerBaseUrl, endpoint: "dealers/list?embed=dealer_location" }
  },
  dealer: {
    id: { origin: "local", component: "DealersLocationsDropdown", data: "selectedDealerId" },
    details: { origin: "local", component: "DealersLocationsDropdown", data: "selectedDealer" },
    documentation: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/documentations/list" }
  },
  location: {
    details: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "locations/details" },
    id: { origin: "local", component: "DealersLocationsDropdown", data: "selectedLocationId" }
  },
  appointments: {
    list: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "appointments/list_daily" },
    searchData: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/appointments/search" },
    specialIndicators: { origin: "local", component: "Appointments", data: "specialIndicators" },
    searchTerm: { origin: "local", component: "Appointments", data: "searchTerm" },
    carMakes: { origin: "local", component: "Appointments", data: "carMakes" },
    carMakeSearch: { origin: "local", component: "Appointments", data: "carMakeSearch" },
    tyreBrands: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/tyres/list_brands" },
    deliveryTypes: { origin: "backend", baseUrl: ENV.tyreteamBaseURL, endpoint: "/catalog/list_delivery_types" },
    tyreSearch: { origin: "backend", baseUrl: ENV.tyreteamBaseURL, endpoint: "/catalog/search" }
  },
  car: {
    details: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/cars/details" },
    appintmentHistory: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/cars/get_checks" },
    pinItems: { origin: "backend", baseUrl: ENV.appointmentBaseURL, endpoint: "/pins/list_by_car_id" },
    snoozeItems: { origin: "backend", baseUrl: ENV.appointmentBaseURL, endpoint: "/snoozes/list_by_car_id" }
  },
  appointmentDetails: {
    view: {
      origin: "backend",
      baseUrl: ENV.apiBase,
      endpoint: "/appointments/view",
      params: { embed: "appointment_status,customer_owner,customer_driver,car,customer_contract,customcomv2,pin,snooze,deskcom,keycom" }
    },
    checklist: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/checks/list" }
  },
  dayplanner: {
    appointments: { origin: "backend", baseUrl: ENV.dayplannerBaseUrl, endpoint: "/appointments/list" },
    mechanics: { origin: "backend", baseUrl: ENV.dayplannerBaseUrl, endpoint: "/mechanics/list" },
    searchData: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/appointments/search" },
    searchTerm: { origin: "local", component: "Dayplanner", data: "searchTerm" }
  },
  rdw: {
    details: { origin: "backend", baseUrl: ENV.apiBase, endpoint: "/cars/rdw" }
  }
};

export const staticLocalKey = (component: string) => ["static", { origin: "local", component }];

export const realtimeLocalKey = (component: string) => ["realtime", { origin: "local", component }];

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Checkbox, Form } from "semantic-ui-react";

import { TextEditor } from "components";
import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface SupportProps {
  tabId: string;
}

export const Support = ({ tabId }: SupportProps) => {
  const [value, setValue] = useState("");
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_support").message || "SUPPORT"}</h3>
      <div>
        <div className="row full mt-24">
          <Form.Field>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_enable_the_support_page").message || "Enable the Support page"}
            </p>
          </Form.Field>
        </div>

        <div className="row full mt-24">
          <TextEditor name="support-message" value={value} onValueChange={(value: string) => setValue(value)} />
        </div>
      </div>
    </Element>
  );
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

const useMCC = (car_id: number) => {
  const fetchMcc = async () => {
    const res = await ApiInstance.post("/cars/mcc_set_car", { car_key: car_id });
    return res.data;
  };

  const mccMutation = useMutation({
    mutationFn: fetchMcc,
    onError: error => {
      toast.error(error.message);
    }
  });

  return { mccMutation };
};

export default useMCC;

import moment from "moment";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { GridColumn } from "semantic-ui-react";

import {
  ActionModalSelectorData,
  Images,
  SNOOZE_DEPARTMENT,
  SNOOZE_STATUS,
  SNOOZE_TYPE,
  getSnoozeDepartmentData,
  getSnoozeStatusData,
  getSnoozeTypeData,
  useCan
} from "components";
import { QuestionResult, QuestionResultImage } from "models";
import SnoozeActions from "modules/AppointmentDetail/components/SnoozedItems/SnoozeActions";
import { useChecklist } from "modules/AppointmentDetail/hooks";
import { ITranslation } from "util/interfaces";

type SnoozeItemProps = {
  data: QuestionResult;
  noBorder?: boolean;
  t: ITranslation;
  onSelect: (data: ActionModalSelectorData) => void;
};

const SnoozeItem = ({ data, noBorder, onSelect, t }: SnoozeItemProps) => {
  const [showImages, setShowImages] = useState(false);
  const { updateCheckQuestionItem } = useChecklist(data.appointment_id);
  const canUpdateSnoozeItems = useCan("snooze", "update");

  const getSnoozeType = (snoozeType?: SNOOZE_TYPE) => {
    return getSnoozeTypeData(t).find(type => type.value === snoozeType)?.text || "";
  };

  const getSnoozeStatus = (snoozeStatus?: SNOOZE_STATUS) => {
    return getSnoozeStatusData(t).find(status => status.value === snoozeStatus)?.text || "";
  };

  const getSnoozeDepartment = (snoozeDepartment?: SNOOZE_DEPARTMENT) => {
    return getSnoozeDepartmentData(t).find(status => status.value === snoozeDepartment)?.text || "";
  };

  const handleDeleteImage = (image: QuestionResultImage, index: number) => {
    const updatedImage = { ...image, active: false };
    const updatedData = { ...data };
    (updatedData as QuestionResult).images[index] = updatedImage;
    updateCheckQuestionItem.mutate({ data: updatedData as QuestionResult });
  };
  const images = data?.images?.filter(item => item.active) || [];
  const handleImages = () => {
    setShowImages(prev => !prev);
  };

  const sortedSnoozeHistory = data.snooze_history?.sort((history1, history2) => history2.created_on.localeCompare(history1.created_on));
  const snoozeItem = sortedSnoozeHistory?.length && sortedSnoozeHistory[0].snooze_status_id !== SNOOZE_STATUS.Deleted ? sortedSnoozeHistory[0] : null;

  return (
    <>
      <GridColumn className="content">
        <p>{data.title}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeType(snoozeItem?.snooze_type_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{snoozeItem?.snooze_date ? moment(snoozeItem.snooze_date).format("DD-MM-YYYY") : ""}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeStatus(snoozeItem?.snooze_status_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{getSnoozeDepartment(snoozeItem?.snooze_department_id)}</p>
      </GridColumn>
      <GridColumn className="content">
        <p>{snoozeItem?.note || ""}</p>
      </GridColumn>
      <GridColumn className="content last">
        <SnoozeActions data={data} onSelect={onSelect} toggleShowImages={handleImages} />
      </GridColumn>
      {showImages && (
        <div className="snooze-images">
          <Images data={images} onDeleteRequest={handleDeleteImage} canDelete={canUpdateSnoozeItems} />
        </div>
      )}
      <div className={`divider ${noBorder ? "no-border" : ""}`} />
    </>
  );
};
export default withTranslation()(SnoozeItem);

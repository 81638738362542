import { ReactElement } from "react";
import { Button, Icon } from "semantic-ui-react";

import { mockStatusData, mockToggleData, mockToggleOffData } from "modules/Styleguide/mockData";

export const Buttons = (): ReactElement => {
  return (
    <div className="buttons-styleguide">
      <h2>Buttons</h2>
      <div className="buttons_container">
        <h4>Default</h4>
        <div className="default-buttons">
          <Button color="green">Primary</Button>
          <Button color="blue">Blue</Button>
          <Button color="red">Red</Button>
          <Button color="orange">Orange</Button>
          <Button color="aquamarine">Aquamarine</Button>
          <Button color="black">Black</Button>
          <Button color="light">Light</Button>
          <Button color="grey">Grey</Button>

          <a href="#">Link</a>

          <Button color="green">
            Save
            <Icon className="check" />
          </Button>

          <Button color="blue">
            Save
            <Icon className="check" />
          </Button>

          <Button color="grey">
            Close
            <Icon className="xmark" />
          </Button>

          <Button color="light">
            Close
            <Icon className="xmark" />
          </Button>

          <Button color="red">
            Delete
            {/* <Icon name="trash" /> */}
            <Icon className="trash" />
          </Button>
        </div>
        <br />
        <div className="default-buttons">
          <Button disabled color="green">
            Primary
          </Button>
          <Button disabled color="blue">
            Blue
          </Button>
          <Button disabled color="red">
            Red
          </Button>
          <Button disabled color="orange">
            Orange
          </Button>
          <Button disabled color="aquamarine">
            Aquamarine
          </Button>
          <Button disabled color="black">
            Black
          </Button>
          <Button disabled color="light">
            Light
          </Button>
          <Button disabled color="grey">
            Grey
          </Button>

          <a href="#">Link</a>

          <Button disabled color="green">
            Save
            <Icon className="check" />
          </Button>

          <Button disabled color="blue">
            Save
            <Icon className="check" />
          </Button>

          <Button disabled color="grey">
            Close
            <Icon className="xmark" />
          </Button>

          <Button disabled color="light">
            Close
            <Icon className="xmark" />
          </Button>

          <Button disabled color="red">
            Delete
            <Icon className="trash" />
          </Button>
        </div>

        <h4>Outline</h4>
        <div className="outline-buttons">
          <Button basic color="green">
            Primary
          </Button>
          <Button basic color="blue">
            Blue
          </Button>
          <Button basic color="red">
            Red
          </Button>
          <Button basic color="orange">
            Orange
          </Button>
          <Button basic color="aquamarine">
            Aquamarine
          </Button>
          <Button basic color="black">
            Black
          </Button>
          <Button basic color="light">
            Light
          </Button>
          <Button basic color="grey">
            Grey
          </Button>

          <a href="#">Link</a>

          <Button basic color="green">
            Save
            <Icon className="check" />
          </Button>

          <Button basic color="blue">
            Save
            <Icon className="check" />
          </Button>

          <Button basic color="grey">
            Close
            <Icon className="xmark" />
          </Button>

          <Button basic color="light">
            Close
            <Icon className="xmark" />
          </Button>

          <Button basic color="red">
            Delete
            <Icon className="trash" />
          </Button>
        </div>

        <h4>Secondary</h4>

        <div className="default-buttons">
          <Button secondary color="green">
            Primary
          </Button>
          <Button secondary color="blue">
            Blue
          </Button>
          <Button secondary color="red">
            Red
          </Button>
          <Button secondary color="orange">
            Orange
          </Button>
          <Button secondary color="aquamarine">
            Aquamarine
          </Button>
          <Button secondary color="black">
            Black
          </Button>
          <Button secondary color="grey">
            Grey
          </Button>
        </div>

        <h4>Secondary with icons</h4>

        <div className="default-buttons">
          <Button secondary color="green">
            Primary
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="blue">
            Blue
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="red">
            Red
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="orange">
            Orange
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="aquamarine">
            Aquamarine
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="black">
            Black
            <Icon className="file pdf" />
          </Button>
          <Button secondary color="grey">
            Grey
            <Icon className="file pdf" />
          </Button>
        </div>

        <h4>Large buttons</h4>
        <div className="default-buttons">
          <Button size="large" color="green">
            Large button
          </Button>

          <Button basic size="large" color="green">
            Large button
          </Button>
        </div>
        <br />
        <div className="default-buttons">
          <Button disabled size="large" color="green">
            Large button
          </Button>
        </div>

        <h4>Small buttons</h4>
        <div className="default-buttons">
          <Button size="tiny" color="green">
            Small button
          </Button>

          <Button basic size="tiny" color="green">
            Small button
          </Button>
        </div>
        <br />
        <div className="default-buttons">
          <Button disabled size="tiny" color="green">
            Small button
          </Button>
        </div>

        <h4>Status icon button</h4>
        <div className="default-buttons">
          {mockStatusData.map((item: any, index: number) => {
            return (
              <div key={`filter-status-${index}`} className="sf-btn">
                <Button color="orange" className={`${item.className} af`}>
                  <Icon className={item.icon} />
                </Button>
              </div>
            );
          })}

          <div className="sf-btn">
            <Button color="orange" className={`afPurpleBg af`}>
              BO
            </Button>
          </div>
        </div>

        {/* <Button icon="calendar check" color="orange" /> */}
        <br />
        <div className="default-buttons">
          {mockStatusData.map((item: any, index: number) => {
            return (
              <div key={`filter-status-${index}`} className="sf-btn">
                <Button color="orange" className={`${item.className} af`} disabled>
                  <Icon className={item.icon} />
                </Button>
              </div>
            );
          })}
          <div className="sf-btn">
            <Button color="orange" className={`afPurpleBg af`} disabled>
              BO
            </Button>
          </div>
          {/* <Button icon="calendar check" disabled /> */}
        </div>

        <h3>Leads</h3>

        <h4>Toggle Button Active</h4>
        <div className="default-buttons nogap">
          {mockToggleData.map((item: any, index: number) => {
            const isFirst = index === 0;
            const isLast = index === mockToggleData.length - 1;
            const isBetween = !isFirst && !isLast;
            return (
              <div key={`filter-status-${index}`} className="sf-btn">
                <Button className={`${item.className} af${isFirst ? " left-rounded" : ""}${isLast ? " right-rounded" : ""}${isBetween ? " no-rounded" : ""}`}>
                  <Icon className={item.icon} />
                </Button>
              </div>
            );
          })}
        </div>

        <h4>Toggle Button Off</h4>
        <div className="default-buttons nogap">
          {mockToggleOffData.map((item: any, index: number) => {
            const isFirst = index === 0;
            const isLast = index === mockToggleData.length - 1;
            const isBetween = !isFirst && !isLast;
            return (
              <div key={`filter-status-${index}`} className="sf-btn">
                <Button className={`${item.className} af${isFirst ? " left-rounded" : ""}${isLast ? " right-rounded" : ""}${isBetween ? " no-rounded" : ""}`}>
                  <Icon className={item.icon} />
                </Button>
              </div>
            );
          })}
        </div>

        <h3>Warranty Dashboard</h3>
        <h4>Icon Buttons</h4>
        <div className="default-buttons">
          <Button className={`wdSkyblue af`} color="orange">
            <Icon className="thumbtack" />
          </Button>

          <Button className={`wdSkyblue af`} color="orange">
            <Icon className="shield halved" />
          </Button>

          <Button className={`wdSkyblue af`} color="orange">
            <Icon className="circle r" />
          </Button>
        </div>

        <h4>Button with icons</h4>
        <div className="default-buttons">
          <Button icon labelPosition="left">
            <Icon name="filter" color="green" />
            More filters
          </Button>

          <Button icon labelPosition="left" color="grey">
            <Icon name="pause" color="black" />
            Pause
          </Button>

          <Button icon labelPosition="right" color="grey">
            <Icon name="play" color="black" />
            Play
          </Button>
        </div>
        <br />
        <div className="default-buttons">
          <Button disabled icon labelPosition="left">
            <Icon name="filter" color="green" />
            More filters
          </Button>

          <Button disabled icon labelPosition="left" color="grey">
            <Icon name="pause" color="black" />
            Pause
          </Button>

          <Button disabled icon labelPosition="right" color="grey">
            <Icon name="play" color="black" />
            Play
          </Button>
        </div>

        <h4>Status button</h4>
        <div className="default-buttons">
          <Button className="-appointment-status" size="small">
            <Icon name="phone" color="green" />
            Contact
          </Button>

          <Button active className="-appointment-status" size="small">
            <Icon name="phone" color="green" />
            Contact active
          </Button>
        </div>
        <br />
        <div className="default-buttons">
          <Button className="-appointment-status" size="small">
            <Icon className="arrows minimize" color="green" />
            Collapse
          </Button>

          <Button className="-appointment-status" size="small">
            <Icon className="arrows maximize" color="green" />
            Expand
          </Button>

          <Button className="-appointment-status icon" size="small">
            <Icon className="arrows minimize" color="green" />
          </Button>

          <Button className="-appointment-status icon" size="small">
            <Icon className="arrows maximize" color="green" />
          </Button>

          <Button className="-appointment-status icon" size="small">
            PSA
            <Icon className="arrow up right from square" color="green" />
          </Button>

          <Button className="-appointment-status icon" size="small">
            DBB
            <Icon className="arrow up right from square" color="green" />
            <div className="refresh-button">
              <Icon className="arrows rotate" color="black" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Popup, Step } from "semantic-ui-react";

import "modules/Styleguide/components/AppointmentSteps/AppointmentSteps.scss";
import { ITranslation } from "util/interfaces";

export const AppointmentSteps = (): ReactElement => {
  const t = useTranslation().t as ITranslation;

  const statusesConfig = {
    3: {
      disabled: false
    },
    4: {
      disabled: true
    },
    5: {
      disabled: true
    },
    8: {
      disabled: false
    },
    9: {
      disabled: true
    },
    12: {
      disabled: true
    }
  };

  return (
    <div className="AppointmentSteps">
      <h3>Appoinment steps</h3>
      <div className="AppointmentSteps__content">
        <div className="AppointmentSteps__content_steps">
          <div>
            <Step.Group size="tiny">
              <Step className={`-active-status-active`} link disabled={statusesConfig[8].disabled}>
                <Icon style={{ fontSize: "2em" }} className="calculator regular" />
                <Popup content={t("v8_calculating").message} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title className="title">{t("v8_calculating").message || "Calculating"}</Step.Title>
                </Step.Content>
              </Step>

              <Step className={`-active-status-none`} link disabled={statusesConfig[3].disabled}>
                <Icon style={{ fontSize: "2em" }} className="check regular " />
                <Popup content={t("v8_proposal_ok").message} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title>{t("v8_proposal_ok").message || "Proposal Accepted"}</Step.Title>
                </Step.Content>
              </Step>

              <Step className={`-active-status-none`} link disabled={statusesConfig[9].disabled}>
                <Icon style={{ fontSize: "2em" }} className="envelope regular " />
                <Popup content={t("v8_contact").message} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title>{t("v8_contact").message || "Contact"}</Step.Title>
                </Step.Content>
              </Step>

              <Step className={`-active-status-none`} link disabled={statusesConfig[12].disabled}>
                <Icon className="reply regular" style={{ fontSize: "2em" }} />
                <Popup content={t("v8_customer_replied").message} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title>{t("v8_customer_replied").message || "Customer replied"}</Step.Title>
                </Step.Content>
              </Step>

              <Step className={`-active-status-none`} link disabled={statusesConfig[4].disabled}>
                <Icon style={{ fontSize: "2em" }} className=" user check regular" />
                <Popup content={t("v8_customer_ok").message || "Customer OK"} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title>{t("v8_customer_ok").message || "Customer Approved"}</Step.Title>
                </Step.Content>
              </Step>

              <Step disabled={statusesConfig[5].disabled} link className={`-active-status-none`}>
                <Icon style={{ fontSize: "2em" }} className="car regular" />
                <Popup content={t("v8_car_ok").message} trigger={<Icon style={{ fontSize: "2em" }} />}></Popup>
                <Step.Content>
                  <Step.Title>{t("v8_car_ok").message || "Car finished"}</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import ApiInstance from "util/Api";

type SearchDropdownProp = {
  onClose?: () => void;
  onSelect: (id: number) => void;
};

type Mutation = {
  id: number;
};

const useSearchDropdown = ({ onClose, onSelect }: SearchDropdownProp) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { selectedLocation } = useDealersLocations();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickAboveThreshold = event.clientY <= 50;
      const isOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target as Node);
      if (isOutsideDropdown && !isClickAboveThreshold) {
        onClose && onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const { mutate: importAppointment, isPending } = useMutation({
    mutationKey: ["keyloopImportMutation"],
    mutationFn: async ({ id }: Mutation) => {
      const response = await ApiInstance.post("/scheduler/import", { dealer_location_id: selectedLocation?.id, dms_nr: id }, ENV.dmsV3ImportBaseUrl);
      return response;
    },
    onSuccess: data => {
      onSelect(data.appointment_id);
    }
  });

  return { dropdownRef, importAppointment, isPending };
};

export default useSearchDropdown;

import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { QuestionImages } from "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ChecklistSection/useChecklistSection";
import "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ImageGallery/ImageGallery.scss";
import useImageGallery from "modules/AppointmentDetail/components/Interventions/components/PinOverview/components/ImageGallery/useImageGallery";

type Props = {
  lettersMap: QuestionImages[];
};

const numOfImagesPerRow = 4;

export const ImageGallery = ({ lettersMap }: Props) => {
  if (!lettersMap?.length) return null;
  const { chunkLetters } = useImageGallery();

  return (
    <div className="ImageGallery">
      <div className="images-container">
        {chunkLetters(lettersMap, numOfImagesPerRow).map((row, idx) => (
          <div className="image-container-row" key={idx}>
            {row.map((item, index) => (
              <div className="image-container" key={index + row.length}>
                {item.url && (
                  <>
                    <div className="img-label">
                      <div className="img-icon-wrapper">
                        <FontAwesomeIcon icon={faCamera} size="sm" />
                        <span className="report-car-images-letter">{item.letterCode}</span>
                      </div>
                    </div>
                    <img alt={`img-${item.letterCode}`} className="image-style" src={item.url} />
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { WebSocketComponent, WebSocketStatus } from "components";
import ENV from "config/Env";
import { useAuth, useDealersLocations, useUser } from "hooks";
import { DealerLocation } from "models";
import Service from "modules/Auth/service";
import apiInstance from "util/Api";
import { hideZohoWhenNotNeeded, reloadZohoWhenClosed } from "util/common";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

export const useApp = () => {
  const { logout } = useAuth();
  const user = useUser();
  const router = useLocation();

  const { dealerMutation, selectedLocation } = useDealersLocations();
  const { data: websocket } = useQuery({ queryKey: staticLocalKey(QUERY_KEY_COMPONENTS.Websocket) });

  const checkUser = async () => {
    if (apiInstance.authToken) {
      const user = await Service.getUser();
      if (!user) {
        logout();
      }
    }
  };

  const setZohoDeskASAP = (): void => {
    if (!(window as any).ZohoHCAsap && user) {
      (window as any).ZohoHCAsapSettings = {
        hideLauncherIcon: false,
        ticketsSettings: {
          preFillFields: {
            email: { defaultValue: user.email },
            departmentId: { defaultValue: ENV.zohoDepartmentId },
            contactId: { defaultValue: `${user.first_name} ${user.last_name}` }
          }
        },
        userInfo: { jwtToken: sessionStorage._zohoAsapJwt }
      };

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = ENV.zohoASAP;
      script.defer = true;

      script.onload = () => {
        (window as any).ZohoHCAsapReady = function (o: () => void) {
          (window as any).ZohoHCAsap__asyncalls = (window as any).ZohoHCAsap__asyncalls || [];
          if ((window as any).ZohoHCAsapReadyStatus) {
            o && (window as any).ZohoHCAsap__asyncalls.push(o);
            for (let s = 0; s < (window as any).ZohoHCAsap__asyncalls.length; s++) {
              const n = (window as any).ZohoHCAsap__asyncalls[s];
              n && n();
            }
            (window as any).ZohoHCAsap__asyncalls = null;
          } else o && (window as any).ZohoHCAsap__asyncalls.push(o);
        };

        (window as any).ZohoHCAsapReady(() => {
          const locale = localStorage.getItem("locale");
          (window as any).ZohoHCAsap.Actions.UpdateSettings({
            lang: locale ? locale.replace("-", "_") : "en_GB"
          });
          reloadZohoWhenClosed(() => hideZohoWhenNotNeeded(router.pathname));
        });
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    (window as any).ZohoHCAsapReady &&
      (window as any).ZohoHCAsapReady(() => {
        (window as any).ZohoHCAsap.Action("hideLauncher");
      });
  }, [router.pathname]);

  useEffect(() => {
    if (user) {
      dealerMutation.mutate();
      setZohoDeskASAP();
    }
  }, [user]);

  useEffect(() => {
    if (!selectedLocation || !(websocket as WebSocketStatus)?.isConnected) return;
    WebSocketComponent.subscribeToLocationQueue((selectedLocation as DealerLocation).notifier_key);

    return () => {
      if ((websocket as WebSocketStatus)?.isConnected) {
        WebSocketComponent.unsubscribeFromLocationQueue((selectedLocation as DealerLocation).notifier_key);
      }
    };
  }, [selectedLocation, (websocket as WebSocketStatus)?.isConnected]);
};

import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { WebsocketMessageListener } from "components";
import ENV from "config/Env";
import { useRealTimeQueryOptions, useUpdate } from "hooks";
import { Car } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useCarDetails = (id: string) => {
  const { requestUpdate } = useUpdate();
  const queryClient = useQueryClient();

  const carDetailsQueryKey = ["realtime", { ...queryKeys.car.details, id }];
  const listeners: WebsocketMessageListener[] = [
    {
      model: "Car",
      action: "update",
      id: Number(id),
      callback: message => {
        const carData: Car | undefined = queryClient.getQueryData(carDetailsQueryKey);
        if (carData) {
          const update = message.data as Car;
          const updatedCarData = { ...carData, ...update };
          queryClient.setQueryData(carDetailsQueryKey, updatedCarData);
          requestUpdate();
        }
      }
    }
  ];

  const getCarDetail = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint } = queryKey[1] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { id: Number(id) }, ENV.carBaseUrl);
    return res.data;
  };

  const clearCarPageQueryCache = () => {
    queryClient.removeQueries({ queryKey: carDetailsQueryKey });
  };

  useEffect(() => {
    return () => clearCarPageQueryCache();
  }, []);

  return useRealTimeQueryOptions({ queryKey: carDetailsQueryKey, queryFn: getCarDetail, listeners });
};

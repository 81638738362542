import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { QuestionResultData } from "components";
import "components/Snooze/Snooze.scss";
import SnoozeModal from "components/SnoozeModal";
import { SnoozeItemWithHistory } from "modules/CarDetails/utils";
import { ITranslation } from "util/interfaces";

export enum SNOOZE_STATUS {
  Deleted = 0,
  New,
  InProgress,
  Approved,
  Declined,
  Fixed,
  Done
}

export enum SNOOZE_TYPE {
  NextVisitCustomer = 1,
  MakeOffer,
  MakeNewAppointment,
  RemindCustomer
}

type SnoozeProps = {
  data: SnoozeItemWithHistory;
  showOwnerInfo?: boolean;
};

export const Snooze = ({ data, showOwnerInfo }: SnoozeProps) => {
  if (data.snooze_status_id === SNOOZE_STATUS.Deleted) return null;
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslation().t as ITranslation;

  return (
    <>
      <div className="Snooze">
        {showOwnerInfo && data.user && (
          <Popup
            hoverable
            content={
              <div className="owner-info">
                <p className="owner-title">{t("v8_owner").message || "Owner"}</p>
                <p className="owner-email">
                  {`${t("v8_private_email").message || "Private email"}:`} {data.user.email}
                </p>
              </div>
            }
            header={`${data.user.first_name} ${data.user.last_name}`}
            trigger={
              <Button className="tiLightPurple af" color="orange" disabled={!Object.values(SNOOZE_TYPE).includes(data.snooze_type_id)}>
                <Icon className="user" />
              </Button>
            }
          />
        )}
        <Button className="wdSkyblue af" color="orange" onClick={() => setIsOpen(true)} disabled={!Object.values(SNOOZE_TYPE).includes(data.snooze_type_id)}>
          <Icon className="clock" />
        </Button>
      </div>
      <SnoozeModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data={{ ...data, description: data.note, snooze_history: data.history } as unknown as QuestionResultData}
      />
    </>
  );
};

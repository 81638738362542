import { useEffect } from "react";

export const useScrollPageTop = (behavior = "auto") => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: behavior as ScrollBehavior
    });
  }, []);
};

import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

const updateQueryKey = ["updateTime"];

export const useUpdate = () => {
  const queryClient = useQueryClient();

  const { data: checkUpdate } = useQuery({ queryKey: updateQueryKey });

  const requestUpdate = () => queryClient.setQueryData(updateQueryKey, moment().toISOString());

  return { checkUpdate, requestUpdate };
};

import { useEffect, useRef, useState } from "react";
import { Popup } from "semantic-ui-react";

import "components/EllipsisText/EllipsisText.scss";

type EllipsisTextProps = {
  text: string;
  containerHeight?: number;
};

export const EllipsisText = ({ text, containerHeight = 24 }: EllipsisTextProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState("auto");
  const [isTruncated, setIsTruncated] = useState(true);

  useEffect(() => {
    const textRefWidth = textRef.current?.clientWidth;
    if (textRefWidth && width === "auto") {
      setWidth(`${textRefWidth}px`);
    }
    if (textRefWidth) {
      setIsTruncated(textRefWidth < textRef.current?.scrollWidth);
    }
  }, [textRef.current, text, width]);

  return (
    <Popup
      disabled={!isTruncated}
      content={text}
      trigger={
        <div
          ref={textRef}
          style={{ height: containerHeight, lineHeight: `${containerHeight}px`, width, whiteSpace: width === "auto" ? "wrap" : "nowrap" }}
          className="EllipsisText"
        >
          {text}
        </div>
      }
    />
  );
};

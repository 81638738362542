import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Icon, Label, Popup } from "semantic-ui-react";

import { StatusCell } from "components";
import { DAYPLANNER_COLUMNS, useDayplannerContext } from "modules/Dayplanner";
import "modules/Dayplanner/components/AppointmentCard/AppointmentCard.scss";
import { CarQuickView, InterventionQuickView, NotificationIcons } from "modules/Dayplanner/components/AppointmentCard/components";
import { Appointment, MechanicWithAppointments } from "modules/Dayplanner/utils";
import { classNames } from "util/common";

type AppointmentCardProps = {
  appointment: Appointment;
  column: DAYPLANNER_COLUMNS;
  mechanic?: MechanicWithAppointments;
  collapsedColumns?: DAYPLANNER_COLUMNS[];
};

type AppointmentContentProps = {
  appointment: Appointment;
  column: DAYPLANNER_COLUMNS;
  collapsedColumns?: DAYPLANNER_COLUMNS[];
  mechanic?: MechanicWithAppointments;
};

const AppointmentContent = ({ appointment, column, mechanic, collapsedColumns }: AppointmentContentProps) => {
  const history = useHistory();
  const { onDragAppointmentStart, onDraggedAppointmentEnterAnotherAppointment } = useDayplannerContext();

  const planningWorkStop = appointment.car_return_time || appointment.planning_work_stop;
  const isDraggable = [DAYPLANNER_COLUMNS.Unassigned, DAYPLANNER_COLUMNS.Mechanics].includes(column);
  const boardItemClassNames = classNames({
    "-bg-color-red": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 0),
    "-bg-color-yellow": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 60 && moment(planningWorkStop).diff(moment(), "minutes") > 0),
    "-appointment-internal": appointment.internal,
    "-panic": appointment.has_panic,
    "-popup": collapsedColumns?.includes(column),
    "-inactive": column === DAYPLANNER_COLUMNS.Mechanics && appointment.inactive
  });

  const onAppointmentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (isDraggable) return;
    history.push(`/appointments/${appointment.id}`);
  };

  const handleCarDetailLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    history.push(`/cardetails/${appointment.car_id}`);
  };

  const handleAppointmentDetailLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    history.push(`/appointments/${appointment.id}`);
  };

  return (
    <div
      key={`app-${appointment.id}`}
      className={`BoardItem ${boardItemClassNames}`}
      onClick={onAppointmentClick}
      data-mechanic-order={appointment.assigned_mechanic_order}
      data-mechanic-id={appointment.assigned_mechanic || null}
      data-appointment-id={appointment.id}
      draggable={isDraggable}
      onDragStart={e => onDragAppointmentStart(e, appointment, mechanic)}
      onDragEnter={e => isDraggable && onDraggedAppointmentEnterAnotherAppointment(e)}
      onMouseDown={e => e.stopPropagation()}
      onDragLeave={e => e.preventDefault()}
      onDragOver={e => e.preventDefault()}
    >
      <div className="BoardItem-appointment-status -expanded">
        <StatusCell
          value={{
            id: appointment.appointment_status_identifier,
            car_out_of_shop: appointment.car_out_of_shop,
            customcom_status: appointment.customcom_status,
            check_paused_at: appointment.check_paused_at
          }}
        />

        {appointment.kiosk_label_number && (
          <Label className="BoardItem-kiosk-label">
            <Icon className="tag" />
            <span>{appointment.kiosk_label_number}</span>
          </Label>
        )}
      </div>

      <div className="BoardItem-info -cursor-move">
        <CarQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer" onClick={handleCarDetailLink}>
              {appointment.reg_number ? `${appointment.reg_number}` : ""}
            </span>
          }
        />

        {appointment.reg_number ? " - " : ""}

        <InterventionQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer wo-nr" onClick={handleAppointmentDetailLink}>
              {appointment.wo_nr}
            </span>
          }
        />
      </div>

      <div className="BoardItem-last-update">{planningWorkStop && moment(planningWorkStop).format("DD-MM-YYYY HH:mm")}</div>

      <div className="BoardItem-bottom-elements">
        <NotificationIcons appointment={appointment} />

        {[DAYPLANNER_COLUMNS.Unassigned, DAYPLANNER_COLUMNS.CarReady].includes(column) && <div className="BoardItem-planning-mechanic"></div>}
      </div>
    </div>
  );
};

export const AppointmentCard = ({ appointment, column, mechanic, collapsedColumns }: AppointmentCardProps) => {
  if (appointment.inactive && column !== DAYPLANNER_COLUMNS.Mechanics) return null;
  const isMechanicColumn = column === DAYPLANNER_COLUMNS.Mechanics;
  return (
    <div className={`AppointmentCard ${isMechanicColumn ? "-mechanic" : ""}`}>
      {collapsedColumns?.includes(column) ? (
        <Popup
          basic
          hoverable
          size="large"
          trigger={
            <Link className="BoardItem-appointment-status -collapsed" to={`/appointments/${appointment.id}`}>
              <StatusCell
                value={{
                  id: appointment.appointment_status_identifier,
                  car_out_of_shop: appointment.car_out_of_shop,
                  customcom_status: appointment.customcom_status,
                  check_paused_at: appointment.check_paused_at
                }}
              />
            </Link>
          }
          content={
            <div className="BoardItem-appointment-status">
              <AppointmentContent appointment={appointment} mechanic={mechanic} column={column} collapsedColumns={collapsedColumns} />
            </div>
          }
          style={{ padding: "0", boxShadow: "none", border: "none" }}
        />
      ) : (
        <AppointmentContent mechanic={mechanic} appointment={appointment} column={column} collapsedColumns={collapsedColumns} />
      )}
    </div>
  );
};

import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { WebsocketMessageListener } from "components";
import { useRealTimeQueryOptions, useUpdate } from "hooks";
import {
  Appointment,
  AppointmentAttachment,
  AppointmentNote,
  AppointmentStatusHistory,
  Car,
  CustomerCommunication,
  Intervention,
  KeylockerRemark,
  KioskRemark,
  PinModel
} from "models";
import { StatusData } from "modules/AppointmentDetail/components";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useAppointmentData = (id: string) => {
  const queryClient = useQueryClient();
  const { requestUpdate } = useUpdate();

  const appointmentDetailsViewKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id }
    }
  ];

  const listeners: WebsocketMessageListener[] = [
    {
      model: "Appointment",
      action: "update",
      id: Number(id),
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (appointmentData) {
          const update = message.data as Appointment;
          const updatedAppointment = { ...appointmentData, ...update };
          queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
          requestUpdate();
        }
      }
    },
    {
      model: "Car",
      action: "update",
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        const carData = appointmentData?.car;
        if (carData?.id === message.id) {
          const update = message.data as Car;
          const updatedCarData = { ...carData, ...update };
          const updatedAppointment = { ...appointmentData, car: updatedCarData };
          queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
          requestUpdate();
        }
      }
    },
    {
      model: "Pin",
      action: "append",
      filter: {
        appointment_id: Number(id)
      },
      callback: message => {
        const pinData = message.data as PinModel;
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (appointmentData) {
          if (!appointmentData?.interventions?.length) {
            return;
          }
          const updatedInterventions = [...appointmentData.interventions].map(intervention => {
            if (intervention.id === pinData.intervention_id) {
              return {
                ...intervention,
                pin_history: intervention.pin_history?.length ? intervention.pin_history.concat(pinData) : [pinData],
                pinned: true
              };
            }
            return intervention;
          });
          const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
          queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
          requestUpdate();
        }
      }
    },
    {
      model: "Pin",
      action: "delete",
      filter: {
        appointment_id: Number(id)
      },
      callback: message => {
        const pinData = message.data as PinModel;
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (appointmentData) {
          if (!appointmentData?.interventions?.length) {
            return;
          }
          const updatedInterventions = [...appointmentData.interventions].map(intervention => {
            if (intervention.id === pinData.intervention_id) {
              return {
                ...intervention,
                pin_history: [],
                pinned: false
              };
            }
            return intervention;
          });
          const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
          queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
          requestUpdate();
        }
      }
    },
    {
      model: "AppointmentNote",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.notes) {
          return;
        }
        const updatedNotes = appointmentData.notes.map(note => {
          if (note.id === (message.data as AppointmentNote).id) {
            return { ...note, ...(message.data as AppointmentNote) };
          }
          return note;
        });
        const updatedAppointment = { ...appointmentData, notes: updatedNotes };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.notes) {
          return;
        }
        const updatedNotes = appointmentData.notes.filter(note => note.id !== (message.data as AppointmentNote).id);
        const updatedAppointment = { ...appointmentData, notes: updatedNotes };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "AppointmentNote",
      action: "create",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData) {
          return;
        }
        const existingNote = appointmentData.notes?.find(note => note.id === (message.data as AppointmentNote).id);
        if (!existingNote) {
          const updatedNotes = appointmentData.notes?.length ? [...appointmentData.notes, message.data as AppointmentNote] : [message.data as AppointmentNote];
          const updatedAppointment = { ...appointmentData, notes: updatedNotes };
          queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        }
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.interventions) {
          return;
        }
        const updatedInterventions = appointmentData.interventions.map(intervention => {
          if (intervention.id === (message.data as Intervention).id) {
            return { ...intervention, ...(message.data as Intervention) };
          }
          return intervention;
        });
        const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.interventions) {
          return;
        }
        const updatedInterventions = appointmentData.interventions.filter(intervention => intervention.id !== (message.data as Intervention).id);
        const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "Intervention",
      action: "create",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData) {
          return;
        }
        const updatedInterventions = appointmentData.interventions?.length
          ? [...appointmentData.interventions, message.data as Intervention]
          : [message.data as Intervention];
        const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "AppointmentAttachment",
      action: "create",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData) {
          return;
        }
        const updatedAttachments = appointmentData.attachments?.length
          ? [...appointmentData.attachments, message.data as AppointmentAttachment]
          : [message.data as AppointmentAttachment];
        const updatedAppointment = { ...appointmentData, attachments: updatedAttachments };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "AppointmentAttachment",
      action: "delete",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.attachments) {
          return;
        }
        const updatedAttachments = appointmentData.attachments.filter(attachment => attachment.id !== (message.data as AppointmentAttachment).id);
        const updatedAppointment = { ...appointmentData, attachments: updatedAttachments };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "KeyLockerRemark",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        const keylocker_communications = appointmentData?.keylocker_communications.map(comm =>
          comm.id === (message.data as KeylockerRemark).keylocker_communication_id ? { ...comm, remark: message.data } : comm
        );

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointmentData, keylocker_communications });
        requestUpdate();
      }
    },
    {
      model: "KioskRemark",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        const kiosk_communications = appointmentData?.kiosk_communications.map(comm =>
          comm.id === (message.data as KioskRemark).kiosk_communication_id ? { ...comm, remark: message.data } : comm
        );

        queryClient.setQueryData(appointmentDetailsViewKey, { ...appointmentData, kiosk_communications });
        requestUpdate();
      }
    },
    {
      model: "AppointmentStatusHistory",
      action: "create",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        const update = message.data as AppointmentStatusHistory;

        if (!update) return;

        const status_history = appointmentData?.status_history?.length ? appointmentData.status_history.concat(update) : [update];

        const updatedAppointment = { ...appointmentData, status_history };

        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    },
    {
      model: "AppointmentStatusHistory",
      action: "update",
      filter: { appointment_id: Number(id) },
      callback: message => {
        const update = message.data as AppointmentStatusHistory;
        if (!update) return;
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData?.status_history) {
          return;
        }
        const updatedStatusHistory = appointmentData.status_history.map(history => {
          if (history.id === update.id) {
            return { ...history, ...update };
          }
          return history;
        });
        const updatedAppointment = { ...appointmentData, status_history: updatedStatusHistory };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
      }
    },
    {
      model: "CustomerCommunication",
      filter: { appointment_id: Number(id) },
      action: "update",
      callback: message => {
        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
        if (!appointmentData) return;
        const update = message.data as CustomerCommunication;

        const updatedAppointment = { ...appointmentData };
        if (!updatedAppointment.customer_communication) {
          updatedAppointment.customer_communication = update;
        } else {
          const updatedCustomerCommData = { ...updatedAppointment.customer_communication, ...update } as CustomerCommunication;
          updatedAppointment.customer_communication = updatedCustomerCommData;
        }

        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointment);
        requestUpdate();
      }
    }
  ];

  const getAppointment = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint, params } = queryKey[1] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { ...params, id: Number(params?.id) });
    return res.data;
  };

  const query = useRealTimeQueryOptions({
    queryKey: appointmentDetailsViewKey,
    queryFn: getAppointment,
    listeners
  });

  const updateStatus = async (data: StatusData) => {
    const updateData = {
      id: Number(id),
      ...data
    };

    const res = await ApiInstance.post("/appointments/update", updateData);
    return res.data;
  };

  const statusMutation = useMutation({
    mutationFn: updateStatus,
    onError: (e, _variables, _context) => {
      toast.error(e.message);
    }
  });

  const getAppointmentCancelReasons = async () => {
    const res = await ApiInstance.get("/appointments/cancel_reasons");
    return res.data;
  };

  const cancelReasonsQuery = useQuery({
    queryKey: ["realtime", queryKeys.appointmentDetails.cancelReasons],
    queryFn: getAppointmentCancelReasons,
    retry: false
  });

  const cancelAppointment = async (reason: any) => {
    const updateData = {
      appointment_id: Number(id),
      ...reason
    };
    const res = await ApiInstance.post("/appointments/cancel", updateData);
    return res.data;
  };

  const cancelAppointmentMutation = useMutation({
    mutationFn: cancelAppointment,
    onError: (e, _variables) => {
      toast.error(e.message);
      const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      queryClient.setQueryData(appointmentDetailsViewKey, appointmentData);
    }
  });

  const restoreAppointment = async () => {
    const res = await ApiInstance.post("/appointments/restore", { appointment_id: Number(id) });
    return res.data;
  };

  const restoreAppointmentMutation = useMutation({
    mutationFn: restoreAppointment
  });

  return {
    data: query.data,
    loading: query.isFetching,
    error: query.error,
    updateStatus: statusMutation,
    cancelReasonsQuery,
    cancelAppointment: cancelAppointmentMutation,
    restore: restoreAppointmentMutation
  };
};

import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Checkbox, Form, Input, TextArea } from "semantic-ui-react";

import "modules/CustomerCom/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface OnlineCheckInProps {
  tabId: string;
}

export const OnlineCheckIn = ({ tabId }: OnlineCheckInProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_online_check_in").message || "ONLINE CHECK IN"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <Input value="" placeholder={t("v8_email").message || "E-mail"} fluid />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <Input value="" placeholder={t("v8_phone").message || "Phone"} fluid type="Phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-16">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <TextArea value="" placeholder={`${t("v8_hello").message || "Hello"}!`} />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_success_text").message || "Success text"}</label>
            <TextArea value="" placeholder={`${t("v8_thank_you").message || "Thank You"}!`} />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8} className="pr-12">
            <label>{t("v8_days_prior_to_appointment").message || "Send how many days prior to the appointment date"}</label>
            <Input value="" placeholder="7" fluid />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-24">
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_enable_sending_SMS").message || "Enable sending SMS"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_enable_remarks").message || "Enable remarks"}
            </p>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_key_locker_pin").message || "Send Key Locker PIN"}
            </p>
          </Form.Field>

          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_ask_customer_enter_name").message || "Ask the Customer to enter his name"}
            </p>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={8} />
          <Form.Field width={8}>
            <p className="items-center">
              <span>
                <Checkbox toggle />
              </span>
              {t("v8_key_locker_pin_for_checkin").message || "Send Key Locker PIN in Online check-in"}
            </p>
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
